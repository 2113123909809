/** Import Externals */
import React, { useEffect, useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import QrCodeIcon from "assets/svg/qrCode";

/** Import Internals */

/** Import Context */
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import { getIconSport } from "data/utils";
import theme from "../../config/uiTheme";

/** Import CSS */
import "./slider.css";

const useStyles = makeStyles({
  container: {
    margin: "3vh 2vW",
    position: "relative",
    webkitPerspective: 2000,
    webkitBackfaceVisibility: "hidden"
  },
  space: {
    width: "33%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  smallSpace: {
    width: "25%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      margin: "0 auto",
    },
  },
  card: {
    width: "90%",
    background: theme.palette.common.white,
    margin: "0 auto",
    borderRadius: "10px",
    cursor: "pointer",
    paddingBottom: "4px",
    [theme.breakpoints.down("md")]: {
      margin: "4px auto",
      width: "100%",
    },
  },
  picture: {
    width: "100%",
    borderRadius: "10px",
  },
  textContainer: {
    width: "90%",
    margin: "0 auto",
    color: theme.palette.common.darkGrey,
  },
  line: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0",
  },
  dateText: {
    display: "flex",
    alignItems: "center",
    width: "55%",
    color: theme.palette.common.darkGrey,
    fontSize: "0.9rem",
    fontWeight: "300",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  city: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.grey,
    fontSize: "0.9rem",
    fontWeight: "300",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardTitle: {
    fontSize: "1.2rem",
    fontWeight: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  cardSport: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.grey,
    borderRadius: "10px",
    padding: "0 5%",
    fontWeight: 400,
    marginTop: "5%",
  },
  sportText: {
    fontWeight: 500,
    marginLeft: "10px",
  },
  clubText: {
    fontSize: "0.9rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.common.grey,
    marginBottom: "10px",
  },
  scanButton: {
    display: "flex",
    width: "90%",
    margin: "0 auto 10px",
    background: theme.palette.common.darkGrey,
    borderRadius: "10px",
    justifyContent:"center",
  },
  scanLogo: {
    margin: "5px 5%",
  },
  scanTextContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "auto 0",
  },
  scanText: {
    fontWeight: "600",
    color: theme.palette.common.white,
    marginRight:"4px"
  },
  registers: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: theme.palette.common.grey,
    marginBottom: "10px",
  },
  commandPrev: {
    color: theme.palette.common.darkGrey,
    zIndex: 2,
    position: "absolute",
    left: "-20px",
    bottom: "20%",
    cursor: "pointer",
    transitionDuration: ".3s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  commandNext: {
    position: "absolute",
    color: theme.palette.common.darkGrey,
    zIndex: 2,
    right: "-30px",
    bottom: "20%",
    cursor: "pointer",
    transitionDuration: ".3s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  fewEvents: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      maxHeight: "90vh",
      overflow: "scroll",
    },
  },
  noEvent: {
    color: theme.palette.common.lightGrey,
    fontSize: "2rem",
    fontWeight: 600,
    textAlign: "center",
    margin: "10% 0",
  },
});

function EventImagesSlider({ array, small, scan }) {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const { setGlobalContext, mobileScreen } = useContext(GlobalContext);

  const customSlider = useRef();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(array);
  }, [array]);

  const handleGoEvent = (event_id) => {
    history.push(`/events/${event_id}`);
    window.scrollTo(0, 0);
  };

  const handleScanOpen = (event) => {
    setGlobalContext({ scanOpen: true, scanEvent: event });
  };

  const gotoNext = () => {
    customSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customSlider.current.slickPrev();
  };

  const items = [];

  events.forEach((event, index) => {
    let eventSportArray = event.event_sport.split("/");
    let iconSport = getIconSport(eventSportArray[0], eventSportArray[1]);
    items.push(
      <div key={index} className={small ? styles.smallSpace : styles.space}>
        <div className={styles.card}>
          <div onClick={() => handleGoEvent(event.event_id)}>
            <img
              className={styles.picture}
              src={event.image[0].image_file}
              alt={event.event_name}
            />
            <div className={styles.textContainer}>
              <div className={styles.line}>
                <div className={styles.dateText}>
                  <AccessTimeIcon fontSize="small" />{" "}
                  {moment(event.event_start).format("l")}
                </div>
                <div className={styles.city}>
                  <LocationOnIcon fontSize="small" /> {event.event_city}
                </div>
              </div>
              <div className={styles.cardTitle}>{event.event_name}</div>
              <div className={styles.cardSport}>
                <div className={styles.sportIcon}>{iconSport}</div>
                <div className={styles.sportText}>
                  {t(`sports.${eventSportArray[0]}`)}
                </div>
              </div>
              {event.event_registers !== undefined ? (
                <div className={styles.line}>
                  <div className={styles.clubText}>{event.club_name}</div>
                  <div className={styles.registers}>
                    <div>
                      {event.event_registers === ""
                        ? "0"
                        : event.event_registers.includes(",")
                        ? event.event_registers.split(",").length
                        : "1"}{" "}
                      / {event.event_places}
                    </div>
                    <PeopleIcon />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {scan ? (
            <div
              className={styles.scanButton}
              onClick={() => handleScanOpen(event)}
            >
              <div className={styles.scanLogo}>
                <QrCodeIcon color={theme.palette.common.white} />
              </div>
              <div className={styles.scanTextContainer}>
                <div className={styles.scanText}>
                  {t(`myEvents.scanButton1`)}
                </div>
                <div className={styles.scanText}>
                  {t(`myEvents.scanButton2`)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  });

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: small ? 4 : 3,
    slidesToScroll: small ? 4 : 3,
    arrows: false,
    variableHeight: true,
  };

  return (
    <div className={styles.container}>
      {items.length > 0 ? (
        (items.length > 3 && !small && !mobileScreen) ||
        (items.length > 5 && small && !mobileScreen) ? (
          <>
            <div className={styles.commandPrev} onClick={gotoPrev}>
              <ArrowBackIosIcon fontSize="large" />
            </div>
            <div className={styles.commandNext} onClick={gotoNext}>
              <ArrowForwardIosIcon fontSize="large" />
            </div>
            <Slider {...settings} className={styles.slider} ref={customSlider}>
              {items.map((value) => {
                return value;
              })}
            </Slider>
          </>
        ) : (
          <div className={styles.fewEvents}>
            {items.map((value) => {
              return value;
            })}
          </div>
        )
      ) : (
        <div className={styles.noEvent}>{t("profile.noSlider")}</div>
      )}
    </div>
  );
}

export default EventImagesSlider;
