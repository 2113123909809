import React from "react";

const LogoWWW = (props) => {
  let size = props.size || 50;
  let color = props.color || "#000";

  return (
    <svg height={size} width={size} viewBox="0 0 420 420" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path stroke={color} stroke-width="26" d="M209 15a195 195 0 1 0 2 0z" />
      <path
        stroke={color}
        stroke-width="18"
        d="M210 15v390m195-195H15M59 90a260 260 0 0 0 302 0m0 240a260 260 0 0 0-302 0M195 20a250 250 0 0 0 0 382m30 0a250 250 0 0 0 0-382"
      />
    </svg>
  );
};

export default LogoWWW;
