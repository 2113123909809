/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    background: theme.palette.common.white,
    margin: "10px auto",
    borderRadius: "10px",
    padding: "20px 0",
  },
  title: {
    fontWeight:600,
    fontSize:"1.3rem",
    textAlign:"center",
    margin:"20px 0",
  },
  text:{
    width: "80%",
    margin: "0 auto",
  },
  subtitle:{
    fontWeight:500,
    fontSize:"1.1rem",
    width: "80%",
    margin: "20px auto",
  },
  subtext:{
    width: "80%",
    margin: "0 auto",
  },
});

function CguText() {
  const styles = useStyles();

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
        <div className={styles.title}>
            {t("cgu.title1")}
        </div>
        <div className={styles.text}>
            {t("cgu.text1")}
        </div>
        <div className={styles.title}>
            {t("cgu.title2")}
        </div>
        <div className={styles.text}>
            {t("cgu.text2")}
        </div>
        <div className={styles.title}>
            {t("cgu.title3")}
        </div>
        <div className={styles.text}>
            {t("cgu.text3")}
        </div>
        <div className={styles.title}>
            {t("cgu.title4")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle1")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText1")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle2")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText2")}
        </div>
        <div className={styles.title}>
            {t("cgu.title5")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle3")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText3")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle4")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText4")}
        </div>
        <div className={styles.title}>
            {t("cgu.title6")}
        </div>
        <div className={styles.text}>
            {t("cgu.text6")}
        </div>
        <div className={styles.title}>
            {t("cgu.title7")}
        </div>
        <div className={styles.text}>
            {t("cgu.text7")}
        </div>
        <div className={styles.title}>
            {t("cgu.title8")}
        </div>
        <div className={styles.text}>
            {t("cgu.text8")}
        </div>
        <div className={styles.title}>
            {t("cgu.title9")}
        </div>
        <div className={styles.text}>
            {t("cgu.text9")}
        </div>
        <div className={styles.title}>
            {t("cgu.title10")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle101")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText101")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle102")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText102")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle103")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText103")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle104")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText104")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle105")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText105")}
        </div>
        <div className={styles.subtitle}>
            {t("cgu.subTitle106")}
        </div>
        <div className={styles.subtext}>
            {t("cgu.subText106")}
        </div>
    </div>
  );
}

export default CguText;
