/** Import Externals */
import React, {useState} from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";

import CancelIcon from "@mui/icons-material/Cancel";

/** Import Internals */
import Loader from "assets/svg/loader";

/** Import Context */

/** Import API */
import { StripePayment } from "data/api/stripeQuerys";

/** Import Utils */
import theme from "../../../config/uiTheme";

const useStyles = makeStyles({
  container: {
    background: theme.palette.common.white,
    display: "flex",
    flexDirection: "row",
    borderRadius: "20px",
    alignItems: "center",
    margin: "5vh auto",
    width: "70%",
    boxShadow: "0px 3px 13px rgba(30, 25, 37, 0.6)",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  all: {
    margin: "20px 5% auto",
    padding: "5%",
    position: "relative",
    width: "80%",
  },
  center: {
    width: "100%",
  },
  textContainer: {
    color: theme.palette.common.darkGrey,
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  text: {
    fontSize: "1.3rem",
    fontWeight: 500,
    margin: "5% 0",
  },
  infosContainer: {
    border: "1px solid",
    borderColor: theme.palette.common.black,
    borderRadius: "10px",
    padding: "10px",
    margin: "0 0 5% 0",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  line: {
    margin: "10px 0",
  },
  lineTitle: {
    fontWeight: 500,
  },
  warning: {
    fontWeight: 500,
    color: theme.palette.common.red,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    widht: "100%",
    justifyContent: "space-around",
  },
  buttonsYes: {
    padding: "2% 4%",
    background: theme.palette.common.green,
    color: theme.palette.common.white,
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "2rem",
    textAlign: "center",
    widht: "40%",
    cursor: "pointer",
  },
  icon: {
    position: "absolute",
    top: 5,
    right: -5,
    color: theme.palette.common.darkGrey,
    cursor: "pointer",
  },
});

function PayingEventModal({ subscribe, close, event }) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  let sport = event.event_sport.split("/");
  let start = moment(event.event_start).format("lll");

  const handleStripePayment = () => {
    setLoading(true)
    StripePayment(event)
      .then((resStripePayment) => {
        if(resStripePayment?.data?.includes("https://checkout.stripe.com")){
          window.location.replace(resStripePayment.data);
        } else {
          setLoading(false)
        }
      })
      .catch((error) => {
        setLoading(false)
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.all}>
        <div className={styles.center}>
          <div className={styles.textContainer}>
            <div className={styles.title}>
              {t("components.payingEventModal.cool")}
            </div>
            <div className={styles.text}>
              {t("components.payingEventModal.recap")}
            </div>
            <div className={styles.infosContainer}>
              <div className={styles.line}>
                <span className={styles.lineTitle}>
                  {t("components.payingEventModal.sport")} :
                </span>{" "}
                {t(`sports.${sport[0]}`)}
              </div>
              <div className={styles.line}>
                <span className={styles.lineTitle}>
                  {t("components.payingEventModal.address")} :
                </span>{" "}
                {event.event_address} {event.event_city} {event.event_zip_code},{" "}
                {event.event_country}
              </div>
              <div className={styles.line}>
                <span className={styles.lineTitle}>
                  {t("components.payingEventModal.start")} :
                </span>{" "}
                {start}
              </div>
              <div className={styles.line}>
                <span className={styles.lineTitle}>
                  {t("components.payingEventModal.price")} :
                </span>{" "}
                {event.event_price}€
              </div>
              <div className={styles.warning}>
                {t("components.payingEventModal.warning")}
              </div>
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            {loading ?
            <Loader /> :
            <div className={styles.buttonsYes} onClick={handleStripePayment}>
              {t("components.payingEventModal.yes")}
            </div>
            }
          </div>
        </div>
        <div>
          <CancelIcon className={styles.icon} onClick={close} />
        </div>
      </div>
    </div>
  );
}

export default PayingEventModal;
