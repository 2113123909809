/** Import Externals */
import React, {useState} from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

/** Import Internals */
import Slider from "components/slider";
import Down from "assets/svg/down";

/** Import Context */

/** Import API */

/** Import Utils */

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection:"column",
  },
  sectionName:{
    display:"flex",
    cursor:"pointer",
    fontWeight:"600",
    fontSize:"1.2rem",
    alignContent:"center",
    margin:"20px 5%"
  },
  sectionIcon:{
    marginLeft: "10px",
    transitionDuration: ".35s",
  },
  iconRotation:{
    transform: "rotate(-90deg)",
  }
});

function MyFuturEvents({events}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [toggle, setToggle] = useState(true);

  const handleToggleEvents = () => {
    setToggle(!toggle);
  }

  return (
    <div className={styles.container}>
      <div className={styles.sectionName} onClick={handleToggleEvents}>
        {t(`myEvents.futurEvents`)}
        <div className={clsx(styles.sectionIcon, !toggle ? styles.iconRotation : null)}>
         <Down size={15} />
        </div>
      </div>

       {toggle ? 
        <Slider array={events}/> : null
       }
    </div>
  );
}

export default MyFuturEvents;

