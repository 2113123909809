/** Import Externals */
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";

import Select from "react-select";
import SearchIcon from "@mui/icons-material/Search";

/** Import Internals */
import Background from "assets/pngJpg/eventListBackground.jpg";

/** Import Context */
import { SearchContext } from "data/context/searchContext";
import { GlobalContext } from "data/context/globalContext";

/** Import API */
import { GetSports } from "data/api/sportQuerys";

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    background: theme.palette.common.grey,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  darkContainer: {
    backgroundColor: `rgba(0, 0, 0, 0.7)`,
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "default",
  },
  title: {
    fontSize: "2.5rem",
    fontWeight: 500,
    color: theme.palette.common.white,
    marginTop: "5vh",
  },
  subTitle: {
    fontSize: "1.2rem",
    fontWeight: 400,
    color: theme.palette.common.white,
    marginTop: "2vh",
  },
  inputContainer: {
    display: "flex",
    margin: "5vh 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  searchButton: {
    color: theme.palette.common.white,
    background: theme.palette.common.green,
    borderRadius: "0 5px 5px 0",
    padding: ".5vh",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      borderRadius: "5px",
      textAlign: "center",
      margin: "10px 0",
    },
  },
});

function Filters() {
  const styles = useStyles();
  const { t } = useTranslation();

  const { setSearchContext, sportsList, searchToggle, city } =
    useContext(SearchContext);

  const { mobileScreen } = useContext(GlobalContext);

  const [sportsListState, setSportsListState] = useState([]);
  const [dateListState, setDateListState] = useState([]);
  const [locationState, setLocationState] = useState("Paris, France");
  const [rangeListState, setRangeListState] = useState(20);

  useEffect(() => {
    if (sportsList.length === 0) {
      GetSports()
        .then((res) => {
          setSearchContext({ sportsList: res.data });
          let optionsSport = res.data.map((sport) => {
            return {
              label: t(`sports.${sport.sport_name}`),
              value: sport.sport_name,
            };
          });
          optionsSport.unshift({
            label: t(`search.allSports`),
            value: "all",
          });
          setSportsListState(optionsSport);
        })
        .catch((err) => {});
    } else {
      let optionsSport = sportsList.map((sport) => {
        return {
          label: t(`sports.${sport.sport_name}`),
          value: sport.sport_name,
        };
      });
      optionsSport.unshift({
        label: t(`search.allSports`),
        value: "all",
      });
      setSportsListState(optionsSport);
    }
    let dateArray = ["week", "nextWeek", "month", "nextMonth", "all"];
    let optionsDate = dateArray.map((date) => {
      if (date === "all") {
        return {
          label: t(`search.allDates`),
          value: date,
        };
      } else {
        return {
          label: t(`search.${date}`),
          value: date,
        };
      }
    });
    setDateListState(optionsDate);
    let rangeArray = [5, 10, 20, 50, 100, 100000];
    let optionsRange = rangeArray.map((range) => {
      if (range === 100000) {
        return {
          label: t(`search.maxRange`),
          value: range,
        };
      } else {
        return {
          label: `${range} km`,
          value: range,
        };
      }
    });
    setRangeListState(optionsRange);
    setLocationState(city);
  }, []);

  const handleChangeDate = (e) => {
    const date = new Date();
    let d = new Date();
    let day = d.getDay();
    let diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    d.setDate(diff + 7);
    d.setHours(0, 0, 0, 0);

    switch (e.value) {
      case "week":
        setSearchContext({ dateStart: new Date(), dateEnd: new Date(d) });
        break;
      case "nextWeek":
        setSearchContext({
          dateStart: new Date(d),
          dateEnd: new Date(d.setDate(diff + 14)),
        });
        break;
      case "month":
        setSearchContext({
          dateStart: new Date(),
          dateEnd: new Date(date.getFullYear(), date.getMonth() + 1),
        });
        break;
      case "nextMonth":
        setSearchContext({
          dateStart: new Date(date.getFullYear(), date.getMonth() + 1),
          dateEnd: new Date(date.getFullYear(), date.getMonth() + 2),
        });
        break;
      case "all":
        setSearchContext({
          dateStart: new Date(),
          dateEnd: new Date(d.setDate(diff + 365)),
        });
        break;
      default:
    }
  };

  const handleChangeSport = (e) => {
    setSearchContext({ sport: e.value });
  };

  const handleChangeLocation = (e) => {
    geocodeByPlaceId(e.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setSearchContext({ city: e.label, lat: lat, lng: lng });
      });
    setLocationState(e.label);
  };

  const handleChangeRange = (e) => {
    setSearchContext({ range: e.value });
  };

  const handleSearch = () => {
    setSearchContext({ searchToggle: !searchToggle });
  };

  return (
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className={styles.darkContainer}>
        <div className={styles.subContainer}>
          <div className={styles.title}>{t(`components.eventList.title`)}</div>
          <div className={styles.subTitle}>
            {t(`components.eventList.subtitle`)}
          </div>
          <div className={styles.inputContainer}>
            <Select
              options={dateListState}
              onChange={handleChangeDate}
              placeholder={t(`search.week`)}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  cursor: "pointer",
                  borderRadius: mobileScreen ? "5px" : "5px 0 0 5px",
                  margin: mobileScreen ? "2px auto" : "0",
                  width: mobileScreen ? "80vW" : "200px",
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                input: (provided) => ({
                  ...provided,
                  color: theme.palette.common.darkGrey,
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontWeight: state.isSelected ? 600 : 400,
                  color: state.isSelected
                    ? theme.palette.common.darkGrey
                    : theme.palette.common.grey,
                  background: theme.palette.common.white,
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.common.darkGrey,
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: theme.palette.common.darkGrey,
                  fontWeight: 600,
                }),
              }}
            />
            <Select
              options={sportsListState}
              onChange={handleChangeSport}
              placeholder={t(`search.allSports`)}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  cursor: "pointer",
                  borderRadius: mobileScreen ? "5px" : "0",
                  margin: mobileScreen ? "2px auto" : "0",
                  width: mobileScreen ? "80vW" : "200px",
                }),
                menu: (base) => ({
                  ...base,
                  width: "max-content",
                  minWidth: "100%",
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                input: (provided) => ({
                  ...provided,
                  color: theme.palette.common.darkGrey,
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontWeight: state.isSelected ? 600 : 400,
                  color: state.isSelected
                    ? theme.palette.common.darkGrey
                    : theme.palette.common.grey,
                  background: theme.palette.common.white,
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.common.darkGrey,
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: theme.palette.common.darkGrey,
                  fontWeight: 600,
                }),
              }}
            />
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              selectProps={{
                placeholder: `${t(`search.noLocation`)}`,
                locationState,
                onChange: handleChangeLocation,
                styles: {
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    cursor: "pointer",
                    borderRadius: mobileScreen ? "5px" : "0",
                    margin: mobileScreen ? "2px auto" : "0",
                    width: mobileScreen ? "80vW" : "max-content",
                    minWidth: "200px",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    color: "red",
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    display:"none",
                  }),
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                  loadingIndicator: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: theme.palette.common.darkGrey,
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: theme.palette.common.darkGrey,
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: theme.palette.common.darkGrey,
                    fontWeight: 600,
                  }),
                },
              }}
            />
            <Select
              options={rangeListState}
              onChange={handleChangeRange}
              placeholder="20 km"
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  cursor: "pointer",
                  borderRadius: mobileScreen ? "5px" : "0",
                  margin: mobileScreen ? "2px auto" : "0",
                  width: mobileScreen ? "80vW" : "150px",
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: "none",
                }),
                input: (provided) => ({
                  ...provided,
                  fontWeight: 600,
                }),
                option: (provided, state) => ({
                  ...provided,
                  fontWeight: state.isSelected ? 600 : 400,
                  color: state.isSelected
                    ? theme.palette.common.darkGrey
                    : theme.palette.common.grey,
                  background: theme.palette.common.white,
                  cursor: "pointer",
                  "&:hover": {
                    color: theme.palette.common.darkGrey,
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: theme.palette.common.darkGrey,
                  fontWeight: 600,
                }),
              }}
            />
            <div className={styles.searchButton} onClick={handleSearch}>
              <SearchIcon size={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filters;
