/** Import Externals */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import AccountBoxIcon from "@mui/icons-material/AccountBox";

/** Import Internals */

/** Import Context */

/** Import API */
import { FetchMultiUsers } from "data/api/userQuerys";

/** Import Utils */
import theme from "config/uiTheme";

/** Import CSS */
import "./slider.css";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.common.grey,
    width: "10vw",
    margin: "10px",
    padding: "1vh",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "25%",
    },
  },
  image: {
    maxWidth: "5vW",
    maxHeight: "5vW",
    borderRadius: "200px",
    margin: "0 auto 1vh",
    [theme.breakpoints.down("md")]: {
      maxWidth: "50px",
      maxHeight: "50px",
    },
  },
  nameContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: "1rem",
    fontWeight: 500,
  },
});

function EventList(props) {
  const styles = useStyles();

  let history = useHistory();

  const [registers, setRegisters] = useState([]);

  useEffect(() => {
    FetchMultiUsers(props.array)
      .then((result) => {
        let registers = result.data.map((el) => {
          let imageRegister;
          el.image.forEach((image) => {
            if (image.image_name.includes("profile")) {
              return (imageRegister = image.image_file);
            }
          });
          return {
            id: el.id,
            first_name: el.first_name,
            last_name: el.last_name,
            image: imageRegister,
          };
        });
        setRegisters(registers);
      })
      .catch((err) => {});
  }, [props.array]);

  const handleGoProfil = (userId) => {
    history.push(`/profile/${userId}`);
    window.scrollTo(0, 0);
  };

  const items = [];

  registers.forEach((register, index) => {
    items.push(
      <div
        key={index}
        className={styles.card}
        onClick={() => handleGoProfil(register.id)}
      >
        {register.image !== "" ? (
          <img
            className={styles.image}
            src={register.image}
            alt={register.first_name + " " + register.last_name}
          ></img>
        ) : (
          <AccountBoxIcon sx={{ fontSize: 100 }} />
        )}
        <div className={styles.nameContainer}>
          <div className={styles.name}>{register.first_name}</div>
          <div className={styles.name}>{register.last_name}</div>
        </div>
      </div>
    );
  });

  return <div className={styles.container}>{items}</div>;
}

export default EventList;
