/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";

/** Import Internals */
import TopBar from "components/topBar";
import Filters from "./filters";
import ListEvents from "./listEvents";
import Footer from "components/footer";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "../../config/uiTheme";

const useStyles = makeStyles({
  container: { 
    display: "flex",
    flexDirection:"column",
    width:"100%",
    background: theme.palette.common.darkWhite,
  },
});

function Events() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <TopBar />
      <Filters />
      <ListEvents />
      <Footer />
    </div>
  );
}

export default Events;
