import React from "react";

const SaveIcon = (props) => {
  let size = props.size || 50;
  let color = props.color || "#4d4d4d"

  return (
    <svg height={size/2} width={size} viewBox="0 0 469.333 469.333" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M466.208 88.458 380.875 3.125c-2-2-4.708-3.125-7.542-3.125H42.667C19.146 0 0 19.135 0 42.667v384c0 23.531 19.146 42.667 42.667 42.667h384c23.521 0 42.667-19.135 42.667-42.667V96a10.665 10.665 0 0 0-3.126-7.542zM106.667 21.333h234.667v128c0 11.76-9.563 21.333-21.333 21.333H128c-11.771 0-21.333-9.573-21.333-21.333v-128zM384 448H85.333V256H384v192zm64-21.333c0 11.76-9.563 21.333-21.333 21.333h-21.333V245.333a10.66 10.66 0 0 0-10.667-10.667h-320A10.66 10.66 0 0 0 64 245.333V448H42.667c-11.771 0-21.333-9.573-21.333-21.333v-384c0-11.76 9.563-21.333 21.333-21.333h42.667v128C85.333 172.865 104.479 192 128 192h192c23.521 0 42.667-19.135 42.667-42.667v-128h6.25L448 100.417v326.25z" />{" "}
        <path fill={color} d="M266.667 149.333h42.667a10.66 10.66 0 0 0 10.667-10.667V53.333a10.66 10.66 0 0 0-10.667-10.667h-42.667A10.66 10.66 0 0 0 256 53.333v85.333a10.66 10.66 0 0 0 10.667 10.667zM277.333 64h21.333v64h-21.333V64z" />{" "}
    </svg>

  );
};

export default SaveIcon;