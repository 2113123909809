/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import CancelIcon from "@mui/icons-material/Cancel";

/** Import Internals */
import Calendar from "assets/svg/calendar";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "../../../config/uiTheme";

const useStyles = makeStyles({
  container: {
    background: theme.palette.common.white,
    display: "flex",
    flexDirection: "row",
    borderRadius: "20px",
    alignItems: "center",
    margin: "5% auto",
    boxShadow: "0px 3px 13px rgba(30, 25, 37, 0.6)",
    width:"70%",
    [theme.breakpoints.down("md")]: {
      width:"90%",
      margin: "15vh auto",
    },    
  },
  all: {
    margin: "auto 5%",
    padding: "5%",
    position: "relative",
    width: "100%",
  },
  center: {
    width: "100%",
  },
  textContainer: {
    color: theme.palette.common.darkGrey,
    display: "flex",
    flexDirection: "column",
    height: "50%",
    margin: "0 auto",
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  text: {
    fontSize: "1.3rem",
    fontWeight: 500,
    margin: "5% 0",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    widht: "100%",
    justifyContent: "space-around",
  },
  buttonsNo: {
    padding: "4% 8%",
    border: "2px solid",
    borderColor: theme.palette.common.darkGrey,
    color: theme.palette.common.darkGrey,
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "1.2rem",
    textAlign: "center",
    widht: "100%",
    marginRight: "5%",
    cursor: "pointer",
  },
  buttonsYes: {
    padding: "4% 8%",
    background: theme.palette.common.darkGrey,
    color: theme.palette.common.white,
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "1.2rem",
    textAlign: "center",
    widht: "40%",
    cursor: "pointer",
  },
  icon: {
    position: "absolute",
    top: 10,
    right: 10,
    color: theme.palette.common.darkGrey,
    cursor: "pointer",
  },
});

function ConfirmModal(props) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.all}>
        <div className={styles.center}>
          <Calendar />
          <div className={styles.textContainer}>
            <div className={styles.title}>
              {t("components.confirmModal.wait")}
            </div>
            <div className={styles.text}>
              {props.type === "delete"
                ? t("components.confirmModal.areYouSureDelete")
                : t("components.confirmModal.areYouSureUnregister")}
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonsNo} onClick={props.close}>
              {props.type === "delete"
                  ? t("components.confirmModal.noDelete")
                  : t("components.confirmModal.noUnregister")}
            </div>
            <div className={styles.buttonsYes} onClick={props.unsubscribe}>
              {props.type === "delete"
                  ? t("components.confirmModal.yesDelete")
                  : t("components.confirmModal.yesUnregister")}
            </div>
          </div>
        </div>
        <div>
          <CancelIcon className={styles.icon} onClick={props.close} />
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;
