/** Import Externals */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */
import Slider from "components/slider";
import Loader from "assets/svg/loader";

/** Import Context */

/** Import API */
import { GetNextEvents } from "data/api/eventQuerys";

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    width: "95%",
    margin: "5% auto",
    minHeight: "30vh",
  },
  topSection: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 600,
    color: theme.palette.common.darkGrey,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  nextEvents: {
    color: theme.palette.common.green,
    fontWeight: 600,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  },
  eventSection: {
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  loader:{
    textAlign:"center",
    margin: "15vh auto",
  },
  noEvent:{
    textAlign:"center",
    margin: "14vh auto",
    color: theme.palette.common.lightGrey,
    fontSize: "2rem",
    fontWeight: 600,
  }
});

function NextEvents() {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const [eventList, setEventList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    GetNextEvents()
      .then((res) => {
        setEventList(res.data);
        if (res.status === 200) {
          setLoader(false);
        }
      })
      .catch((error) => {});
  }, []);

  const handleGoOtherEvents = () => {
    history.push("/events");
    window.scrollTo(0, 0);
  };
    
  return (
    <div className={styles.container}>
      <div className={styles.topSection}>
        <div className={styles.title}>{t("home.nextEvents.nextEvents")}</div>
        <div className={styles.nextEvents} onClick={handleGoOtherEvents}>
          {t("home.nextEvents.moreEvents")}
        </div>
      </div>
      <div className={styles.eventSection}>

        {loader ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : 
        eventList.length ? (
            <Slider array={eventList}/>
        ) : (
          <div className={styles.noEvent}>
            {t("home.nextEvents.noEvents")}
          </div>
        )}
      </div>
    </div>
  );
}

export default NextEvents;
