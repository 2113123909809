/** Import Internals */
import { LaunchQuery } from './queryLauncher'

export const SendMessageToJutsu = (formData) => {
  const url = process.env.REACT_APP_SEND_MESSAGE_JUTSU

  const postObject = {
    name: formData.name,
    email: formData.email,
    message: formData.message,
  }

  return LaunchQuery(url, 'post', postObject)
}

export const SendMessageToClub = (formData, clubId, language) => {
  const url = process.env.REACT_APP_SEND_MESSAGE_CLUB

  const postObject = {
    name: formData.name,
    email: formData.email,
    message: formData.message,
    clubId: clubId,
    language: language,
  }

  return LaunchQuery(url, 'post', postObject)
}
