/** Import Externals */
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

/** Import Internals */
import Loader from "assets/svg/loader";

/** Import Context */

/** Import API */
import { FormSignUp } from "data/api/connectionQuerys";

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
    width: "70%",
    cursor: "default",
    marginTop: "5vh",
  },
  inputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "1vh 0",
  },
  inputLabel: {
    marginBottom: "1vh",
  },
  inputText: {
    border: "1px solid",
    borderRadius: "10px",
    borderColor: theme.palette.common.lightGrey,
    fontSize: "16px",
    height: "35px",
  },
  forgot: {
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    margin: "1.7vW 0",
  },
  logInButton: {
    width: "100%",
    textAlign: "center",
    color: theme.palette.common.green,
    fontWeight: 600,
    border: "2px solid",
    borderRadius: "10px",
    borderColor: theme.palette.common.green,
    padding: ".5vW",
    cursor: "pointer",
  },
  error: {
    color: theme.palette.common.red,
  },
  success: {
    color: theme.palette.common.green,
  },
});

function SignUpForm() {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleChangeFirstName = (e) => {
    setFirstName(e.target.value);
  };
  const handleChangeLastName = (e) => {
    setLastName(e.target.value);
  };
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleSignUp = () => {
    setLoading(true);
    setError("");
    setSuccess("");
    FormSignUp(email, password, firstName, lastName)
      .then((res) => {
        const {
          xsrfToken,
          accessToken,
          accessTokenExpiresIn,
          refreshToken,
          refreshTokenExpiresIn,
        } = res.data;
        localStorage.setItem("xsrfToken", xsrfToken);
        document.cookie = `accessToken=${accessToken};${accessTokenExpiresIn};path=/`;
        document.cookie = `refreshToken=${refreshToken};${refreshTokenExpiresIn};path=/`;
        history.push(`/`);
      })
      .catch((error) => {
        if (error.response.data.message === "Missing fields") {
          setError(t("login.errors.missingField"));
        }
        else if (error.response.data.message === "This account already exist") {
          setError(t("login.errors.accountExist"));
        }
        else if (error.response.data.message === "Wrong password") {
          setError(t("login.errors.wrongPsw"));
        } else {
          setError(t("login.errors.error"));
        }
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <label className={styles.inputLabel}>{t("login.firstName")}</label>
        <input
          className={styles.inputText}
          type="text"
          value={firstName}
          onChange={(e) => handleChangeFirstName(e)}
        />
      </div>
      <div className={styles.inputContainer}>
        <label className={styles.inputLabel}>{t("login.lastName")}</label>
        <input
          className={styles.inputText}
          type="text"
          value={lastName}
          onChange={(e) => handleChangeLastName(e)}
        />
      </div>
      <div className={styles.inputContainer}>
        <label className={styles.inputLabel}>{t("login.email")}</label>
        <input
          className={styles.inputText}
          type="email"
          value={email}
          onChange={(e) => handleChangeEmail(e)}
        />
      </div>
      <div className={styles.inputContainer}>
        <label className={styles.inputLabel}>{t("login.password")}</label>
        <input
          className={styles.inputText}
          type="password"
          value={password}
          onChange={(e) => handleChangePassword(e)}
        />
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.logInButton} onClick={handleSignUp}>
          {loading ? <Loader /> : t("login.signUp")}
        </div>
      </div>
      <div className={styles.statusContainer}>
        {error ? (
          <div className={styles.error}>{error}</div>
        ) : success ? (
          <div className={styles.success}>{success}</div>
        ) : null}
      </div>
    </div>
  );
}

export default SignUpForm;
