/** Import Externals */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";

import Slider from "react-slick";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

/** Import Internals */

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "../../../config/uiTheme";

/** Import CSS */
import './slider.css';

const useStyles = makeStyles({
  container: {
    width:"100%",
    position: "relative",
  },
  slider:{
    borderRadius:"10px",
    width:"100%",
  },
  image:{
    borderRadius: "10px",
    width:"100%",
  },
  commandPrev:{
    color: theme.palette.common.white,
    zIndex:2,
    position:"absolute",
    left: "5%",
    top: "50%",
    cursor:"pointer",
    transitionDuration: ".3s",
    "&:hover": {
      transform: "scale(1.1)"
    },
  },
  commandNext:{
    position:"absolute",
    color: theme.palette.common.white,
    zIndex:2,
    right: "5%",
    top: "50%",
    cursor: "pointer",
    transitionDuration: ".3s",
    "&:hover": {
      transform: "scale(1.1)"
    },
  },
});

function EventImagesSlider(props) {
  const styles = useStyles();

  const customSlider = useRef();

  const [images, setImages] = useState([]);

  useEffect(() => {
      setImages(props.array);
  }, []);

  const items = [];

  images.forEach((image, index) => {
    items.push(
        <div key={index} className={styles.card}>
            <img key={index} className={styles.image} alt="event" src={image.image_file} />
        </div>
    );
  });

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const gotoNext = () => {
    customSlider.current.slickNext()
  }

  const gotoPrev = () => {
    customSlider.current.slickPrev()
  }

  return (
    <div className={styles.container}>
      <div className={styles.commandPrev} onClick={gotoPrev}> <ArrowBackIosIcon fontSize="large" /> </div>
      <div className={styles.commandNext} onClick={gotoNext}> <ArrowForwardIosIcon fontSize="large" /> </div>
      <Slider {...settings} className={styles.slider} ref={customSlider}>
        {items.map((value) => {
          return (
              value
          );
        })}
      </Slider>
    </div>
  );
}

export default EventImagesSlider;
