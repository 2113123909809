import React from "react";

const Capoeira = (props) => {
  let size = props.size || 50;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26 57h-5V40l5-2z" fill="#f9bb4b" />
      <path d="M31 25v2l-10 4v10l17-7 3-9z" fill="#ffd782" />
      <path d="m36 17-26-5v5l21 4v4h10v-4l16-8V8z" fill="#cfdfe8" />
      <path d="m21 31-8-13.429 6.5 1.239L26 29z" fill="#f9bb4b" />
      <path d="M7 32h10v10H7z" fill="#ffd782" />
      <path
        d="M3 32h4v10H3zM51 40h2c0-7.069-1.657-11.92-4.926-14.417A8.965 8.965 0 0 0 40.882 24H31v2h9.882c.5.159 5.118 2.063 5.118 17h2c0-10.143-2.1-14.872-3.98-17.077a6.588 6.588 0 0 1 2.864 1.268C49.577 29.268 51 33.7 51 40z"
        fill="#394d5c"
      />
      <g fill="#ffd782">
        <path d="M18 57h8v4h-8zM3 12h7v5H3zM61 11l-4 2V3h4z" />
      </g>
    </svg>
  );
};

export default Capoeira;
