/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */
import MobileImage from "assets/pngJpg/helpMobile.png";
import DesktopConsoleImage from "assets/pngJpg/helpDesktopConsole.png";
import DesktopXHRImage from "assets/pngJpg/helpDesktopXHR.png";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    background: theme.palette.common.white,
    margin: "10px auto",
    borderRadius: "10px",
    padding: "20px 0",
  },
  title: {
    fontWeight: 600,
    fontSize: "1.5rem",
    textAlign: "center",
    margin: "20px 0",
  },
  textBold: {
    fontWeight: 600,
    width: "80%",
    margin: "10px auto",
  },
  text: {
    width: "80%",
    margin: "10px auto",
  },
  subtitle: {
    fontWeight: 500,
    fontSize: "1.2rem",
    width: "80%",
    margin: "20px auto",
  },
  sections:{
    display:"flex",
    width:"90%",
    margin: "0 auto",
    alignItems:"center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width:"100%",
    },
  },
  leftSection:{
    dispaly:"flex",
    flexDirection:"column",
    width:"70%",
    [theme.breakpoints.down("md")]: {
      width:"100%",
    },
  },
  mobileImage:{
    maxHeight:"50vh",
  },
  imageContainer: {
    width: "50%",
    margin: "20px auto",
    [theme.breakpoints.down("md")]: {
      width:"90%",
    },
  },
  image: {
    width: "100%",
    margin: "0px auto",
  },
});

function AboutPage() {
  const styles = useStyles();

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t("help.about.title1")}</div>
      <div className={styles.text}>{t("help.about.text1")}</div>
      <div className={styles.text}>{t("help.about.text2")}</div>
      <div className={styles.text}>{t("help.about.text3")}</div>
      <div className={styles.text}>{t("help.about.text4")}</div>
      <div className={styles.text}>{t("help.about.text5")}</div>
    </div>
  );
}

export default AboutPage;
