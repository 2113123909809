/** Import Externals */
import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import moment from "moment";

import CancelIcon from "@mui/icons-material/Cancel";

/** Import Internals */

/** Import Context */
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import theme from "../../../config/uiTheme";

const useStyles = makeStyles({
  container: {
    background: theme.palette.common.white,
    display: "flex",
    flexDirection: "row",
    borderRadius: "20px",
    alignItems: "center",
    margin: "5vh auto",
    width: "50%",
    maxHeight: "90vh",
    boxShadow: "0px 3px 13px rgba(30, 25, 37, 0.6)",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      maxHeight: "100%",
    },
  },
  icon: {
    position: "absolute",
    top: 0,
    right: -5,
    color: theme.palette.common.darkGrey,
    cursor: "pointer",
  },
  all: {
    margin: "5%",
    padding: "5%",
    width: "100%",
    position: "relative",
    textAlign:"center",
  },
  qrCode: {
    width: "60%",
    margin: "0 auto",
  },
  userName:{
    fontWeight:"600",
    fontSize:"1.5rem",
    margin:"10px 0",
  },
  eventName:{
    fontWeight:"600",
    fontSize:"1.2rem",
    margin:"10px 0",

  },
  startDate:{
    fontWeight:"500",
    fontSize:"1.2rem",
    margin:"10px 0",
  },
  warning:{
    margin:"10px 0",
    color:theme.palette.common.red
  },
});

function QrCodeModal({ event, user }) {
  const styles = useStyles();
  const { t } = useTranslation();

  const { setGlobalContext } = useContext(GlobalContext);

  const handleClose = () => {
    setGlobalContext({
      qrCodeOpen: false,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.all}>
        <div>
          <CancelIcon className={styles.icon} onClick={handleClose} />
        </div>
        <div className={styles.qrCode}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${process.env.REACT_APP_SCAN_SECRET}/${event.event_id}/${user.user_id}`}
            viewBox={`0 0 256 256`}
          />
        </div>
        <div>
            <div className={styles.userName}>
            {user.first_name} {user.last_name}
            </div>
            <div className={styles.eventName}>
            {event.event_name}
            </div>
            <div className={styles.startDate}>
            {t("components.qrCodeModal.startDate")}
            {moment(event.event_start).format('L')} {moment(event.event_start).format('LT')}
            </div>
            <div className={styles.warning}>
            {t("components.qrCodeModal.warning")}
            </div>
        </div>
      </div>
    </div>
  );
}

export default QrCodeModal;
