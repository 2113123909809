import React from "react";

const Kendo = (props) => {
  let size = props.size || 50;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m25.393 28.431-6.359-6.359 2.83-2.829 6.359 6.359zM19.034 22.07l-6.37-6.37 2.83-2.829 6.37 6.37zM12.663 15.698l-9.65-9.65 2.832-2.83 9.649 9.65z"
        fill="#f7c292"
      />
      <path
        d="M1.595 1.796a2.002 2.002 0 0 0-.001 2.831l1.42 1.419 2.83-2.83-1.42-1.42a2.004 2.004 0 0 0-2.829 0z"
        fill="#e6e9ed"
      />
      <path
        d="m25.394 28.427 3.78 3.779 2.83 2.831 11.41 11.419.359-.35 2.121-2.119.35-.361-11.409-11.42-2.831-2.83-3.78-3.779z"
        fill="#f7c292"
      />
      <path
        d="M60.186 62.502 43.801 46.117l2.12-2.12 16.385 16.385z"
        fill="#545c66"
      />
      <path
        d="M42.716 48.155a.998.998 0 0 0 .707-.292l4.24-4.241a.999.999 0 1 0-1.414-1.414l-4.24 4.24a.998.998 0 0 0 .707 1.707z"
        fill="#656d78"
      />
      <path
        d="M60.874 62.782a.998.998 0 0 0 .707-.292l.701-.702a.999.999 0 1 0-1.414-1.414l-.701.701a.998.998 0 0 0 .707 1.707z"
        fill="#545c66"
      />
      <path
        d="M19.032 25.137a1 1 0 0 0 1-1v-2.074a1 1 0 1 0-2 0v2.074a1 1 0 0 0 1 1z"
        fill="#ccd1d9"
      />
      <path
        d="M16.944 23.063h2.088a1 1 0 1 0 0-2h-2.088a1 1 0 1 0 0 2z"
        fill="#ccd1d9"
      />
      <path
        d="m13.02 16.053-.707-.707 2.829-2.829.707.707zM25.752 28.786l-.707-.707 2.828-2.828.707.707z"
        fill="#656d78"
      />
      <path
        d="M19.032 23.063a.993.993 0 0 0 .707-.293l2.829-2.829a.999.999 0 1 0-1.414-1.414l-2.829 2.829a.999.999 0 0 0 .707 1.707z"
        fill="#e6e9ed"
      />
      <g>
        <path
          d="m35.771 25.606 6.359-6.359 2.83 2.83-6.36 6.358z"
          fill="#ffd2a6"
        />
        <path
          d="m42.13 19.247 6.37-6.369 2.83 2.83-6.37 6.369z"
          fill="#ffd2a6"
        />
        <path
          d="m48.502 12.88 9.65-9.65 2.829 2.83-9.65 9.65z"
          fill="#ffd2a6"
        />
        <path
          d="M62.407 1.803c.78.782.78 2.051 0 2.831l-1.42 1.419-2.83-2.83 1.42-1.42a2.006 2.006 0 0 1 2.83 0z"
          fill="#e6e9ed"
        />
        <path
          d="m38.607 28.434-3.78 3.779-2.83 2.831-11.41 11.419-.359-.35-2.121-2.119-.35-.361 11.41-11.42 2.83-2.83 3.78-3.779z"
          fill="#ffd2a6"
        />
        <path
          d="m1.72 60.36 16.386-16.386 2.12 2.12L3.84 62.479z"
          fill="#545c66"
        />
        <path
          d="M21.285 48.162a.998.998 0 0 1-.707-.292l-4.24-4.241a.999.999 0 1 1 1.414-1.414l4.24 4.241a.997.997 0 0 1-.707 1.706z"
          fill="#656d78"
        />
        <path
          d="M3.127 62.789a.998.998 0 0 1-.707-.292l-.701-.702a.999.999 0 1 1 1.414-1.414l.701.701a.998.998 0 0 1-.707 1.707z"
          fill="#545c66"
        />
        <path
          d="M44.969 25.144a1 1 0 0 1-1-1V22.07a1 1 0 1 1 2 0v2.074a1 1 0 0 1-1 1z"
          fill="#ccd1d9"
        />
        <path
          d="M47.057 23.069h-2.088a1 1 0 1 1 0-2h2.088a1 1 0 1 1 0 2z"
          fill="#ccd1d9"
        />
        <path
          d="m48.145 13.233.707-.707 2.828 2.829-.707.707zM35.414 25.961l.707-.707 2.829 2.829-.707.707z"
          fill="#656d78"
        />
        <path
          d="M44.969 23.069a.993.993 0 0 1-.707-.293l-2.829-2.829a.999.999 0 1 1 1.414-1.414l2.829 2.829a.999.999 0 0 1-.707 1.707z"
          fill="#e6e9ed"
        />
      </g>
    </svg>
  );
};

export default Kendo;
