/** Import Externals */
import React, { useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";

/** Import Internals */
import TopVideo from "./components/topVideo";
import NextEvents from "./components/nextEvents";
import Footer from "components/footer";
import TopBar from "components/topBar";

/** Import Context */
import { UserContext } from "data/context/userContext";

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.common.darkWhite,
  },
});

function Home() {
  const styles = useStyles();

  const { setUserContext, lat, lng } = useContext(UserContext);

  useEffect(() => {
    if (lat !== 0 && lng !== 0) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserContext({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, []);

  return (
    <div className={styles.container}>
      <TopBar />
      <TopVideo />
      <NextEvents />
      <Footer />
    </div>
  );
}

export default Home;
