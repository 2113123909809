/** Import Externals */
import React, { useEffect, useContext, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */
import EventCard from "components/eventCard";
import Loader from "assets/svg/loader";

/** Import Context */
import { SearchContext } from "data/context/searchContext";

/** Import API */
import { GetMultiEvents } from "data/api/eventQuerys";

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "90vW",
    margin: "5vh auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  loader: {
    height: "70vh",
    margin: "30vh auto 0",
  },
  noEvent: {
    height: "40vh",
    margin: "30vh auto 0",
    [theme.breakpoints.down("md")]: {
      margin: "10vh auto 10vh",
    },
  },
  textNoEvent: {
    color: theme.palette.common.lightGrey,
    fontSize: "2rem",
    fontWeight: 600,
    textAlign: "center",
  },
});

function ListEvents(props) {
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    setSearchContext,
    sport,
    dateStart,
    dateEnd,
    lat,
    lng,
    range,
    listEvents,
    searchToggle,
    noEvent,
  } = useContext(SearchContext);

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (sport !== "all" || lat !== 0 || lng !== 0) {
      GetMultiEvents(
        sport,
        new Date(),
        new Date(new Date().setDate(new Date().getDate() + 7)),
        lat,
        lng,
        20
      ).then((res) => {
        if (res.data.length === 0) {
          setSearchContext({ noEvent: true, listEvents: res.data });
        } else {
          setSearchContext({ noEvent: false, listEvents: res.data });
        }
      });
    } else {
      setSearchContext({
        sport: "all",
        dateStart: new Date(),
        dateEnd: new Date(new Date().setDate(new Date().getDate() + 7)),
        lat: 0,
        lng: 0,
        range: 20,
      });
      GetMultiEvents(
        "all",
        new Date(),
        new Date(new Date().setDate(new Date().getDate() + 7)),
        0,
        0,
        20
      )
        .then((res) => {
          if (res.data.length === 0) {
            setSearchContext({ noEvent: true, listEvents: res.data });
          } else {
            setSearchContext({ noEvent: false, listEvents: res.data });
          }
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      GetMultiEvents(sport, dateStart, dateEnd, lat, lng, range)
        .then((res) => {
          if (res.data.length === 0) {
            setSearchContext({ noEvent: true, listEvents: res.data });
          } else {
            setSearchContext({ noEvent: false, listEvents: res.data });
          }
        })
        .catch((error) => {});
    }
  }, [searchToggle]);

  return (
    <div className={styles.container}>
      {listEvents.length ? (
        listEvents.map((e, i) => {
          return <EventCard key={i} event={e} />;
        })
      ) : noEvent ? (
        <div className={styles.noEvent}>
          <div className={styles.textNoEvent}>
            {t(`components.eventList.noEvents`)}
          </div>
        </div>
      ) : (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </div>
  );
}

export default ListEvents;
