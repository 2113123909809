/** Import Externals */
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import Select from "react-select";

/** Import Internals */

/** Import Context */
import { SearchContext } from "data/context/searchContext";
import { EventContext } from "data/context/eventContext";
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    margin: "3vH auto",
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  listTellUs: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.common.darkGrey,
    margin: "1vW auto",
    cursor: "default",
  },
  inputError: {
    color: theme.palette.common.red,
  },
  sportContainer: {
    display: "flex",
    width: "100%",
    margin: "10px auto",
    fontWeight: 500,
    fontSize: "1.3rem",
  },
  sportSelected: {
    margin: "0 10px",
    width: "50%",
  },
  rightText: {
    textAlign: "right",
  },
});

function SportSelectorNewEvent({ error, sportArray }) {
  const styles = useStyles();
  const { t } = useTranslation();

  const { sportsList } = useContext(SearchContext);
  const { setEventContext, eventSport } = useContext(EventContext);
  const { mobileScreen } = useContext(GlobalContext);

  const [sportsListState, setSportsListState] = useState([]);
  const [sport, setSport] = useState("");
  const [LevelList, setLevelList] = useState([]);
  const [level, setLevel] = useState("");

  useEffect(() => {
    let clubSports = [];
    if (sportArray.length) {
      sportArray.map((sport) => {
        let finded = sportsList.find(e => e.sport_name === sport);
        return clubSports.push(finded);
      })
    } else {
      clubSports = sportsList;
    }
    let optionsSports = clubSports.map((sport) => {
      return {
        label: t(`sports.${sport.sport_name}`),
        value: sport.sport_name,
        grades: sport.sport_grades,
      };
    });
    setSportsListState(optionsSports);
  }, [sportsList,sportArray]);

  const handleChangeSport = (e) => {
    setSport(e.value);
    let grades = e.grades.split(",");
    let optionsGrade = grades.map((grade) => {
      return {
        label: t(`grades.${grade}`),
        value: grade,
      };
    });
    setLevel(optionsGrade[0]);
    setLevelList(optionsGrade);
    setEventContext({ eventSport: `${e.value}/${optionsGrade[0].value}` });
  };

  const handleChangeLevel = (e) => {
    setLevel(e);
    setEventContext({ eventSport: `${sport}/${e.value}` });
  };

  let sportLevel = eventSport.split("/");

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.listTellUs, error ? styles.inputError : null)}
      >
        {t("components.sportSelector.selectNewEvent")}
      </div>
      <div className={styles.selectContainer}>
        <Select
          options={sportsListState}
          onChange={handleChangeSport}
          placeholder={t("components.sportSelector.sport")}
          maxMenuHeight={160}
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius: mobileScreen ? "10px 10px 0 0" : "10px 0 0 10px",
              cursor: "pointer",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            input: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
              textAlign: "center",
              paddingTop: "5px",
              height: "30px",
              width: mobileScreen ? "50vW" : "15vW",
            }),
            option: (provided, state) => ({
              ...provided,
              fontWeight: state.isSelected ? 600 : 400,
              color: state.isSelected
                ? theme.palette.common.darkGrey
                : theme.palette.common.grey,
              background: theme.palette.common.white,
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.common.darkGrey,
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
            }),
          }}
        />
        <Select
          options={LevelList}
          onChange={handleChangeLevel}
          placeholder={t("components.sportSelector.level")}
          value={level}
          maxMenuHeight={160}
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius: mobileScreen ? " 0 0 10px 10px" : "0 10px 10px 0",
              cursor: "pointer",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            input: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
              textAlign: "center",
              paddingTop: "5px",
              height: "30px",
              width: mobileScreen ? "50vW" : "15vW",
            }),
            option: (provided, state) => ({
              ...provided,
              fontWeight: state.isSelected ? 600 : 400,
              color: state.isSelected
                ? theme.palette.common.darkGrey
                : theme.palette.common.grey,
              background: theme.palette.common.white,
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.common.darkGrey,
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
            }),
          }}
        />
      </div>
      {sportLevel[0] ? (
        <div className={styles.sportContainer}>
          <span className={clsx(styles.sportSelected, styles.rightText)}>
            {t(`sports.${sportLevel[0]}`)}
          </span>
          -
          <span className={styles.sportSelected}>
            {t(`grades.${sportLevel[1]}`)}
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default SportSelectorNewEvent;
