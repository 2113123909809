/** Import Externals */
import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import Geocode from 'react-geocode'
import { fr } from 'date-fns/locale'

import TextareaAutosize from 'react-textarea-autosize'
import { TextField, Divider } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CancelIcon from '@mui/icons-material/Cancel'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/fr'

/** Import Internals */
import Loader from 'assets/svg/loader'
import SportSelectorNewEvent from 'components/sportSelector/sportSelectorNewEvent'
import PictureModal from 'components/pictureModal'
import UploadSvg from 'assets/svg/upload.svg'
import Check from 'assets/svg/check'
import Cross from 'assets/svg/cross'

/** Import Context */
import { UserContext } from 'data/context/userContext'
import { EventContext } from 'data/context/eventContext'
import { GlobalContext } from 'data/context/globalContext'

/** Import API */

import { CreateEvent, CreateMultipleEvents } from 'data/api/eventQuerys'

/** Import Utils */
import theme from 'config/uiTheme'

/** Import CSS */
import 'react-multi-date-picker/styles/colors/green.css'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    width: '60%',
    margin: '2% auto',
    borderRadius: '10px',
    padding: '2%',
    cursor: 'default',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      padding: '0%',
    },
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: theme.palette.common.black,
    fontSize: '20px',
    fontWeight: 500,
    margin: '1vH 1.5vW',
    cursor: 'default',
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    margin: '0 auto',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2vH 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      margin: '0',
    },
  },
  inputContainer: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '1rem 0',
    },
  },
  inputLabel: {
    marginBottom: '1vh',
  },
  inputText: {
    border: '1px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    fontSize: '16px',
    height: '35px',
  },
  checkBox: {
    accentColor: theme.palette.common.green,
  },
  dateButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.green,
    background: theme.palette.common.white,
    fontWeight: 500,
    fontSize: '1.2rem',
    border: '1px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.green,
    padding: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: 'max-content',
      margin: '10px auto',
    },
  },
  currency: {
    marginRight: '2%',
  },
  inputPrice: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
  },
  inputTextAreaContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  inputTextArea: {
    border: '1px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    fontSize: '16px',
  },
  pictureContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    margin: '3vW auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  picture: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      margin: '10px auto',
    },
  },
  pictureImg: {
    width: '100%',
    borderRadius: '10px',
  },
  cancelIcon: {
    cursor: 'pointer',
  },
  pictureButton: {
    width: '25%',
    borderRadius: '10px',
    boxShadow: '0px 3px 13px rgba(30, 25, 37, 0.2)',
    padding: '2% 0',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      margin: '10px auto',
    },
  },
  uploadImage: {
    width: '30%',
  },
  completeStripe: {
    color: theme.palette.common.grey,
    fontSize: '0.8rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0,7rem',
    },
  },
  displayNone: {
    display: 'none',
  },
  paymentButton: {
    display: 'flex',
    width: 'min-content',
    textAlign: 'center',
    color: theme.palette.common.white,
    background: theme.palette.common.green,
    fontWeight: 600,
    borderRadius: '10px',
    padding: '.5vW',
    marginLeft: '20px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  buttonContainer: {
    display: 'flex',
    margin: '2vW 0',
    justifyContent: 'space-evenly',
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.green,
    fontWeight: 600,
    border: '2px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.green,
    padding: '10px',
    cursor: 'pointer',
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.lightGrey,
    fontWeight: 600,
    border: '2px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    padding: '10px',
    marginLeft: '3vW',
    cursor: 'pointer',
  },
  statusContainer: {
    height: '4vh',
    margin: '0 auto',
  },
  errorText: {
    color: theme.palette.common.red,
    fontWeight: 600,
  },
  errorInput: {
    color: theme.palette.common.red,
  },
  errorButton: {
    color: theme.palette.common.red,
    borderColor: theme.palette.common.red,
  },
  successText: {
    color: theme.palette.common.green,
    fontWeight: 600,
  },
})

function NewEvent() {
  const styles = useStyles()
  const { t, i18n } = useTranslation()

  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API_KEY}`)

  let history = useHistory()

  const { user, club, stripeAccountValid } = useContext(UserContext)
  const { setEventContext, eventPictures, eventSport } = useContext(
    EventContext,
  )
  const { setGlobalContext } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [reset, setReset] = useState(false)
  const [locale, setLocale] = useState('fr')
  const [recurrentEvent, setRecurrentEvent] = useState(false)

  const baseDate = new Date()
  baseDate.setDate(baseDate.getDate() + 1)

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [location, setLocation] = useState('')
  const [recurrentStartHour, setRecurrentStartHour] = useState('')
  const [recurrentEndHour, setRecurrentEndHour] = useState('')
  const [recurrentDates, setRecurrentDates] = useState([])
  const [start, setStart] = useState(baseDate.setHours(9, 0, 0))
  const [end, setEnd] = useState(baseDate.setHours(18, 0, 0))
  const [places, setPlaces] = useState('')
  const [price, setPrice] = useState(0)
  const [equipments, setEquipments] = useState('')
  const [description, setDescription] = useState('')
  const [eventPictureModal, setEventPictureModal] = useState(false)
  const [sportArray, setSportArray] = useState([])

  useEffect(() => {
    if (i18n.resolvedLanguage === 'en') {
      setLocale('en')
    } else {
      setLocale('fr')
    }
    if (reset) {
      setName('')
      setPlaces('')
      setPrice(0)
      setEquipments('')
      setDescription('')
    }
  }, [reset])

  useEffect(() => {
    setAddress(club.address)
    setZipCode(club.zip_code)
    setLocation(`${club.city}, ${club.country}`)
    setCity(club.city)
    setCountry(club.country)
  }, [club])

  useEffect(() => {
    if (error && error !== t('newEvent.error.tooLongString')) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [error])

  useEffect(() => {
    if (club.sports) {
      setSportArray(club.sports.split(','))
    }
  }, [club])

  const handleChangeName = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setName(e.target.value)
    } else {
      e.target.blur()
      setError(t('newEvent.error.tooLongString'))
    }
  }

  const handleChangeAddress = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setAddress(e.target.value)
    } else {
      e.target.blur()
      setError(t('newEvent.error.tooLongString'))
    }
  }

  const handleChangeZipCode = (e) => {
    if (e.target.value.length < 20) {
      setError('')
      setZipCode(e.target.value)
    } else {
      e.target.blur()
      setError(t('newEvent.error.tooLongString'))
    }
  }

  const handleChangeLocation = (e) => {
    let names = e.label.split(',')
    setCity(names[0])
    setCountry(names[1])
    setLocation(e.label)
  }

  const handleChangeRecurrentEvent = (e) => {
    setRecurrentEvent(!recurrentEvent)
  }

  const handleChangeRecurrentDate = (e) => {
    setRecurrentDates(e)
  }

  const handleChangeRecurrentStartHour = (e) => {
    if (e === null || e === undefined) {
      setRecurrentStartHour(new Date().getTime())
    } else {
      setRecurrentStartHour(e?.$d.getTime())
      if (recurrentEndHour < e?.$d.getTime()) {
        setRecurrentEndHour(e?.$d.getTime())
      }
    }
  }

  const handleChangeRecurrentEndHour = (e) => {
    if (e === null || e === undefined) {
      setRecurrentEndHour(new Date().getTime())
    } else {
      if (e?.$d.getTime() < recurrentStartHour) {
        setRecurrentEndHour(recurrentStartHour)
      } else {
        setRecurrentEndHour(e?.$d.getTime())
      }
    }
  }

  const handleChangeStart = (e) => {
    setStart(e?.$d.getTime())
    if (end < e?.$d.getTime()) {
      setEnd(e?.$d.getTime())
    }
  }

  const handleChangeEnd = (e) => {
    if (e?.$d.getTime() < start) {
      setEnd(start)
    } else {
      setEnd(e?.$d.getTime())
    }
  }

  const handleChangePlaces = (e) => {
    if (e.target.value < 1) {
      e.target.value = 1
    }
    if (e.target.value > 500) {
      e.target.value = 500
    }
    e.target.value = e.target.value.replace('e', '')
    setPlaces(e.target.value.replace(/^(?!0$)0+/, ''))
  }

  const handleChangePrice = (e) => {
    if (e.target.value > 0 && !stripeAccountValid) {
      e.target.value = 0
    } else if (e.target.value > 2000) {
      e.target.value = 2000
    }
    e.target.value = e.target.value.replace('e', '')
    setPrice(e.target.value.replace(/^(?!0$)0+/, ''))
  }

  const handleGoOptions = () => {
    setGlobalContext({ accountPage: 'settings' })
    history.push('/account')
  }

  const handleChangeEquipments = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setEquipments(e.target.value)
    } else {
      e.target.blur()
      setError(t('newEvent.error.tooLongString'))
    }
  }

  const handleChangeDescription = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setDescription(e.target.value)
    } else {
      e.target.blur()
      setError(t('newEvent.error.tooLongString'))
    }
  }

  const handleSupprImage = (index) => {
    if (eventPictures.length > 1) {
      let newArray = eventPictures
      newArray.splice(index, 1)
      setEventContext({ eventPictures: [...newArray] })
    } else {
      setEventContext({ eventPictures: [] })
    }
  }

  const handleOpenModal = () => {
    setEventPictureModal(true)
    window.scrollTo(0, 0)
  }

  const handleCloseModal = () => {
    setEventPictureModal(false)
    window.scrollTo(0, document.body.scrollHeight)
  }

  const handleSaveEvent = () => {
    setReset(false)
    setLoading(true)
    setSuccess('')
    setError('')
    if (
      recurrentEvent &&
      (recurrentDates.length <= 1 || !recurrentStartHour || !recurrentEndHour)
    ) {
      setError(t('newEvent.error.missingField'))
      setLoading(false)
      return
    }
    if (
      !user.club_id ||
      !club.name ||
      !club.address ||
      !club.zip_code ||
      !club.city ||
      !club.country
    ) {
      setError(t('newEvent.error.moreInfos'))
      setLoading(false)
    } else if (
      eventPictures.length > 0 &&
      name !== '' &&
      address !== '' &&
      zipCode !== '' &&
      city !== '' &&
      country !== '' &&
      eventSport !== '' &&
      places > 0 &&
      price >= 0 &&
      equipments !== '' &&
      description !== ''
    ) {
      Geocode.fromAddress(`${address} ${zipCode}, ${city} ${country}`)
        .then((response) => {
          const { lat, lng } = response.results[0].geometry.location
          if (recurrentEvent) {
            CreateMultipleEvents(
              name,
              address,
              zipCode,
              city,
              country,
              lat,
              lng,
              eventSport,
              recurrentDates,
              recurrentStartHour,
              recurrentEndHour,
              places,
              price,
              equipments,
              description,
              eventPictures,
            )
              .then((success) => {
                setSuccess(t('newEvent.success.created'))
                setReset(true)
                setEventContext({ eventPictures: [], eventSport: '' })
                setLoading(false)
              })
              .catch((error) => {
                if (error.response.data.message) {
                  if (error.response.data.message === 'Event already exist') {
                    setError(t('newEvent.error.exist'))
                  }
                  if (error.response.data.message === 'Missing fields') {
                    setError(t('newEvent.error.missingField'))
                  }
                } else {
                  setError(t('newEvent.error.error'))
                }
                setLoading(false)
              })
          } else {
            CreateEvent(
              name,
              address,
              zipCode,
              city,
              country,
              lat,
              lng,
              eventSport,
              moment(start).format('YYYY-MM-DD HH:mm:ss'),
              moment(end).format('YYYY-MM-DD HH:mm:ss'),
              places,
              price,
              equipments,
              description,
              eventPictures,
            )
              .then((success) => {
                setSuccess(t('newEvent.success.created'))
                setReset(true)
                setEventContext({ eventPictures: [], eventSport: '' })
                setLoading(false)
              })
              .catch((error) => {
                if (error.response.data.message) {
                  if (error.response.data.message === 'Event already exist') {
                    setError(t('newEvent.error.exist'))
                  }
                  if (error.response.data.message === 'Missing fields') {
                    setError(t('newEvent.error.missingField'))
                  }
                } else {
                  setError(t('newEvent.error.error'))
                }
                setLoading(false)
              })
          }
        })
        .catch((err) => {
          if (err.status === 'ZERO_RESULTS') {
            setError(t('newEvent.error.wrongAddress'))
            setLoading(false)
          }
        })
    } else {
      setError(t('newEvent.error.missingField'))
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setEventContext({ eventPictures: [], eventSport: '' })
    history.push(`/home`)
    window.scrollTo(0, 0)
  }

  let months = [
    'Janvier',
    'Fevrier',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Julliet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ]
  let weekDays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']

  if (i18n.resolvedLanguage === 'en') {
    months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  } else if (i18n.resolvedLanguage === 'es') {
    months = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]
    weekDays = ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']
  }

  // Disable scroll on inputs
  const inputTypeNumbers = document.querySelectorAll('input[type=number]')
  for (let a = 0; a < inputTypeNumbers.length; a++) {
    inputTypeNumbers[a].onwheel = function (event) {
      event.target.blur()
    }
  }

  return (
    <div className={styles.container}>
      {eventPictureModal ? (
        <PictureModal
          close={handleCloseModal}
          type={'event'}
          page={'event'}
          error={(message) => setError(message)}
        />
      ) : (
        <div className={styles.subContainer}>
          <div className={styles.title}>{t('newEvent.title')}</div>
          <Divider />
          <div className={styles.fields}>
            <div className={styles.line}>
              <div className={styles.inputContainer}>
                <label
                  className={clsx(
                    styles.inputLabel,
                    error !== t('newEvent.error.tooLongString') &&
                      error &&
                      name === ''
                      ? styles.errorInput
                      : null,
                  )}
                >
                  {t('newEvent.eventName')}
                </label>
                <input
                  className={styles.inputText}
                  type="text"
                  value={name}
                  placeholder={t('newEvent.placeholder.eventName')}
                  onChange={(e) => handleChangeName(e)}
                />
              </div>
              <div className={styles.inputContainer}>
                <label
                  className={clsx(
                    styles.inputLabel,
                    error !== t('newEvent.error.tooLongString') &&
                      error &&
                      address === ''
                      ? styles.errorInput
                      : null,
                  )}
                >
                  {t('account.club.address')}:
                </label>
                <input
                  className={styles.inputText}
                  type="text"
                  value={address}
                  onChange={(e) => handleChangeAddress(e)}
                />
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.inputContainer}>
                <label
                  className={clsx(
                    styles.inputLabel,
                    error !== t('newEvent.error.tooLongString') &&
                      error &&
                      zipCode === ''
                      ? styles.errorInput
                      : null,
                  )}
                >
                  {t('account.club.zipCode')}:
                </label>
                <input
                  className={styles.inputText}
                  type="text"
                  value={zipCode}
                  onChange={(e) => handleChangeZipCode(e)}
                />
              </div>
              <div className={styles.inputContainer}>
                <label
                  className={clsx(
                    styles.inputLabel,
                    error !== t('newEvent.error.tooLongString') &&
                      error &&
                      city === ''
                      ? styles.errorInput
                      : null,
                  )}
                >
                  {t('account.club.cityCountry')}:
                </label>
                <GooglePlacesAutocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                  selectProps={{
                    placeholder: city
                      ? `${location}`
                      : `${t('account.club.cityCountryPlaceholder')}`,
                    onChange: handleChangeLocation,
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '10px',
                        cursor: 'pointer',
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        color: 'red',
                      }),
                      indicatorSeparator: (provided) => ({
                        ...provided,
                        display: 'none',
                      }),
                      loadingIndicator: (provided) => ({
                        ...provided,
                        display: 'none',
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: theme.palette.common.darkGrey,
                        height: '30px',
                        width: '6rem',
                        maxWidth: '300px',
                        minWidth: '100px',
                        lineHeight: '26px',
                      }),
                      option: (provided) => ({
                        ...provided,
                        color: theme.palette.common.darkGrey,
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: theme.palette.common.darkGrey,
                      }),
                    },
                  }}
                />
              </div>
            </div>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={locale}
            >
              <div className={styles.line}>
                <div onClick={handleChangeRecurrentEvent}>
                  <label onClick={handleChangeRecurrentEvent}>
                    {t('newEvent.recurrentEvent')}
                    <input
                      className={styles.checkBox}
                      type="checkbox"
                      checked={recurrentEvent}
                      onChange={handleChangeRecurrentEvent}
                    />
                  </label>
                </div>
                {recurrentEvent ? (
                  <DatePicker
                    className="green"
                    plugins={[<DatePanel />]}
                    multiple
                    minDate={moment(baseDate).toDate()}
                    format="MMMM DD YYYY"
                    months={months}
                    weekDays={weekDays}
                    sort
                    render={(value, openCalendar) => {
                      return (
                        <button
                          className={clsx(
                            styles.dateButton,
                            error !== t('newEvent.error.tooLongString') &&
                              error &&
                              recurrentDates.length <= 1
                              ? styles.errorButton
                              : null,
                          )}
                          onClick={openCalendar}
                        >
                          {t('newEvent.dateSelect')}
                        </button>
                      )
                    }}
                    onChange={handleChangeRecurrentDate}
                    value={recurrentDates}
                  />
                ) : null}
              </div>
              {recurrentEvent ? (
                <div className={styles.line}>
                  <div className={styles.inputContainer}>
                    <label className={styles.inputLabel}>
                      {t('newEvent.start')}:
                    </label>
                    <TimePicker
                      value={recurrentStartHour}
                      ampm={false}
                      minDate={moment(baseDate).toDate()}
                      onChange={handleChangeRecurrentStartHour}
                      renderInput={(params) => (
                        <TextField {...params} className={styles.textFields} />
                      )}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <label className={styles.inputLabel}>
                      {t('newEvent.end')}:
                    </label>
                    <TimePicker
                      value={recurrentEndHour}
                      minDate={moment(baseDate).toDate()}
                      ampm={false}
                      onChange={handleChangeRecurrentEndHour}
                      renderInput={(params) => (
                        <TextField {...params} className={styles.textFields} />
                      )}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.line}>
                  <div className={styles.inputContainer}>
                    <label className={styles.inputLabel}>
                      {t('newEvent.start')}:
                    </label>
                    <DateTimePicker
                      value={start}
                      minDate={moment(baseDate).toDate()}
                      ampm={false}
                      onChange={handleChangeStart}
                      renderInput={(params) => (
                        <TextField {...params} className={styles.textFields} />
                      )}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <label className={styles.inputLabel}>
                      {t('newEvent.end')}:
                    </label>
                    <DateTimePicker
                      value={end}
                      minDate={moment(start).toDate()}
                      ampm={false}
                      onChange={handleChangeEnd}
                      renderInput={(params) => (
                        <TextField {...params} className={styles.textFields} />
                      )}
                    />
                  </div>
                </div>
              )}
            </LocalizationProvider>
            <div className={styles.line}>
              <div className={styles.inputContainer}>
                <label
                  className={clsx(
                    styles.inputLabel,
                    error !== t('newEvent.error.tooLongString') &&
                      error &&
                      places === ''
                      ? styles.errorInput
                      : null,
                  )}
                >
                  {t('newEvent.places')}:
                </label>
                <input
                  className={styles.inputText}
                  type="number"
                  value={places}
                  placeholder={t('newEvent.placeholder.places')}
                  onChange={(e) => handleChangePlaces(e)}
                  min="0"
                  max="500"
                  step="1"
                />
              </div>
              {stripeAccountValid ? (
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error !== t('newEvent.error.tooLongString') &&
                        error &&
                        price === ''
                        ? styles.errorInput
                        : null,
                    )}
                  >
                    {t('newEvent.price')}:{' '}
                    <span className={styles.completeStripe}>
                      {t('newEvent.feeJutsu')} <br />
                      {t('newEvent.youGet')} : {price - (price * 15) / 100}€
                    </span>
                  </label>
                  <div className={styles.inputPrice}>
                    <span className={styles.currency}>€ </span>
                    <input
                      className={clsx(styles.inputPrice, styles.inputText)}
                      type="number"
                      value={price}
                      placeholder={t('newEvent.placeholder.price')}
                      onChange={(e) => handleChangePrice(e)}
                      min="0"
                      max="2000"
                      step="1"
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error !== t('newEvent.error.tooLongString') &&
                        error &&
                        price === ''
                        ? styles.errorInput
                        : null,
                    )}
                  >
                    {t('newEvent.price')}:{' '}
                    <span className={styles.completeStripe}>
                      {t('newEvent.stripeSubText')}
                    </span>
                  </label>
                  <div className={styles.inputPrice}>
                    <span className={styles.currency}>€ </span>
                    <input
                      className={clsx(styles.inputPrice, styles.inputText)}
                      type="number"
                      value={price}
                      placeholder="0"
                      onChange={(e) => handleChangePrice(e)}
                      min="0"
                      max="2000"
                    />
                    <span
                      className={styles.paymentButton}
                      onClick={handleGoOptions}
                    >
                      {t('account.club.payment')}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <SportSelectorNewEvent
              error={
                error !== t('newEvent.error.tooLongString') &&
                error &&
                eventSport === ''
                  ? true
                  : false
              }
              sportArray={sportArray}
            />
            <div className={styles.line}>
              <div className={styles.inputTextAreaContainer}>
                <label
                  className={clsx(
                    styles.inputLabel,
                    error !== t('newEvent.error.tooLongString') &&
                      error &&
                      equipments === ''
                      ? styles.errorInput
                      : null,
                  )}
                >
                  {t('newEvent.equipments')}:
                </label>
                <TextareaAutosize
                  className={styles.inputTextArea}
                  minRows={3}
                  maxRows={6}
                  value={equipments}
                  placeholder={t('newEvent.placeholder.equipments')}
                  onChange={(e) => handleChangeEquipments(e)}
                />
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.inputTextAreaContainer}>
                <label
                  className={clsx(
                    styles.inputLabel,
                    error !== t('newEvent.error.tooLongString') &&
                      error &&
                      description === ''
                      ? styles.errorInput
                      : null,
                  )}
                >
                  {t('newEvent.description')}:
                </label>
                <TextareaAutosize
                  className={styles.inputTextArea}
                  minRows={3}
                  maxRows={6}
                  value={description}
                  placeholder={t('newEvent.placeholder.description')}
                  onChange={(e) => handleChangeDescription(e)}
                />
              </div>
            </div>
            <div className={styles.pictureContainer}>
              {eventPictures.map((image) => {
                return (
                  <div className={styles.picture} key={image.id}>
                    <img
                      className={styles.pictureImg}
                      src={image.file}
                      alt={'event'}
                    />
                    <CancelIcon
                      className={styles.cancelIcon}
                      onClick={() => handleSupprImage(image.id)}
                    />
                  </div>
                )
              })}
              {eventPictures.length < 3 ? (
                <div className={styles.pictureButton} onClick={handleOpenModal}>
                  <img
                    className={styles.uploadImage}
                    src={UploadSvg}
                    alt="upload"
                  />
                  <span
                    className={
                      error !== t('newEvent.error.tooLongString') &&
                      error &&
                      eventPictures.length < 1
                        ? styles.errorInput
                        : null
                    }
                  >
                    {t('newEvent.uploadImage')}
                  </span>
                </div>
              ) : null}
            </div>
            <div className={styles.buttonContainer}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className={styles.saveButton} onClick={handleSaveEvent}>
                    <Check size={20} color={theme.palette.common.green} />
                    {t('newEvent.save')}
                  </div>
                  <div className={styles.cancelButton} onClick={handleCancel}>
                    <Cross size={20} color={theme.palette.common.lightGrey} />
                    {t('newEvent.cancel')}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.statusContainer}>
            {error ? (
              <div className={styles.errorText}>{error}</div>
            ) : success ? (
              <div className={styles.successText}>{success}</div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  )
}

export default NewEvent
