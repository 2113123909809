/** Import Internals */
import { LaunchQuery } from "./queryLauncher";

export const GetClub = (club_id) => {
  const url = process.env.REACT_APP_GET_CLUB;

  return LaunchQuery(url, "get", { club_id: club_id });
};

export const CreateClub = (
  name,
  address,
  zip_code,
  city,
  country,
  lat,
  lng,
  sports,
  description,
  insta_link,
  website,
  imageProfile,
  imageLandscape,
  language,
) => {
  const url = process.env.REACT_APP_CREATE_CLUB;

  const postObject = {
    name: name,
    address: address,
    zip_code: zip_code,
    city: city,
    country: country,
    lat: lat,
    lng: lng,
    sports: sports,
    description: description,
    insta_link: insta_link,
    website:website,
    profilePicture: imageProfile,
    landscapePicture: imageLandscape,
    language: language,
  };

  return LaunchQuery(url, "post", postObject);
};

export const ChangeClub = (
  name,
  address,
  zip_code,
  city,
  country,
  lat,
  lng,
  sports,
  description,
  insta_link,
  website,
) => {
  const url = process.env.REACT_APP_CHANGE_CURRENT_CLUB;

  const postObject = {
    club_name: name,
    club_address: address,
    club_zip_code: zip_code,
    club_city: city,
    club_country: country,
    club_lat: lat,
    club_lng: lng,
    club_sports: sports,
    club_description: description,
    club_insta_link: insta_link,
    club_website: website,
  };

  return LaunchQuery(url, "post", postObject);
};

export const ChangeImageClub = (typePicture, filePicture) => {
  const url = process.env.REACT_APP_CHANGE_CLUB_IMAGE;

  const postObject = {
    type: typePicture,
    file: filePicture,
  };

  return LaunchQuery(url, "post", postObject);
};

export const ScanQrCode = (text, eventId) => {
  const url = process.env.REACT_APP_SCAN_QRCODE;

  const postObject = {
    text: text,
    eventId: eventId,
  };

  return LaunchQuery(url, "post", postObject);
};

export const DeleteClub = () => {
  const url = process.env.REACT_APP_DELETE_CLUB;

  return LaunchQuery(url, "post", {});
};
