/** Import Externals */
import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { QrReader } from "react-qr-reader";
import Lottie from "lottie-react";

import CancelIcon from "@mui/icons-material/Cancel";

/** Import Internals */
import validAnimation from "assets/lotties/validAnimation.json";
import invalidAnimation from "assets/lotties/invalidAnimation.json";
import scanAnimation from "assets/lotties/scanAnimation.json";
import ScanInlay from "assets/svg/scanInlay";
import QrCodeIcon from "assets/svg/qrCode";

/** Import Context */
import { GlobalContext } from "data/context/globalContext";

/** Import API */
import { ScanQrCode } from "data/api/clubQuerys";

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    background: theme.palette.common.darkWhite,
    zIndex: 100,
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    width: "100%",
    height: "min-content",
    [theme.breakpoints.down("md")]: {
      width: "96%",
      margin: "2%",
    },
  },
  iconContainer: {
    textAlign: "right",
    width: "100%",
  },
  icon: {
    color: theme.palette.common.darkGrey,
    margin: "20px 20px 0 0",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      margin: "10px 10px 0 0",
    },
  },
  textContainer: {
    color: theme.palette.common.darkGrey,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "0 auto",
  },
  title: {
    color: theme.palette.common.darkGrey,
    fontSize: "1.8rem",
    fontWeight: 700,
    textAlign: "center",
    cursor: "default",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
      marginBottom: "0",
    },
  },
  scanContainer: {
    width: "100%",
    minHeight: "10vh",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  qrcodeContainer: {
    width: "30%",
    margin: "0 10%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  loaderContainer: {
    height: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  scanButton: {
    display: "flex",
    width: "30%",
    margin: "18vh auto",
    background: theme.palette.common.darkGrey,
    borderRadius: "10px",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  scanLogo: {
    margin: "5px 5%",
  },
  scanTextContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "auto 0",
  },
  scanText: {
    fontWeight: "600",
    color: theme.palette.common.white,
    marginRight: "4px",
  },
  qrCodeInlayView: {
    position: "absolute",
    width: "50%",
    top: "25%",
    left: "25%",
    alignItems: "center",
    justifyContent: "center",
  },
  scanHere: {
    margin: "0 auto",
    fontSize: "1.2rem",
    fontWeight: 600,
  },
  success: {
    width: "25%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.2rem",
    color: theme.palette.common.green,
    fontWeight: 600,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column-reverse",
    },
  },
  ticketUser: {
    display: "flex",
    fontSize: "1rem",
    color: theme.palette.common.darkGrey,
    fontWeight: 600,
    margin: "10px 0",
  },
  lastName: {
    marginLeft: "5px",
  },
  error: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.2rem",
    color: theme.palette.common.red,
    fontWeight: 600,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
});

function ScanModal() {
  const styles = useStyles();
  const { t } = useTranslation();

  const { setGlobalContext, scanEvent } = useContext(GlobalContext);

  const [success, setSuccess] = useState(false);
  const [ticketUser, setTicketUser] = useState({});
  const [newScan, setNewScan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {}, [newScan]);

  const handleClose = () => {
    setGlobalContext({ scanOpen: false });
  };

  const handleQrCode = (text) => {
    setLoading(true);
    setSuccess(false);
    setError(false);
    ScanQrCode(text, scanEvent.event_id)
      .then((result) => {
          setTicketUser({
            firstName: result.data.firstName,
            lastName: result.data.lastName,
          });
          setSuccess(t("components.scanModal.success"));
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setError(t("components.scanModal.error.already"));
        } else if (error.message.includes("404")) {
          setError(t("components.scanModal.error.unvalid"));
        } else if (error.message.includes("405")) {
          setError(t("components.scanModal.error.unvalid"));
        } else if (error.message.includes("406")) {
          setError(t("components.scanModal.error.wrongEvent"));
        } else if (error.message.includes("500")) {
          setError(t("components.scanModal.error.errorScan"));
        } else {
          setError(t("components.scanModal.error.unvalid"));
        }
      });

    setTimeout(() => {
      const video = document.querySelector("video");
      // A video's MediaStream object is available through its srcObject attribute
      if (video) {
        const mediaStream = video.srcObject;
        // Through the MediaStream, you can get the MediaStreamTracks with getTracks():
        const tracks = mediaStream.getTracks();
        // stop all like so:
        tracks.forEach((track) => track.stop());
      }
      setLoading(false);
      setNewScan(true);
    }, "4000");
  };

  const handleNewScan = () => {
    setNewScan(false);
  };

  const handleError = (error) => {
    if (error === "e") {
      setLoading(true);
      setError(t("components.scanModal.error.errorScan"));
      setTimeout(() => {
        setLoading(false);
      }, "4000");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <CancelIcon className={styles.icon} onClick={handleClose} />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.title}>{scanEvent.event_name}</div>
        <div className={styles.scanContainer}>
          {loading ? (
            <div className={styles.qrcodeContainer}>
              <div className={styles.loaderContainer}>
                <Lottie animationData={scanAnimation} loop={false} />
              </div>
            </div>
          ) : newScan ? (
            <div className={styles.scanButton} onClick={handleNewScan}>
              <div className={styles.scanLogo}>
                <QrCodeIcon color={theme.palette.common.white} />
              </div>
              <div className={styles.scanTextContainer}>
                <div className={styles.scanText}>
                  {t(`myEvents.scanButton1`)}
                </div>
                <div className={styles.scanText}>
                  {t(`myEvents.scanButton2`)}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.qrcodeContainer}>
              <QrReader
                delay={300}
                onResult={(result, error) => {
                  if (result && !loading) {
                    handleQrCode(result?.text);
                  }
                  if (error && !loading) {
                    handleError(error);
                  }
                }}
                style={{ width: "100%" }}
              />
              <div className={styles.qrCodeInlayView}>
                <div className={styles.inlay}>
                  <ScanInlay size={"100%"} />
                </div>
              </div>
            </div>
          )}
          {success ? (
            <div className={styles.success}>
              <Lottie animationData={validAnimation} loop={false} />
              {success}
              <div className={styles.ticketUser}>
                <div>{ticketUser.firstName}</div>
                <div className={styles.lastName}>{ticketUser.lastName}</div>
              </div>
            </div>
          ) : error ? (
            <div className={styles.error}>
              <Lottie animationData={invalidAnimation} loop={false} />
              {error}
            </div>
          ) : (
            <div className={styles.scanHere}>
              {t("components.scanModal.scanHere")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ScanModal;
