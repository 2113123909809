/** Import Externals */
import React, { useContext, useRef, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Divider } from "@mui/material";

/** Import Internals */
import Add from "assets/svg/add";

/** Import Context */
import { UserContext } from "../../data/context/userContext";
import { GlobalContext } from "../../data/context/globalContext";

/** Import API */

/** Import Utils */
import theme from "../../config/uiTheme";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    top: "100px",
    right: "-70px",
    maxWidth: "220px",
    transform: "translateX(-45%)",
    background: theme.palette.common.white,
    boxShadow: "0px 3px 13px rgba(30, 25, 37, 0.2)",
    borderRadius: "10px",
    padding: "1rem",
    overflow: "hidden",
    transition: "height 1s ease",
    zIndex: 9999,
    [theme.breakpoints.down("md")]: {
      top: "60px",
      right: "-75px",
      padding: ".5rem",
      maxWidth: "160px",
      minWidth:"150px",
    },
  },
  topSectionMenu: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
    cursor: "pointer",
    transitionDuration: "500ms",
    color: theme.palette.common.grey,
    "&:hover": {
      color: theme.palette.common.darkGrey,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: ".5rem",
    },
  },
  menuImage: {
    height: "3rem",
    width: "3rem",
    borderRadius: "200px",
    boxShadow: "0px 3px 13px rgba(30, 25, 37, 0.2)",
    marginRight: "1rem",
    [theme.breakpoints.down("md")]: {
      height: "2rem",
      width: "2rem",
      marginRight: ".5rem",
    },
  },
  userClubName: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 600,
  },
  itemContainer: {
    margin: "1rem 0",
    [theme.breakpoints.down("md")]: {
      margin: ".5rem 0",
    },
  },
  item: {
    padding: "1rem 1rem 1rem 2rem",
    color: theme.palette.common.grey,
    borderRadius: "10px",
    cursor: "pointer",
    transitionDuration: "500ms",
    "&:hover": {
      color: theme.palette.common.darkGrey,
    },
    [theme.breakpoints.down("md")]: {
      padding: ".5rem .5rem .5rem 1rem",
    },
  },
  selected: {
    color: theme.palette.common.darkGrey,
    fontWeight: 500,
  },
  newEvent: {
    padding: ".5rem",
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.grey,
    cursor: "pointer",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.common.grey,
    borderRadius: "10px",
  },
  newEventText: {
    marginLeft: ".8rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  logOut: {
    padding: "1rem 1rem 1rem 2rem",
    color: theme.palette.common.red,
    opacity: 0.5,
    borderRadius: "10px",
    cursor: "pointer",
    transitionDuration: "500ms",
    "&:hover": {
      opacity: 1,
    },
    [theme.breakpoints.down("md")]: {
      padding: ".5rem .5rem .5rem 1rem",
    },
  },
});

function SubMenu({
  logOut,
  goHome,
  goAccount,
  goProfile,
  goClub,
  goCreateEvent,
  goMyEvents,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const { user, club } = useContext(UserContext);
  const { setGlobalContext } = useContext(GlobalContext);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setGlobalContext({ menuOpen: false });
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * Component that alerts if you click outside of it
   */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} className={styles.container}>
      <div className={styles.topSectionMenu} onClick={() => goProfile()}>
        <img className={styles.menuImage} src={user.profileImage} alt="menu" />
        <div className={styles.userClubName}>
          {user.first_name} {user.last_name}
        </div>
      </div>
      {user.club_id ? (
        <div className={styles.topSectionMenu} onClick={() => goClub()}>
          <img
            className={styles.menuImage}
            src={club.profileImage}
            alt="menu"
          />
          <div className={styles.userClubName}>{club.name}</div>
        </div>
      ) : null}
      <Divider />
      <div className={styles.itemContainer}>
        {window.location.pathname.includes("/home") ||
        window.location.pathname === "/" ? (
          <div className={clsx(styles.item, styles.selected)}>
            {" "}
            {t("components.topBar.subMenu.home")}{" "}
          </div>
        ) : (
          <div className={styles.item} onClick={() => goHome()}>
            {t("components.topBar.subMenu.home")}
          </div>
        )}
        {window.location.pathname === "/account" ? (
          <div className={clsx(styles.item, styles.selected)}>
            {t("components.topBar.subMenu.account")}
          </div>
        ) : (
          <div className={styles.item} onClick={() => goAccount()}>
            {t("components.topBar.subMenu.account")}
          </div>
        )}
        {user.club_id &&
        window.location.pathname !== "/newevent" ? (
          <div className={styles.newEvent} onClick={() => goCreateEvent()}>
            <Add size={15} color={theme.palette.common.grey} />
            <div className={styles.newEventText}>
              {t("components.topBar.newEvent")}
            </div>
          </div>
        ) : null}
        {user.club_id ? (
          <div className={styles.item} onClick={() => goMyEvents()}>
            {t("components.topBar.subMenu.myEvents")}
          </div>
        ) : null}
      </div>
      <Divider />
      <div className={styles.logOut} onClick={() => logOut()}>
        {t("components.topBar.subMenu.logOut")}
      </div>
    </div>
  );
}

export default SubMenu;
