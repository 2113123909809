import React from "react";

const Dojo = (props) => {
  let size = props.size || 50;

  return (
    <svg height={size} width={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <path d="M121 121h270v120H121zm0 0" fill="#ff641a" />
    <path d="M256 121h135v120H256zm0 0" fill="#f03800" />
    <path d="M391 30V0h-30v60zm0 0" fill="#5b5555" />
    <path d="M151 60V0h-30v30zm0 0" fill="#766e6e" />
    <path d="M421 181v60l30-30v-30zm0 0" fill="#5b5555" />
    <path d="M91 181H61v30l30 30zm0 0" fill="#766e6e" />
    <path d="M61 302h390v180H61zm0 0" fill="#ff641a" />
    <path d="M256 302h195v180H256zm0 0" fill="#f03800" />
    <path d="M211 362h90v120h-90zM331 362h30v30h-30zm0 0" fill="#5b5555" />
    <path d="M151 362h30v30h-30zm0 0" fill="#766e6e" />
    <path d="M181 181h30v45h-30zM241 181h30v45h-30zm0 0" fill="#5b5555" />
    <path d="M301 181h30v45h-30zM301 452v30l60 30h151v-60zm0 0" fill="#463f3f" />
    <path d="M0 452v60h151l60-30v-30zM391 362h30v30h-30zm0 0" fill="#5b5555" />
    <path d="M91 362h30v30H91zm0 0" fill="#766e6e" />
    <path d="M256 362h45v120h-45zM256 181h15v45h-15zm0 0" fill="#463f3f" />
    <path d="M506.5 297.5 472.3 332H39.7L5.5 297.5l21-21 18.3 18.3C55.3 280.099 61 261.399 61 243.7V211h390v32.7c0 17.698 5.7 36.398 16.2 51.1l18.3-18.3zm0 0" fill="#766e6e" />
    <path d="M506.5 297.5 472.3 332H256V211h195v32.7c0 17.698 5.7 36.398 16.2 51.1l18.3-18.3zm0 0" fill="#5b5555" />
    <path d="m425.5 95.5-18.3 18.3C396.7 99.099 391 80.399 391 62.7V30H121v32.7c0 17.698-5.7 36.398-16.2 51.1L86.5 95.5l-21 21L99.7 151h312.6l34.2-34.5zm0 0" fill="#766e6e" />
    <path d="M412.3 151H256V30h135v32.7c0 17.698 5.7 36.398 16.2 51.1l18.3-18.3 21 21zm0 0" fill="#5b5555" />
    <path d="M361 512H151l60-60h90zm0 0" fill="#766e6e" />
    <path d="m301 452 60 60H256v-60zm0 0" fill="#5b5555" />
</svg>
  );
};

export default Dojo;
