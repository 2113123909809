/** Import Externals */
import React, { useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

/** Import Internals */
import TopBar from "components/topBar";
import FaqPage from "./faqPage";
import ContactPage from "./contactPage";
import ReportPage from "./reportPage";
import AboutPage from "./aboutPage";

import Footer from "components/footer";

/** Import Context */
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.common.darkWhite,
    overflow: "scroll",
    position: "relative",
  },
  mainContainer: {
    width: "80%",
    margin: "2rem auto",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      flexDirection: "column",
      margin: "0 auto",
    },
  },
  selectorList: {
    borderRadius: "10px",
    height: "3rem",
    width: "15rem",
    marginRight: "1rem",
    [theme.breakpoints.down("md")]: {
      width:"auto",
      display: "flex",
      flexDirection: "row",
      margin: "0 auto 20px",
    },
  },
  selector: {
    padding: "1rem",
    color: theme.palette.common.grey,
    borderRadius: "10px",
    cursor: "pointer",
    whiteSpace:"nowrap",
    fontSize:"0.9rem",
    "&:hover": {
      color: theme.palette.common.darkGrey,
    },
    [theme.breakpoints.down("md")]: {
      margin: "0 5px 0 0",
      display: "flex",
      alignItems:"center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize:"0.7rem",
      padding: "0.5rem",
    },
  },
  selected: {
    borderBottom: "0.1rem solid",
    borderRadius:0,
    borderColor: theme.palette.common.green,
    color: theme.palette.common.green,
    fontWeight: 500,
    "&:hover": {
      color: theme.palette.common.green,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      background: theme.palette.common.ultraLightGrey,
    },
  },
});

function Help() {
  const styles = useStyles();

  const { t } = useTranslation();

  const { setGlobalContext, helpPage } = useContext(GlobalContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [helpPage]);

  const handleChangeSelector = (e) => {
    setGlobalContext({ helpPage: e });
  };

  return (
    <div className={styles.container}>
      <TopBar />
      <div className={styles.mainContainer}>
        <div className={styles.selectorList}>
          <div
            className={clsx(
              styles.selector,
              helpPage === "faq" ? styles.selected : null
            )}
            onClick={() => handleChangeSelector("faq")}
          >
            {t("help.menu.faq")}
          </div>
          <div
            className={clsx(
              styles.selector,
              helpPage === "contact" ? styles.selected : null
            )}
            onClick={() => handleChangeSelector("contact")}
          >
            {t("help.menu.contact")}
          </div>
          <div
            className={clsx(
              styles.selector,
              helpPage === "report" ? styles.selected : null
            )}
            onClick={() => handleChangeSelector("report")}
          >
            {t("help.menu.report")}
          </div>
          <div
            className={clsx(
              styles.selector,
              helpPage === "about" ? styles.selected : null
            )}
            onClick={() => handleChangeSelector("about")}
          >
            {t("help.menu.about")}
          </div>
        </div>
        {helpPage === "faq" ? (
          <FaqPage />
        ) : helpPage === "contact" ? (
          <ContactPage />
        ) : helpPage === "report" ? (
          <ReportPage />
        ) : (
          <AboutPage />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Help;
