/** Import Externals */
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import TextareaAutosize from 'react-textarea-autosize'
import { Divider } from '@mui/material'

/** Import Internals */
import Loader from 'assets/svg/loader'
import PictureModal from 'components/pictureModal'
import Upload from 'assets/svg/upload.svg'
import Check from 'assets/svg/check'
import Cross from 'assets/svg/cross'
import SportSelector from 'components/sportSelector/sportSelectorUser'

/** Import Context */
import { UserContext } from 'data/context/userContext'
import { GlobalContext } from 'data/context/globalContext'

/** Import API */
import { ChangeProfileUser } from 'data/api/userQuerys'

/** Import Utils */
import theme from 'config/uiTheme'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    width: '100%',
    minHeight: '70vh',
    cursor: 'default',
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    margin: '0 auto',
  },
  whiteBackground: {
    background: theme.palette.common.white,
    borderRadius: '10px',
    padding: '2vh 2vw',
    marginTop: '2vh',
  },
  whiteBackgroundError:{
    borderRadius: "10px 10px 0 0",
  },
  title: {
    color: theme.palette.common.black,
    fontSize: '20px',
    fontWeight: 500,
    margin: '1vH 1.5vW',
    cursor: 'default',
  },
  pictureContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2vW auto 8vW',
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto 80px',
    },
  },
  profilePicture: {
    width: '8vW',
    height: '8vW',
    border: '2px solid',
    borderRadius: '200px',
    borderColor: theme.palette.common.white,
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: '3vW',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      left: '33%',
      width: '5rem',
      height: '5rem',
    },
  },
  profileHoverPicture: {
    opacity: 0,
    background: theme.palette.common.black,
    transitionDuration: '500ms',
    '&:hover': {
      opacity: 0.8,
    },
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      margin: '3rem 0 0',
    },
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2vH 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
      margin: '0 auto',
    },
  },
  inputContainer: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '0 auto',
    },
  },
  inputLabel: {
    marginBottom: '1vh',
  },
  inputText: {
    border: '1px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    fontSize: '16px',
    height: '35px',
  },
  inputTextAreaContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  inputTextArea: {
    border: '1px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    fontSize: '16px',
  },
  buttonContainer: {
    display: 'flex',
    margin: '2vW 0',
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.green,
    fontWeight: 600,
    border: '2px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.green,
    padding: '.5vW',
    cursor: 'pointer',
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.lightGrey,
    fontWeight: 600,
    border: '2px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    padding: '.5vW',
    marginLeft: '3vW',
    cursor: 'pointer',
  },
  statusContainer: {
    height: '4vh',
    width: '90%',
    margin: '0 auto',
    textAlign:"center",
    paddingBottom:"10px",
    background: theme.palette.common.white,
    borderRadius:"0 0 10px 10px",
    [theme.breakpoints.down('md')]: {
      height: '8vh',
    },
  },
  errorText: {
    color: theme.palette.common.red,
    fontWeight: 600,
  },
  errorInput: {
    color: theme.palette.common.red,
  },
  successText: {
    color: theme.palette.common.green,
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      margin: '10px 0',
    },
  },
})

function Account() {
  const styles = useStyles()
  const { t } = useTranslation()

  const { user, userSportList } = useContext(UserContext)
  const { setGlobalContext, accountReload } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [cancel, setCancel] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [city, setCity] = useState('')
  const [insta, setInsta] = useState('')
  const [description, setDescription] = useState('')
  const [imageProfile, setImageProfile] = useState('')
  const [profilePictureModal, setProfilePictureModal] = useState(false)

  useEffect(() => {
    setFirstName(user.first_name)
    setLastName(user.last_name)
    setCity(user.city)
    setInsta(user.insta_link)
    setDescription(user.description)
    setImageProfile(user.profileImage || Upload)
  }, [user, cancel])

  useEffect(() => {}, [userSportList])

  const handleChangeFirstName = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setFirstName(e.target.value)
    } else {
      setError(t('account.profile.error.tooLongString'))
    }
  }
  const handleChangeLastName = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setLastName(e.target.value)
    } else {
      setError(t('account.profile.error.tooLongString'))
    }
  }

  const handleChangeCity = (e) => {
    setCity(e.label)
  }

  const handleChangeDescription = (e) => {
    if (e.target.value.length < 1500) {
      setError('')
      setDescription(e.target.value)
    } else {
      setError(t('account.profile.error.tooLongString'))
    }
  }
  const handleChangeInsta = (e) => {

    if (e.target.value.length < 250) {
      setError('')
      const inputValue = e.target.value
      const regex = /^[a-z0-9._]{1,30}$/

      // Vérifie si l'entrée correspond à la regex
      if (regex.test(inputValue) || inputValue === '') {
        setInsta(inputValue)
      }
    } else {
      setError(t('account.profile.error.tooLongString'))
    }
  }

  const handleSaveUser = () => {
    setLoading(true)
    setSuccess('')
    setError('')
    let sports = ''
    userSportList.forEach((sport, index) => {
      if (index === 0) {
        return (sports = `${sport.sport}/${sport.level}`)
      } else {
        return (sports = `${sports},${sport.sport}/${sport.level}`)
      }
    })

    ChangeProfileUser(
      firstName,
      lastName,
      city,
      insta,
      description,
      sports,
      user.club_id,
    )
      .then(() => {
        setSuccess(t('account.profile.success.modif'))
        setGlobalContext({ accountReload: !accountReload })
        setLoading(false)
      })
      .catch((error) => {
        if (error.response.data === 'Missing fields') {
          setError(t('account.profile.error.missingField'))
        } else if (error.response.data === 'String too long') {
          setError(t('account.profile.error.missingField'))
        } else {
          setError(t('account.profile.error.modif'))
        }
        setLoading(false)
      })
  }

  return (
    <div className={styles.container}>
      {profilePictureModal ? (
        <PictureModal
          close={() => setProfilePictureModal(false)}
          type={'profile'}
          page={'user'}
          error={(message) => setError(message)}
        />
      ) : (
        <div className={styles.profileContainer}>
          <div className={styles.title}>{t('account.profile.title')}</div>
          <Divider />
          <div
            className={clsx(
              styles.whiteBackground,
              error || success ? styles.whiteBackgroundError : null,
            )}
          >
            <div className={styles.pictureContainer}>
              {imageProfile && imageProfile !== '' ? (
                <>
                  <img
                    className={styles.profilePicture}
                    src={imageProfile}
                    alt={'profile'}
                    onClick={() => setProfilePictureModal(true)}
                  />
                  <img
                    className={clsx(
                      styles.profilePicture,
                      styles.profileHoverPicture,
                    )}
                    src={Upload}
                    alt={'profileHover'}
                    onClick={() => setProfilePictureModal(true)}
                  />
                </>
              ) : null}
            </div>
            <div className={styles.fields}>
              <div className={styles.line}>
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error && firstName === '' ? styles.errorInput : null,
                    )}
                  >
                    {t('account.profile.firstName')}:
                  </label>
                  <input
                    className={styles.inputText}
                    type="text"
                    value={firstName}
                    onChange={(e) => handleChangeFirstName(e)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error && lastName === '' ? styles.errorInput : null,
                    )}
                  >
                    {t('account.profile.lastName')}:
                  </label>
                  <input
                    className={styles.inputText}
                    type="text"
                    value={lastName}
                    onChange={(e) => handleChangeLastName(e)}
                  />
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.inputContainer}>
                  <label className={styles.inputLabel}>
                    {t('account.profile.cityCountry')}:
                  </label>
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    selectProps={{
                      placeholder:
                        city !== ''
                          ? `${city}`
                          : `${t('account.club.cityCountryPlaceholder')}`,
                      city,
                      onChange: handleChangeCity,
                      styles: {
                        control: (provided) => ({
                          ...provided,
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          color: 'red',
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: 'none',
                        }),
                        loadingIndicator: (provided) => ({
                          ...provided,
                          display: 'none',
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: theme.palette.common.darkGrey,
                          height: '30px',
                          width: '6rem',
                          maxWidth: '300px',
                          minWidth: '100px',
                          lineHeight: '26px',
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: theme.palette.common.darkGrey,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: theme.palette.common.darkGrey,
                        }),
                      },
                    }}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label className={styles.inputLabel}>
                    {t('account.profile.instagram')}:
                  </label>
                  <input
                    className={styles.inputText}
                    type="text"
                    value={insta}
                    placeholder="ex: jutsuevent"
                    label={t('account.profile.instagram')}
                    onChange={(e) => handleChangeInsta(e)}
                  />
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.inputTextAreaContainer}>
                  <label className={styles.inputLabel}>
                    {t('account.profile.description')}:
                  </label>
                  <TextareaAutosize
                    className={styles.inputTextArea}
                    minRows={3}
                    maxRows={6}
                    value={description}
                    onChange={(e) => handleChangeDescription(e)}
                  />
                </div>
              </div>
              <SportSelector />
            </div>
            <div className={styles.buttonContainer}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className={styles.saveButton} onClick={handleSaveUser}>
                    <Check size={20} color={theme.palette.common.green} />
                    {t('account.profile.save')}
                  </div>
                  <div
                    className={styles.cancelButton}
                    onClick={() => setCancel(!cancel)}
                  >
                    <Cross size={20} color={theme.palette.common.lightGrey} />
                    {t('account.profile.cancel')}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.statusContainer}>
        {error ? (
          <div className={styles.errorText}>{error}</div>
        ) : success ? (
          <div className={styles.successText}>{success}</div>
        ) : null}
      </div>
    </div>
  )
}

export default Account
