/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";

/** Import Internals */
import TopBar from "components/topBar";
import EventForm from "./newEventForm";
import Footer from "components/footer";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection:"column",
  },
  formContainer: {
    background: theme.palette.common.darkWhite,
    display: "flex",
    padding: "0.5em 0.5em 0.5em 0 ",
    width: "100%",
    minHeight: "75vh",
  },
});

function Account() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <TopBar />
      <div className={styles.formContainer}>
        <EventForm />
      </div>
      <Footer />
    </div>
  );
}

export default Account;
