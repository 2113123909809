import { createTheme } from "@mui/material/styles";

const white = [255, 255, 255];
const darkWhite = [246, 247, 248];
const black = [0, 0, 0];
const lightGreen = [0, 255, 229];
const green = [3, 201, 184];
const darkGreen = [86, 210, 221];
const greenBlue = [86, 210, 221];
const lightGrey = [217, 217, 217];
const ultraLightGrey = [240, 240, 240];
const grey = [149, 152, 154];
const darkGrey = [36, 43, 44];
const darkPlusGrey = [30, 35, 37];
const red = [255, 66, 0];

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 400,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  palette: {
    common: {
      white: `rgba(${white}, 1)`,
      darkWhite: `rgba(${darkWhite}, 1)`,
      black: `rgba(${black}, 1)`,
      green: `rgba(${green}, 1)`,
      darkGreen: `rgba(${darkGreen}, 1)`,
      lightGreen: `rgba(${lightGreen}, 1)`,
      greenBlue: `rgba(${greenBlue}, 1)`,
      lightGrey: `rgba(${lightGrey}, 1)`,
      ultraLightGrey: `rgba(${ultraLightGrey}, 1)`,
      grey: `rgba(${grey}, 1)`,
      darkGrey: `rgba(${darkGrey}, 1)`,
      darkPlusGrey: `rgba(${darkPlusGrey}, 1)`,
      red: `rgba(${red}, 1)`,
    },
    primary: {
      light: `rgba(${lightGreen})`,
      main: `rgba(${green}, 1)`,
      dark: `rga(${greenBlue}, 1)`,
    },
    secondary: {
      light: `rgba(${grey}, 1)`,
      main: `rgba(${darkGrey}, 1)`,
    },
    info: {
      main: `rgba(${white}, 1)`,
      dakr: `rgba(${darkWhite}, 1)`,
    },
    error: {
      light: "rgba(237, 166, 166, 1)",
      main: `rgb(203,33,54)`,
      dark: "rgb(122,11,11)",
    },
    warning: {
      light: "rgb(255,243,224)",
      main: "rgb(255,152,0)",
      dark: "rgb(230,81,0)",
    },
    success: {
      light: "rgb(195,241,166)",
      main: "rgb(66,157,45)",
      dark: "rgb(32,65,20)",
    },
    spacing: 8,
  },
});

export default theme;
