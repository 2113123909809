import React from "react";

const Trash = (props) => {
  let size = props.size || 50;
  let color = props.color || "black"

  return (
    <svg height={size} width={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="m421.538 93.322-74.247-17.06 1.748-7.607c5.172-22.514-8.936-45.039-31.448-50.211L241.943 1.063C231.072-1.436 219.85.467 210.344 6.42c-9.505 5.953-16.115 15.219-18.613 26.091l-1.748 7.607-74.247-17.06c-22.669-5.211-45.335 8.993-50.541 31.655l-7.204 31.353a9.997 9.997 0 0 0 7.506 11.985l267.344 61.428H74.149a9.997 9.997 0 0 0-9.955 10.948l29.149 305.929c1.936 20.32 18.788 35.644 39.2 35.644h230.083c20.412 0 37.265-15.323 39.201-35.644l28.068-294.577 4.108.944c.754.173 1.507.256 2.248.256 4.556 0 8.674-3.134 9.737-7.763l7.204-31.353c2.515-10.943.599-22.239-5.393-31.807-5.99-9.566-15.318-16.219-26.261-18.734zm-39.62 381.137A19.301 19.301 0 0 1 362.627 492H132.544a19.3 19.3 0 0 1-19.29-17.54L85.148 179.48h324.877zM211.224 36.989c1.302-5.665 4.76-10.502 9.737-13.619 4.976-3.117 10.833-4.118 16.504-2.815l75.647 17.382c11.766 2.703 19.138 14.475 16.435 26.24l-1.748 7.607-118.323-27.187zm222.477 102.397-4.965 21.606L79.723 80.799l4.965-21.606c2.737-11.914 14.66-19.376 26.57-16.642l221.806 50.964.003.001h.003l83.99 19.298c11.913 2.738 19.378 14.658 16.641 26.572zM312.144 460.52l12.218-250.534c.269-5.517 4.951-9.754 10.476-9.501 5.517.27 9.77 4.959 9.501 10.476L332.12 461.494c-.261 5.351-4.682 9.513-9.98 9.513-.164 0-.329-.004-.495-.012-5.517-.269-9.77-4.959-9.501-10.475zm-149.093.974L150.834 210.96c-.269-5.517 3.984-10.206 9.501-10.476 5.516-.254 10.207 3.984 10.476 9.501l12.217 250.534c.269 5.517-3.985 10.206-9.501 10.476-.166.008-.331.012-.495.012-5.301 0-9.72-4.162-9.981-9.513zm74.535-.487V210.473c0-5.522 4.478-10 10-10s10 4.478 10 10v250.534c0 5.522-4.478 10-10 10s-10-4.478-10-10z"/>

    </svg>
  );
};

export default Trash;
