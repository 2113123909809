import React from "react";

const MmaGloves = (props) => {
  let size = props.size || 50;

  return (
    <svg height={size} width={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M366 50V30h-70V10h-70v40h-70v110l-30-40-50 20 8.435 101.216a167.478 167.478 0 0 0 46.556 102.565C144.146 357.373 162.288 365 181.204 365H386c29.677-8.903 50-36.218 50-67.201V50z" fill="#ff7b79" />
        <path d="M371 502H201c-16.569 0-30-13.431-30-30v-77c0-16.569 13.431-30 30-30h170c16.569 0 30 13.431 30 30v77c0 16.569-13.431 30-30 30z" fill="#ff9eb1" />
        <path d="M401 413.5v40h-81.22c1.93-6.32 2.97-13.04 2.97-20s-1.04-13.68-2.97-20z" fill="#ff7b79" />
        <g>
            <path d="M436 40h-60V30c0-5.522-4.478-10-10-10h-60V10c0-5.522-4.478-10-10-10h-70c-5.522 0-10 4.478-10 10v30h-60c-5.522 0-10 4.478-10 10v80l-12-16a10.004 10.004 0 0 0-11.714-3.285l-50 20a10.002 10.002 0 0 0-6.252 10.115l8.435 101.217c3.395 40.727 20.916 79.326 49.336 108.689 11.644 12.029 26.941 20.032 43.335 22.98a39.917 39.917 0 0 0-5.109 12.233 9.922 9.922 0 0 0 1.26 7.55 9.904 9.904 0 0 0 6.229 4.439c2.92.681 6.07-.02 8.431-1.88a10.032 10.032 0 0 0 3.569-5.6c2.07-8.96 10.25-15.46 19.431-15.46h53.3c32.257 0 58.5 26.243 58.5 58.5s-26.243 58.5-58.5 58.5h-53.29c-9.42 0-17.641-6.7-19.55-15.92-.95-4.63-5.061-7.98-9.78-7.98-2.542.015-6.235 1.31-8.36 4.5a9.93 9.93 0 0 0-1.439 7.521c1.83 8.89 6.72 16.97 13.75 22.77a40.054 40.054 0 0 0 11.68 6.68c4.39 1.61 9.021 2.421 13.74 2.431h170c10.689 0 20.729-4.16 28.28-11.72C406.84 492.729 411 482.689 411 472v-77c0-9.446-3.253-18.381-9.224-25.542 11.139-5.562 20.803-13.631 28.375-23.81C440.52 331.714 446 315.168 446 297.799V50c0-5.522-4.477-10-10-10zM166 60h50v30c0 5.522 4.478 10 10 10s10-4.478 10-10V20h50v50c0 5.522 4.478 10 10 10s10-4.478 10-10V40h50v50c0 5.522 4.478 10 10 10s10-4.478 10-10V60h50v122.5c0 27.57-22.43 50-50 50H216c-27.57 0-50-22.43-50-50zM94.401 240.387l-7.821-93.849 36.02-14.407 23.4 31.202V182.5c0 25.436 13.641 47.742 33.988 60-20.347 12.258-33.988 34.564-33.988 60v41.074a58.776 58.776 0 0 1-7.824-6.748c-25.218-26.054-40.764-60.304-43.775-96.439zM306.523 375H371c11.01.02 19.979 8.99 20 20v8.5h-64.22a78.836 78.836 0 0 0-20.257-28.5zM391 443.5h-58.883c.012-.092.012-.182.008-.272a78.87 78.87 0 0 0 .625-9.728c0-3.297-.228-6.539-.625-9.73a1.733 1.733 0 0 0-.008-.27H391zM371 492h-64.477a78.836 78.836 0 0 0 20.257-28.5H391v8.5c-.02 11.01-8.99 19.979-20 20zm13.477-137H181.203A59.883 59.883 0 0 1 166 353.023V302.5c0-27.57 22.43-50 50-50h160c28.972 0 50 19.051 50 45.299 0 26.301-16.626 49.111-41.523 57.201zM426 251.224a66.458 66.458 0 0 0-12.908-9.4A70.47 70.47 0 0 0 426 231.422z" />
            <circle cx="171" cy="433.14" r="10" />
            <path d="M257 140h16c5.522 0 10-4.478 10-10s-4.478-10-10-10h-16c-5.522 0-10 4.478-10 10s4.478 10 10 10zM318 140h17c5.522 0 10-4.478 10-10s-4.478-10-10-10h-17c-5.522 0-10 4.478-10 10s4.478 10 10 10zM196 140h16c5.522 0 10-4.478 10-10s-4.478-10-10-10h-16c-5.522 0-10 4.478-10 10s4.478 10 10 10zM380 140h16c5.522 0 10-4.478 10-10s-4.478-10-10-10h-16c-5.522 0-10 4.478-10 10s4.478 10 10 10z" />
            <circle cx="282.75" cy="433.5" r="10" />
        </g>
    </svg>
  );
};

export default MmaGloves;
