/** Import Externals */
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import Geocode from 'react-geocode'

import TextareaAutosize from 'react-textarea-autosize'
import { Divider } from '@mui/material'

/** Import Internals */
import Loader from 'assets/svg/loader'
import PictureModal from 'components/pictureModal'
import SportSelectorClub from 'components/sportSelector/sportSelectorClub'
import Upload from 'assets/svg/upload.svg'
import Check from 'assets/svg/check'
import Cross from 'assets/svg/cross'

/** Import Context */
import { UserContext } from 'data/context/userContext'
import { GlobalContext } from 'data/context/globalContext'

/** Import API */
import { ChangeUserClubId } from 'data/api/userQuerys'

import { CreateClub, ChangeClub } from 'data/api/clubQuerys'

/** Import Utils */
import theme from 'config/uiTheme'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    width: '100%',
    minHeight: '70vh',
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px',
    },
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    margin: '0 auto',
  },
  whiteBackground: {
    background: theme.palette.common.white,
    borderRadius: '10px',
    padding: '2vh 2vw',
    marginTop: '2vh',
  },
  whiteBackgroundError: {
    borderRadius: '10px 10px 0 0',
  },
  title: {
    color: theme.palette.common.black,
    fontSize: '20px',
    fontWeight: 500,
    margin: '1vH 1.5vW',
    cursor: 'default',
  },
  pictureContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2vH auto 8vH',
    position: 'relative',
    width: '95%',
  },
  landscapePicture: {
    width: '100%',
    height: '100%',
    maxHeight: '200px',
    cursor: 'pointer',
    borderRadius: '5px',
  },
  landscapeHoverPicture: {
    opacity: 0,
    background: theme.palette.common.black,
    position: 'absolute',
    transitionDuration: '500ms',
    '&:hover': {
      opacity: 0.8,
    },
  },
  profilePicture: {
    width: '8vW',
    height: '8vW',
    border: '2px solid',
    borderRadius: '200px',
    borderColor: theme.palette.common.white,
    background: theme.palette.common.white,
    cursor: 'pointer',
    position: 'absolute',
    top: '5vh',
    left: '2vW',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      width: '16vW',
      height: '16vW',
      top: '7vh',
    },
  },
  profileHoverPicture: {
    opacity: 0,
    background: theme.palette.common.black,
    transitionDuration: '500ms',
    '&:hover': {
      opacity: 0.8,
    },
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    margin: '0 auto',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2vH 0',
    [theme.breakpoints.down('md')]: {
      margin: '0',
      flexDirection: 'column',
    },
  },
  inputContainer: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0',
      width: '100%',
    },
  },
  inputLabel: {
    marginBottom: '1vh',
  },
  inputText: {
    border: '1px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    fontSize: '16px',
    height: '35px',
  },
  inputTextAreaContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '2vh auto',
  },
  inputTextArea: {
    border: '1px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    fontSize: '16px',
  },
  sportTags: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    padding: '1vh',
    margin: '1vh',
    borderRadius: '10px',
    background: theme.palette.common.darkGrey,
    color: theme.palette.common.white,
  },
  tagName: {
    marginRight: '10px',
  },
  buttonContainer: {
    display: 'flex',
    margin: '2vW 0',
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.green,
    fontWeight: 600,
    border: '2px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.green,
    padding: '.5vW',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
    },
  },
  cancelButton: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.lightGrey,
    fontWeight: 600,
    border: '2px solid',
    borderRadius: '10px',
    borderColor: theme.palette.common.lightGrey,
    padding: '.5vW',
    margin: '0 3vW',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.7rem',
    },
  },
  paymentButton: {
    display: 'flex',
    width: 'min-content',
    textAlign: 'center',
    color: theme.palette.common.white,
    background: theme.palette.common.green,
    fontWeight: 600,
    borderRadius: '10px',
    padding: '.5vW',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
    },
  },
  statusContainer: {
    height: '4vh',
    width: '90%',
    margin: '0 auto',
    textAlign: 'center',
    paddingBottom: '10px',
    background: theme.palette.common.white,
    borderRadius: '0 0 10px 10px',
    [theme.breakpoints.down('md')]: {
      height: '8vh',
    },
  },
  errorText: {
    color: theme.palette.common.red,
    fontWeight: 600,
  },
  errorInput: {
    color: theme.palette.common.red,
  },
  errorPicture: {
    border: '1px solid',
    borderColor: theme.palette.common.red,
  },
  successText: {
    color: theme.palette.common.green,
    fontWeight: 600,
  },
  displayNone: {
    display: 'none',
  },
})

function ClubForm() {
  const styles = useStyles()
  const { t, i18n } = useTranslation()

  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API_KEY}`)

  const {
    user,
    club,
    stripeAccountValid,
    newClubProfilePic,
    newClubLandscapePic,
  } = useContext(UserContext)

  const { setGlobalContext, accountReload } = useContext(GlobalContext)

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [cancel, setCancel] = useState(false)

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [location, setLocation] = useState('')
  const [sports, setSports] = useState([])
  const [description, setDescription] = useState('')
  const [insta, setInsta] = useState('')
  const [website, setWebsite] = useState('')
  const [imageProfile, setImageProfile] = useState('')
  const [imageLandscape, setImageLandscape] = useState('')
  const [profilePictureModal, setProfilePictureModal] = useState(false)
  const [landscapePictureModal, setLandscapePictureModal] = useState(false)

  useEffect(() => {
    if (user.club_id) {
      setName(club.name)
      setAddress(club.address)
      setZipCode(club.zip_code)
      setLocation(`${club.city}, ${club.country}`)
      setCity(club.city)
      setCountry(club.country)
      setDescription(club.description)
      if (club.sports) {
        let clubSports = club.sports.split(',')
        let sportsArray = []
        if (clubSports.length > 0) {
          clubSports.forEach((sport, index) => {
            return sportsArray.push({
              sport: sport,
              id: index,
            })
          })
          setSports(sportsArray)
        }
      }
      setInsta(club.insta_link)
      setWebsite(club.website)
      setImageProfile(club.profileImage || Upload)
      setImageLandscape(club.landscapeImage || Upload)
    }
  }, [club, cancel])

  useEffect(() => {
    setImageProfile(club.profileImage || newClubProfilePic || Upload)
    setImageLandscape(club.landscapeImage || newClubLandscapePic || Upload)
  }, [newClubProfilePic, newClubLandscapePic])

  const handleChangeName = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setName(e.target.value)
    } else {
      setError(t('account.club.error.tooLongString'))
    }
  }
  const handleChangeAddress = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setAddress(e.target.value)
    } else {
      setError(t('account.club.error.tooLongString'))
    }
  }
  const handleChangeZipCode = (e) => {
    if (e.target.value.length < 20) {
      setError('')
      setZipCode(e.target.value)
    } else {
      setError(t('account.club.error.tooLongString'))
    }
  }

  const handleChangeLocation = (e) => {
    let names = e.label.split(',')
    setCity(names[0])
    if (names[1]) {
      setCountry(names[names.length - 1])
    } else {
      setCountry(names[0])
    }
    setLocation(e.label)
  }

  const handleChangeInsta = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      const inputValue = e.target.value
      const regex = /^[a-z0-9._]{1,30}$/

      // Vérifie si l'entrée correspond à la regex
      if (regex.test(inputValue) || inputValue === '') {
        setInsta(inputValue)
      }
    } else {
      setError(t('account.club.error.tooLongString'))
    }
  }

  const handleChangeWebsite = (e) => {
    if (e.target.value.length < 250) {
      setError('')
      setWebsite(e.target.value)
    } else {
      setError(t('account.club.error.tooLongString'))
    }
  }

  const handleAddSport = (sport, index) => {
    if (sports.length < 10) {
      setSports((oldArray) => [
        ...oldArray,
        {
          sport: sport,
          id: index,
        },
      ])
    }
  }
  const handleDeleteSport = (id) => {
    let newArray = [...sports]
    let index = newArray.findIndex((e) => e.id === id)
    newArray.splice(index, 1)
    setSports(newArray)
  }

  const handleChangeDescription = (e) => {
    if (e.target.value.length < 1500) {
      setError('')
      setDescription(e.target.value)
    } else {
      setError(t('account.club.error.tooLongString'))
    }
  }

  const handleSaveClub = async () => {
    setLoading(true)
    setSuccess('')
    setError('')
    const regex = /^https:\/\/([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}(:\d+)?(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?$/
    if (website !== '' && !regex.test(website)) {
      setError(t('account.club.error.wrongUrl'))
      return setLoading(false)
    }
    if (imageProfile === Upload || imageLandscape === Upload) {
      setError(t('account.club.error.missingField'))
      return setLoading(false)
    }
    let clubSports = ''
    sports.forEach((sport, index) => {
      if (index === 0) {
        return (clubSports = `${sport.sport}`)
      } else {
        return (clubSports = `${clubSports},${sport.sport}`)
      }
    })
    Geocode.fromAddress(`${address} ${zipCode}, ${city} ${country}`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        if (!user.club_id) {
          CreateClub(
            name,
            address,
            zipCode,
            city,
            country,
            lat,
            lng,
            clubSports,
            description,
            insta,
            website,
            imageProfile,
            imageLandscape,
            i18n.resolvedLanguage,
          )
            .then((resIdClub) => {
              ChangeUserClubId(resIdClub.data.club_id)
                .then(() => {
                  setSuccess(t('account.club.success.create'))
                  setGlobalContext({ accountReload: !accountReload })
                  setLoading(false)
                })
                .catch((err) => {
                  setError(t('account.profile.error.modif'))
                  setLoading(false)
                })
            })
            .catch((error) => {
              if (error?.response?.data) {
                if (error.response.data === 'Club already exist') {
                  setError(t('account.club.error.exist'))
                } else if (error.response.data === 'Missing fields') {
                  setError(t('account.club.error.missingField'))
                } else {
                  setError(t('account.club.error.create'))
                }
              } else {
                setError(t('account.club.error.create'))
              }
              setLoading(false)
            })
        } else {
          ChangeClub(
            name,
            address,
            zipCode,
            city,
            country,
            lat,
            lng,
            clubSports,
            description,
            insta,
            website,
          )
            .then(() => {
              setSuccess(t('account.club.success.modif'))
              setGlobalContext({ accountReload: !accountReload })
              setLoading(false)
            })
            .catch(() => {
              setError(t('account.club.error.modif'))
              setLoading(false)
            })
        }
      },
      (error) => {
        setError(t('account.club.error.localisation'))
        setLoading(false)
      },
    )
  }

  const handleGoOptions = () => {
    setGlobalContext({ accountPage: 'settings' })
  }

  return (
    <div className={styles.container}>
      {profilePictureModal ? (
        <PictureModal
          close={() => setProfilePictureModal(false)}
          type={'profile'}
          page={'club'}
          error={(message) => setError(message)}
          setProfile={setImageProfile}
        />
      ) : landscapePictureModal ? (
        <PictureModal
          close={() => setLandscapePictureModal(false)}
          type={'landscape'}
          page={'club'}
          error={(message) => setError(message)}
          setLandscape={setImageLandscape}
        />
      ) : (
        <div className={styles.profileContainer}>
          <div className={styles.title}>{t('account.club.title')}</div>
          <Divider />
          <div
            className={clsx(
              styles.whiteBackground,
              error || success ? styles.whiteBackgroundError : null,
            )}
          >
            <div className={styles.pictureContainer}>
              {imageLandscape && imageLandscape !== '' ? (
                <>
                  <img
                    className={clsx(
                      styles.landscapePicture,
                      error && imageProfile === Upload
                        ? styles.errorPicture
                        : null,
                    )}
                    src={imageLandscape}
                    alt={'landscape'}
                    onClick={() => setLandscapePictureModal(true)}
                  />
                  <img
                    className={clsx(
                      styles.landscapePicture,
                      styles.landscapeHoverPicture,
                    )}
                    src={Upload}
                    alt={'landscapeHover'}
                    onClick={() => setLandscapePictureModal(true)}
                  />
                </>
              ) : null}
              {imageProfile && imageProfile !== '' ? (
                <>
                  <img
                    className={clsx(
                      styles.profilePicture,
                      error && imageProfile === Upload
                        ? styles.errorPicture
                        : null,
                    )}
                    src={imageProfile}
                    alt={'profile'}
                    onClick={() => setProfilePictureModal(true)}
                  />
                  <img
                    className={clsx(
                      styles.profilePicture,
                      styles.profileHoverPicture,
                    )}
                    src={Upload}
                    alt={'profileHover'}
                    onClick={() => setProfilePictureModal(true)}
                  />
                </>
              ) : null}
            </div>
            <div className={styles.fields}>
              <div className={styles.line}>
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error && name === '' ? styles.errorInput : null,
                    )}
                  >
                    {t('account.club.name')}:
                  </label>
                  <input
                    className={styles.inputText}
                    type="text"
                    value={name}
                    onChange={(e) => handleChangeName(e)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error && address === '' ? styles.errorInput : null,
                    )}
                  >
                    {t('account.club.address')}:
                  </label>
                  <input
                    className={styles.inputText}
                    type="text"
                    value={address}
                    onChange={(e) => handleChangeAddress(e)}
                  />
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error && zipCode === '' ? styles.errorInput : null,
                    )}
                  >
                    {t('account.club.zipCode')}:
                  </label>
                  <input
                    className={styles.inputText}
                    type="text"
                    value={zipCode}
                    onChange={(e) => handleChangeZipCode(e)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error && city === '' ? styles.errorInput : null,
                    )}
                  >
                    {t('account.club.cityCountry')}:
                  </label>
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    selectProps={{
                      placeholder: city
                        ? `${location}`
                        : `${t('account.club.cityCountryPlaceholder')}`,
                      onChange: handleChangeLocation,
                      styles: {
                        control: (provided) => ({
                          ...provided,
                          borderRadius: '10px',
                          cursor: 'pointer',
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          color: 'red',
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: 'none',
                        }),
                        loadingIndicator: (provided) => ({
                          ...provided,
                          display: 'none',
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: theme.palette.common.darkGrey,
                          height: '30px',
                          width: '6rem',
                          maxWidth: '300px',
                          minWidth: '100px',
                          lineHeight: '26px',
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: theme.palette.common.darkGrey,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: theme.palette.common.darkGrey,
                        }),
                      },
                    }}
                  />
                </div>
              </div>
              <div className={styles.line}>
                <div className={styles.inputContainer}>
                  <label className={styles.inputLabel}>
                    {t('account.club.instagram')}:
                  </label>
                  <input
                    className={styles.inputText}
                    type="text"
                    value={insta}
                    placeholder="ex: jutsuevent"
                    label={t('account.club.instagram')}
                    onChange={(e) => handleChangeInsta(e)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label
                    className={clsx(
                      styles.inputLabel,
                      error === t('account.club.error.wrongUrl')
                        ? styles.errorInput
                        : null,
                    )}
                  >
                    {t('account.club.website')}:
                  </label>
                  <input
                    className={styles.inputText}
                    type="text"
                    value={website}
                    placeholder="ex: https://www.jutsuevent.com/"
                    label={t('account.club.website')}
                    onChange={(e) => handleChangeWebsite(e)}
                  />
                </div>
              </div>
              <SportSelectorClub
                addSport={handleAddSport}
                error={error && sports.length === 0 ? error : null}
              />
              <div className={styles.sportTags}>
                {sports.length > 0
                  ? sports.map((sport) => {
                      return (
                        <div
                          className={styles.tag}
                          key={sport.id}
                          onClick={() => {
                            handleDeleteSport(sport.id)
                          }}
                        >
                          <div className={styles.tagName}>
                            {t(`sports.${sport.sport}`)}
                          </div>
                          <Cross
                            size={15}
                            color={theme.palette.common.darkWhite}
                          />
                        </div>
                      )
                    })
                  : null}
              </div>
              <div className={styles.line}>
                <div className={styles.inputTextAreaContainer}>
                  <label className={styles.inputLabel}>
                    {t('account.club.description')}:
                  </label>
                  <TextareaAutosize
                    className={styles.inputTextArea}
                    minRows={3}
                    maxRows={6}
                    value={description}
                    onChange={(e) => handleChangeDescription(e)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className={styles.saveButton} onClick={handleSaveClub}>
                    <Check size={20} color={theme.palette.common.green} />
                    {t('account.club.save')}
                  </div>
                  <div
                    className={styles.cancelButton}
                    onClick={() => setCancel(!cancel)}
                  >
                    <Cross size={20} color={theme.palette.common.lightGrey} />
                    {t('account.club.cancel')}
                  </div>
                  {user.club_id && !stripeAccountValid ? (
                    <div
                      className={styles.paymentButton}
                      onClick={handleGoOptions}
                    >
                      {t('account.club.payment')}
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={clsx(
          styles.statusContainer,
          profilePictureModal || landscapePictureModal
            ? styles.displayNone
            : null,
        )}
      >
        {error ? (
          <div className={styles.errorText}>{error}</div>
        ) : success ? (
          <div className={styles.successText}>{success}</div>
        ) : null}
      </div>
    </div>
  )
}

export default ClubForm
