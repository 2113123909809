import React from "react";

const ImageIcon = (props) => {
  let size = props.size || 50;
  let color = props.color || "#4d4d4d"

  return (
    <svg height={size/2} width={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
	    <path fill={color} d="M486.4 68.267H25.6C11.483 68.267 0 79.75 0 93.867v324.267c0 14.117 11.483 25.6 25.6 25.6h460.8c14.117 0 25.6-11.483 25.6-25.6V93.867c0-14.117-11.483-25.6-25.6-25.6zm-460.8 358.4c-4.704 0-8.533-3.829-8.533-8.533V312.271l79.371-79.375c3.208-3.25 8.675-3.292 11.967.042l84.343 84.265L83.251 426.667H25.6zm469.333-8.534c0 4.704-3.829 8.533-8.533 8.533H107.388l253.583-253.504c3.208-3.25 8.679-3.292 11.963.042l122 121.918v123.011zm0-147.143L385.037 161.171c-9.675-9.792-26.571-9.75-36.171-.042l-144.05 144.009-84.311-84.233c-9.675-9.792-26.571-9.754-36.171-.037l-67.267 67.27V93.867c0-4.704 3.829-8.533 8.533-8.533h460.8c4.704 0 8.533 3.829 8.533 8.533V270.99z" />
	    <path fill={color} d="M213.333 128c-28.233 0-51.2 22.967-51.2 51.2s22.967 51.2 51.2 51.2c28.233 0 51.2-22.967 51.2-51.2s-22.966-51.2-51.2-51.2zm0 85.333c-18.821 0-34.133-15.313-34.133-34.133s15.312-34.133 34.133-34.133c18.821 0 34.133 15.313 34.133 34.133s-15.312 34.133-34.133 34.133z" />
    </svg>
  );
};

export default ImageIcon;