/** Import Externals */
import React, { useState, useEffect, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import HomeIcon from '@mui/icons-material/Home'
import EuroIcon from '@mui/icons-material/Euro'
import PeopleIcon from '@mui/icons-material/People'

/** Import Internals */
import RegisterList from 'scenes/EventDisplay/registerList'
import ConfirmModal from 'scenes/EventDisplay/confirmModal'
import PayingEventModal from 'scenes/EventDisplay/payingEventModal'
import EventImagesSlider from 'scenes/EventDisplay/eventImagesSlider'
import SmallMap from 'components/smallMap'
import Loader from 'assets/svg/loader'

/** Import Context */
import { SearchContext } from 'data/context/searchContext'
import { UserContext } from 'data/context/userContext'
import { GlobalContext } from 'data/context/globalContext'

/** Import API */
import {
  GetEvent,
  SubscribeEvent,
  UnSubscribeEvent,
  DeleteEvent,
} from 'data/api/eventQuerys'
import { GetClub } from 'data/api/clubQuerys'

/** Import Utils */
import { getIconSport, DisplayFormattedText } from 'data/utils'
import theme from 'config/uiTheme'
import QrCodeModal from './qrCodeModal'
import { LocalConvenienceStoreOutlined } from '@mui/icons-material'

const useStyles = makeStyles({
  container: {
    width: '100%',
    cursor: 'default',
    background: theme.palette.common.darkWhite,
  },
  loader: {
    height: '70vh',
    width: 'max-content',
    margin: '30vh auto',
  },
  subContainer: {
    width: '80%',
    margin: '5vh auto',
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      margin: '10px auto',
    },
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      margin: '0 auto',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    borderRadius: '10px',
    padding: '2vH 2vW',
    marginBottom: '2vh',
    boxShadow: '0px 3px 13px rgba(30, 25, 37, 0.1)',
  },
  topDate: {
    fontWeight: 300,
  },
  topName: {
    margin: '2vh 0',
    fontSize: '2.2rem',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem',
    },
  },
  topClub: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: '100%',
  },
  clubPicture: {
    width: '4rem',
    height: '4rem',
    borderRadius: '200px',
  },
  clubText: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    width:'100%',
    margin: '0 1vw',
  },
  host: {
    color: theme.palette.common.grey,
    margin: '.5vh 0',
  },
  clubName: {
    fontWeight: 500,
    fontSize: '1.2rem',
    overflow: 'hidden',
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
  },
  eventInfos: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    borderRadius: '10px',
  },
  imageSolo: {
    width: '100%',
    borderRadius: '10px',
    margin: '0 auto',
  },
  descriptionSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5vh 2vw 2vh',
  },
  equipmentSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2vh 2vw',
  },
  sectionTitle: {
    fontSize: '1.2rem',
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  description: {
    margin: '2vh',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  minimumContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '2vh',
    fontWeight: 600,
    color: theme.palette.common.grey,
  },
  minimumSport: {
    margin: '0 1vw',
    color: theme.palette.common.black,
  },
  registersSection: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    borderRadius: '10px',
    margin: '2vh 0',
    padding: '2vh 2vW',
  },
  noRegister: {
    color: theme.palette.common.lightGrey,
    textAlign: 'center',
    margin: '10% 0',
    fontSize: '2rem',
    fontWeight: 700,
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: theme.palette.common.darkGrey,
    borderRadius: '10px',
    width: '28%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      margin: '10px auto',
    },
  },
  iconNameSport: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  sportIcon: {
    borderRadius: '200px',
    padding: '1vh',
    background: theme.palette.common.darkWhite,
  },
  sportText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    fontWeight: 600,
    fontSize: '1.3rem',
  },
  sportLevel: {
    color: theme.palette.common.grey,
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    marginTop: '10%',
    padding: '0 1% 0 5%',
  },
  rightContent: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    fontWeight: 600,
    marginLeft: '1vW',
    color: theme.palette.common.white,
    textDecoration: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateBottomLine: {
    marginTop: '1vh',
  },
  registerButton: {
    textAlign: 'center',
    borderRadius: '10px',
    fontSize: '1.2rem',
    fontWeight: 700,
    padding: '5%',
    margin: '0 5% 5% 5%',
    cursor: 'pointer',
  },
  subscribe: {
    background: theme.palette.common.green,
    color: theme.palette.common.white,
  },
  subscribed: {
    color: theme.palette.common.green,
    cursor: 'default',
  },
  unsubscribe: {
    color: theme.palette.common.darkWhite,
    opacity: 0.7,
  },
  deleteButton: {
    textAlign: 'center',
    borderRadius: '10px',
    fontSize: '1.2rem',
    fontWeight: 700,
    padding: '5%',
    margin: '0 5% 5% 5%',
    cursor: 'pointer',
    color: theme.palette.common.red,
  },
  errorMessage: {
    textAlign: 'center',
    color: theme.palette.common.white,
    fontWeight: 600,
    margin: '5%',
  },
  loadingDiv: {
    height: '100vh',
  },
})

function EventInfos() {
  const styles = useStyles()
  const { t, i18n } = useTranslation()
  let history = useHistory()
  let { id } = useParams()

  const { listEvents } = useContext(SearchContext)
  const { user } = useContext(UserContext)
  const { setGlobalContext, qrCodeOpen } = useContext(GlobalContext)

  const [eventState, setEventState] = useState({})
  const [eventSport, setEventSport] = useState({})
  const [clubState, setClubState] = useState({})
  const [clubPicture, setClubPicture] = useState('')
  const [mapsLink, setMapsLink] = useState({})
  const [nbRegisters, setNbRegisters] = useState([])
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [payingEventModal, setPayingEventModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [isExpandedEquip, setIsExpandedEquip] = useState(false)
  const [isExpandedDesc, setIsExpandedDesc] = useState(false)

  useEffect(() => {
    setGlobalContext({ qrCodeOpen: false })
    let event = listEvents.find((e) => e.event_id === id)
    if (!event) {
      GetEvent(id)
        .then((result) => {
          setEventState(result.data)
          setMapsLink(
            `https://www.google.com/maps/place/${result.data.event_address.replace(
              / /g,
              '+',
            )},+${result.data.event_zip_code}+${result.data.event_city}`,
          )
          if (result.data.event_registers !== '') {
            setNbRegisters(result.data.event_registers.split(','))
          }
          GetClub(result.data.event_club_id)
            .then((res) => {
              setClubState(res.data)
              res.data.image.forEach((image) => {
                if (image.image_name.includes('profile')) {
                  return setClubPicture(image.image_file)
                }
              })
            })
            .catch((errorGetClub) => {
              history.push('/')
            })
        })
        .catch((errorGetEvent) => {
          if (errorGetEvent?.response) {
            if (errorGetEvent?.response?.status === 404) {
              history.push('/')
            }
          } else {
            history.push('/')
          }
        })
    } else {
      setEventState(event)
      setMapsLink(
        `https://www.google.com/maps/place/${event.event_address.replace(
          / /g,
          '+',
        )},+${event.event_zip_code}+${event.event_city}`,
      )
      if (event.event_registers !== '') {
        setNbRegisters(event.event_registers.split(','))
      }
      GetClub(event.event_club_id)
        .then((res) => {
          setClubState(res.data)
          res.data.image.forEach((image) => {
            if (image.image_name.includes('profile')) {
              return setClubPicture(image.image_file)
            }
          })
        })
        .catch((errorGetClub) => {
          history.push('/')
        })
    }
  }, [])

  useEffect(() => {
    if (eventState.event_sport) {
      let sportLevel = eventState.event_sport.split('/')
      setEventSport({
        sport: t(`sports.${sportLevel[0]}`),
        level: t(`grades.${sportLevel[1]}`),
        iconImage: getIconSport(sportLevel[0], sportLevel[1]),
      })
    }
  }, [eventState])

  useEffect(() => {}, [nbRegisters])

  let unregistrable = nbRegisters.find((e) => e === `${user.user_id}`)
  let fullPlaces = nbRegisters.length >= eventState.event_places

  const startDate = new Date(eventState.event_start)
  const endDate = new Date(eventState.event_end)

  const handleRegister = () => {
    if (user.user_id && !unregistrable) {
      if (!fullPlaces) {
        let now = new Date()
        if (endDate.getTime() * 60 * 1000 > now.getTime()) {
          if (eventState.event_price > 0) {
            setPayingEventModal(true)
            window.scrollTo(0, 0)
          } else {
            handleSubscribe()
          }
        } else {
          if (endDate.getTime() < now.getTime()) {
            setErrorMsg(t('events.errors.past'))
          } else if (startDate.getTime() < now.getTime()) {
            setErrorMsg(t('events.errors.started'))
          } else {
            setErrorMsg(t('events.errors.close'))
          }
        }
      }
    } else if (user.user_id && unregistrable) {
      setConfirmationModal('unregister')
      window.scrollTo(0, 0)
    } else {
      history.push('/login')
    }
  }

  const handleSubscribe = () => {
    SubscribeEvent(eventState.event_id, i18n.resolvedLanguage).then((res) => {
      if (res?.data) {
        if (res.data.message) {
          setErrorMsg(res.data.message)
        }
      } else {
        if (nbRegisters[0] === '') {
          setNbRegisters([`${user.user_id}`])
        } else {
          setNbRegisters((oldArray) => [...oldArray, `${user.user_id}`])
        }
        setErrorMsg(t('events.successRegister'))
      }
    })
  }

  const handleUnregister = () => {
    let now = new Date()
    if (startDate.getTime() - 60 * 60 * 1000 > now.getTime()) {
      UnSubscribeEvent(eventState.event_id, i18n.resolvedLanguage).then(
        (res) => {
          setConfirmationModal(false)
          if (res.data) {
            if (res.data.message) {
              setErrorMsg(res.data.message)
            }
          } else {
            let newArray = nbRegisters.filter((value) => {
              return value !== `${user.user_id}`
            })
            setNbRegisters(newArray)
            setErrorMsg(t('events.successUnregister'))
          }
        },
      )
    } else {
      setConfirmationModal(false)
      setErrorMsg(t('events.errors.close'))
    }
  }

  const handleCloseModal = () => {
    setConfirmationModal(false)
    setPayingEventModal(false)
  }

  const handleOpenQrcode = () => {
    setGlobalContext({ qrCodeOpen: true })
    window.scrollTo(0, 0)
  }

  const handleDeleteEvent = () => {
    DeleteEvent(eventState.event_id, i18n.resolvedLanguage).then(
      (resultDelete) => {
        history.push('/')
      },
    )
  }

  const now = Date.now()
  const startTime = new Date(eventState.event_start).getTime()

  return (
    <div className={styles.container}>
      {eventState.event_name ? (
        <>
          {confirmationModal ? (
            <ConfirmModal
              close={handleCloseModal}
              unsubscribe={
                confirmationModal === 'unregister'
                  ? handleUnregister
                  : handleDeleteEvent
              }
              type={confirmationModal}
            />
          ) : payingEventModal ? (
            <PayingEventModal
              close={handleCloseModal}
              subscribe={handleSubscribe}
              event={eventState}
            />
          ) : qrCodeOpen ? (
            <QrCodeModal event={eventState} user={user} />
          ) : (
            <div className={styles.subContainer}>
              <div className={styles.leftSection}>
                <div className={styles.header}>
                  <div className={styles.topDate}>
                    <div>{moment(eventState.event_start).format('LLL')}</div>
                  </div>
                  <div className={styles.topName}>{eventState.event_name}</div>
                  <div
                    className={styles.topClub}
                    onClick={() => {
                      history.push(`/club/${clubState.club_id}`)
                      window.scrollTo(0, 0)
                    }}
                  >
                    <img
                      className={styles.clubPicture}
                      src={clubPicture}
                      alt="club Profile"
                    />
                    <div className={styles.clubText}>
                      <div className={styles.host}>{t('events.host')}</div>
                      <div className={styles.clubName}>
                        {clubState.club_name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.eventInfos}>
                  {eventState.image ? (
                    eventState.image.length > 1 ? (
                      <EventImagesSlider array={eventState.image} />
                    ) : (
                      <img
                        className={styles.imageSolo}
                        alt="event"
                        src={eventState.image[0].image_file}
                      />
                    )
                  ) : null}
                  <div className={styles.descriptionSection}>
                    <div className={styles.sectionTitle}>
                      {t('events.description')}
                    </div>
                    <div className={styles.description}>
                      {DisplayFormattedText(
                        eventState.event_description,
                        isExpandedDesc,
                        setIsExpandedDesc,
                        t('components.utils.expanded'),
                        t('components.utils.collapsed'),
                      )}
                    </div>
                  </div>
                  <div className={styles.equipmentSection}>
                    <div className={styles.sectionTitle}>
                      {t('events.equipments')}
                    </div>
                    <div className={styles.description}>
                      {DisplayFormattedText(
                        eventState.event_equipments,
                        isExpandedEquip,
                        setIsExpandedEquip,
                        t('components.utils.expanded'),
                        t('components.utils.collapsed'),
                      )}
                    </div>
                  </div>
                  <div className={styles.equipmentSection}>
                    <div className={styles.sectionTitle}>
                      {t('events.minimumLevel')}
                    </div>
                    <div className={styles.minimumContainer}>
                      <div className={styles.sportIcon}>
                        {eventSport.iconImage}
                      </div>
                      <div className={styles.minimumSport}>
                        {eventSport.sport}
                      </div>
                      <div className={styles.minimumLevel}>
                        {eventSport.level}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.registersSection}>
                  {nbRegisters[0] !== '' ? (
                    <div className={styles.sectionTitle}>
                      {t('events.registers')} ({nbRegisters.length})
                    </div>
                  ) : (
                    <div className={styles.sectionTitle}>
                      {t('events.registers')} (0)
                    </div>
                  )}
                  {nbRegisters[0] === '' || !nbRegisters[0] ? (
                    <div className={styles.noRegister}>
                      {t('events.noRegisters')}
                    </div>
                  ) : (
                    <RegisterList array={nbRegisters} />
                  )}
                </div>
              </div>
              <div className={styles.rightSection}>
                <div className={styles.rightContainer}>
                  <div className={styles.iconNameSport}>
                    <div className={styles.sportIcon}>
                      {eventSport.iconImage}
                    </div>
                    <div className={styles.sportText}>
                      <div>{eventSport.sport}</div>
                      <div className={styles.sportLevel}>
                        {eventSport.level}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <AccessTimeIcon />
                  {startDate.getDay() === endDate.getDay() ? (
                    <div className={styles.rightContent}>
                      {moment(eventState.event_start).format(
                        'dddd D MMMM YYYY',
                      )}
                      <div>
                        {moment(eventState.event_start).format('HH:mm') +
                          ' - ' +
                          moment(eventState.event_end).format('HH:mm')}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.rightContent}>
                      {moment(eventState.event_start).format(
                        'dddd D MMMM YYYY',
                      )}{' '}
                      {moment(eventState.event_start).format('HH:mm')} -
                      <div className={styles.dateBottomLine}>
                        {moment(eventState.event_end).format(
                          'dddd D MMMM YYYY',
                        )}{' '}
                        {moment(eventState.event_end).format('HH:mm')}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.rightContainer}>
                  <HomeIcon />
                  <a
                    href={mapsLink}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.rightContent}
                  >
                    <div>
                      {eventState.event_address}, {eventState.event_city}
                    </div>
                  </a>
                </div>
                <div className={styles.rightContainer}>
                  <EuroIcon />
                  <div className={styles.rightContent}>
                    <div>
                      {eventState.event_price < 1
                        ? t('events.free')
                        : eventState.event_price + ' €'}
                    </div>
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  <PeopleIcon />
                  <div className={styles.rightContent}>
                    <div>
                      {nbRegisters[0] !== ''
                        ? nbRegisters.length + ' / ' + eventState.event_places
                        : '0 / ' + eventState.event_places}
                    </div>
                  </div>
                </div>
                <div className={styles.rightContainer}>
                  {eventState.event_lat ? (
                    <SmallMap event={eventState} link={mapsLink} />
                  ) : null}
                </div>
                {unregistrable ? (
                  <div
                    className={clsx(styles.registerButton, styles.subscribe)}
                    onClick={handleOpenQrcode}
                  >
                    {t('events.qrCode')}
                  </div>
                ) : null}
                {eventState.event_price > 1 && unregistrable ? (
                  <div
                    className={clsx(styles.registerButton, styles.subscribed)}
                  >
                    {t('events.subscribed')}
                  </div>
                ) : (
                  <div
                    className={clsx(
                      styles.registerButton,
                      unregistrable || fullPlaces
                        ? styles.unsubscribe
                        : styles.subscribe,
                    )}
                    onClick={handleRegister}
                  >
                    {unregistrable
                      ? t('events.unsubscribe')
                      : fullPlaces
                      ? t('events.fullPlaces')
                      : user.user_id
                      ? t('events.subscribe')
                      : t('events.connexion')}
                  </div>
                )}
                {user.club_id === eventState.event_club_id && now < startTime ? (
                  <div
                    className={styles.deleteButton}
                    onClick={() => {
                      setConfirmationModal('delete')
                    }}
                  >
                    {t('events.delete')}
                  </div>
                ) : null}
                {errorMsg ? (
                  <div className={styles.errorMessage}>{errorMsg}</div>
                ) : null}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default EventInfos
