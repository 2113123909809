/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";

/** Import Internals */
import TopBar from "components/topBar";
import Footer from "components/footer";
import CguText from "./cguText";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    background: theme.palette.common.darkWhite,
  },
});

function Cgu() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <TopBar />
      <CguText />
      <Footer />
    </div>
  );
}

export default Cgu;
