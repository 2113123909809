import React from "react";

const YogaMat = (props) => {
  let size = props.size || 50;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 58 58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path
          d="M50 21c0 1-6.8 5-12.6 5-3.14 0-5.35-.66-6.71-1.71a2.366 2.366 0 0 1-3.38 0C25.95 25.34 23.74 26 20.6 26 14.8 26 8 22 8 21c0-.83 4.72-3.75 9.65-4.69-2.82-4.16-4.1-9.58-3.51-10.17s6.01.69 10.17 3.51C25.25 4.72 28.17 0 29 0s3.75 4.72 4.69 9.65c4.16-2.82 9.58-4.1 10.17-3.51s-.69 6.01-3.51 10.17C45.28 17.25 50 20.17 50 21z"
          fill="#26b99a"
        />
        <path
          d="M49 57.99V58H4a4 4 0 0 1-4-4v-1.657c0-.227.039-.452.114-.666L5.06 37.663a1 1 0 0 1 1.755-.244c.11.145.228.282.355.411A4.025 4.025 0 0 0 10 39h27.44z"
          fill="#955ba5"
        />
        <path
          d="M8 55H6a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zM38 55H12a1 1 0 0 1 0-2h26a1 1 0 0 1 0 2z"
          fill="#b49aca"
        />
        <path
          d="m41.17 51.21-.05.01L37.44 39l-.03-.09v-.01l-.31-1.2v-.01a6.668 6.668 0 0 1-.1-1.19 7.5 7.5 0 0 1 14.41-2.92h.01l5.67 12.1z"
          fill="#894b9d"
        />
        <path
          d="M34 12.6a16.169 16.169 0 0 1-.4 3.8 9.8 9.8 0 0 0-1.97.71C30.93 14.39 29.45 12 29 12s-1.93 2.39-2.63 5.11a8.715 8.715 0 0 0-1.97-.71 16.169 16.169 0 0 1-.4-3.8c.005-.991.109-1.98.31-2.95C25.25 4.72 28.17 0 29 0s3.75 4.72 4.69 9.65c.201.97.305 1.959.31 2.95z"
          fill="#14a085"
        />
        <path
          d="M32 19.8c0 2.23-.52 3.7-1.31 4.49a2.366 2.366 0 0 1-3.38 0C26.52 23.5 26 22.03 26 19.8c.014-.908.138-1.812.37-2.69.7-2.72 2.18-5.11 2.63-5.11s1.93 2.39 2.63 5.11c.232.878.356 1.782.37 2.69z"
          fill="#81ccb8"
        />
        <path
          d="M24.4 16.4a16.169 16.169 0 0 0-3.8-.4c-.991.005-1.98.109-2.95.31-2.82-4.16-4.1-9.58-3.51-10.17s6.01.69 10.17 3.51c-.201.97-.305 1.959-.31 2.95a16.169 16.169 0 0 0 .4 3.8zM40.35 16.31A14.937 14.937 0 0 0 37.4 16a16.169 16.169 0 0 0-3.8.4c.283-1.246.417-2.522.4-3.8a14.937 14.937 0 0 0-.31-2.95c4.16-2.82 9.58-4.1 10.17-3.51s-.69 6.01-3.51 10.17z"
          fill="#33907c"
        />
        <path
          d="M58 49.5a8.506 8.506 0 0 1-8.5 8.5c-.17 0-.33 0-.5-.01a8.517 8.517 0 0 1-7.83-6.78 8.5 8.5 0 0 1 15.92-5.53l.21.45.18.45c.346.934.522 1.923.52 2.92z"
          fill="#5d4c72"
        />
        <circle cx="49.5" cy="49.5" fill="#3d324c" r="4.5" />
      </g>
    </svg>
  );
};

export default YogaMat;
