import React from "react";

const Eject = (props) => {
  let size = props.size || 50;
  let color = props.color || "white"

  return (
    <svg height={size} width={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M25 21H7a1 1 0 0 1-.823-1.569l9-13c.373-.539 1.271-.539 1.645 0l9 13a.998.998 0 0 1 .063 1.033A.997.997 0 0 1 25 21zM8.908 19h14.184L16 8.757zM25 26H7a1 1 0 1 1 0-2h18a1 1 0 1 1 0 2z"/>  
    </svg>
  );
};

export default Eject;