/** Import Externals */
import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId, getLatLng } from "react-google-places-autocomplete";

import Select from "react-select";

/** Import Internals */
import SearchIcon from "assets/svg/search";

/** Import Context */
import { SearchContext } from "data/context/searchContext";
import { GlobalContext } from "data/context/globalContext";

/** Import API */
import { GetSports } from "data/api/sportQuerys";

/** Import Utils */
import theme from "../../config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "0 10px",
  },
  searchButton: {
    color: theme.palette.common.white,
    background: theme.palette.common.green,
    borderRadius: "0 5px 5px 0",
    padding: "7px",
    cursor: "pointer",
  },
});

function SearchField() {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const { setSearchContext, sportsList, city } = useContext(SearchContext);
  const { accountReload } = useContext(GlobalContext);

  const [sportsListState, setSportsListState] = useState([]);
  const [locationState, setLocationState] = useState("all");

  useEffect(() => {
    if (sportsList.length === 0) {
      GetSports()
        .then((res) => {
          setSearchContext({ sportsList: res.data });
          let options = res.data.map((sport) => {
            return {
              label: t(`sports.${sport.sport_name}`),
              value: sport.sport_name,
            };
          });
          setSportsListState(options);
        })
        .catch((err) => {});
    } else {
      let options = sportsList.map((sport) => {
        return {
          label: t(`sports.${sport.sport_name}`),
          value: sport.sport_name,
        };
      });
      setSportsListState(options);
    }
    setLocationState(city);
  }, [accountReload]);

  const handleChangeSport = (e) => {
    setSearchContext({ sport: e.value });
  };

  const handleChangeLocation = (e) => {
    geocodeByPlaceId(e.value.place_id)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setSearchContext({ city: e.label, lat: lat, lng: lng });
      });
    setLocationState(e.label);
  };

  const handleSearch = () => {
    history.push("/events");
  };

  return (
    <div className={styles.container}>
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        selectProps={{
          placeholder: t(`search.noLocation`),
          locationState,
          onChange: handleChangeLocation,
          styles: {
            control: (provided) => ({
              ...provided,
              borderRadius: "5px 0 0 5px",
              border: "none",
              cursor: "pointer",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            loadingIndicator: (provided) => ({
              ...provided,
              display: "none",
            }),
            input: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
              height: "30px",
              width: "6rem",
              maxWidth: "300px",
              minWidth: "100px",
              lineHeight: "26px",
            }),
            option: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
            }),
            singleValue: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
            }),
          },
        }}
      />
      <Select
        options={sportsListState}
        onChange={handleChangeSport}
        placeholder={t(`search.allSports`)}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: "0px",
            border: "none",
            cursor: "pointer",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          placeholder: (provided) => ({
            ...provided,
            lineHeight: "16px",
          }),
          input: (provided) => ({
            ...provided,
            color: theme.palette.common.darkGrey,
            textAlign: "center",
            paddingTop: "5px",
            height: "30px",
            width: "6rem",
            maxWidth: "300px",
            minWidth: "100px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? 600 : 400,
            color: state.isSelected
              ? theme.palette.common.darkGrey
              : theme.palette.common.grey,
            background: theme.palette.common.white,
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.common.darkGrey,
            },
          }),
          singleValue: (provided) => ({
            ...provided,
            color: theme.palette.common.darkGrey,
          }),
        }}
      />
      <div className={styles.searchButton} onClick={handleSearch}>
        <SearchIcon size={20} />
      </div>
    </div>
  );
}

export default SearchField;
