/** Import Externals */
import React, { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Divider } from "@mui/material";

/** Import Internals */
import ClubForm from "./clubForm";

/** Import Context */
import { UserContext } from "data/context/userContext";

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    minHeight: "75vh",
    cursor: "default",
  },
  noClubContainer: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    margin: "0 auto",
  },
  title: {
    color: theme.palette.common.black,
    fontSize: "20px",
    fontWeight: 500,
    margin: "1vH 1.5vW",
    cursor: "default",
  },
  textSection: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.common.white,
    borderRadius: "10px",
    padding: "2vh 2vw",
    marginTop: "2vh",
    textAlign: "center",
  },
  text1: {
    fontSize: "1.5rem",
    marginTop: "2vh",
  },
  text2: {
    fontSize: "1.5rem",
    marginBottom: "2vh",
  },
  text3: {
    color: theme.palette.common.grey,
    fontSize: "0.9rem",
    margin: "2vh 0",
  },
  cgu: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  creationButton: {
    display: "flex",
    textAlign: "center",
    width: "max-content",
    margin: "2vh auto",
    color: theme.palette.common.green,
    fontWeight: 600,
    border: "2px solid",
    borderRadius: "10px",
    borderColor: theme.palette.common.green,
    padding: ".5vW",
    cursor: "pointer",
    transitionDuration:"500ms",
    "&:hover": {
        color: theme.palette.common.white,
        background: theme.palette.common.green,
    },
  },
});

function ClubPage() {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const { user } = useContext(UserContext);

  const [createClub, setCreateClub] = useState(false);

  return (
    <>
      {user.club_id || createClub ? (
        <ClubForm />
      ) : (
        <div className={styles.container}>
          <div className={styles.noClubContainer}>
            <div className={styles.title}>{t("account.noClub.title")}</div>
            <Divider />
            <div className={styles.textSection}>
              <div className={styles.text1}>
                {t("account.noClub.text1")}
              </div>
              <div className={styles.text2}>
                {t("account.noClub.text2")}
              </div>
              <div className={styles.text3}>
                {t("account.noClub.text3")}
                <span
                  className={styles.cgu}
                  onClick={() => history.push("/cgu")}
                >
                  {t("account.noClub.cgu")}
                </span>
              </div>
              <div
                className={styles.creationButton}
                onClick={() => setCreateClub(true)}
              >
                {t("account.noClub.createClub")}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ClubPage;
