import React, { createContext, useState } from "react";

export const GlobalContext = createContext({
  menuOpen: false,
  accountPage: "profile",
  accountReload: false,
  helpPage: "faq",
  mobileScreen: true,
  scanOpen: false,
  scanEvent: {},
  qrCodeOpen: false,
  setGlobalContext: (data) => {},
});

const GlobalContextProvider = ({ children }) => {
  const globalState = {
    menuOpen: false,
    accountPage: "profile",
    accountReload: false,
    helpPage: "faq",
    mobileScreen: true,
    scanOpen: false,
    scanEvent: {},
    qrCodeOpen: false,
    setGlobalContext: (data) =>
      setGlobal((prevState) => ({
        ...prevState,
        ...data,
      })),
  };

  const [global, setGlobal] = useState(globalState);
  return (
    <GlobalContext.Provider value={global}>{children}</GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
