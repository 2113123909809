/** Import Externals */
import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { Divider } from '@mui/material'

/** Import Internals */
import TopBar from 'components/topBar'
import Loader from 'assets/svg/loader'
import InstaSVG from 'assets/svg/instaLogo'
import Slider from 'components/slider'
import Footer from 'components/footer'

/** Import Context */

/** Import API */
import { GetOtherUser } from 'data/api/userQuerys'
import { GetClub } from 'data/api/clubQuerys'
import { GetAllEventsUser } from 'data/api/eventQuerys'

/** Import Utils */
import { getIconSport, DisplayFormattedText } from 'data/utils'
import theme from 'config/uiTheme'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.darkWhite,
    cursor: 'default',
  },
  loader: {
    height: '70vh',
    margin: '30vh auto 0',
  },
  subContainer: {
    width: '100%',
    borderRadius: '10px',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    borderRadius: '10px 10px 0 0',
    background: theme.palette.common.white,
  },
  profileImg: {
    width: '10%',
    borderRadius: '200px',
    border: '3px solid',
    borderColor: theme.palette.common.white,
    margin: '20px auto',
    background: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      width: '150px',
    },
  },
  infosContainer: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    alignItems: 'center',
    borderRadius: '0 0 10px 10px',
    width: '100%',
  },
  name: {
    fontSize: '2rem',
    fontWeight: 700,
  },
  description: {
    margin: '20px 0',
    maxWidth: '60%',
    [theme.breakpoints.down('md')]: {
      width: '70%',
      margin: '10px auto',
    },
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5vh 2vW',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      margin: '10px 0',
    },
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '70vW',
    marginRight: '2vW',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '0 auto',
    },
  },
  eventsTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  eventsSection: {
    margin: '1vh 0',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '0 auto',
    },
  },
  eventsSubTitle: {
    fontSize: '1.1rem',
    fontWeight: 500,
    margin: '1vh 0',
  },
  blocksContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '20vW',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '0 auto',
    },
  },
  blockInfo: {
    borderRadius: '10px',
    background: theme.palette.common.white,
    padding: '1vw',
    marginBottom: '1vh',
  },
  smallTitle: {
    fontSize: '1.1rem',
    fontWeight: 500,
    marginBottom: '2vh',
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.common.darkGrey,
  },
  blockText: {
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    margin: '10px',
    color: theme.palette.common.darkGrey,
  },
  clubImage: {
    width: '50px',
    borderRadius: '200px',
  },
  sport: {
    display: 'flex',
    alignItems: 'center',
  },
  iconNameSport: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '1vh 0',
  },
  sportIcon: {
    borderRadius: '200px',
    padding: '1vh',
    background: theme.palette.common.darkWhite,
  },
  sportText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    fontWeight: 500,
  },
  sportLevel: {
    color: theme.palette.common.grey,
  },
  notSpecifiedSmall: {
    margin: '1vh auto',
    color: theme.palette.common.lightGrey,
    fontSize: '1rem',
    fontWeight: 500,
  },
})

function Profile() {
  const styles = useStyles()
  const { t } = useTranslation()
  let history = useHistory()
  let { id } = useParams()
  const location = useLocation();

  const [profile, setProfile] = useState({})
  const [club, setClub] = useState({})
  const [sports, setSports] = useState([])
  const [futureEvents, setFutureEvents] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [isExpandedDesc, setIsExpandedDesc] = useState(false)

  useEffect(() => {
    GetOtherUser(id)
      .then((res) => {
        setProfile(res.data)
        if (res.data.image) {
          res.data.image.forEach((i) => {
            if (i.image_name.includes('profile')) {
              setProfile((profile) => ({
                ...profile,
                profilePicture: i.image_file,
              }))
            }
          })
        }
        if (res.data.club_id !== '') {
          GetClub(res.data.club_id).then((resClub) => {
            setClub(resClub.data)
          })
        }
        if (res.data.events !== '') {
          let eventsId = res.data.events.split(',')
          GetAllEventsUser(eventsId)
            .then((result) => {
              setPastEvents([])
              setFutureEvents([])
              let now = new Date()
              result.data.map((event) => {
                if (event) {
                  let start = new Date(event.event_start)
                  if (now > start) {
                    return setPastEvents((oldArray) => [...oldArray, event])
                  } else {
                    return setFutureEvents((oldArray) => [...oldArray, event])
                  }
                } else {
                  return 0
                }
              })
            })
            .catch((err) => {})
        }
      })
      .catch((err) => {
        history.push('/home')
      })
  }, [location])

  useEffect(() => {
    if (profile.sport) {
      let userSports = []
      let sportArray = profile.sport.split(',')
      sportArray.forEach((e) => {
        let sportLevel = e.split('/')
        return userSports.push({
          sport: t(`sports.${sportLevel[0]}`),
          level: t(`grades.${sportLevel[1]}`),
          icon: getIconSport(sportLevel[0], sportLevel[1]),
        })
      })
      setSports(userSports)
    }
  }, [profile])

  return (
    <div className={styles.container}>
      <TopBar />
      {profile.first_name ? (
        <div className={styles.subContainer}>
          <div className={styles.imageContainer}>
            <img
              className={styles.profileImg}
              src={profile.profilePicture}
              alt={profile.first_name}
            />
          </div>
          <div className={styles.infosContainer}>
            <div className={styles.name}>
              {profile.first_name} {profile.last_name}
            </div>
            <div className={styles.description}>
              {DisplayFormattedText(
                profile.description,
                isExpandedDesc,
                setIsExpandedDesc,
                t('components.utils.expanded'),
                t('components.utils.collapsed'),
              )}
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.eventsContainer}>
              <div className={styles.eventsTitle}>{t('profile.events')}</div>
              <div className={styles.eventsSection}>
                <div className={styles.eventsSubTitle}>
                  {t('profile.futureEvents')}
                </div>
                <Divider style={{ width: '100%' }} />
                <Slider array={futureEvents} />
              </div>
              <div className={styles.eventsSection}>
                <div className={styles.eventsSubTitle}>
                  {t('profile.pastEvents')}
                </div>
                <Divider style={{ width: '100%' }} />
                <Slider array={pastEvents} small={true} />
              </div>
            </div>
            <div className={styles.blocksContainer}>
              {club.image ? (
                <div className={styles.blockInfo}>
                  <div className={styles.smallTitle}>{t('profile.club')}</div>
                  <div
                    className={styles.line}
                    onClick={() => {
                      history.push(`/club/${club.club_id}`)
                      window.scrollTo(0, 0)
                    }}
                  >
                    <img
                      className={styles.clubImage}
                      src={club.image[1].image_file}
                      alt="clubImage"
                    />
                    <div className={styles.blockText}>{club.club_name}</div>
                  </div>
                </div>
              ) : null}
              <div className={styles.blockInfo}>
                <div className={styles.smallTitle}>{t('profile.contact')}</div>
                {profile.insta !== '' ? (
                  <a
                    className={styles.line}
                    href={`https://www.instagram.com/${profile.insta}/`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <InstaSVG color={theme.palette.common.lightGrey} />

                    <div className={styles.blockText}>{profile.insta}</div>
                  </a>
                ) : (
                  <div className={styles.line}>
                    <InstaSVG
                      size={20}
                      color={theme.palette.common.lightGrey}
                    />
                    <div className={styles.notSpecifiedSmall}>
                      {t('club.notSpecified')}
                    </div>
                  </div>
                )}
              </div>
              <div className={clsx(styles.blockInfo, styles.blockSport)}>
                <div className={styles.smallTitle}>{t('profile.sports')}</div>
                {profile.sport !== '' ? (
                  sports.map((sport, i) => {
                    return (
                      <div className={styles.sport} key={i}>
                        <div className={styles.iconNameSport}>
                          <div className={styles.sportIcon}>{sport.icon}</div>
                          <div className={styles.sportText}>
                            <div>{sport.sport}</div>
                            <div className={styles.sportLevel}>
                              {sport.level}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className={styles.line}>
                    <div className={styles.notSpecifiedSmall}>
                      {t('club.notSpecified')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <Footer />
    </div>
  )
}

export default Profile
