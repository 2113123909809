/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */
import MobileImage from "assets/pngJpg/helpMobile.png";
import DesktopConsoleImage from "assets/pngJpg/helpDesktopConsole.png";
import DesktopXHRImage from "assets/pngJpg/helpDesktopXHR.png";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    background: theme.palette.common.white,
    margin: "10px auto",
    borderRadius: "10px",
    padding: "20px 0",
  },
  title: {
    fontWeight: 600,
    fontSize: "1.5rem",
    textAlign: "center",
    margin: "20px 0",
  },
  textBold: {
    fontWeight: 600,
    width: "80%",
    margin: "10px auto",
  },
  text: {
    width: "80%",
    margin: "10px auto",
  },
  subtitle: {
    fontWeight: 500,
    fontSize: "1.2rem",
    width: "80%",
    margin: "20px auto",
  },
  sections:{
    display:"flex",
    width:"90%",
    margin: "0 auto",
    alignItems:"center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width:"100%",
    },
  },
  leftSection:{
    dispaly:"flex",
    flexDirection:"column",
    width:"70%",
    [theme.breakpoints.down("md")]: {
      width:"100%",
    },
  },
  mobileImage:{
    maxHeight:"50vh",
  },
  imageContainer: {
    width: "50%",
    margin: "20px auto",
    [theme.breakpoints.down("md")]: {
      width:"90%",
    },
  },
  image: {
    width: "100%",
    margin: "0px auto",
  },
});

function ReportPage() {
  const styles = useStyles();

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t("help.report.title2")}</div>
      <div className={styles.text}>{t("help.report.text21")}</div>
      <div className={styles.text}>{t("help.report.text22")}</div>
      <div className={styles.textBold}>{t("help.report.title3")}</div>
      <div className={styles.sections}>
        <div className={styles.leftSection}>
          <div className={styles.text}>{t("help.report.text31")}</div>
          <br/>
          <div className={styles.text}>{t("help.report.text32")}</div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.mobileImageContainer}>
            <img className={styles.mobileImage} src={MobileImage} alt="mobile help" />
          </div>
        </div>
      </div>
      <div className={styles.textBold}>{t("help.report.title4")}</div>
      <div className={styles.text}>{t("help.report.text41")}</div>
      <div className={styles.text}>{t("help.report.text42")}</div>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={DesktopConsoleImage}
          alt="mobile help"
        />
      </div>
      <div className={styles.text}>{t("help.report.text43")}</div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={DesktopXHRImage} alt="mobile help" />
      </div>
    </div>
  );
}

export default ReportPage;
