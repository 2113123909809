/** Import Externals */
import React, { useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import moment from "moment";

import "moment/locale/fr";
import "moment/locale/es";

/** Import Internals */

/** Import Context */
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import theme from "../../config/uiTheme";

const useStyles = makeStyles({
  container: {},
});

function SearchField() {
  const styles = useStyles();
  const { i18n } = useTranslation();

  const { setGlobalContext, accountReload } = useContext(GlobalContext);

  useEffect(() => {
    moment.locale(i18n.resolvedLanguage);
  }, []);

  const handleChangeLanguage = (e) => {
    if (e.value !== i18n.resolvedLanguage) {
      i18n.changeLanguage(e.value);
      moment.locale(e.value);
      setGlobalContext({ accountReload: !accountReload, menuOpen: false });
    }
  };

  const languageList = [
    { value: "fr", label: "🇫🇷" },
    { value: "en", label: "🇬🇧" },
    { value: "es", label: "🇪🇸" },
  ]
  ;

  return (
    <div className={styles.container}>
      <Select
        options={languageList}
        onChange={handleChangeLanguage}
        placeholder={
          languageList.find((x) => x.value === i18n.resolvedLanguage).label
        }
        value={i18n.resolvedLanguage}
        menuPlacement= {localStorage.getItem("xsrfToken") ? "top" : "bottom"}
        styles={{
          container: (provided) => ({
            ...provided,
            color: theme.palette.common.darkGrey,
          }),
          control: () => ({
            display: "flex",
            width:"70px",
            borderRadius: "10px",
            cursor: "pointer",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          input: (provided) => ({
            ...provided,
            color: theme.palette.common.darkGrey,
            textAlign: "center",
            paddingTop: "5px",
            height: "30px",
            width: "15vW",
          }),
          menu: (provided) => ({
            ...provided,
            background: theme.palette.common.darkGrey,
          }),
          option: (provided, state) => ({
            ...provided,
            fontWeight: state.isSelected ? 600 : 400,
            color: state.isSelected
              ? theme.palette.common.darkGrey
              : theme.palette.common.grey,
            background: theme.palette.common.darkGrey,
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.common.darkGrey,
            },
          }),
          singleValue: (provided) => ({
            ...provided,
            color: theme.palette.common.darkGrey,
          }),
        }}
      />
    </div>
  );
}

export default SearchField;
