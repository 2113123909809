/** Import Externals */
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from 'clsx';

import Select from "react-select";

/** Import Internals */

/** Import Context */
import { SearchContext } from "data/context/searchContext";
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    margin: "3vH auto",
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    alignItems: "center",
  },
  listTellUs: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.common.darkGrey,
    margin: "1vW auto",
    cursor: "default",
  },
  error:{
    color:theme.palette.common.red,
  }
});

function SportSelectorClub({ addSport, error }) {
  const styles = useStyles();
  const { t } = useTranslation();

  const { sportsList } = useContext(SearchContext);
  const { mobileScreen } = useContext(GlobalContext);

  const [sportsListState, setSportsListState] = useState([]);

  const [id, setId] = useState(0);

  useEffect(() => {
    let optionsSports = sportsList.map((sport) => {
      return {
        label: t(`sports.${sport.sport_name}`),
        value: sport.sport_name,
      };
    });
    setSportsListState(optionsSports);
  }, [sportsList]);

  const handleChangeSport = (e) => {
    addSport(e.value, id);
    setId(id + 1);
  };

  return (
    <div className={styles.container}>
      <div className={clsx(styles.listTellUs, error ? styles.error : null)}>
        {t("components.sportSelector.selectClub")}
      </div>
      <div className={styles.selectContainer}>
        <Select
          options={sportsListState}
          onChange={handleChangeSport}
          placeholder={t("components.sportSelector.sport")}
          maxMenuHeight={160}
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius: "10px",
              cursor: "pointer",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: "none",
            }),
            input: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
              textAlign: "center",
              paddingTop: "5px",
              height: "30px",
              width: mobileScreen ? "60vW" : "15vW",

            }),
            option: (provided, state) => ({
              ...provided,
              fontWeight: state.isSelected ? 600 : 400,
              color: state.isSelected
                ? theme.palette.common.darkGrey
                : theme.palette.common.grey,
              background: theme.palette.common.white,
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.common.darkGrey,
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: theme.palette.common.darkGrey,
            }),
          }}
        />
      </div>
    </div>
  );
}

export default SportSelectorClub;
