import React from "react";

const Wrestling = (props) => {
  let size = props.size || 50;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 497.006 497.006"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M497.006 294.606v45.202c0 3.015-3.442 4.736-5.855 2.927l-39.219-29.418a9.907 9.907 0 0 0-12.19.236l-15.587 12.663-4.551-30.799 4.551-34.231 1.69-1.16a28.785 28.785 0 0 1 33.22.45l32.616 23.684a12.91 12.91 0 0 1 5.325 10.446z"
        fill="#ffdfcf"
      />
      <path
        d="m440.33 260.477 56.676 39.416v-5.287c0-4.133-1.979-8.016-5.324-10.445l-32.616-23.684c-7.722-5.615-17.554-6.922-26.284-3.921a28.766 28.766 0 0 1 7.548 3.921z"
        fill="#ffcebf"
      />
      <path
        d="M424.156 261.187v65.03l-40.31 32.76a58.383 58.383 0 0 1-58.68 7.18l-76.42-33.16-15.81-54.51-10.09-34.81 27.9-40.27.06-.09a55.219 55.219 0 0 1 52.07 19.74l58.58 72.38c2.56 3.18 7.13 3.84 10.5 1.54z"
        fill="#fd4755"
      />
      <g fill="#e41f2d">
        <path d="M405.42 274.031v46.775c0 3.424-1.543 6.665-4.199 8.824l-36.111 29.347a58.327 58.327 0 0 1-26.086 11.236 58.39 58.39 0 0 0 44.822-11.236l40.31-32.76v-65.03zM371.956 296.977l-10.16 7.15c-4.44 3.13-10.55 2.27-13.95-1.96l-63.71-79.11c-8.52-10.54-20.45-17.38-33.39-19.65l.06-.09a55.219 55.219 0 0 1 52.07 19.74l58.58 72.38c2.56 3.18 7.13 3.84 10.5 1.54z" />
      </g>
      <path
        d="M284.765 295.127a18.43 18.43 0 0 1-18.666.862l-33.164-17.501-10.09-34.81 27.96-40.36c3.97-.66 7.95-.89 11.88-.69v.01l29.035 69.993a18.424 18.424 0 0 1-6.955 22.496z"
        fill="#ffdfcf"
      />
      <path
        d="m291.721 272.63-29.035-69.993v-.01c-3.93-.2-7.91.03-11.88.69l-4.112 5.936 26.291 63.377a18.428 18.428 0 0 1-6.956 22.497c-.239.156-.484.297-.728.44l.798.421a18.428 18.428 0 0 0 25.622-23.358z"
        fill="#ffcebf"
      />
      <path
        d="M334.076 356.087v15.78c0 4.16-3.72 7.34-7.84 6.69l-15.29-2.42c-1.16-.18-2.33-.27-3.5-.27h-67.28c-21.39 0-38.72-17.34-38.72-38.72v-69.66c0-6.12 2.31-11.74 6.14-16a23.93 23.93 0 0 1 8.57-6.09c2.09-.88 4.33-1.47 6.69-1.72 14.27-1.52 26.67 9.77 26.49 24.12l-.51 56.46c-.04 4.81 3.85 8.74 8.66 8.74h53.5c12.75 0 23.09 10.33 23.09 23.09z"
        fill="#ffdfcf"
      />
      <g fill="#ffcebf">
        <path d="M257.486 332.997h-19.07c-4.63 0-8.37-3.78-8.33-8.41l.51-56.79c.13-10.09-5.97-18.67-14.56-22.34l.12-.06c2.09-.88 4.33-1.47 6.69-1.72 14.27-1.52 26.67 9.77 26.49 24.12l-.51 56.46c-.04 4.81 3.85 8.74 8.66 8.74zM310.984 332.995h-18.736c12.751 0 23.087 10.336 23.087 23.087v20.751l10.904 1.725c4.112.65 7.832-2.528 7.832-6.691v-15.785c0-12.75-10.336-23.087-23.087-23.087z" />
      </g>
      <path
        d="m135.396 428.727-11.23-.34-81.79-2.44c-.51-.02-1.01-.02-1.52.01-2.91.09-5.78.73-8.45 1.88l-26.5 11.41c-2.94 1.27-6.16-1.06-5.89-4.24l.12-1.33v-.01l3.96-45.92c.93-8.1 8.02-14.07 16.15-13.61l100.08 5.01 27.43-6.86 9.58 5.52 19.92 11.48c2.18 22.05-19.73 40.68-41.86 39.44z"
        fill="#ffb09e"
      />
      <g fill="#f7a28b">
        <path d="m135.396 428.727-11.23-.34c19.29-2.71 36.29-19.56 34.35-39.1l-1.18-11.48 19.92 11.48c2.18 22.05-19.73 40.68-41.86 39.44zM40.876 425.947l-.02.01c-2.91.09-5.78.73-8.45 1.88l-26.5 11.41c-2.94 1.27-6.16-1.06-5.89-4.24l.12-1.33v-.01l15.74-6.78c1.69-.72 3.51-1.09 5.35-1.08z" />
      </g>
      <path
        d="m259.766 179.957-70.44 86.69-17.34 25.28c-2.7 3.93-3.91 8.68-3.43 13.43l8.61 83.84-19-3.38-37.93-6.76-22.7-62.64a67.575 67.575 0 0 1-3.47-31.92c.72-5.5 2.12-10.9 4.18-16.08l-16.65-9.88 51.61-88.23 59.87-68.18 1.76-2 22.22 3.08 37.46 34.85 5.25 8.7z"
        fill="#6c7fd8"
      />
      <path
        d="m259.766 179.957-70.44 86.69-17.34 25.28c-2.7 3.93-3.91 8.68-3.43 13.43l8.61 83.84-19-3.38-8.25-80.37c-.48-4.74.73-9.5 3.42-13.43l86.28-125.74c4.37-6.37 4.56-14.73.47-21.3l-15.29-24.55a37.102 37.102 0 0 0-26.39-17.13l-5.17-.72-.16-.45 1.76-2 22.22 3.08 37.46 34.85 5.25 8.7s.411.918.573 1.264c5.191 11.124-.573 31.936-.573 31.936z"
        fill="#4f66d0"
      />
      <path
        d="m254.513 138.053-44.227 29.865-15.36-7.59 22.22-57.03a37.102 37.102 0 0 1 26.39 17.13z"
        fill="#ffb09e"
      />
      <path
        d="M243.536 120.427a37.102 37.102 0 0 0-26.39-17.13l-2.498 6.412a37.079 37.079 0 0 1 10.152 10.718l5.165 8.293c3.244 5.209 1.786 12.052-3.299 15.486l-27.198 18.365 10.819 5.346 44.227-29.865z"
        fill="#f7a28b"
      />
      <path
        d="M262.686 158.277v69.66c0 12.4-5.84 23.42-14.9 30.51a38.585 38.585 0 0 1-23.82 8.2h-54.86l-22.31 1.68-6.5-12.61 9.44-8.02.63-4.45c1.59-11.17 11.15-19.47 22.43-19.47h46.09l.01.01 25.05-36.51 14.41-21c3.77-5.5 3.94-13.49 1.41-19.52 2.7 4.92 2.92 7.77 2.92 11.52z"
        fill="#ffb09e"
      />
      <path d="M200.162 223.782h-.009l.009.001z" fill="#ffb09e" />
      <path
        d="M262.686 158.277v69.66c0 12.4-5.84 23.42-14.9 30.51a38.585 38.585 0 0 1-23.82 8.2h-18.74c8.99 0 17.25-3.07 23.82-8.2 9.07-7.09 14.9-18.11 14.9-30.51v-40.66l14.41-21c3.77-5.5 3.94-13.49 1.41-19.52 2.7 4.92 2.92 7.77 2.92 11.52z"
        fill="#f7a28b"
      />
      <path
        d="m300.619 91.04-13.86 20.71a31.398 31.398 0 0 1-9.47 9.2 31.582 31.582 0 0 1-14.25 4.72c-6.86.56-13.95-1.11-20.12-5.23l-8.73-5.84c-14.5-9.71-18.39-29.34-8.69-43.85l13.86-20.7c8.34-12.47 25.2-15.81 37.66-7.47l16.13 10.79c12.47 8.34 15.81 25.21 7.47 37.67z"
        fill="#ffb09e"
      />
      <path
        d="m293.154 53.375-16.13-10.794c-7.5-5.019-16.592-5.801-24.463-2.916a27.226 27.226 0 0 1 5.727 2.916l16.13 10.794c12.462 8.339 15.805 25.202 7.466 37.664l-13.856 20.706a31.469 31.469 0 0 1-16.908 12.607c13.02 4.05 27.675-.697 35.644-12.607l13.856-20.706c8.339-12.462 4.996-29.325-7.466-37.664z"
        fill="#f7a28b"
      />
      <path
        d="m194.931 100.217-70.424 98.512a12.376 12.376 0 0 0 .071 14.493l25.159 34.472-2.937 20.637-10.046 2.023a40.8 40.8 0 0 1-41.64-16.832l-17.042-24.708a36.187 36.187 0 0 1 .284-41.497l46.867-65.996a50.126 50.126 0 0 1 40.868-21.103h28.84z"
        fill="#ffb09e"
      />
      <path
        d="M194.931 100.217h-18.736l-70.424 98.512a12.376 12.376 0 0 0 .071 14.493l22.35 30.623a11.73 11.73 0 0 1 2.138 8.566l-1.02 7.164a12.51 12.51 0 0 1-9.916 10.501 40.718 40.718 0 0 0 17.359.277l10.046-2.023 2.937-20.637-25.159-34.472a12.375 12.375 0 0 1-.071-14.493z"
        fill="#f7a28b"
      />
      <path
        d="m139.256 339.027-33.475 78.247a30.72 30.72 0 0 0-1.202 20.84l3.747 12.603c1.31 4.41-2.29 8.75-6.88 8.26l-42.17-4.48c-6.6-.75-11.55-6.39-11.42-13.02l.169-9.464a30.71 30.71 0 0 1 2.638-11.918l35.693-80.398 3.796-46.109 40.954 11.789.012.018a44.006 44.006 0 0 0 3.3 4.168 27.794 27.794 0 0 1 4.838 29.464z"
        fill="#ffb09e"
      />
      <path
        d="M134.418 309.563a44.168 44.168 0 0 1-3.3-4.168l-.012-.018-20.72-29.71-26.6-38.57-10.7 11.653 18.564 26.917 20.72 29.71 1.14 1.632c.506.724 1.087 1.389 1.693 2.032a27.794 27.794 0 0 1 5.317 29.986l-33.475 78.247a30.72 30.72 0 0 0-1.202 20.84l3.747 12.603c.779 2.622-.185 5.21-2.053 6.782l13.91 1.478c4.59.49 8.19-3.85 6.88-8.26l-3.747-12.603a30.72 30.72 0 0 1 1.202-20.84l33.475-78.247a27.796 27.796 0 0 0-4.839-29.464z"
        fill="#f7a28b"
      />
      <path
        d="M131.106 305.377c-15.75-2.8-31.61 5.38-38.33 20.18l-6.42 14.14-27.59-28.29c-12.48-12.8-12.79-33.13-.7-46.3l25.72-28.01 26.6 38.57z"
        fill="#6c7fd8"
      />
      <path
        d="m110.386 275.667-26.6-38.57-10.7 11.653 18.564 26.917 13.126 18.822c2.908 4.17.526 9.99-4.486 10.843-11.261 1.918-21.268 9.252-26.251 20.225l-.468 1.031 12.784 13.109 6.42-14.14c6.72-14.8 22.58-22.98 38.33-20.18z"
        fill="#4f66d0"
      />
      <path
        d="m218.161 224.728-9.68 4.06c-16.1 6.76-34.62-.81-41.38-16.91l-9.64-22.97c-5.8-13.83.7-29.74 14.53-35.55l17.9-7.51c13.82-5.8 29.74.7 35.54 14.53l6.74 16.06 2.9 6.91c1 2.38 1.68 4.81 2.07 7.24 2.27 14.07-5.26 28.38-18.98 34.14z"
        fill="#ffdfcf"
      />
      <path
        d="m235.072 183.35-9.642-22.973c-5.645-13.449-20.855-19.966-34.407-14.971 6.826 2.511 12.634 7.735 15.67 14.971l9.642 22.973c6.756 16.095-.816 34.62-16.911 41.375l-9.68 4.063c-.946.397-1.9.74-2.86 1.039a31.474 31.474 0 0 0 21.596-1.039l9.68-4.063c16.096-6.755 23.667-25.28 16.912-41.375z"
        fill="#ffcebf"
      />
      <path
        d="M277.289 120.95a31.582 31.582 0 0 1-14.25 4.72l-3.79-12.82c-1.17-3.98 1.1-8.15 5.07-9.32s8.14 1.1 9.32 5.07z"
        fill="#b98080"
      />
      <g>
        <path
          d="m237.141 190.588-15.34 5.93c-.89.34-1.8.51-2.7.51-3.01 0-5.85-1.83-7-4.8a7.498 7.498 0 0 1 4.3-9.7l15.77-6.09 2.9 6.91c1 2.38 1.68 4.81 2.07 7.24z"
          fill="#ffb09e"
        />
      </g>
    </svg>
  );
};

export default Wrestling;
