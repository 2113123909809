/** Import Externals */
import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { Divider } from "@mui/material";

/** Import Internals */
import Loader from "assets/svg/loader";
import ConfirmDeleteModal from "./confirmDeleteModal";

/** Import Context */
import { UserContext } from "data/context/userContext";
import { GlobalContext } from "data/context/globalContext";

/** Import API */
import { DeleteUser } from "data/api/userQuerys";
import { DeleteClub } from "data/api/clubQuerys";
import { StripeCreateAccount } from "data/api/stripeQuerys";

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    cursor: "default",
    minHeight: "75vh",
  },
  profileContainer: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    margin: "0 auto",
  },
  opacity: {
    opacity: 0.3,
    pointerEvents: "none",
  },
  title: {
    color: theme.palette.common.black,
    fontSize: "20px",
    fontWeight: 500,
    margin: "1vH 1.5vW",
    cursor: "default",
  },
  section: {
    background: theme.palette.common.white,
    borderRadius: "10px",
    padding: "2vh 2vw",
    margin: "2vh 0",
  },
  userSection: {
    background: theme.palette.common.white,
    borderRadius: "10px",
    padding: "2vh 2vw",
    marginTop: "4vh",
  },
  supressToo: {
    margin: "2vh 0",
    color: theme.palette.common.grey,
  },
  name: {
    fontWeight: 600,
  },
  sectionTitle: {
    fontWeight: 500,
  },
  registerButton: {
    display: "inline-flex",
    alignItems: "center",
    color: theme.palette.common.green,
    fontWeight: 600,
    borderBottom: "2px solid",
    borderColor: theme.palette.common.green,
    padding: ".5vW",
    height: "30px",
    cursor: "pointer",
  },
  deleteButton: {
    display: "inline-flex",
    alignItems: "center",
    color: theme.palette.common.red,
    fontWeight: 600,
    borderBottom: "2px solid",
    borderColor: theme.palette.common.red,
    padding: ".5vW",
    height: "30px",
    cursor: "pointer",
  },
  line: {
    display: "flex",
  },
  error: {
    margin: "10px auto",
    width: "60%",
    textAlign: "center",
    color: "red",
    alignContent: "center",
  },
});

function SettingsForm() {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const { setUserContext, user, club, stripeAccountValid } =
    useContext(UserContext);
  const { setGlobalContext, accountReload } = useContext(GlobalContext);

  const [loadingStripe, setLoadingStripe] = useState(false);
  const [errorStripe, setErrorStripe] = useState("");
  const [loadingClub, setLoadingClub] = useState(false);
  const [errorClub, setErrorClub] = useState("");
  const [loadingUser, setLoadingUser] = useState(false);
  const [errorUser, setErrorUser] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const handleStripeRegister = () => {
    setErrorStripe("");
    setLoadingStripe(true);
    StripeCreateAccount(club.club_id, club.name, club.country)
      .then((resStripe) => {
        window.location.replace(resStripe.data);
      })
      .catch(() => {
        setLoadingStripe(false);
        setErrorStripe(t("account.settings.errorStripe"));
      });
  };

  const handleDeleteUser = () => {
    setOpenModal(false);
    setLoadingUser(true);
    setErrorClub("");
    if (user.club_id) {
      DeleteClub()
        .then(() => {
          DeleteUser()
            .then(() => {
              setUserContext({
                user: {},
                club: {},
              });
              localStorage.removeItem("xsrfToken");
              history.push(`/login`);
            })
            .catch(() => {
              setLoadingUser(false);
              setErrorUser(t("account.settings.cantSuppUser"));
            });
        })
        .catch((err) => {
          if (err?.response?.data?.message === "Can't suppress club") {
            setLoadingUser(false);
            setErrorUser(t("account.settings.cantSuppClubUser"));
          }
        });
    } else {
      DeleteUser()
        .then(() => {
          setUserContext({
            user: {},
            club: {},
          });
          localStorage.removeItem("xsrfToken");
          history.push(`/login`);
        })
        .catch(() => {
          setLoadingUser(false);
          setErrorUser(t("account.settings.cantSuppUser"));
        });
    }
  };

  const handleDeleteClub = () => {
    setOpenModal(false);
    setLoadingClub(true);
    DeleteClub()
      .then(() => {
        setUserContext({ club: {} });
        setGlobalContext({ accountReload: !accountReload });
      })
      .catch((err) => {
        if (err?.response?.data?.message === "Can't suppress club") {
          setLoadingClub(false);
          setErrorClub(t("account.settings.cantSuppClub"));
          setErrorUser("");
        }
      });
  };

  return (
    <div className={styles.container}>
      {openModal ? (
        <ConfirmDeleteModal
          close={() => {
            setOpenModal(false);
          }}
          del={openModal === "club" ? handleDeleteClub : handleDeleteUser}
          type={openModal}
        />
      ) : null}
      <div
        className={clsx(
          styles.profileContainer,
          openModal ? styles.opacity : null
        )}
      >
        {user.club_id && !stripeAccountValid ? (
          <>
            <div className={styles.title}>
              {t("account.settings.paymentTitle")}
            </div>
            <Divider />
            <div className={styles.section}>
              <div className={styles.sectionTitle}>
                {t("account.settings.registerText")}
              </div>
              <div className={styles.supressToo}>
                {t("account.settings.registerSubText")}
              </div>
              {loadingStripe ? (
                <Loader />
              ) : (
                <div className={styles.line}>
                  <div
                    className={styles.registerButton}
                    onClick={handleStripeRegister}
                  >
                    {t("account.settings.registerButton")}
                  </div>
                  {errorStripe ? (
                    <div className={styles.error}>{errorStripe}</div>
                  ) : null}
                </div>
              )}
            </div>
          </>
        ) : user.club_id && stripeAccountValid ? (
          <>
            <div className={styles.title}>
              {t("account.settings.paymentTitle")}
            </div>
            <Divider />
            <div className={styles.section}>
              <div className={styles.sectionTitle}>
                {t("account.settings.updateText")}
              </div>
              <div className={styles.supressToo}>
                {t("account.settings.updateSubText")}
              </div>
              {loadingStripe ? (
                <Loader />
              ) : (
                <div className={styles.line}>
                  <div
                    className={styles.registerButton}
                    onClick={handleStripeRegister}
                  >
                    {t("account.settings.updateButton")}
                  </div>
                  {errorStripe ? (
                    <div className={styles.error}>{errorStripe}</div>
                  ) : null}
                </div>
              )}
            </div>
          </>
        ) : null}
        <div className={styles.title}>
          {t("account.settings.parametersTitle")}
        </div>
        <Divider />
        {user.club_id ? (
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              {t("account.settings.deleteClubText")}
              <span className={styles.name}> {club.name} </span>
            </div>
            <div className={styles.supressToo}>
              {t("account.settings.clubSuppressToo")}
            </div>
            {loadingClub ? (
              <Loader />
            ) : (
              <div className={styles.line}>
                <div
                  className={styles.deleteButton}
                  onClick={() => {
                    setOpenModal("club");
                  }}
                >
                  {t("account.settings.deleteClubButton")}
                </div>
                {errorClub ? (
                  <div className={styles.error}>{errorClub}</div>
                ) : null}
              </div>
            )}
          </div>
        ) : null}
        <div className={styles.section}>
          <div className={styles.sectionTitle}>
            {t("account.settings.deleteUserText")}
            <span className={styles.name}>
              {" "}
              {user.first_name} {user.last_name}{" "}
            </span>
          </div>
          <div className={styles.supressToo}>
            {user.club_id ? (
              <>
                {t("account.settings.clubLinked")}
                <span className={styles.name}> {club.name} </span>
                {t("account.settings.userSuppressToo")}
              </>
            ) : (
              <>{t("account.settings.noClub")}</>
            )}
          </div>
          {loadingUser ? (
            <Loader />
          ) : (
            <div className={styles.line}>
              <div
                className={styles.deleteButton}
                onClick={() => {
                  setOpenModal("user");
                }}
              >
                {t("account.settings.deleteUserButton")}
              </div>
              {errorUser ? (
                <div className={styles.error}>{errorUser}</div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SettingsForm;
