/** Import Externals */
import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

import { Divider } from '@mui/material'

/** Import Internals */
import TopBar from 'components/topBar'
import InstaSVG from 'assets/svg/instaLogo'
import Dojo from 'assets/svg/dojo'
import Slider from 'components/slider'
import SmallMap from 'components/smallMap'
import Loader from 'assets/svg/loader'
import Footer from 'components/footer'

/** Import Context */
import { UserContext } from 'data/context/userContext'

/** Import API */
import { GetClub } from 'data/api/clubQuerys'
import { GetAllEventsClub } from 'data/api/eventQuerys'

/** Import Utils */
import { DisplayFormattedText } from 'data/utils'
import theme from 'config/uiTheme'
import ContactForm from 'components/contactForm/contactForm'
import LogoWWW from 'assets/svg/wwwLogo'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.darkWhite,
  },
  loader: {
    height: '70vh',
    margin: '30vh auto 0',
  },
  subContainer: {
    display: 'flex',
    width: '80%',
    margin: '0 auto',
    padding: '4vh 0',
    cursor: 'default',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '0',
      margin: '0 auto',
      flexDirection: 'column',
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    width: '72%',
    marginRight: '3%',
    borderRadius: '10px',
    background: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0 auto',
    },
  },
  coverImg: {
    width: '100%',
    maxHeight: '35vh',
    borderRadius: '10px',
    background: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderRadius: '0px',
      margin: '0 auto',
    },
  },
  clubImg: {
    width: '15%',
    borderRadius: '200px',
    border: '3px solid',
    borderColor: theme.palette.common.white,
    margin: '-50px auto 0px',
    background: theme.palette.common.white,
    [theme.breakpoints.down('md')]: {
      width: '40%',
      margin: '-50px auto 0px',
    },
  },
  name: {
    margin: '2vh 5%',
    fontSize: '1.7rem',
    fontWeight: 600,
  },
  sportTags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '90%',
    margin: '0 auto',
  },
  tag: {
    padding: '1vh',
    margin: '10px auto',
    borderRadius: '10px',
    background: theme.palette.common.darkGrey,
    color: theme.palette.common.white,
  },
  descriptionContainer: {
    marginTop: '2vh',
    padding: ' 2vh 5%',
  },
  description: {
    margin: '2vh 0',
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    margin: '0 auto',
  },
  eventsTitle: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  eventsSection: {
    margin: '1vh 0',
  },
  eventsSubTitle: {
    fontSize: '1.1rem',
    fontWeight: 500,
    margin: '1vh 0',
  },
  blocks: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '10px auto',
    },
  },
  blockInfo: {
    borderRadius: '10px',
    background: theme.palette.common.white,
    padding: '10px',
    marginBottom: '1vh',
  },
  smallTitle: {
    fontSize: '1.1rem',
    fontWeight: 500,
    marginBottom: '2vh',
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    color: theme.palette.common.darkGrey,
  },
  instaline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    margin: '10px',
    color: theme.palette.common.darkGrey,
  },
  blockText: {
    maxWidth: '200px',
    margin: '0 auto',
    padding:"0 0 0 5px",
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 500,
  },
  clubAddress: {
    margin: '5%',
    fontSize: '0.8rem',
    textAlign: 'center',
    fontWeight: 600,
  },
  notSpecifiedBig: {
    margin: '2vh 0',
    color: theme.palette.common.lightGrey,
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  contactFormContainer: {
    width: '80%',
    margin: '20px auto',
    border: '0.1rem solid',
    borderColor: theme.palette.common.darkWhite,
    padding: '10px',
  },
})

function Club() {
  const styles = useStyles()
  const { t } = useTranslation()
  let history = useHistory()
  let { id } = useParams()
  const location = useLocation();

  const { user } = useContext(UserContext)

  const [club, setClub] = useState({})
  const [sports, setSports] = useState([])
  const [mapsLink, setMapsLink] = useState({})
  const [futureEvents, setFutureEvents] = useState([])
  const [pastEvents, setPastEvents] = useState([])
  const [isExpandedDesc, setIsExpandedDesc] = useState(false)

  useEffect(() => {
    GetClub(id)
      .then((res) => {
        setClub(res.data)
        if (res.data.image) {
          res.data.image.forEach((i) => {
            if (i.image_name.includes('profile')) {
              setClub((club) => ({
                ...club,
                profilePicture: i.image_file,
              }))
            } else {
              setClub((club) => ({
                ...club,
                landscapePicture: i.image_file,
              }))
            }
          })
        }
        setMapsLink(
          `https://www.google.com/maps/place/${res.data.club_address.replace(
            / /g,
            '+',
          )},+${res.data.club_zip_code}+${res.data.club_city}`,
        )
        GetAllEventsClub(id).then((result) => {
          setPastEvents([])
          setFutureEvents([])
          let now = new Date()
          result.data.map((event) => {
            if (event) {
              let start = new Date(event.event_start)
              if (now > start) {
                return setPastEvents((oldArray) => [...oldArray, event])
              } else {
                return setFutureEvents((oldArray) => [...oldArray, event])
              }
            } else {
              return 0
            }
          })
        })
      })
      .catch((err) => {
        history.push('/home')
      })
  }, [location])

  useEffect(() => {
    if (club.club_sports) {
      let clubSports = []
      let sportArray = club.club_sports.split(',')
      sportArray.forEach((sport) => {
        return clubSports.push(t(`sports.${sport}`))
      })
      setSports(clubSports)
    }
  }, [club])

  return (
    <div className={styles.container}>
      <TopBar />
      {club.club_name ? (
        <>
          <div className={styles.subContainer}>
            <div className={styles.main}>
              <img
                className={styles.coverImg}
                alt="cover"
                src={club.landscapePicture}
              />
              <img
                className={styles.clubImg}
                src={club.profilePicture}
                alt={club.club_name}
              />
              <div className={styles.name}>{club.club_name}</div>
              <div className={styles.sportTags}>
                {sports.length ? (
                  sports.map((sport) => {
                    return <div className={styles.tag}>{sport}</div>
                  })
                ) : (
                  <div className={styles.notSpecifiedBig}>
                    {t('club.sports')} : {t('club.notSpecified')}
                  </div>
                )}
              </div>
              <Divider style={{ width: '90%', margin: '4% auto 0' }} />
              <div className={styles.descriptionContainer}>
                <div className={styles.smallTitle}>{t('club.aboutMe')}</div>
                {club.club_description !== '' ? (
                  <div className={styles.description}>
                    {DisplayFormattedText(
                      club.club_description,
                      isExpandedDesc,
                      setIsExpandedDesc,
                      t('components.utils.expanded'),
                      t('components.utils.collapsed'),
                    )}
                  </div>
                ) : (
                  <div className={styles.notSpecifiedBig}>
                    {t('club.notSpecified')}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.blocks}>
              <div className={styles.blockInfo}>
                <div className={styles.smallTitle}>{t('club.location')}</div>
                <a
                  href={mapsLink}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.line}
                >
                  <Dojo size={30} />
                  <div className={styles.clubAddress}>
                    {club.club_address} {club.club_city}
                  </div>
                </a>
                {club.club_lat ? (
                  <SmallMap event={club} link={mapsLink} />
                ) : null}
              </div>
              <div className={styles.blockInfo}>
                <div className={styles.smallTitle}>{t('club.contact')}</div>
                {club.club_insta_link !== '' ? (
                  <a
                    className={styles.instaline}
                    href={`https://www.instagram.com/${club.club_insta_link}/`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <InstaSVG
                      size={20}
                      color={theme.palette.common.lightGrey}
                    />

                    <div className={styles.blockText}>
                      {club.club_insta_link}
                    </div>
                  </a>
                ) : null}
                {club.club_website !== '' ? (
                  <a
                    className={styles.instaline}
                    href={`${club.club_website}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <LogoWWW size={20} color={theme.palette.common.lightGrey} />
                    <div className={styles.blockText}>{club.club_website}</div>
                  </a>
                ) : null}
                {user.user_id ? (
                  <div className={styles.contactFormContainer}>
                    <ContactForm clubToSend={id} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.eventsContainer}>
            <div className={styles.eventsTitle}>{t('profile.events')}</div>
            <div className={styles.eventsSection}>
              <div className={styles.eventsSubTitle}>
                {t('profile.futureEvents')}
              </div>
              <Divider style={{ width: '100%' }} />
              <Slider array={futureEvents} />
            </div>
            <div className={styles.eventsSection}>
              <div className={styles.eventsSubTitle}>
                {t('profile.pastEvents')}
              </div>
              <Divider style={{ width: '100%' }} />
              <Slider array={pastEvents} />
            </div>
          </div>
        </>
      ) : (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
      <Footer />
    </div>
  )
}

export default Club
