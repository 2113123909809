/** Import Externals */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@mui/system";
import theme from "./config/uiTheme.js";

/** Import Context Providers */
import UserContextProvider from "./data/context/userContext";
import SearchContextProvider from "./data/context/searchContext";
import EventContextProvider from "./data/context/eventContext";
import GlobalContextProvider from "./data/context/globalContext";

/** Import Context */

/** Import Internals */
import Login from "./scenes/Login";
import Forgot from "./scenes/Forgot";
import Home from "./scenes/Home";
import Cgu from "./scenes/Cgu";
import Confidential from "./scenes/Confidential";
import Help from "./scenes/Help";
import Account from "./scenes/Account";
import NewEvent from "./scenes/NewEvent";
import MyEvents from "./scenes/MyEvents";
import Events from "./scenes/Events";
import EventDisplay from "./scenes/EventDisplay";
import Profile from "./scenes/Profile";
import Club from "./scenes/Club";

/** Import Utils */
import GuardedRoute from "./data/utils/guardedRoute";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider>
        <SearchContextProvider>
          <EventContextProvider>
            <GlobalContextProvider>
              <Router>
                <Switch>
                  <Route path="/home/:xsrf/:access/:refresh" component={Home} />
                  <Route exact path="/home" component={Home} />
                  <Route exact path="/cgu" component={Cgu} />
                  <Route exact path="/confidential" component={Confidential} />
                  <Route exact path="/help" component={Help} />
                  <GuardedRoute exact path="/account" component={Account} />
                  <GuardedRoute exact path="/newevent" component={NewEvent} />
                  <GuardedRoute exact path="/myevents" component={MyEvents} />
                  <Route exact path="/events" component={Events} />
                  <Route exact path="/events/:id" component={EventDisplay} />
                  <Route exact path="/profile/:id" component={Profile} />
                  <Route exact path="/club/:id" component={Club} />
                  <Route exact path="/login" component={Login} />
                  <Route path="/forgot/:token" component={Forgot} />
                  <Route path="/forgot" component={Forgot} />
                  <Route path="*" component={Home} />
                </Switch>
              </Router>
            </GlobalContextProvider>
          </EventContextProvider>
        </SearchContextProvider>
      </UserContextProvider>
    </ThemeProvider>
  );
};

export default App;
