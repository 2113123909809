/** Import Externals */
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Divider } from "@mui/material";

/** Import Internals */
import LogoJutsu from "assets/pngJpg/jutsuLogo.png";
import GoogleLogo from "assets/svg/googleLogo";
import SignInForm from "./signInForm";

/** Import API */
import { LogInGoogle } from "data/api/connectionQuerys";

/** Import Context */
import { GlobalContext } from "data/context/globalContext";

/** Import Utils */
import theme from "config/uiTheme";
import SignUpForm from "./signUpForm";

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    cursor: "default",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      minHeight: "100vh",
    },
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(3,201,184,1) 100%)",
    width: "55vw",
    paddingLeft: "20px",
  },
  title: {
    fontSize: "4rem",
    color: "#fff",
    fontWeight: "800",
  },
  subTitle: {
    fontSize: "2rem",
    color: "#fff",
    fontWeight: "600",
    marginTop: "50px",
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "45vw",
    padding: "10px",
    background: theme.palette.common.darkWhite,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  imgLogo: {
    maxWidth: "200px",
    height: "120px",
    margin: "0 2vW",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "50%",
      margin: "1vw auto",
    },
  },
  noAccount: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    marginRight: "3vw",
  },
  noAccountText: {
    fontWeight: 500,
  },
  registerText: {
    color: theme.palette.common.green,
    fontWeight: 500,
    margin: "0 5px",
    cursor: "pointer",
  },
  subText: {
    fontSize: "1rem",
    fontWeight: "500",
  },
  dividerContainer: {
    display: "flex",
    flexDirection: "row",
    width: "70%",
    alignItems: "center",
    margin: "2vh 0",
  },
  dividerText: {
    margin: "0 1vw",
    width: "10%",
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: 600,
    color: theme.palette.common.grey,
  },
  networksContainer: {
    display: "flex",
  },
  network: {
    background: theme.palette.common.white,
    borderRadius: "200px",
    padding: "1vw",
    margin: "20px",
    cursor: "pointer",
  },
  bottomContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  copyright: {
    margin: "2vh 0",
  },
  bottomLine: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    margin: "2vh 0 0",
  },
  bottomText: {
    cursor: "pointer",
    margin: "0 5px",
  },
});

function Login() {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const { setGlobalContext, mobileScreen } = useContext(GlobalContext);

  const [noAccount, setNoAccount] = useState(false);

  let mobileScreenBool = useMediaQuery("(max-width:767px)");
  useEffect(() => {
    setGlobalContext({ mobileScreen: mobileScreenBool });
  }, [mobileScreenBool]);

  useEffect(() => {
    if (localStorage.getItem("xsrfToken")) {
      history.push("/home");
    }
  }, []);

  let year = new Date();
  year = year.getFullYear();

  return (
    <div className={styles.container}>
      {!mobileScreen ? (
        <section className={styles.leftSection}>
          <div className={styles.title}>{t("login.welcome1")}</div>
          <div className={styles.title}>{t("login.welcome2")}</div>
          <div className={styles.subTitle}>{t("login.subtitle")}</div>
        </section>
      ) : null}
      <div className={styles.rightSection}>
        <div className={styles.noAccount}>
          <div className={styles.noAccountText}>
            {noAccount ? t("login.haveAccount") : t("login.noAccount")}
          </div>
          <div
            className={styles.registerText}
            onClick={() => {
              setNoAccount(!noAccount);
            }}
          >
            {noAccount ? t("login.connect") : t("login.register")}
          </div>
        </div>
        {noAccount ? null : (
          <img
            src={LogoJutsu}
            alt="logo"
            className={styles.imgLogo}
            onClick={() => {
              history.push(`/home`);
            }}
          />
        )}
        <div className={styles.subText}>
          {noAccount ? null : t("login.choose")}
        </div>
        {noAccount ? <SignUpForm /> : <SignInForm />}
        <div className={styles.dividerContainer}>
          <Divider {...{ width: "40%", margin: "0 auto" }} />
          <div className={styles.dividerText}>{t("login.or")}</div>
          <Divider {...{ width: "40%", margin: "0 auto" }} />
        </div>
        <div className={styles.networksContainer}>
          <div
            className={styles.network}
            onClick={() => {
              LogInGoogle();
            }}
          >
            <GoogleLogo size={40} className={styles.logoLog} />
          </div>
          {/* <div
            className={styles.network}
            onClick={() => {
              LogInFacebook();
            }}
          >
            <FacebookLogo size={40} className={styles.logoLog} />
          </div> */}
        </div>
        <div className={styles.bottomContainer}>
          <div className={styles.copyright}>© {year} Jutsu</div>
          <div className={styles.bottomLine}>
            <div
              className={styles.bottomText}
              onClick={() => history.push("/cgu")}
            >
              {t("login.cgu")}
            </div>
            <div
              className={styles.bottomText}
              onClick={() => history.push("/confidential")}
            >
              {t("login.confidential")}
            </div>
            <div
              className={styles.bottomText}
              onClick={() => history.push("/help")}
            >
              {t("login.help")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
