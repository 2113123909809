/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import CancelIcon from "@mui/icons-material/Cancel";

/** Import Internals */
import TrashCan from "assets/svg/trashCan";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    background: theme.palette.common.white,
    zIndex: 1000,
    display: "flex",
    flexDirection: "row",
    borderRadius: "20px",
    alignItems: "center",
    margin: "0 auto",
    position: "absolute",
    top: "25%",
    right: "15%",
    width: "50%",
    boxShadow: "0px 3px 13px rgba(30, 25, 37, 0.6)",
    [theme.breakpoints.down("md")]: {
      right: "5%",
    
    width: "90%",
    },
  },
  all: {
    margin: "auto 5%",
    padding: "5%",
    position: "relative",
    width: "100%",
  },
  center: {
    width: "100%",
  },
  textContainer: {
    color: theme.palette.common.darkGrey,
    display: "flex",
    flexDirection: "column",
    height: "50%",
    margin: "0 auto",
  },
  title: {
    fontSize: "1.8rem",
    fontWeight: 700,
  },
  text: {
    fontSize: "1.3rem",
    fontWeight: 500,
    margin: "5% 0",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    widht: "100%",
    justifyContent: "space-around",
  },
  buttonsNo: {
    padding: "4% 8%",
    border: "2px solid",
    borderColor: theme.palette.common.darkGrey,
    color: theme.palette.common.darkGrey,
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "1.2rem",
    textAlign: "center",
    widht: "100%",
    marginRight: "5%",
    cursor: "pointer",
  },
  buttonsYes: {
    padding: "4% 8%",
    border: "2px solid",
    borderColor: theme.palette.common.red,
    color: theme.palette.common.red,
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "1.2rem",
    textAlign: "center",
    widht: "40%",
    cursor: "pointer",
  },
  icon: {
    position: "absolute",
    top: 10,
    right: 10,
    color: theme.palette.common.darkGrey,
    cursor: "pointer",
  },
});

function ConfirmDeleteModal({ close, del, type }) {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.all}>
        <div className={styles.center}>
          <TrashCan size={50} />
          <div className={styles.textContainer}>
            <div className={styles.title}>
              {type === "club"
                ? t("account.settings.deleteModal.titleClub")
                : t("account.settings.deleteModal.titleUser")}
            </div>
            <div className={styles.text}>
              {type === "club"
                ? t("account.settings.deleteModal.sureClub")
                : t("account.settings.deleteModal.sureUser")}
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonsNo} onClick={close}>
              {t("account.settings.deleteModal.no")}
            </div>
            <div className={styles.buttonsYes} onClick={del}>
              {t("account.settings.deleteModal.yes")}
            </div>
          </div>
        </div>
        <div>
          <CancelIcon className={styles.icon} onClick={close} />
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeleteModal;
