/** Import Externals */
import axios from "axios";

/** Import Utils */

export const FormLogIn = (email, password) => {
  const url = process.env.REACT_APP_LOG_IN_FORM;
  const postObject = {
    email: email,
    password: password,
  };

  return axios.post(url, postObject);
};

export const FormSignUp = (email, password, first_name, last_name) => {
  const url = process.env.REACT_APP_SIGN_UP_FORM;
  const postObject = {
    email: email,
    password: password,
    first_name: first_name,
    last_name: last_name,
  };

  return axios.post(url, postObject);
};

export const ForgotPassword = (email, language) => {
  const url = process.env.REACT_APP_FORGOT_PASSWORD;
  const postObject = {
    email: email,
    language: language,
  };

  return axios.post(url, postObject);
};

export const ChangeForgotPassword = (email, password, token) => {
  const url = process.env.REACT_APP_CHANGE_FORGOT_PASSWORD;
  const postObject = {
    email: email,
    password: password,
    token: token,
  };

  return axios.post(url, postObject);
};

export const LogInGoogle = () => {
  const url = process.env.REACT_APP_LOG_IN_GOOGLE;

  window.location.replace(url);
};

export const LogInFacebook = () => {
  const url = process.env.REACT_APP_LOG_IN_FACEBOOK;

  window.location.replace(url);
};

export const LogInTwitter = () => {
  const url = process.env.REACT_APP_LOG_IN_TWITTER;

  window.location.replace(url);
};
