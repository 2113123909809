/** Import Externals */
import React, { useState, useRef, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import * as imageConversion from "image-conversion";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

/** Import Internals */
import { useDebounceEffect } from "./useDebounceEffect.js";
import { canvasPreview } from "./canvasPreview";
import ImageIcon from "assets/svg/imageIcon.js";
import SaveIcon from "assets/svg/saveIcon.js";
import { ChangeImageClub } from "data/api/clubQuerys";
import { ChangeImageUser } from "data/api/userQuerys";
import Loader from "assets/svg/loader";

/** Import Context */
import { UserContext } from "data/context/userContext";
import { GlobalContext } from "data/context/globalContext";
import { EventContext } from "data/context/eventContext";

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
  },
  inputLabel: {
    display: "flex",
    width: "40%",
    alignItems: "center",
    margin: "2rem auto",
    padding: "1.5rem",
    color: theme.palette.common.darkGrey,
    border: "1px solid",
    borderColor: theme.palette.common.darkGrey,
    borderRadius: "5px",
    fontWeight: 600,
    fontSize: "1rem",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "70%",
      margin: "2rem auto",
    },
  },
  inputLabelText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "0 auto",
  },
  imageDisplay: {
    display: "flex",
    width: "100%",
    maxWidth: "70%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "90%",
    },
  },
  image: {
    width: "100%",
  },
  saveButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    background: theme.palette.common.darkGrey,
    color: theme.palette.common.white,
    borderRadius: "5px",
    width: "40%",
    margin: "2rem auto",
    padding: "1.5rem",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      width: "70%",
      margin: "2rem auto",
    },
  },
  saveLabelText: {
    margin: "0 auto",
  },
  loader: {
    width: "50px",
    margin: "20px auto",
  },
  errorMsg: {
    color: theme.palette.common.red,
    margin: "2rem auto 0",
    textAlign: "center",
  },
  none: {
    display: "none",
  },
  noBorder: {
    border: "none",
  },
});

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 50,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function UpdatePicture({ type, page, close, error, setProfile, setLandscape }) {
  const styles = useStyles();
  const { t } = useTranslation();

  const { setUserContext, user, club } = useContext(UserContext);
  const { setGlobalContext, accountReload } = useContext(GlobalContext);
  const { setEventContext, eventPictures, eventPictureId } =
    useContext(EventContext);

  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");
  const [imgName, setImgName] = useState("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [aspect, setAspect] = useState(1);
  const [circular, setCircular] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type === "landscape") {
      setAspect(16 / 3);
      setCircular(0);
      if (page === "club") {
        setImgSrc(club.landscapeImage);
      } else {
        setImgSrc(user.landscapeImage);
      }
    }
    if (type === "profile") {
      if (page === "club") {
        setImgSrc(club.profileImage);
      } else {
        setImgSrc(user.profileImage);
      }
    }
    if (type === "event") {
      setAspect(16 / 9);
      setCircular(0);
    }
  }, []);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      error("");
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result.toString() || "");
        setImgName(e.target.files[0].name);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const handleSaveImg = async () => {
    setLoading(true);
    const dataUrl = previewCanvasRef.current.toDataURL("image/jpeg", 1);
    if (dataUrl.includes("image/jpeg;base64,")) {
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      imageConversion
        .compressAccurately(blob, 400)
        .then(async (resConversion) => {
          let urlImg = await new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(resConversion);
          });
          if (user.club_id || page === "user") {
            try {
              if (page === "club") {
                ChangeImageClub(type, urlImg)
                  .then(() => {
                    close();
                    setGlobalContext({ accountReload: !accountReload });
                  })
                  .catch((err) => {
                    setLoading(false);
                    if (err.response.statusText === "Payload Too Large") {
                      setErrorMsg(t("components.pictureModal.errors.size"));
                    } else {
                      setErrorMsg(
                        t("components.pictureModal.errors.otherFile")
                      );
                    }
                  });
              }
              if (page === "user") {
                ChangeImageUser(type, urlImg)
                  .then(() => {
                    close();
                    setGlobalContext({ accountReload: !accountReload });
                  })
                  .catch((err) => {
                    setLoading(false);
                    if (err.response.statusText === "Payload Too Large") {
                      setErrorMsg(t("components.pictureModal.errors.size"));
                    } else {
                      setErrorMsg(
                        t("components.pictureModal.errors.otherFile")
                      );
                    }
                  });
              }
              if (page === "event") {
                setEventContext({
                  eventPictures: [
                    ...eventPictures,
                    {
                      id: eventPictureId,
                      file: urlImg,
                    },
                  ],
                  eventPictureId: eventPictureId + 1,
                });
                close();
              }
            } catch (e) {
              setErrorMsg(t("components.pictureModal.errors.otherFile"));
            }
          } else {
            if (type === "landscape") {
              setUserContext({
                newClubLandscapePic: urlImg,
              });
              close();
            }
            if (type === "profile") {
              setUserContext({
                newClubProfilePic: urlImg,
              });
              close();
            }
          }
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <input
          className={styles.none}
          type="file"
          name="file"
          id="file"
          accept="image/png, image/jpeg"
          onChange={onSelectFile}
        />
        <label
          className={clsx(styles.inputLabel, imgName ? styles.noBorder : null)}
          for="file"
        >
          {imgName ? <ImageIcon color={theme.palette.common.darkGrey} /> : null}
          <div className={styles.inputLabelText}>
            {imgName ? imgName : t("components.pictureModal.chooseFile")}
          </div>
        </label>
        {imgSrc ? (
          loading ? (
            <div className={styles.loader}>
              <Loader />
            </div>
          ) : (
            <div className={styles.saveButton} onClick={handleSaveImg}>
              <SaveIcon color={theme.palette.common.white} />
              <div className={styles.saveLabelText}>
                {t("components.pictureModal.save")}
              </div>
            </div>
          )
        ) : null}
        {errorMsg ? <div className={styles.errorMsg}>{errorMsg}</div> : null}
      </div>
      {Boolean(imgSrc) && (
        <div className={styles.imageDisplay}>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            circularCrop={circular}
          >
            <img
              className={styles.image}
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
      )}
      <div className={styles.none}>
        {Boolean(completedCrop) && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
          />
        )}
      </div>
    </div>
  );
}

export default UpdatePicture;
