import React from "react";

const ThaiBoxing = (props) => {
  let size = props.size || 50;

  return (
    <svg height={size} width={size} viewBox="0 0 496.997 496.997" xmlns="http://www.w3.org/2000/svg">
      <path d="m57.09 402.333-21.142-26.208a9.997 9.997 0 0 1-2.293-10.077L74.184 243.45l2.888 7.624-1.482 101.601-6.767 33.964-3.228 13.202c-.789 3.96-5.703 5.4-8.505 2.492zM111.123 379.957l38.582 16.456c4.026 1.335 7.782-2.634 6.226-6.58l-4.651-14.869-36.754-93.221-24.178-9.225-5.957 9.362 20.172 90.982a9.999 9.999 0 0 0 6.56 7.095z" fill="#ebe1dc" />
      <g fill="#cf9875">
          <path d="m10.859 264.86 273.829-23.568a40.302 40.302 0 0 1 22.002 4.407l26.393 13.739a178.398 178.398 0 0 0 82.37 20.144l12.739 10V123.447c0-18.386-14.98-33.292-33.458-33.292h-5.146c-47.366 3.531-58.373 13.768-75.283 27.215l-4.536 5.068a40.045 40.045 0 0 1-7.028 6.206c-7.658 5.309-114.219 91.444-123.71 90.6L39.71 114.505l-19.604-1.753-8.905 8.704z" />
          <path d="M493.012 215.447v15.987c0 7.18-5.82 13-13 13H398.96c-9.315 0-16.867-7.552-16.867-16.867v-8.254c0-9.315 7.552-16.867 16.867-16.867h81.051c7.18.001 13.001 5.822 13.001 13.001zM496.997 171.88v15.3c0 7.18-5.82 13-13 13h-72.436v-41.3h72.436c7.18 0 13 5.82 13 13zM487.851 132.012v9.935c0 .357-.011.711-.033 1.062-.551 8.728-7.842 11.638-16.756 11.638h-33.269l-.605-35.336 7.222-4h26.652c9.272.001 16.789 7.478 16.789 16.701z" />
      </g>
      <path d="M403.367 230.015c-5.523 0-10-4.477-10-10v-16.701c0-.293.029-.578.044-.867h-.177c-9.315 0-16.867 7.552-16.867 16.867v9.834c0 9.315 7.552 16.867 16.867 16.867h86.777c7.18 0 13-5.82 13-13v-3zM445.184 176.447V158.88h-17.016v43.568h55.83c7.18 0 13-5.82 13-13v-3h-41.814c-5.523-.001-10-4.478-10-10.001zM444.656 133.018l-.246-17.706h-17.222l.604 43.568h43.269c9.006 0 16.335-7.096 16.75-16h-33.157a10 10 0 0 1-9.998-9.862z" fill="#c27e4e" />
      <path d="m428.263 180.371-14.072-46.958-41.059 25.002a4.999 4.999 0 0 0-2.304 4.211v19.37c0 20.635 18.884 37.519 39.519 37.519 10.215-.718 17.845-9.423 17.845-17.069z" fill="#c27e4e" />
      <path d="m389.779 90.155 5.02-.026c18.424-.06 33.392 14.86 33.392 33.284v69.257c0 7.646-6.198 13.845-13.845 13.845-15.198 0-27.519-12.321-27.519-27.519V149.89" fill="#cf9875" />
      <path d="M417.452 277.583c-13.516 0-26.937-9.534-40.011-12.541a178.232 178.232 0 0 1-42.359-15.604L308.69 235.7a49.652 49.652 0 0 0-.608-.31c-3.3-1.64-5.403-4.986-5.403-8.671v-98.035a40.275 40.275 0 0 1-26.461 6.97l-114.531 29.759L59.7 116.293l-19.989-1.787v130.319a9.676 9.676 0 0 1-8.802 9.636c-5.858.531-10.907-4.085-10.902-9.967l.1-131.741-16.719-1.495a3 3 0 0 0-3.267 2.986L0 272.181a3 3 0 0 0 3.254 2.992l36.457-3.093 19.999-1.697 104.376-38.856 118.59 19.938 2.012-.171a40.78 40.78 0 0 1 2.589-.127c.322-.006.644.001.966.002.524.002 1.048.012 1.571.034a39.652 39.652 0 0 1 2.432.185c.496.052.992.104 1.486.175.194.028.387.065.581.095a40.682 40.682 0 0 1 8.367 2.253 40.085 40.085 0 0 1 4.012 1.79l26.393 13.739a178.307 178.307 0 0 0 52.315 17.597c9.895 1.689 19.948-1.453 30.055-1.453h12.739v-8h-10.742z" fill="#c27e4e" />
      <path d="M404.446 273.583a9.994 9.994 0 0 1-8.849-5.349 178.325 178.325 0 0 1-18.151-3.211v7.693c0 6.039 3.184 11.322 7.955 14.301a178.492 178.492 0 0 0 30.052 2.566h12.739v-16z" fill="#b36d3c" />
      <path d="M479.57 259.015v14.568c0 7.18-5.82 13-13 13h-58.258c-9.315 0-12.867-4.552-12.867-13.867v-9.834c0-9.315 3.552-16.867 12.867-16.867h58.258c7.18 0 13 5.821 13 13z" fill="#cf9875" />
      <path d="M418.446 273.583c-5.523 0-10-4.477-10-10v-16.701c0-.292.029-.578.044-.867h-.177c-9.315 0-16.867 7.552-16.867 16.867v9.834c0 9.315 7.552 16.867 16.867 16.867h58.257c7.18 0 13-5.82 13-13v-3z" fill="#c27e4e" />
      <path d="M59.71 116.175v92.405l17.617 34.663 30.645 23.055 18.888-1.6 69.678-25.904 62.9 14.67 23.24-1.97v-64.653l-6.839-19.938-26.214-3.983-36.019-33.175-23.16-2.043-53.629 35.371-45.986-44.154zM157.931 391.834l-6.651-16.869-27.157-9.008a10 10 0 0 1-6.56-7.094l-14.86-60.217a5 5 0 0 1 4.181-6.152l4.847-.671-28.276-10.41-10.213.766-22.273 10.318-24.315 73.55a10.001 10.001 0 0 0 2.293 10.077l28.142 29.208c2.801 2.907 7.715 1.467 8.504-2.492l3.228-16.202-16.875-17.513a9.997 9.997 0 0 1-2.293-10.077l10.303-31.165c1.574-4.763 8.399-4.499 9.601.371l6.031 24.421 8.93-44.823 17.043 69.011a10 10 0 0 0 6.56 7.094l43.582 14.456c4.028 1.336 7.784-2.633 6.228-6.579z" fill="#dcd2cd" />
      <path d="M282.678 186.841V135.71l-69.073-5.965-39.778 115.139V127.161c0-.318-.032-.629-.089-.932l-82.908-7.31c-.03.074-.07.141-.097.218L59.71 208.58v61.808l29.34-2.486 37.777-108.099v104.33c0 .191.012.38.033.565l132.578-11.234z" fill="#c8beb9" />
      <path d="m189.827 257.571-30.575 1.543a5 5 0 0 1-5.425-4.982V124.577l13-4.302 22.423 1.904a5 5 0 0 1 4.577 4.982v120.115z" fill="#ebe1dc" />
      <path d="m160.822 119.757-30.313-2.674-13.25 6.201a5 5 0 0 0-5.163 3.342L61.357 267.142c-1.189 3.429 1.556 6.943 5.17 6.619l27.588-3.639a5 5 0 0 0 4.274-3.331l15.311-12.198z" fill="#ebe1dc" />
      <path d="m107.625 264.094-27.234 4.298-20.723-2.298-11.296 34.26 69.849-9.323z" fill="#c8beb9" />
      <path d="m276.053 129.619-22.97-2.06-6.775 5.359a5.002 5.002 0 0 0-5.173 3.347L200.477 256.52l30.152-2.688a5 5 0 0 0 4.27-3.333l8.018-7.007 37.41-107.246c1.072-3.074-1.032-6.336-4.274-6.627z" fill="#ebe1dc" />
      <path d="M172.252 249.114a5 5 0 0 1-5.425-4.982V120.275l-20-1.698v145.556a5 5 0 0 0 5.425 4.982l41.575-3.543v-18.295zM89.528 256.761c-3.615.324-6.36-3.19-5.17-6.618l46.152-133.059-14.612-1.289a5 5 0 0 0-5.163 3.342L58.357 270.143c-1.189 3.429 1.556 6.943 5.17 6.618l40.588-3.639a5 5 0 0 0 4.274-3.331l5.311-15.198z" fill="#dcd2cd" />
      <path d="m214.97 238.944 38.47-111.352-14.621-1.311a5 5 0 0 0-5.172 3.347l-47.202 136.627 46.908-4.252a5 5 0 0 0 4.27-3.333l5.294-15.177-22.77 2.064c-3.614.326-6.362-3.184-5.177-6.613z" fill="#dcd2cd" />
    </svg>
  );
};

export default ThaiBoxing;
