/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";

/** Import Internals */
import TopBar from "components/topBar";
import EventInfos from "./eventInfos";
import Footer from "components/footer";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    width: "100%",
    justifyContent:"space-between",
    background: theme.palette.common.darkWhite,
  },
});

function EventDisplay() {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <TopBar />
      <EventInfos />
      <Footer />
    </div>
  );
}

export default EventDisplay;
