/** Import Internals */
import { LaunchQuery } from "./queryLauncher";

export const StripeCreateAccount = (clubId, clubName, clubCountry) => {
  const url = process.env.REACT_APP_STRIPE_CLUB_CREATE_ACCOUNT;

  const postObject = {
    clubId: clubId,
    clubName: clubName,
    clubCountry: clubCountry,
  };

  return LaunchQuery(url, "post", postObject);
};

export const StripeIsValidAccount = (clubId) => {
  const url = process.env.REACT_APP_STRIPE_CLUB_IS_VALID_ACCOUNT;

  const postObject = {
    clubId: clubId,
  };

  return LaunchQuery(url, "post", postObject);
};

export const StripePayment = (event) => {
  const url = process.env.REACT_APP_STRIPE_CHARGE;

  const postObject = {
    eventDetails: event,
  };

  return LaunchQuery(url, "post", postObject);
};
