/** Import Externals */
import React from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

/** Import Internals */
import MobileImage from 'assets/pngJpg/helpMobile.png'
import DesktopConsoleImage from 'assets/pngJpg/helpDesktopConsole.png'
import DesktopXHRImage from 'assets/pngJpg/helpDesktopXHR.png'

/** Import Context */

/** Import API */

/** Import Utils */
import theme from 'config/uiTheme'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    background: theme.palette.common.white,
    margin: '10px auto',
    borderRadius: '10px',
    padding: '20px 0',
  },
  title: {
    fontWeight: 600,
    fontSize: '1.5rem',
    textAlign: 'center',
    margin: '20px 0',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '1.2rem',
    width: '80%',
    margin: '20px auto',
  },
  text: {
    width: '80%',
    margin: '10px auto',
  },
})

function FaqPage() {
  const styles = useStyles()

  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.title}> {t('help.faq.mainTitle')} </div>
      <div className={styles.subtitle}> {t('help.faq.title1')} </div>
      <div className={styles.text}> {t('help.faq.text1')} </div>
      <div className={styles.subtitle}> {t('help.faq.title2')} </div>
      <div className={styles.text}> {t('help.faq.text2')} </div>
      <div className={styles.subtitle}> {t('help.faq.title3')} </div>
      <div className={styles.text}> {t('help.faq.text3')} </div>
      <div className={styles.subtitle}> {t('help.faq.title4')} </div>
      <div className={styles.text}> {t('help.faq.text4')} </div>
      <div className={styles.subtitle}> {t('help.faq.title5')} </div>
      <div className={styles.text}> {t('help.faq.text5')} </div>
      <div className={styles.subtitle}> {t('help.faq.title6')} </div>
      <div className={styles.text}> {t('help.faq.text6')} </div>
      <div className={styles.subtitle}> {t('help.faq.title7')} </div>
      <div className={styles.text}> {t('help.faq.text7')} </div>
      <div className={styles.subtitle}> {t('help.faq.title8')} </div>
      <div className={styles.text}> {t('help.faq.text8')} </div>
      <div className={styles.subtitle}> {t('help.faq.title9')} </div>
      <div className={styles.text}> {t('help.faq.text9')} </div>
      <div className={styles.subtitle}> {t('help.faq.title10')} </div>
      <div className={styles.text}> {t('help.faq.text10')} </div>
      <div className={styles.subtitle}> {t('help.faq.title11')} </div>
      <div className={styles.text}> {t('help.faq.text11')} </div>
      <div className={styles.subtitle}> {t('help.faq.title12')} </div>
      <div className={styles.text}> {t('help.faq.text12')} </div>
      <div className={styles.subtitle}> {t('help.faq.title13')} </div>
      <div className={styles.text}> {t('help.faq.text13')} </div>
      <div className={styles.subtitle}> {t('help.faq.title14')} </div>
      <div className={styles.text}> {t('help.faq.text14')} </div>
    </div>
  )
}

export default FaqPage
