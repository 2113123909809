import React from "react";

const Calendar = (props) => {
  let size = props.size || 50;

  return (
    <svg height={size} width={size} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <path d="M61 10v9H3v-9a2 2 0 0 1 2-2h54a2 2 0 0 1 2 2z" fill="#fc6067" />
        <g fill="#e7eaef">
        <path d="M61 19v40a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V19z" />
        <rect height="10" rx="2" width="4" x="12" y="3" />
        <rect height="10" rx="2" width="4" x="24" y="3" />
        <rect height="10" rx="2" width="4" x="36" y="3" />
        <rect height="10" rx="2" width="4" x="48" y="3" />
        </g>
        <path d="M3 22h55v37a3 3 0 0 1-.78 2H59a2 2 0 0 0 2-2V19H3z" fill="#d8dbe0"/>
        <path d="M58 19h3v-9a2 2 0 0 0-2-2h-1.78a3 3 0 0 1 .78 2z" fill="#fb4b5b" />
        <path d="m43.59 30.59-2.18-2.18a2 2 0 0 0-2.82 0L32 35l-6.59-6.59a2 2 0 0 0-2.82 0l-2.18 2.18a2 2 0 0 0 0 2.82L27 40l-6.59 6.59a2 2 0 0 0 0 2.82l2.18 2.18a2 2 0 0 0 2.82 0L32 45l6.59 6.59a2 2 0 0 0 2.82 0l2.18-2.18a2 2 0 0 0 0-2.82L37 40l6.59-6.59a2 2 0 0 0 0-2.82z" fill="#fc6067" />
        <path d="m37 40 6.59-6.59a2 2 0 0 0 0-2.82l-2.18-2.18a2 2 0 0 0-2.69-.1l1.57 1.57a3 3 0 0 1 0 4.24L34.41 40l5.88 5.88a3 3 0 0 1 0 4.24l-1.57 1.57a2 2 0 0 0 2.69-.1l2.18-2.18a2 2 0 0 0 0-2.82z" fill="#fb4b5b" />
        <path d="m19.71 50.12 2.17 2.17a3.06 3.06 0 0 0 4.24 0L32 46.41l5.88 5.88a3 3 0 0 0 4.24 0l2.17-2.17a3 3 0 0 0 0-4.24L38.41 40l5.88-5.88a3 3 0 0 0 0-4.24l-2.17-2.17a3.06 3.06 0 0 0-4.24 0L32 33.59l-5.88-5.88a3.06 3.06 0 0 0-4.24 0l-2.17 2.17a3 3 0 0 0 0 4.24L25.59 40l-5.88 5.88a3 3 0 0 0 0 4.24zm1.41-2.83 6.59-6.58a1 1 0 0 0 0-1.42l-6.59-6.58a1 1 0 0 1 0-1.42l2.17-2.17a1 1 0 0 1 1.42 0l6.58 6.59a1 1 0 0 0 1.42 0l6.58-6.59a1 1 0 0 1 1.42 0l2.17 2.17a1 1 0 0 1 0 1.42l-6.59 6.58a1 1 0 0 0 0 1.42l6.59 6.58a1 1 0 0 1 0 1.42l-2.17 2.17a1 1 0 0 1-1.42 0l-6.58-6.59a1 1 0 0 0-1.42 0l-6.58 6.59a1 1 0 0 1-1.42 0l-2.17-2.17a1 1 0 0 1 0-1.42z" />
        <path d="M59 7h-6V5a3 3 0 0 0-6 0v2h-6V5a3 3 0 0 0-6 0v2h-6V5a3 3 0 0 0-6 0v2h-6V5a3 3 0 0 0-6 0v2H5a3 3 0 0 0-3 3v15a1 1 0 0 0 2 0v-5h56v31a1 1 0 0 0 2 0V10a3 3 0 0 0-3-3zM49 5a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0zM37 5a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0zM25 5a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0zM13 5a1 1 0 0 1 2 0v6a1 1 0 0 1-2 0zM4 18v-8a1 1 0 0 1 1-1h6v2a3 3 0 0 0 6 0V9h6v2a3 3 0 0 0 6 0V9h6v2a3 3 0 0 0 6 0V9h6v2a3 3 0 0 0 6 0V9h6a1 1 0 0 1 1 1v8zM61 54a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V29a1 1 0 0 0-2 0v30a3 3 0 0 0 3 3h54a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1z" />
    </svg>
  );
};

export default Calendar;