/** Import Externals */
import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */
import TopBar from "components/topBar";
import TodayEvents from "./todayEvents";
import MyFuturEvents from "./myFuturEvents";
import Footer from "components/footer";

/** Import Context */
import { UserContext } from "data/context/userContext";
import { GlobalContext } from "data/context/globalContext";

/** Import API */
import { GetAllEventsClub } from "data/api/eventQuerys";

/** Import Utils */
import theme from "config/uiTheme";
import MyPastEvents from "./myPastEvents";
import ScanModal from "components/scanModale";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.common.darkWhite,
  },
  content: {
    minHeight: "90vh",
  },
  title: {
    textAlign:"center",
    fontSize:"2rem",
    fontWeight:"600",
    margin:"20px",
  }
});

function MyEvents() {
  const styles = useStyles();
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const { scanOpen } = useContext(GlobalContext);

  const [futurEventsState, setFuturEventsState] = useState([]);
  const [scannableEventsState, setScannableEventsState] = useState([]);
  const [pastEventsState, setPastEventsState] = useState([]);

  useEffect(() => {
    setFuturEventsState([])
    setScannableEventsState([])
    setPastEventsState([])
    if (user.club_id) {
      GetAllEventsClub(user.club_id).then((result) => {
        result.data.map((e) => {
          if (
            new Date(e.event_start).getTime() >
            new Date().getTime() + 2 * 60 * 60 * 1000
          ) {
            return setFuturEventsState((oldArray) => [...oldArray, e]);
          } else if (
            new Date(e.event_end).getTime() <
            new Date().getTime() + 1 * 60 * 60 * 1000
          ) {
            return setPastEventsState((oldArray) => [...oldArray, e]);
          } else {
            return setScannableEventsState((oldArray) => [...oldArray, e]);
          }
        });
      });
    }
  }, [user.club_id]);

  return (
    <div className={styles.container}>
      <TopBar />
      {scanOpen ? (
        <div className={styles.content}>
          <ScanModal />
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.title}>
            {t(`myEvents.title`)}
          </div>
          <TodayEvents events={scannableEventsState} />
          <MyFuturEvents events={futurEventsState} />
          <MyPastEvents events={pastEventsState} />
        </div>
      )}
      <Footer />
    </div>
  );
}

export default MyEvents;
