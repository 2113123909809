/** Import Externals */
import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";

/** Import Internals */
import LogoJutsu from "assets/pngJpg/jutsuLogo.png";
import Loader from "assets/svg/loader";

/** Import API */

/** Import Context */
import { GlobalContext } from "data/context/globalContext";

/** Import Utils */
import theme from "config/uiTheme";

import {
  ForgotPassword,
  ChangeForgotPassword,
} from "data/api/connectionQuerys";

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    cursor: "default",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      minHeight: "100vh",
    },
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background:
      "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(3,201,184,1) 100%)",
    width: "55vw",
    paddingLeft:"20px"
  },
  title: {
    fontSize: "5rem",
    color: "#fff",
    fontWeight: "800",
  },
  subTitle: {
    fontSize: "2rem",
    color: "#fff",
    fontWeight: "600",
    marginTop:"50px",
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "45vw",
    background: theme.palette.common.darkWhite,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding:"10px",
    },
  },
  noAccount: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    marginRight: "3vw",
    color: theme.palette.common.green,
    cursor: "pointer",
    fontWeight: 500,
  },
  imgLogo: {
    maxWidth: "200px",
    height: "120px",
    margin: "0 2vW",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "50%",
      margin: "1vw auto",
    },
  },
  subText: {
    fontSize: "1rem",
    fontWeight: "500",
  },
  forgotFormContainer: {
    margin: "2vh 0",
    width: "20vw",
    [theme.breakpoints.down("md")]: {
      width: "80%",
      padding:"10px",
    },
  },
  inputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "2vh 0",
  },
  inputLabel: {
    marginBottom: "1vh",
  },
  inputText: {
    border: "1px solid",
    borderRadius: "10px",
    borderColor: theme.palette.common.lightGrey,
    fontSize: "16px",
    height: "35px",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    margin: "1vW 0",
  },
  logInButton: {
    width: "100%",
    textAlign: "center",
    color: theme.palette.common.green,
    fontWeight: 600,
    border: "2px solid",
    borderRadius: "10px",
    borderColor: theme.palette.common.green,
    padding: ".5vW",
    cursor: "pointer",
  },
  bottomContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  copyright: {
    margin: "2vh 0",
  },
  bottomLine: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    margin: "2vh 0 0",
  },
  bottomText: {
    cursor: "pointer",
    margin: "0 5px",
  },
  error: {
    color: theme.palette.common.red,
  },
  success: {
    color: theme.palette.common.green,
  },
});

function Forgot() {
  const styles = useStyles();
  const { t, i18n } = useTranslation();

  let history = useHistory();

  const { setGlobalContext, mobileScreen } = useContext(GlobalContext);

  let { token } = useParams();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  let mobileScreenBool = useMediaQuery("(max-width:767px)");
  useEffect(() => {
    setGlobalContext({ mobileScreen: mobileScreenBool });
  }, [mobileScreenBool]);

  useEffect(() => {
    if (localStorage.getItem("xsrfToken")) {
      history.push("/home");
    }
  }, []);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSendEmail = () => {
    setLoading(true)
    setError("");
    setSuccess("");
    ForgotPassword(email, i18n.resolvedLanguage);
    setLoading(false);
    setSuccess(t("forgot.mail"));
  };

  const handleSendPsw = () => {
    setLoading(true);
    setError("");
    setSuccess("");
    if (password === confirmPassword) {
      ChangeForgotPassword(email, password, token)
        .then((res) => {
          if (res.data.message === "passwordChanged") {
            setSuccess(t("forgot.success"));
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data.message === "Missing fields") {
            setError(t("login.errors.missingField"));
          }
          if (error.response.data.message === "Email not valid") {
            setError(t("login.errors.wrongEmail"));
          }
          if (error.response.data.message === "Wrong password") {
            setError(t("login.errors.wrongPsw"));
          }
          if (error.response.data.message === "Unvalid link") {
            setError(t("login.errors.wrongLink"));
          }
        });
    } else {
      setLoading(false);
      setError(t("forgot.wrongConfirm"));
    }
  };

  let year = new Date();
  year = year.getFullYear();


  return (
    <div className={styles.container}>
      {!mobileScreen ? (
        <section className={styles.leftSection}>
          <div className={styles.title}>{t("login.welcome1")}</div>
          <div className={styles.title}>{t("login.welcome2")}</div>
          <div className={styles.subTitle}>{t("login.subtitle")}</div>
        </section>
      ) : null}
      <div className={styles.rightSection}>
        <div
          className={styles.noAccount}
          onClick={() => {
            history.push("/login");
          }}
        >
          {t("forgot.backToConnection")}
        </div>
        <img
          src={LogoJutsu}
          alt="logo"
          className={styles.imgLogo}
          onClick={() => {
            history.push(`/home`);
          }}
        />
        <div className={styles.subText}>{t("forgot.newPsw")}</div>
        {token ? (
          <div className={styles.forgotFormContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t("login.email")}</label>
              <input
                className={styles.inputText}
                type="email"
                value={email}
                onChange={(e) => handleChangeEmail(e)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t("login.password")}</label>
              <input
                className={styles.inputText}
                type="password"
                value={password}
                onChange={(e) => handleChangePassword(e)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>
                {t("forgot.confirmPassword")}
              </label>
              <input
                className={styles.inputText}
                type="password"
                value={confirmPassword}
                onChange={(e) => handleChangeConfirmPassword(e)}
              />
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.logInButton} onClick={handleSendPsw}>
                {loading ? <Loader /> : t("forgot.save")}
              </div>
            </div>
            <div className={styles.statusContainer}>
              {error ? (
                <div className={styles.error}>{error}</div>
              ) : success ? (
                <div className={styles.success}>{success}</div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className={styles.forgotFormContainer}>
            <div className={styles.inputContainer}>
              <label className={styles.inputLabel}>{t("login.email")}</label>
              <input
                className={styles.inputText}
                type="email"
                value={email}
                onChange={(e) => handleChangeEmail(e)}
              />
            </div>
            <div className={styles.buttonContainer}>
              <div className={styles.logInButton} onClick={handleSendEmail}>
                {loading ? <Loader /> : t("forgot.send")}
              </div>
            </div>
            <div className={styles.statusContainer}>
              {error ? (
                <div className={styles.error}>{error}</div>
              ) : success ? (
                <div className={styles.success}>{success}</div>
              ) : null}
            </div>
          </div>
        )}

        <div className={styles.bottomContainer}>
          <div className={styles.copyright}>© {year} Jutsu</div>
          <div className={styles.bottomLine}>
            <div
              className={styles.bottomText}
              onClick={() => history.push("/cgu")}
            >
              {t("login.cgu")}
            </div>
            <div
              className={styles.bottomText}
              onClick={() => history.push("/confidential")}
            >
              {t("login.confidential")}
            </div>
            <div
              className={styles.bottomText}
              onClick={() => history.push("/help")}
            >
              {t("login.help")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgot;
