import React from "react";

const Kimono = (props) => {
  let size = props.size || 50;
  let color = props.color || "#4d4d4d";

  return (
    <svg height={size} width={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="m160 256-48 128-88-24 80-248 64-32 24-24h128l24 25 64 32 80 248-88 24-48-129v88l16 112H144l16-112z" fill="#e9eef2" />
        <path d="m320 56-68.22 74.72L192 56z" fill="#b6b9ba" />
        <path d="m256 136-24 24-3.25-4.06L168 80l24-24 59.78 74.72z" fill="#bec1c2" />
        <path d="M344.01 320c-.267 0-.535-.014-.806-.041a8 8 0 0 1-7.164-8.756l16-160a8 8 0 0 1 15.92 1.592l-16 160a8 8 0 0 1-7.95 7.205zM152 240l-7.96-87.2a8 8 0 1 1 15.92-1.592L168 240z" fill="#dadcde" />
        <path d="M343.53 80.51 184 256l8 56v32l-16 112h-32l16-112v-32l-4.8-43.2L152 240l76.75-84.06 23.03-25.22L320 56l24 24z" fill="#c3c6c7" />
        <path d="M160 312h192v32H160z" fill={color} />
        <path d="M232 304h48v48h-48z" fill={color} />
        <g fill={color}>
            <path d="m360 416-80-64v-48l96 80zM152 416l80-64v-48l-96 80z" />
        </g>
    </svg>
  );
};

export default Kimono;
