/** Import Externals */
import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

/** Import Internals */

/** Import Context */

/** Import API */

/** Import Utils */
import theme from 'config/uiTheme'
import { SendMessageToClub, SendMessageToJutsu } from 'data/api/contactQuerys'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.common.white,
    margin: '10px auto',
    padding: '20px 0',
  },
  input: {
    border: 'none',
    borderBottom: '0.1rem solid #e6e6e6',
    width: '100%',
    marginBottom: '1rem',
    fontSize: '1rem',
    padding: '0.7rem 0',
    outline: 'none',
  },
  send: {
    color: theme.palette.common.green,
    background: theme.palette.common.white,
    fontWeight: 600,
    border: 'none',
    borderBottom: '0.1rem solid',
    borderColor: theme.palette.common.green,
    padding: '10px',
    cursor: 'pointer',
  },
  success:{
    color: theme.palette.common.green,
    fontWeight: 600,
    padding: '10px',
  }
})

function ContactForm({ clubToSend }) {
  const styles = useStyles()

  const { t, i18n } = useTranslation()

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (clubToSend !== {}) {
      SendMessageToClub(formData, clubToSend, i18n.resolvedLanguage).then(
        (resMessage) => {
          setSuccess(true)
        },
      )
    } else {
      SendMessageToJutsu(formData).then((resMessage) => {
        setSuccess(true)
      })
    }
    setFormData({
      name: '',
      email: '',
      message: '',
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input
          className={styles.input}
          type="text"
          id="name"
          name="name"
          placeholder={t('components.contactForm.name')}
          value={formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <input
          className={styles.input}
          type="email"
          id="email"
          name="email"
          placeholder={t('components.contactForm.email')}
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <textarea
          className={styles.input}
          id="message"
          name="message"
          placeholder={t('components.contactForm.message')}
          value={formData.message}
          onChange={handleChange}
          required
        />
      </div>
      {success ? (
        <div className={styles.success}>{t('components.contactForm.success')}</div>
      ) : (
        <button className={styles.send} type="submit">
          {clubToSend
            ? t('components.contactForm.sendToClub')
            : t('components.contactForm.send')}
        </button>
      )}
    </form>
  )
}

export default ContactForm
