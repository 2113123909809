/** Import Externals */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";

/** Import Internals */

/** Import Context */

/** Import API */

/** Import Utils */
import { getIconSport } from "data/utils";
import theme from "../../config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    maxWidth: "28vW",
    margin: "2vh 1vW",
    background: theme.palette.common.white,
    boxShadow: "0px 3px 13px rgba(30, 25, 37, 0.2)",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  image: {
    width: "100%",
    minWidth:"28vW",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      minWidth: "85vw",
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 1vW",
  },
  line: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  dateText: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.darkGrey,
    fontSize: "0.9rem",
    fontWeight: "300",
  },
  city: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.grey,
    fontSize: "0.9rem",
    fontWeight: "300",
  },
  nameText: {
    fontSize: "1.2rem",
    fontWeight: "600",
    margin: "5px 0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  clubText: {
    fontSize: "0.9rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.common.grey,
  },
  cardSport: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.grey,
    borderRadius: "10px",
    padding: "0% 5%",
    fontWeight: 400,
    margin: "2% 0",
  },
  sportText: {
    fontWeight: 500,
    marginLeft: "10px",
  },
  registers: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: theme.palette.common.grey,
  },
});

function EventCard({ event }) {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const handleShowEvent = () => {
    history.push(`/events/${event.event_id}`);
    window.scrollTo(0, 0);
  };

  let eventSportArray = event.event_sport.split("/");
  let iconSport = getIconSport(eventSportArray[0], eventSportArray[1]);

  return (
    <div className={styles.container} onClick={handleShowEvent}>
      <div>
        {event.image ? (
          <img
            className={styles.image}
            src={event.image[0].image_file}
            alt={event.event_name}
          ></img>
        ) : null}
      </div>
      <div className={styles.textContainer}>
        <div className={styles.line}>
          <div className={styles.dateText}>
            <AccessTimeIcon fontSize="small" />{" "}
            {moment(event.event_start).format("lll")}
          </div>
          <div className={styles.city}>
            <LocationOnIcon fontSize="small" /> {event.event_city}
          </div>
        </div>
        <div className={styles.line}>
          <div className={styles.nameText}>{event.event_name}</div>
        </div>
        <div className={styles.cardSport}>
          <div className={styles.sportIcon}>{iconSport}</div>
          <div className={styles.sportText}>
            {t(`sports.${eventSportArray[0]}`)}
          </div>
        </div>
        <div className={styles.line}>
          <div className={styles.clubText}>{event.club_name}</div>
          <div className={styles.registers}>
            <div>
              {event.event_registers === ""
                ? "0"
                : event.event_registers.includes(",")
                ? event.event_registers.split(",").length
                : "1"}{" "}
              / {event.event_places}
            </div>
            <PeopleIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
