/** Import Externals */
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import Select from "react-select";

/** Import Internals */
import AddIcon from "assets/svg/add";
import TrashIcon from "assets/svg/trash";

/** Import Context */
import { UserContext } from "data/context/userContext";
import { SearchContext } from "data/context/searchContext";
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import { getIconSport } from "data/utils";
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    margin: "3vH auto",
  },
  selectContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "3% auto",
    alignItems: "center",
  },
  selectInputs: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  addButton: {
    color: theme.palette.common.white,
    background: theme.palette.common.green,
    borderRadius: "0 5px 5px 0",
    padding: "8px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      height: "50%",
      padding: "20px",
      alignItems:"center",
    },
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sportLine: {
    display: "flex",
    alignItems: "center",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    borderRadius: "10px",
    width: "60%",
    margin: "1vW auto",
    padding: ".5vW",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      padding: "3vW",

    },
  },
  sportIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: theme.palette.common.darkWhite,
    padding: "1vW",
    width: "3vW",
    height: "3vW",
    borderRadius: "200px",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      width: "max-content",
      height: "max-content",
    },
  },
  sportName: {
    fontSize: ".8rem",
    fontWeight: 600,
    cursor: "default",
    [theme.breakpoints.down("md")]: {
      fontSize: ".6rem",
    },
  },
  deleteIcon: {
    cursor: "pointer",
  },
  listTellUs: {
    fontSize: "1.5rem",
    fontWeight: 500,
    color: theme.palette.common.lightGrey,
    margin: "3vW auto",
    cursor: "default",
  },
});

function SportSelectorUser() {
  const styles = useStyles();
  const { t } = useTranslation();

  const { setUserContext, user } = useContext(UserContext);
  const { sportsList } = useContext(SearchContext);
  const { mobileScreen } = useContext(GlobalContext);

  const [sportsListState, setSportsListState] = useState([]);
  const [sport, setSport] = useState("");
  const [LevelList, setLevelList] = useState([]);
  const [level, setLevel] = useState("");
  const [idSport, setIdSport] = useState(0);
  const [userSportList, setUserSportList] = useState([]);

  useEffect(() => {
    let optionsSports = sportsList.map((sport) => {
      return {
        label: t(`sports.${sport.sport_name}`),
        value: sport.sport_name,
        grades: sport.sport_grades,
      };
    });
    setSportsListState(optionsSports);
  }, [sportsList]);

  useEffect(() => {
    if (user.sport) {
      let sportLevel = user.sport.split(",");
      let sportsArray = [];
      if (sportLevel.length > 0) {
        sportLevel.forEach((el, index) => {
          let sportLevelSplit = el.split("/");
          return sportsArray.push({
            sport: sportLevelSplit[0],
            level: sportLevelSplit[1],
            id: index,
            iconImage: getIconSport(sportLevelSplit[0], sportLevelSplit[1]),
          });
        });
        setUserSportList(sportsArray);
        setIdSport(sportsArray.length + 1);
        setUserContext({ userSportList: sportsArray });
      }
    }
  }, [user.sport]);

  useEffect(() => {}, [userSportList]);

  const handleChangeSport = (e) => {
    setSport(e.value);
    let grades = e.grades.split(",");
    let optionsGrade = grades.map((grade) => {
      return {
        label: t(`grades.${grade}`),
        value: grade,
      };
    });
    setLevel(optionsGrade[0]);
    setLevelList(optionsGrade);
  };

  const handleChangeLevel = (e) => {
    setLevel(e);
  };

  const handleAddUserSport = () => {
    if (userSportList.length < 10 && sport !== "" && level.value) {
      setUserSportList((oldArray) => [
        ...oldArray,
        {
          sport: sport,
          level: level.value,
          id: idSport,
          iconImage: getIconSport(sport, level.value),
        },
      ]);
      let newArray = [
        ...userSportList,
        {
          sport: sport,
          level: level.value,
          id: idSport,
          iconImage: getIconSport(sport, level.value),
        },
      ];
      setUserContext({ userSportList: newArray });
      setIdSport(idSport + 1);
    }
  };

  const handleDeleteSport = (id) => {
    let newArray = [...userSportList];
    let index = newArray.findIndex((e) => e.id === id);
    newArray.splice(index, 1);
    setUserSportList(newArray);
    setUserContext({ userSportList: newArray });
  };

  return (
    <div className={styles.container}>
      <div className={styles.selectContainer}>
        <div className={styles.selectInputs}>
          <Select
            options={sportsListState}
            onChange={handleChangeSport}
            placeholder={t("components.sportSelector.sport")}
            maxMenuHeight={160}
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: mobileScreen ? "10px 0 0 0" :"10px 0 0 10px",
                cursor: "pointer",
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: "none",
              }),
              input: (provided) => ({
                ...provided,
                color: theme.palette.common.darkGrey,
                textAlign: "center",
                paddingTop: "5px",
                height: "30px",
                width: "15vW",
              }),
              option: (provided, state) => ({
                ...provided,
                fontWeight: state.isSelected ? 600 : 400,
                color: state.isSelected
                  ? theme.palette.common.darkGrey
                  : theme.palette.common.grey,
                background: theme.palette.common.white,
                cursor: "pointer",
                "&:hover": {
                  color: theme.palette.common.darkGrey,
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: theme.palette.common.darkGrey,
              }),
            }}
          />
          <Select
            options={LevelList}
            onChange={handleChangeLevel}
            placeholder={t("components.sportSelector.level")}
            value={level}
            maxMenuHeight={160}
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: mobileScreen ? " 0 0 0 10px" :"0",
                cursor: "pointer",
              }),
              indicatorSeparator: (provided) => ({
                ...provided,
                display: "none",
              }),
              input: (provided) => ({
                ...provided,
                color: theme.palette.common.darkGrey,
                textAlign: "center",
                paddingTop: "5px",
                height: "30px",
                width: mobileScreen ? "40vW" :"15vW",
              }),
              option: (provided, state) => ({
                ...provided,
                fontWeight: state.isSelected ? 600 : 400,
                color: state.isSelected
                  ? theme.palette.common.darkGrey
                  : theme.palette.common.grey,
                background: theme.palette.common.white,
                cursor: "pointer",
                "&:hover": {
                  color: theme.palette.common.darkGrey,
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: theme.palette.common.darkGrey,
              }),
            }}
          />
        </div>
        <div className={styles.addButton} onClick={handleAddUserSport}>
          <AddIcon size={20} />
        </div>
      </div>
      <div className={styles.listContainer}>
        {userSportList.length > 0 ? (
          userSportList.map((e) => {
            return (
              <div className={styles.sportLine} key={e.id}>
                <div className={styles.sportIcon}>{e.iconImage}</div>
                <div className={styles.sportName}>{`${t(
                  `sports.${e.sport}`
                )} - ${t(`grades.${e.level}`)}`}</div>
                <div
                  className={styles.deleteIcon}
                  onClick={() => handleDeleteSport(e.id)}
                >
                  <TrashIcon size={30} />
                </div>
              </div>
            );
          })
        ) : (
          <div className={styles.listTellUs}>
            {t("components.sportSelector.tellUs")}
          </div>
        )}
      </div>
    </div>
  );
}

export default SportSelectorUser;
