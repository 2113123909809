/** Import Externals */
import React from 'react'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'

/** Import Internals */
import ContactForm from '../../components/contactForm/contactForm.js'

/** Import Context */

/** Import API */

/** Import Utils */
import theme from 'config/uiTheme'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    background: theme.palette.common.white,
    margin: '10px auto',
    borderRadius: '10px',
    padding: '20px 0',
  },
  title: {
    fontWeight: 600,
    fontSize: '1.5rem',
    textAlign: 'center',
    margin: '20px 0',
  },
  text: {
    width: '80%',
    margin: '10px auto',
  },
  textBold: {
    fontWeight: 600,
    width: '80%',
    margin: '10px auto',
  },
  form: {
    width: '60%',
    margin: '20px auto',
  },
})

function FaqPage() {
  const styles = useStyles()

  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t('help.contact.title1')}</div>
      <div className={styles.text}>{t('help.contact.text1')}</div>
      <div className={styles.text}>
        {t('help.contact.text2')}
        <span className={styles.textBold}>{t('help.contact.text2Email')}</span>
      </div>
      <div className={styles.text}>{t('help.contact.text3')}</div>
      <div className={styles.text}>{t('help.contact.text4')}</div>
      <div className={styles.form}>
        <ContactForm />
      </div>
    </div>
  )
}

export default FaqPage
