/** Import Externals */
import React, { useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

/** Import Internals */
import TopBar from "components/topBar";
import ProfileForm from "./profileForm";
import ClubPage from "./clubPage";
import SettingsForm from "./settingsForm";
import Footer from "components/footer";


/** Import Context */
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.common.ultraLightGrey,
    overflow: "scroll",
    position: "relative",
  },
  mainContainer: {
    width: "80%",
    margin: "2rem auto",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      flexDirection: "column",
      margin: "0 auto"
    },
  },
  selectorList: {
    borderRadius: "10px",
    height: "3rem",
    width: "15rem",
    marginRight: "1rem",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "row",
      margin:"0 auto 20px",
    },
  },
  selector: {
    padding: "1rem",
    color: theme.palette.common.grey,
    borderRadius: "10px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.common.darkGrey,
    },
    [theme.breakpoints.down("md")]: {
      borderRadius:"0px",
      borderBottom: "1px solid",
      borderColor: theme.palette.common.grey,
      margin: "0 5px 0 0",
    },
  },
  selected: {
    color: theme.palette.common.green,
    borderBottom: "0.1rem solid",
    borderRadius:0,
    borderColor: theme.palette.common.green,
    fontWeight: 500,
    "&:hover": {
      color: theme.palette.common.green,
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      background: theme.palette.common.ultraLightGrey,
    },
  },
});

function Account() {
  const styles = useStyles();

  const { t } = useTranslation();

  const { setGlobalContext, accountPage } = useContext(GlobalContext);

  useEffect (() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  }, [accountPage])

  const handleChangeSelector = (e) => {
    setGlobalContext({ accountPage: e });
  };

  return (
    <div className={styles.container}>
      <TopBar />
      <div className={styles.mainContainer}>
        <div className={styles.selectorList}>
          <div
            className={clsx(
              styles.selector,
              accountPage === "profile" ? styles.selected : null
            )}
            onClick={() => handleChangeSelector("profile")}
          >
            {t("account.menu.profile")}
          </div>
          <div
            className={clsx(
              styles.selector,
              accountPage === "club" ? styles.selected : null
            )}
            onClick={() => handleChangeSelector("club")}
          >
            {t("account.menu.club")}
          </div>
          <div
            className={clsx(
              styles.selector,
              accountPage === "settings" ? styles.selected : null
            )}
            onClick={() => handleChangeSelector("settings")}
          >
            {t("account.menu.settings")}
          </div>
        </div>
        {accountPage === "profile" ? (
          <ProfileForm />
        ) : accountPage === "club" ? (
          <ClubPage />
        ) : (
          <SettingsForm />
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Account;
