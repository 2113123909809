import React, { createContext, useState } from "react";

export const UserContext = createContext({
  user: {},
  club: {},
  stripeAccountValid: false,
  lat: 0,
  lng: 0,
  userSportList: [],
  newClubProfilePic: "",
  newClubLandscapePic: "",
  setUserContext: (data) => {},
});

const UserContextProvider = ({ children }) => {
  const userState = {
    user: {},
    club: {},
    stripeAccountValid: false,
    lat: 0,
    lng: 0,
    userSportList: [],
    newClubProfilePic: "",
    newClubLandscapePic: "",
    setUserContext: (data) =>
      setUser((prevState) => ({
        ...prevState,
        ...data,
      })),
  };

  const [user, setUser] = useState(userState);
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
