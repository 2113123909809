import React from "react";

const Lotus = (props) => {
  let size = props.size || 50;

  return (
    <svg height={size} width={size} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M367.495 286.598c25.226-51.608 31.66-106.088 21.713-151.911a8.498 8.498 0 0 0-12.038-5.884c-42.268 20.3-81.306 58.843-106.533 110.45-27.113 55.467-32.517 114.252-19.207 162.041 45.885-18.854 88.952-59.229 116.065-114.696z" fill="#ffcbce" />
        <path d="M144.67 286.598c-25.226-51.608-31.66-106.088-21.713-151.911a8.498 8.498 0 0 1 12.038-5.884c42.268 20.3 81.306 58.843 106.533 110.45 27.113 55.467 32.517 114.252 19.207 162.041-45.885-18.854-88.952-59.229-116.065-114.696z" fill="#ffcbce" />
        <path d="m267.99 96.219-10.24-.469C225.942 135.495 209 185.837 209 248.312c0 66.578 12.147 126.091 47.673 166.087 35.526-39.996 58.13-99.509 58.13-166.087 0-59.202-17.873-112.818-46.813-152.093z" fill="#ffb3b7" />
        <path d="M220.912 248.312c0-59.197 18.144-112.819 47.079-152.093a202.508 202.508 0 0 0-4.9-6.381c-3.251-4.062-9.585-4.062-12.836 0-31.808 39.744-51.712 95.999-51.712 158.474 0 66.578 22.604 126.091 58.13 166.087 3.898-4.389 7.611-9.052 11.185-13.894-28.999-39.286-46.946-92.92-46.946-152.193z" fill="#ff9a9f" />
        <path d="M402.289 410.57c48.259-17.823 86.215-49.01 108.398-84.76a8.51 8.51 0 0 0-4.679-12.67c-40.095-12.749-89.211-11.779-137.47 6.043-52.334 19.328-92.552 54.372-113.678 93.909 41.753 16.314 95.095 16.806 147.429-2.522z" fill="#ff8086" />
        <path d="M109.711 410.57c-48.259-17.823-86.215-49.01-108.398-84.76a8.51 8.51 0 0 1 4.679-12.67c40.095-12.749 89.211-11.779 137.47 6.043 52.334 19.328 92.552 54.372 113.678 93.909-41.753 16.314-95.095 16.806-147.429-2.522z" fill="#ff8086" />
        <path d="M332.419 259.226c44.177-44.177 98.029-69.88 148.624-75.492 5.171-.574 9.65 3.905 9.076 9.076-5.612 50.595-31.315 104.447-75.492 148.624-47.078 47.078-105.143 73.176-158.546 76.337l-6.27-12.835 6.188-8.51s3.239-3.984 3.259-4.089c8.696-45.927 33.474-93.423 73.161-133.111z" fill="#ff9a9f" />
        <path d="M179.746 259.227c-40.81-40.81-89.876-65.855-137.004-73.859L34.5 194c5.612 50.595 26.823 95.323 71 139.5 47.078 47.078 91.098 74.756 144.5 77.917l3.612-14.989c-7.969-47.188-33.001-96.336-73.866-137.201z" fill="#ff9a9f" />
        <path d="M256 396.427c-47.189-7.968-98.721-33-139.589-73.867-40.808-40.807-65.664-90.067-73.67-137.193a202.872 202.872 0 0 0-11.62-1.633c-5.171-.574-9.65 3.905-9.076 9.076 5.612 50.596 31.315 104.448 75.492 148.625 47.078 47.078 105.143 73.176 158.545 76.337z" fill="#ff8086" />
    </svg>
  );
};

export default Lotus;
