import React from "react";

const TrashCan = (props) => {
  let size = props.size || 50;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 52 52"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 11v36a4 4 0 0 0 4 4h32a4 4 0 0 0 4-4V11zm12 31a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V20a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm10 0a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V20a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zm10 0a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V20a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1z"
        fill="#f16573"
      />
      <path
        d="M38.08 18h-2.16a1.09 1.09 0 0 0-1.09 1.08v23.84A1.09 1.09 0 0 0 35.92 44h2.16a1.09 1.09 0 0 0 1.09-1.08V19.08A1.09 1.09 0 0 0 38.08 18zM39 42a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V20a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zM28 18h-2.16a1.09 1.09 0 0 0-1.09 1.08v23.84A1.09 1.09 0 0 0 25.84 44H28a1.09 1.09 0 0 0 1.09-1.08V19.08A1.09 1.09 0 0 0 28 18zm.92 24a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V20a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1zM17.92 18h-2.16a1.09 1.09 0 0 0-1.09 1.08v23.84A1.09 1.09 0 0 0 15.76 44h2.16a1.09 1.09 0 0 0 1.09-1.08V19.08A1.09 1.09 0 0 0 17.92 18zm.92 24a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V20a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1z"
        fill="#db5669"
      />
      <path
        d="m27 11 13.221 1.469A2 2 0 0 1 42 14.457V51h1a4 4 0 0 0 4-4V11z"
        fill="#db5669"
      />
      <path d="m26 11-15 1.667V51a4 4 0 0 1-4-4V11z" fill="#f97a8d" />
      <path d="M21 1h12a4 4 0 0 1 4 4v2H17V5a4 4 0 0 1 4-4z" fill="#374f68" />
      <path
        d="M2 7h49a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1z"
        fill="#30455c"
      />
      <path
        d="M52 8H3a1 1 0 0 0-1 1v2a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h49a1 1 0 0 1 1 1z"
        fill="#374f68"
      />
      <path
        d="M23 2h12c.242-.002.483.021.72.07A4.012 4.012 0 0 0 33 1H21a4 4 0 0 0-4 4v2h12l-7.232-.723a2 2 0 0 1-.968-3.619A3.983 3.983 0 0 1 23 2z"
        fill="#46617a"
      />
      <g fill="#33345d">
        <path d="M43 52H11a5.006 5.006 0 0 1-5-5V11a1 1 0 0 1 1-1h40a1 1 0 0 1 1 1v36a5.006 5.006 0 0 1-5 5zM8 12v35a3 3 0 0 0 3 3h32a3 3 0 0 0 3-3V12zM37 8H17a1 1 0 0 1-1-1V5a5.006 5.006 0 0 1 5-5h12a5.006 5.006 0 0 1 5 5v2a1 1 0 0 1-1 1zM18 6h18V5a3 3 0 0 0-3-3H21a3 3 0 0 0-3 3z" />
        <path d="M18 44h-2a2 2 0 0 1-2-2V20a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v22a2 2 0 0 1-2 2zm-2-24v22h2V20zM28 44h-2a2 2 0 0 1-2-2V20a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v22a2 2 0 0 1-2 2zm-2-24v22h2V20zM38 44h-2a2 2 0 0 1-2-2V20a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v22a2 2 0 0 1-2 2zm-2-24v22h2V20zM51 12H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h49a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2zM2 8v2h49V8z" />
      </g>
    </svg>
  );
};

export default TrashCan;
