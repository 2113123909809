import React from "react";

const QrCode = (props) => {
  let size = props.size || 50;
  let color = props.color || "white";

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color}
        d="M4 9.499v-5.5h5.5c.662 0 .661-1 0-1h-6a.5.5 0 0 0-.5.5v6c0 .662 1 .661 1 0zM9.5 26.001H4v-5.5c0-.662-1-.661-1 0v6a.5.5 0 0 0 .5.5h6c.662 0 .661-1 0-1zM27 3.499a.5.5 0 0 0-.5-.5h-6c-.662 0-.661 1 0 1H26v5.5c0 .662 1 .661 1 0zM26 20.501v5.5h-5.5c-.662 0-.661 1 0 1h6a.5.5 0 0 0 .5-.5v-6c0-.662-1-.661-1 0zM8 9.499a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z"
      />
      <path
        fill={color}
        d="M6.5 5.999a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5zm.5 1h4v4H7zM10 20.499a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5z"
      />
      <path
        fill={color}
        d="M12 18.499a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5zm-1 4.5H7v-4h4zM20 9.499a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5z"
      />
      <path
        fill={color}
        d="M18 11.499a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0-.5.5zm1-4.5h4v4h-4zM9.5 16.999h4c.662 0 .661-1 0-1H12v-1.5c0-.662-1-.661-1 0v1.5H9.5c-.662 0-.661 1 0 1zM14 7.499v-1c0-.662-1-.661-1 0v1c0 .662 1 .661 1 0zM13.5 9.999h2a.5.5 0 0 0 .5-.5v-1.5h.5c.662 0 .661-1 0-1h-1a.5.5 0 0 0-.5.5v1.5h-1.5c-.662 0-.661 1 0 1zM13.5 14.999h2c.662 0 .661-1 0-1H15v-2h1.5c.662 0 .661-1 0-1h-2a.5.5 0 0 0-.5.5v2.5h-.5c-.662 0-.661 1 0 1zM17 14.499c0 .662 1 .661 1 0v-1c0-.662-1-.661-1 0zM14 19.999h1v.5c0 .662 1 .661 1 0v-3c0-.662-1-.661-1 0v1.5h-1.5a.5.5 0 0 0-.5.5v1c0 .662 1 .661 1 0zM18 20.499v.5h-.5a.5.5 0 0 0-.5.5v1.5h-2.5c-.662 0-.661 1 0 1h3a.5.5 0 0 0 .5-.5v-1.5h.5a.5.5 0 0 0 .5-.5v-1c0-.662-1-.661-1 0zM21 21.499v1.5h-.5c-.662 0-.661 1 0 1h3c.662 0 .661-1 0-1H22v-1.5c0-.662-1-.661-1 0zM24 18.499a.5.5 0 0 0-.5-.5h-3c-.662 0-.661 1 0 1H23v1.502c0 .662 1 .661 1 0zM24 14.499c0-.662-1-.661-1 0v1.5h-.5c-.662 0-.661 1 0 1h1a.5.5 0 0 0 .5-.5zM21 16.499v-2.5h.5c.662 0 .661-1 0-1h-1a.5.5 0 0 0-.5.5v2.5h-1.5a.5.5 0 0 0-.5.5v1.5h-.5c-.662 0-.661 1 0 1h1a.5.5 0 0 0 .5-.5v-1.5h1.5a.5.5 0 0 0 .5-.5zM6 13.499v3c0 .662 1 .661 1 0v-1.5h1.5c.662 0 .661-1 0-1H7v-.5c0-.662-1-.661-1 0z"
      />
    </svg>
  );
};

export default QrCode;
