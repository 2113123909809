/** Import Externals */
import React, { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import clsx from 'clsx'

import { Typography } from '@mui/material'

/** Import Internals */
import LogoJutsu from '../../assets/pngJpg/jutsuLogo.png'
import LanguageSelector from './languageSelector'
import SubMenu from './subMenu'
import SearchField from './searchField'
import SearchLogo from 'assets/svg/search'
import Add from 'assets/svg/add'
import Eject from 'assets/svg/eject'

/** Import Context */
import { UserContext } from '../../data/context/userContext'
import { GlobalContext } from '../../data/context/globalContext'

/** Import API */
import { GetCurrentUser } from 'data/api/userQuerys'
import { GetClub } from 'data/api/clubQuerys'

/** Import Utils */
import theme from '../../config/uiTheme'
import { StripeIsValidAccount } from 'data/api/stripeQuerys'

const useStyles = makeStyles({
  largeContainer: {
    background: theme.palette.common.darkPlusGrey,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.common.darkPlusGrey,
    position: 'relative',
  },
  leftSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '4vW',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
    },
  },
  logoJutsu: {
    width: '150px',
    height: '80px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '100px',
      height: '50px',
    },
  },
  logSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '15px 0',
    marginRight: '6vW',
    [theme.breakpoints.down('md')]: {
      marginRight: '1vW',
    },
  },
  newEvent: {
    padding: '.5rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    cursor: 'pointer',
    textAlign: 'center',
    border: '1px solid',
    borderColor: theme.palette.common.white,
    borderRadius: '10px',
    marginLeft: '2vW',
  },
  newEventText: {
    marginLeft: '.8rem',
  },
  newClub: {
    width: 'max-content',
    padding: '.5rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    cursor: 'pointer',
    textAlign: 'center',
    border: '1px solid',
    borderColor: theme.palette.common.white,
    borderRadius: '10px',
    marginLeft: '2vW',
  },
  newClubText: {
    marginLeft: '.8rem',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    cursor: 'pointer',
    padding: '10px 0',
  },
  noSee: {
    display: 'none',
  },
  searchField: {
    width: '100%',
    margin: '0 5%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  searchButton: {
    display: 'flex',
    color: theme.palette.common.white,
    width: 'max-content',
    cursor: 'pointer',
  },
  searchText: {
    fontWeight: 600,
    margin: '0px .5rem',
  },
  languageSelector: {
    marginRight: '2vW',
  },
  menuImage: {
    height: '4rem',
    width: '4rem',
    borderRadius: '200px',
    border: '2px solid',
    borderColor: theme.palette.common.green,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: '2rem',
      width: '2rem',
    },
  },
  menuEjectLogo: {
    display: 'flex',
    alignItems: 'center',
    height: '4rem',
    width: '4rem',
    minWidth: '4rem',
    borderRadius: '200px',
    border: '2px solid',
    borderColor: theme.palette.common.green,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: '2rem',
      width: '2rem',
      minWidth: '2rem',
    },
  },
  ejectLogo: {
    margin: '0 auto',
  },
  mobileSearchField: {
    margin: '0.5rem ',
  },
})

function TopBar() {
  const styles = useStyles()
  const { t } = useTranslation()

  let history = useHistory()

  let { xsrf, access, refresh } = useParams()

  const { setUserContext, user, club } = useContext(UserContext)
  const {
    setGlobalContext,
    menuOpen,
    accountReload,
    mobileScreen,
  } = useContext(GlobalContext)

  let mobileScreenBool = useMediaQuery('(max-width:767px)')
  useEffect(() => {
    setGlobalContext({ mobileScreen: mobileScreenBool })
  }, [mobileScreenBool])

  useEffect(() => {
    if (!user.first_name || accountReload) {
      setGlobalContext({ accountReload: false })
      if (xsrf && access && refresh) {
        localStorage.setItem('xsrfToken', xsrf)
        document.cookie = `accessToken=${access};${3600000};path=/`
        document.cookie = `refreshToken=${refresh};${2592000000};path=/`
      }
      let tokenStore = localStorage.getItem('xsrfToken')
      if (tokenStore) {
        GetCurrentUser()
          .then((res) => {
            if (res.data) {
              let profilePicture
              let landscapePicture
              if (res.data.image) {
                res.data.image.forEach((i) => {
                  if (i.image_name.includes('profile')) {
                    profilePicture = i.image_file
                  } else {
                    landscapePicture = i.image_file
                  }
                })
              }
              setUserContext({
                user: {
                  user_id: res.data.id,
                  club_id: res.data.user_club_id,
                  first_name: res.data.user_first_name,
                  last_name: res.data.user_last_name,
                  city: res.data.user_city,
                  insta_link: res.data.user_insta_link,
                  description: res.data.user_description,
                  sport: res.data.user_sports,
                  profileImage: profilePicture,
                  landscapeImage: landscapePicture,
                },
              })
              if (res.data.user_club_id) {
                GetClub(res.data.user_club_id)
                  .then((res) => {
                    if (res.data) {
                      let profilePicture
                      let landscapePicture
                      if (res.data.image) {
                        res.data.image.forEach((i) => {
                          if (i.image_name.includes('profile')) {
                            profilePicture = i.image_file
                          } else {
                            landscapePicture = i.image_file
                          }
                        })
                      }
                      StripeIsValidAccount(res.data.club_id)
                        .then((resValid) => {
                          if (resValid.status === 200) {
                            setUserContext({ stripeAccountValid: true })
                          }
                        })
                        .catch(() => {})
                      setUserContext({
                        club: {
                          club_id: res.data.club_id,
                          name: res.data.club_name,
                          address: res.data.club_address,
                          zip_code: res.data.club_zip_code,
                          city: res.data.club_city,
                          country: res.data.club_country,
                          sports: res.data.club_sports,
                          description: res.data.club_description,
                          insta_link: res.data.club_insta_link,
                          website: res.data.club_website,
                          profileImage: profilePicture,
                          landscapeImage: landscapePicture,
                        },
                      })
                    }
                  })
                  .catch(() => {})
              }
            }
          })
          .catch((err) => {
            setUserContext({
              user: {},
              club: {},
            })
            // localStorage.removeItem("xsrfToken");
            setGlobalContext({ menuOpen: false })
            history.push(`/`)
          })
      }
    }
  }, [accountReload])

  useEffect(() => {}, [menuOpen])

  const handleGoHome = () => {
    history.push(`/home`)
    setGlobalContext({ menuOpen: false })
  }

  const handleGoProfile = () => {
    history.push(`/profile/${user.user_id}`)
    setGlobalContext({ menuOpen: false })
  }

  const handleGoClub = () => {
    history.push(`/club/${club.club_id}`)
    setGlobalContext({ menuOpen: false })
  }

  const handleLogOut = () => {
    setUserContext({
      user: {},
      club: {},
    })
    document.cookie = 'accessToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
    document.cookie = 'refreshToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
    localStorage.removeItem('xsrfToken')
    setGlobalContext({ menuOpen: false })
    history.push(`/login`)
  }

  const handleGoCreateEvent = () => {
    history.push(`/newevent`)
    setGlobalContext({ menuOpen: false })
  }

  const handleGoCreateClub = () => {
    history.push(`/account`)
    setGlobalContext({ accountPage: 'club' })
  }

  const handleGoMyEvents = () => {
    history.push(`/myevents`)
    setGlobalContext({ menuOpen: false })
  }

  const handleGoAccount = () => {
    history.push(`/account`)
    setGlobalContext({ menuOpen: false })
  }

  const handleGoLogin = () => {
    history.push(`/login`)
    setGlobalContext({ menuOpen: false })
  }

  return (
    <div className={styles.largeContainer}>
      {mobileScreen ? (
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <img
              src={LogoJutsu}
              alt="logo"
              className={styles.logoJutsu}
              onClick={handleGoHome}
            />
          </div>
          {/* SEARCH FIELD POUR LE FETCH DES SPORTS */}
          <div className={styles.noSee}>
            <SearchField />
          </div>
          <div
            className={clsx(
              styles.searchField,
              window.location.pathname === '/events' ? styles.noSee : null,
            )}
          >
            <div
              className={clsx(
                styles.searchButton,
                window.location.pathname === '/events' ? styles.noSee : null,
              )}
              onClick={() => history.push('/events')}
            >
              {window.location.pathname === '/events' ? null : (
                <SearchLogo size={20} />
              )}
            </div>
          </div>
          {window.location.pathname.includes('/home') ||
          window.location.pathname === '/' ? (
            <div className={styles.languageSelector}>
              <LanguageSelector />
            </div>
          ) : null}
          {localStorage.getItem('xsrfToken') ? (
            <div className={styles.logSection}>
              {menuOpen ? (
                <>
                  <div
                    className={styles.menuEjectLogo}
                    onClick={() => setGlobalContext({ menuOpen: false })}
                  >
                    <div className={styles.ejectLogo}>
                      <Eject
                        size={mobileScreen ? 30 : 50}
                        color={theme.palette.common.green}
                      />
                    </div>
                  </div>
                  <SubMenu
                    logOut={() => handleLogOut()}
                    goHome={() => handleGoHome()}
                    goAccount={() => handleGoAccount()}
                    goProfile={() => handleGoProfile()}
                    goClub={() => handleGoClub()}
                    goCreateEvent={() => handleGoCreateEvent()}
                    goMyEvents={() => handleGoMyEvents()}
                  />
                </>
              ) : user.profileImage ? (
                <img
                  className={styles.menuImage}
                  src={user.profileImage}
                  alt={'menuSetter'}
                  onClick={() => setGlobalContext({ menuOpen: true })}
                />
              ) : (
                <div
                  className={styles.menuImage}
                  onClick={() => setGlobalContext({ menuOpen: true })}
                ></div>
              )}
            </div>
          ) : (
            <div className={styles.logSection}>
              <Typography
                className={styles.text}
                noWrap
                onClick={handleGoLogin}
              >
                {t('components.topBar.logIn')}
              </Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.leftSection}>
            <img
              src={LogoJutsu}
              alt="logo"
              className={styles.logoJutsu}
              onClick={handleGoHome}
            />
            {user.club_id && window.location.pathname !== '/newevent' ? (
              <div className={styles.newEvent} onClick={handleGoCreateEvent}>
                <Add size={15} />
                <div className={styles.newEventText}>
                  {t('components.topBar.newEvent')}
                </div>
              </div>
            ) : user.user_id &&
              !user.club_id &&
              window.location.pathname !== '/account' ? (
              <div className={styles.newClub} onClick={handleGoCreateClub}>
                <Add size={15} />
                <div className={styles.newClubText}>
                  {t('components.topBar.newClub')}
                </div>
              </div>
            ) : null}
          </div>
          {/* SEARCH FIELD POUR LE FETCH DES SPORTS */}
          <div className={styles.noSee}>
            <SearchField />
          </div>
          <div
            className={clsx(
              styles.searchField,
              window.location.pathname === '/events' ? styles.noSee : null,
            )}
          >
            <div
              className={clsx(
                styles.searchButton,
                window.location.pathname === '/events' ? styles.noSee : null,
              )}
              onClick={() => history.push('/events')}
            >
              <p
                className={clsx(
                  styles.searchText,
                  window.location.pathname === '/events' || mobileScreen
                    ? styles.noSee
                    : null,
                )}
              >
                {t('components.topBar.search.events')}
              </p>
              {window.location.pathname === '/events' ? null : (
                <SearchLogo size={20} />
              )}
            </div>
          </div>
          {window.location.pathname.includes('/home') ||
          window.location.pathname === '/' ? (
            <div className={styles.languageSelector}>
              <LanguageSelector />
            </div>
          ) : null}
          {localStorage.getItem('xsrfToken') ? (
            <div className={styles.logSection}>
              {menuOpen ? (
                <>
                  <div
                    className={styles.menuEjectLogo}
                    onClick={() => setGlobalContext({ menuOpen: false })}
                  >
                    <div className={styles.ejectLogo}>
                      <Eject
                        size={mobileScreen ? 30 : 50}
                        color={theme.palette.common.green}
                      />
                    </div>
                  </div>
                  <SubMenu
                    logOut={() => handleLogOut()}
                    goHome={() => handleGoHome()}
                    goAccount={() => handleGoAccount()}
                    goProfile={() => handleGoProfile()}
                    goClub={() => handleGoClub()}
                    goCreateEvent={() => handleGoCreateEvent()}
                    goMyEvents={() => handleGoMyEvents()}
                  />
                </>
              ) : user.profileImage ? (
                <img
                  className={styles.menuImage}
                  src={user.profileImage}
                  alt={'menuSetter'}
                  onClick={() => setGlobalContext({ menuOpen: true })}
                />
              ) : (
                <div
                  className={styles.menuImage}
                  onClick={() => setGlobalContext({ menuOpen: true })}
                ></div>
              )}
            </div>
          ) : (
            <div className={styles.logSection}>
              <Typography
                className={styles.text}
                noWrap
                onClick={handleGoLogin}
              >
                {t('components.topBar.logIn')}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TopBar
