/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

import CancelIcon from "@mui/icons-material/Cancel";

/** Import Internals */
import UpdatePicture from "./updatePicture";

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    background: theme.palette.common.white,
    zIndex: 100,
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    width: "100%",
    height: "min-content",
    [theme.breakpoints.down("md")]: {
      width: "96%",
      margin:"2%"
    },
  },
  iconContainer: {
    textAlign: "right",
    width: "100%",
  },
  icon: {
    color: theme.palette.common.darkGrey,
    cursor: "pointer",
  },
  textContainer: {
    color: theme.palette.common.darkGrey,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "0 auto",
  },
  title: {
    color: theme.palette.common.darkGrey,
    fontSize: "1.8rem",
    fontWeight: 700,
    textAlign: "center",
    cursor: "default",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.4rem",
    },
  },
});

function PictureModal({ type, page, close, error, eventPic }) {
  const styles = useStyles();
  const { t } = useTranslation();

  let title;
  if (type === "profile") {
    title = t("components.pictureModal.profile.title");
  } else if (type === "landscape") {
    title = t("components.pictureModal.landscape.title");
  } else {
    title = t("components.pictureModal.event.title");
  }

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <CancelIcon className={styles.icon} onClick={close} />
      </div>
      <div className={styles.textContainer}>
        <div className={styles.title}>{title}</div>
        <UpdatePicture type={type} page={page} close={close} error={error} />
      </div>
    </div>
  );
}

export default PictureModal;
