import React from "react";

const Fencing = (props) => {
  let size = props.size || 50;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 511.999 511.999"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#c8c6cd"
        d="M307.868 320.09 4.557 25.792C-1.39 20.02-1.536 10.514 4.235 4.581c5.771-5.962 15.264-6.094 21.211-.322l303.31 294.299c5.947 5.771 6.094 15.278.322 21.211-5.775 5.946-15.259 6.093-21.21.321z"
      />
      <path
        fill="#e5e5e5"
        d="M182.725 320.186c-5.771-5.933-5.625-15.439.322-21.211L486.553 4.259c5.947-5.771 15.425-5.64 21.211.322 5.771 5.933 5.625 15.439-.322 21.211L203.935 320.509c-5.951 5.771-15.435 5.625-21.21-.323z"
      />
      <path
        fill="#3c3a41"
        d="M511.798 472.811c0 8.02-3.12 15.56-8.79 21.23-11.71 11.68-30.72 11.68-42.42-.02l-4.38-4.38-21.26-21.25-63.609-63.6 15.41-15.43c5.86-5.86 5.86-15.37 0-21.21-5.86-5.86-15.35-5.86-21.21 0l-15.41 15.42-17.81-17.8 46.54-38.35L503.008 451.6c5.67 5.671 8.79 13.201 8.79 21.211z"
      />
      <path
        fill="#3c3a41"
        d="m386.749 389.362-15.41 15.43-21.21-21.22 15.41-15.42c5.86-5.86 15.35-5.86 21.21 0 5.86 5.84 5.86 15.35 0 21.21z"
      />
      <path
        fill="#787780"
        d="M456.208 489.641c-14.2 14.22-33.11 21.96-53.07 21.96a74.9 74.9 0 0 1-53.01-21.97c-29.24-29.24-29.24-76.819 0-106.059l15.41-15.42c5.86-5.86 15.35-5.86 21.21 0 5.86 5.84 5.86 15.35 0 21.21l-15.41 15.43c-17.55 17.54-17.55 46.08 0 63.63 17.66 17.65 45.93 17.58 63.609-.03l21.261 21.249z"
      />
      <path
        fill="#aeadb3"
        d="M307.707 447.208c-40.942-40.942-40.942-107.548 0-148.49s107.548-40.913 148.49 0c5.856 5.856 5.856 15.355 0 21.211l-127.28 127.279c-5.86 5.86-15.351 5.86-21.21 0z"
      />
      <path
        fill="#57555c"
        d="m174.511 371.172-12.83 12.83-.01-.01-17.76-17.77c-5.86-5.86-15.36-5.86-21.21 0-5.86 5.86-5.86 15.36 0 21.22l17.76 17.76.01.01-63.65 63.63-21.21 21.21-4.4 4.39c-11.7 11.7-30.72 11.7-42.42 0-5.67-5.67-8.79-13.2-8.79-21.21s3.12-15.54 8.79-21.21L128.85 331.943l45.661 39.229z"
      />
      <path
        fill="#57555c"
        d="m161.681 384.002-21.21 21.21-.01-.01-17.76-17.76c-5.86-5.86-5.86-15.36 0-21.22 5.85-5.86 15.35-5.86 21.21 0l17.76 17.77.01.01z"
      />
      <path
        fill="#8b8892"
        d="M161.671 490.051c-14.61 14.62-33.82 21.93-53.02 21.93-19.22 0-38.43-7.31-53.04-21.93l21.21-21.21c17.55 17.53 46.1 17.56 63.64 0 8.5-8.5 13.17-19.79 13.17-31.82s-4.67-23.31-13.16-31.81l-.01-.01-17.76-17.76c-5.86-5.86-5.86-15.36 0-21.22 5.85-5.86 15.35-5.86 21.21 0l17.76 17.77.01.01c29.23 29.241 29.23 76.81-.01 106.05z"
      />
      <path
        fill="#c8c6cd"
        d="M182.886 447.627 55.607 320.348c-5.856-5.856-5.856-15.355 0-21.211 40.957-40.942 107.562-40.913 148.49 0 40.942 40.942 40.942 107.548 0 148.49-5.86 5.859-15.351 5.859-21.211 0z"
      />
    </svg>
  );
};

export default Fencing;
