import React from "react";

const SelfDefense = (props) => {
  let size = props.size || 50;

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M276 304v10.746l-46.627 46.627A31.996 31.996 0 0 0 220 384v64c-17.673 0-32 14.327-32 32 0 8.836 7.163 16 16 16h64c8.837 0 16-7.164 16-16v-82.745L329.255 352H396v-48z"
        fill="#323232"
      />
      <path
        d="M300 224c-8.189 0-16.379-3.124-22.627-9.373l-112-112c-12.497-12.497-12.497-32.758 0-45.255s32.758-12.497 45.255 0l112 112c12.497 12.497 12.497 32.758 0 45.255C316.379 220.876 308.189 224 300 224z"
        fill="#5055a5"
      />
      <path
        d="M460 448c0-8.189-3.124-16.379-9.373-22.627L396 370.745V304h-64v80a31.996 31.996 0 0 0 9.373 22.627L382.746 448H380c-17.673 0-32 14.327-32 32 0 8.836 7.163 16 16 16h80c8.837 0 16-7.164 16-16z"
        fill="#464646"
      />
      <path
        d="M396 304H276a8 8 0 0 1-8-8V192c0-17.673 14.327-32 32-32h96z"
        fill="#5055a5"
      />
      <path
        d="M468 352v-32c0-8.837-7.163-16-16-16h-32v48c0 8.836 7.163 16 16 16h32a8 8 0 0 0 0-16zM276 176h56c8.837 0 16-7.163 16-16V88c0-13.255-10.745-24-24-24h-40c-13.255 0-24 10.745-24 24v72c0 8.837 7.164 16 16 16z"
        fill="#f0915a"
      />
      <path
        d="M308 176h-32c-8.837 0-16-7.163-16-16V88c0-13.255 10.745-24 24-24h16c13.255 0 24 10.745 24 24v72c0 8.837-7.163 16-16 16z"
        fill="#fab991"
      />
      <path
        d="M316 136a8 8 0 0 1-8-8v-24a8 8 0 0 0-8-8h-40v-8c0-13.255 10.745-24 24-24h40c17.673 0 32 14.327 32 32v48c0 8.837-7.163 16-16 16s-16-7.163-16-16v-8z"
        fill="#323232"
      />
      <path
        d="M324 144a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8c8.837 0 16 7.163 16 16s-7.163 16-16 16z"
        fill="#f0915a"
      />
      <path
        d="M420 304h48a8 8 0 0 0 8-8v-56a32 32 0 0 0-9.373-22.627l-48-48c-12.708-12.708-33.445-12.493-45.882.644-11.987 12.662-11.232 32.751 1.097 45.081L412 253.255V296a8 8 0 0 0 8 8z"
        fill="#5f7dbe"
      />
      <path d="M188 32h-48v32c0 8.837 7.163 16 16 16h32z" fill="#f0915a" />
      <path
        d="M140 16h32c8.837 0 16 7.163 16 16h-48a8 8 0 0 1 0-16zM180 48h-20a4 4 0 0 0-4 4c0 6.627 5.373 12 12 12h4c0 8.837 7.164 16 16 16V56a8 8 0 0 0-8-8z"
        fill="#fab991"
      />
      <path
        d="M134.177 301.823a7.976 7.976 0 0 1-5.657-2.343c-59.265-59.265-59.265-155.695 0-214.96a7.998 7.998 0 0 1 11.313 0 7.998 7.998 0 0 1 0 11.313c-53.026 53.027-53.026 139.307 0 192.333a7.998 7.998 0 0 1 0 11.313 7.97 7.97 0 0 1-5.656 2.344z"
        fill="#5f7dbe"
      />
      <path
        d="M116 200a8 8 0 0 1-8-8c0-34.19 13.314-66.334 37.491-90.51a8 8 0 0 1 11.313 0 7.999 7.999 0 0 1 0 11.314C135.65 133.958 124 162.084 124 192a8 8 0 0 1-8 8z"
        fill="#5f7dbe"
      />
      <path
        d="M60 200H44a8 8 0 0 1 0-16h16a8 8 0 0 1 0 16zM58.667 174.667a7.976 7.976 0 0 1-5.657-2.343l-14.667-14.667a8 8 0 0 1 0-11.314 7.999 7.999 0 0 1 11.313 0l14.667 14.667a8 8 0 0 1-5.656 13.657zM44 240a7.974 7.974 0 0 1-5.657-2.343 7.999 7.999 0 0 1 0-11.314l14.667-14.667a8 8 0 0 1 11.313 0 7.999 7.999 0 0 1 0 11.314l-14.667 14.667A7.972 7.972 0 0 1 44 240z"
        fill="#faa51e"
      />
    </svg>
  );
};

export default SelfDefense;
