import React from "react";
import axios from "axios";

import Boxing from "assets/svg/boxing";
import ThaiBoxing from "assets/svg/thaiBoxing";
import Lotus from "assets/svg/lotus";
import Mma from "assets/svg/mma";
import Kimono from "assets/svg/kimono";
import Wrestling from "assets/svg/wrestling";
import Fencing from "assets/svg/fencing";
import Kendo from "assets/svg/kendo";
import SelfDefense from "assets/svg/selfDefense";
import Capoeira from "assets/svg/capoeira";
import YogaMat from "assets/svg/yogaMat";

import './utils.css';

export function getIconSport(sport, grade) {
  switch (sport) {
    case "boxing":
      return <Boxing />;
    case "chineseBoxing":
      return <Boxing />;
    case "frenchBoxing":
      return <Boxing />;
    case "fullContact":
      return <Boxing />;
    case "kickBoxing":
      return <Boxing />;

    case "thaiBoxing":
      return <ThaiBoxing />;
    case "bareknuckle":
      return <ThaiBoxing />;
    case "burmeseBoxing":
      return <ThaiBoxing />;
    case "dambe":
      return <ThaiBoxing />;

    case "kravMaga":
      return <SelfDefense />;
    case "selfDefense":
      return <SelfDefense />;
    case "systema":
      return <SelfDefense />;
    case "taiChiChuan":
      return <SelfDefense />;

    case "wrestling":
      return <Wrestling />;
    case "grappling":
      return <Wrestling />;

    case "fencing":
      return <Fencing />;
    case "kendo":
      return <Kendo />;

    case "mma":
      return <Mma />;

    case "capoeira":
      return <Capoeira />;

    case "meditation":
      return <Lotus />;
    case "yoga":
      return <YogaMat />;

    default:
      return <Kimono color={grade} />;
  }
}

export function DisplayFormattedText (text, isExpanded, setIsExpanded, tradExpanded, tradCollapsed) {

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const formattedText = text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
  
  return (
    <div>
      <div
        className={`text-container ${isExpanded ? 'expanded' : 'collapsed'}`}
      >
        {formattedText}
      </div>
      {formattedText.length > 1 || text.length > 250 ?
      <div className="button-container">
        <button className="show-more-button" onClick={toggleExpand}>
          {isExpanded ? tradExpanded : tradCollapsed}
        </button>
      </div> : null
      } 
    </div>
  );
}