import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      fr: {
        translation: {
          sports: {
            judo: 'Judo',
            jujitsu: 'Jujitsu',
            shotokanKarate: 'Karaté Shotokan',
            kyokushinkaiKarate: 'Karaté Kyokushinkaï',
            aikido: 'Aïkido',
            taekwondo: 'Taekwondo',
            brazilianJiuJitsu: 'Jiu-Jitsu Brésilien',
            boxing: 'Boxe Anglaise',
            yoga: 'Yoga',
            thaiBoxing: 'Boxe Thai',
            wrestling: 'Lutte',
            fencing: 'Escrime',
            bareknuckle: 'Bare Knuckle',
            burmeseBoxing: 'Boxe Birmane',
            chineseBoxing: 'Boxe Chinoise',
            frenchBoxing: 'Boxe Française',
            capoeira: 'Capoeira',
            dambe: 'Dambe',
            fullContact: 'Full Contact',
            grappling: 'Grappling',
            kendo: 'Kendo',
            kickBoxing: 'Kick Boxing',
            kravMaga: 'Krav-Maga',
            kungFu: 'Kung-Fu',
            meditation: 'Méditation',
            mma: 'MMA',
            penchakSilat: 'Penchak Silat',
            selfDefense: 'Self Defense',
            systema: 'Systema',
            taiChiChuan: 'Tai Chi Chuan',
            vietVoDao: 'Viet Vo Dao',
          },
          grades: {
            cyan: 'Cyan',
            white: 'Blanc',
            yellow: 'Jaune',
            orange: 'Orange',
            green: 'Vert',
            blue: 'Bleu',
            purple: 'Violet',
            brown: 'Marron',
            black: 'Noir',
            blackRed: 'Rouge et Noir',
            whiteRed: 'Blanc et Noir',
            red: 'Rouge',
            beginner: 'Débutant',
            initiate: 'Confirmé',
            amateur: 'Amateur',
            pro: 'Professionnel',
          },
          components: {
            utils:{
              expanded:"Moins",
              collapsed:"Lire plus"
            },
            contactForm: {
              name: 'Nom',
              email: 'Email',
              message: 'Message',
              send: 'Envoyer',
              sendToClub:'Envoyer au Club',
              success: 'Message envoyé',
            },
            topBar: {
              newEvent: 'Créer un évènement',
              newClub: 'Créer un club',
              logOut: 'Déconnexion',
              logIn: 'Connexion',
              traduct: {
                language: 'Language',
              },
              search: {
                sport: 'Sport',
                location: 'Ville',
                events: 'Évènements',
              },
              subMenu: {
                home: 'Accueil',
                account: 'Compte',
                myEvents: 'Mes évènements',
                logOut: 'Déconnexion',
              },
            },
            eventList: {
              title: 'Évènements',
              subtitle: 'Trouvez votre prochain évènement',
              noEvents:
                "Actuellement aucun évènement n'est disponible pour cette recherche",
            },
            confirmModal: {
              wait: 'Hey, Attendez!!',
              areYouSureDelete:
                'Êtes-vous sûr de vouloir supprimer cet évènement ?',
              areYouSureUnregister:
                'Êtes-vous sûr de vouloir quitter cet évènement ?',
              yesDelete: 'Oui, supprimer',
              yesUnregister: 'Oui, je pars',
              noDelete: 'Non, garder',
              noUnregister: 'Non, je reste',
            },
            payingEventModal: {
              cool: "Cet évènement a l'air parfait pour vous!",
              recap: 'Voici un récapitulatif des informations importantes',
              sport: 'Sport',
              start: 'Commence',
              price: 'Prix',
              warning:
                'Attention cet évènement est payant, vous ne pourrez plus vous désinscrire.',
              address: 'Adresse',
              yes: "M'inscrire!",
            },
            qrCodeModal: {
              startDate: 'Début : ',
              warning:
                "Une pièce d'identité peut vous être demandée à la présentation de votre billet",
            },
            scanModal: {
              scanHere: 'Scanner un billet',
              success: 'Billet validé',
              error: {
                already: 'Billet déjà validé',
                wrongEvent: "Billet d'un autre évènement",
                unvalid: 'Billet invalide',
                errorScan: "Une erreur s'est produite lors du scan du billet",
              },
            },
            sportSelector: {
              selectNewEvent: 'Sélectionnez le sport et le niveau minimum',
              selectClub: "Sélectionnez jusqu'à 10 sports pour votre club",
              tellUs: 'Dîtes-nous ce que vous aimez faire',
              sport: 'Sport',
              level: 'Niveau',
            },
            pictureModal: {
              profile: {
                title: 'Photo de profil',
              },
              landscape: {
                title: 'Photo de couverture',
              },
              event: {
                title: "Photo d'évènement",
              },
              chooseFile: 'Choisir un fichier',
              save: "Enregistrer l'image",
              errors: {
                otherFile: 'Veuillez choisir un autre fichier.',
                size: 'Le fichier doit faire moins de 2MB.',
              },
            },
          },
          home: {
            topVideo: {
              text1: 'Vous aimez les arts martiaux ?',
              text2: 'Partagez et pratiquez avec Jutsu',
              warning1: "Jutsu s'échauffe!",
              warning2:
                'Tous les paiements sont en mode test et les données seront réinitialisées à la fin de cette période.',
              warning3: 'Lancement prévu le 31 mars.',
              letsGo: 'Trouver un événement',
              createClub: 'Créer mon club',
              createEvent: 'Créer un évènement',
              signIn: 'Inscription',
            },
            sportFilter: {
              boxe: 'Boxe Anglaise',
              jjb: 'Jiu-Jitsu Brésilien',
              yoga: 'Yoga',
              thai: 'Boxe Thaïlandaise',
              judo: 'Judo',
            },
            nextEvents: {
              nextEvents: 'Prochains évènements',
              moreEvents: "Plus d'événements",
              noEvents: "Pas encore d'évènement prochainement",
            },
          },
          footer: {
            newEvent: 'Créez votre propre évènement Jutsu',
            start: 'Commencer',
            yourAccount: 'Votre Compte',
            parameters: 'Paramètres',
            logOut: 'Se Déconnecter',
            connexion: 'Connexion',
            discover: 'Découvrir',
            events: 'Évènements',
            sport: 'Sport',
            city: 'Ville',
            calendar: 'Calendrier',
            about: 'À propos',
            follow: 'Nous suivre',
            cgu: "Conditions d'utilisation",
            confidential: 'Politique de confidentialité',
            help: 'Aide',
          },
          login: {
            welcome1: 'Bienvenue sur',
            welcome2: 'Jutsu 👋',
            subtitle: 'Le meilleur endroit où exercer votre art',
            noAccount: 'Pas de compte?',
            haveAccount: 'Déjà un compte?',
            register: "S'inscrire",
            connect: 'Se connecter',
            choose: 'Choisissez comment vous connecter',
            createAccount: 'Créez votre compte',
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'Adresse email',
            password: 'Mot de passe',
            forgotPassword: 'Mot de passe oublié?',
            login: 'Connexion',
            signUp: 'Inscription',
            or: 'Ou',
            cgu: "Conditions d'utilisation",
            confidential: 'Politique de confidentialité',
            help: 'Aide',
            errors: {
              login: 'Mauvais email ou mot de passe.',
              error: 'Une erreur est survenue',
              wrongPsw:
                'Votre mot de passe doit contenir une Majuscule, une miniscule, un chiffre, un symbole et au moins 8 caractères.',
              accountExist: 'Ce compte existe déjà.',
              missingField: 'Certains champs sont manquants.',
              wrongEmail: "Cet email n'est pas valide.",
              wrongLink: 'Votre lien a expiré',
            },
            success: 'Votre compte a été créé avec succès.',
          },
          forgot: {
            backToConnection: 'Retour à la connection',
            newPsw: 'Nouveau mot de passe.',
            confirmPassword: 'Confirmation de mot de passe',
            save: 'Enregistrer',
            send: 'Envoyer un email',
            wrongConfirm: 'Les mots de passe doivent être identiques',
            wrongPsw:
              'Votre mot de passe doit contenir une Majuscule, miniscule, une chiffre, un symbole et au moins 8 caractères.',
            mail: 'Un email vous a été envoyé',
            success: 'Votre mot de passe a été changé',
          },
          account: {
            club: {
              title: 'Informations du Club',
              name: 'Nom du club',
              address: 'Adresse',
              zipCode: 'Code Postal',
              cityCountry: 'Ville, Pays',
              cityCountryPlaceholder: 'Sélectionnez votre ville',
              instagram: 'Instagram',
              website: "Site Web",
              description: 'Description',
              save: 'Enregistrer',
              cancel: 'Annuler',
              payment: 'Coordonnées bancaires',
              success: {
                create: 'Votre club a été créé',
                modif: 'Votre club a été modifié',
              },
              error: {
                modif:
                  'Une erreur est survenue lors de la modification du club',
                create: 'Une erreur est survenue lors de la création du club',
                exist: 'Ce club existe déjà',
                missingField: 'Certains champs sont manquants',
                localisation: "Nous n'arrivons pas à localiser votre club",
                tooLongString: 'Maximum de caractères atteint',
                wrongUrl: "URL non conforme https",
              },
            },
            noClub: {
              title: "Création d'un Club",
              text1:
                'Enregistrez votre club sur Jutsu pour publier des évènements',
              text2: 'et partager votre passion!',
              text3:
                'Avant de vous lancer, veuillez prendre le temps de lire nos ',
              cgu: 'CGU',
              createClub: 'Créer un nouveau club',
            },
            profile: {
              title: 'Informations du profil',
              firstName: 'Prénom',
              lastName: 'Nom',
              cityCountry: 'Ville, Pays',
              cityCountryPlaceholder: 'Sélectionnez votre ville',
              instagram: 'Instagram',
              description: 'Description',
              save: 'Enregistrer',
              cancel: 'Annuler',
              success: {
                create: 'Votre profil a été créé',
                modif: 'Votre profil a été modifié',
              },
              error: {
                modif:
                  'Une erreur est survenue lors de la modification du profil',
                missingField: 'Certains champs sont manquants',
                tooLongString: 'Maximum de caractères atteint',
              },
            },
            settings: {
              paymentTitle: 'Coordonnées Bancaires',
              parametersTitle: 'Paramètres du compte',
              registerText: 'Enregistrement sur Stripe',
              registerSubText:
                'Jutsu utilise le terminal de paiement Stripe pour sécuriser vos transactions, pour commencer à recevoir vos réservations payantes veuillez vous enregistrer !',
              registerButton: "S'enregistrer",
              updateText: 'Modifier vos informations Stripe',
              updateSubText:
                'Jutsu utilise le terminal de paiement Stripe pour sécuriser vos transactions, vous êtes actuellement enregistré, si vous le souhaitez vous pouvez modifier vos informations de paiement.',
              updateButton: 'Modifier',
              errorStripe:
                'Un problème est survenu lors de votre enregistrement sur Stripe',
              deleteClubText: 'Supprimer le club ',
              clubSuppressToo:
                'Les évènements ainsi que toutes les données associées à celui-ci seront également supprimés.',
              deleteClubButton: 'Supprimer le club',
              deleteUserText: 'Supprimer le compte de ',
              clubLinked: 'Ce compte est gestionnaire du club ',
              userSuppressToo:
                'il sera également supprimé ainsi que toutes les données associées.',
              noClub: "Ce compte n'est lié à aucun club",
              deleteUserButton: 'Supprimer mon compte',
              cantSuppUser: "Ce compte ne peut être supprimé pour l'instant.",
              cantSuppClubUser:
                "Ce compte ne peut être supprimé pour l'instant car son club a de futurs évènements payants ayant des inscrits.",
              cantSuppClub:
                "Ce club ne peut être supprimé pour l'instant car vous avez de futurs évènements payants ayant des inscrits.",
              deleteModal: {
                titleUser: 'Suppression de compte',
                titleClub: 'Suppression de club',
                sureUser:
                  'Êtes-vous sür de vouloir supprimer votre compte et toutes les données associées?',
                sureClub:
                  'Êtes-vous sür de vouloir supprimer votre club et toutes les données associées?',
                no: 'Annuler',
                yes: 'Supprimer',
              },
            },
            menu: {
              profile: 'Profil',
              club: 'Club',
              settings: 'Options',
            },
          },
          newEvent: {
            title: 'Nouvel évènement',
            eventName: "Nom de l'évènement",
            sport: 'Sport',
            recurrentEvent: 'Évènement récurrent',
            dateSelect: 'Selection des dates',
            start: 'Début',
            end: 'Fin',
            places: 'Places disponibles',
            price: "Prix d'une place",
            stripeSubText:
              'Veuillez renseigner vos coordonnées bancaires pour créer un évènement payant',
            feeJutsu:"Frais Jutsu (15% TTC)",
            youGet:"Vous recevrez par place vendue",
            equipments: 'Équipements',
            description: 'Description',
            uploadImage: 'Nouvelle Image',
            save: 'Enregistrer',
            cancel: 'Annuler',
            placeholder: {
              eventName: 'Ex: Entraînement tous niveaux sur le jab',
              places: 'Pour une pratique agréable',
              price: '10',
              equipments: 'Ex: Gants de boxe, protège dents, coquille, short',
              description:
                "Ex: Découvrez les secrets et techniques d'entrainement pour faire des jabs comme un pro. Avoir un bon jab ou direct bras avant est essentiel, en plus d'être un coup puissant pouvant faire des dommages à votre adversaire, il peut vous permettre de perturber, feinter, préparer vos prochains mouvements. Rejoignez-nous pour en apprendre plus sur ce mouvement de boxe anglaise basique mais très utile!",
            },
            error: {
              moreInfos:
                "Veuillez modifier les informations de votre club pour procéder è la création d'un évènement",
              exist: 'Cet évènement existe déjà',
              missingField: 'Certains champs sont manquants',
              error:
                "Une erreur est survenue lors de la création de l'évènement",
              tooLongString: 'Maximum de caractères atteint',
              wrongAddress: 'Mauvaise adresse ou code postal',
            },
            success: {
              created: 'Votre évènement a été créé',
            },
          },
          profile: {
            aboutMe: 'Description',
            events: 'Évènements',
            futureEvents: 'À venir',
            pastEvents: 'Déjà passés',
            club: 'Club',
            contact: 'Contact',
            sports: 'Sports',
            noSlider: "Pas d'évènement actuellement",
          },
          club: {
            aboutMe: 'Description',
            events: 'Évènements',
            futureEvents: 'À venir',
            pastEvents: 'Déjà passés',
            club: 'Club',
            contact: 'Contact',
            sports: 'Sports',
            noSlider: "Pas d'évènement actuellement",
            location: 'Adresse',
            notSpecified: 'Non renseigné',
          },
          myEvents: {
            title: 'Mes Évènements',
            todayEvents: "Aujourd'hui",
            futurEvents: 'À venir',
            pastEvents: 'Déjà passés',
            scanButton1: 'Scanner',
            scanButton2: 'une place',
          },
          search: {
            week: 'Cette semaine',
            nextWeek: 'Semaine prochaine',
            month: 'Ce mois',
            nextMonth: 'Mois Prochain',
            allDates: 'Date(toutes)',
            maxRange: 'Très très loin',
            allSports: 'Tous les sports',
            noLocation: 'Ville(toutes)',
          },
          events: {
            host: 'Organisateur',
            description: 'Description',
            equipments: 'Equipements',
            minimumLevel: 'Niveau minimum',
            registers: 'Participants',
            noRegisters: 'Soyez le premier à vous inscrire !',
            free: 'Gratuit',
            fullPlaces: 'Complet',
            unsubscribe: 'Se désinscrire',
            subscribe: "S'inscrire",
            connexion: 'Connexion',
            subscribed: 'Inscrit',
            qrCode: 'Voir mon billet',
            delete: "Supprimer l'évènement",
            successRegister: 'Votre inscription est enregistrée',
            successUnregister: 'Votre désinscription est enregistrée',
            errors: {
              close: 'Les inscriptions pour cet évènement sont closes',
              started: 'Cet évènement à déja commencé',
              passed: 'Cet évènement est déjà passé',
            },
          },
          cgu: {
            title1: "Conditions d'utilisation du site « JUTSU EVENTS »",
            text1:
              "Les termes et conditions énoncés ci-après expliquent en détail comment vous interagissez avec le site Web, quelles sont vos responsabilités lorsque vous visitez le site Web, certains termes et conditions sur la base desquels les services vous sont fournis, etc. Il vous est conseillé de lire le en suivant en détail et avec toute l'attention que ces termes et conditions créent une relation contractuelle entre le site Web et vous, fixant certaines responsabilités aux deux parties, c'est-à-dire le site Web et l'utilisateur. La violation de ces conditions d'utilisation du site peut entraîner certaines répercussions pour l'utilisateur, dont, entre autres, le blocage du compte utilisateur, etc. Il vous est également conseillé dans votre intérêt de respecter ces conditions afin de profiter de tous les services sans interruption via le site Web. Ces termes et conditions peuvent être mis à jour de temps à autre et seront notifiés aux utilisateurs.",
            title2: 'JUTSU EVENTS.',
            text2:
              "JUTSU EVENTS en tant que plateforme vous garantit, ainsi qu'aux autres membres et/ou utilisateurs de la plateforme, de publier des groupes et des événements hors ligne et/ou réels. Le fait de bénéficier des services de la plateforme et/ou même de naviguer sur la plateforme implique que vous reconnaissiez les présentes conditions d'utilisation des services.",
            title3: "Utilisation du site Web par l'utilisateur.",
            text3:
              "Il est nécessaire de suivre les politiques et directives du site Web lors de l'utilisation de notre plateforme. Nous n'avons aucune responsabilité pour le contenu que quiconque publie sur notre plateforme. Vous comprenez et assumez la responsabilité de vous conformer et d'accepter toutes les lois, règles et réglementations applicables, et de ne pas violer ou enfreindre les droits d'un tiers. En outre, votre accès et votre utilisation de certaines zones ou fonctionnalités du site Web peuvent être soumis à des conditions, politiques, normes ou directives supplémentaires (« Conditions supplémentaires »). Vous devrez peut-être accepter ces Conditions supplémentaires avant de pouvoir accéder à ces zones et fonctionnalités de la plateforme.",
            title4: "Compte d'utilisateur.",
            subTitle1: 'Droit.',
            subText1:
              "Le site Web n'est accessible qu'aux personnes âgées d'au moins 18 ans. Votre utilisation du site Web garantit que vous avez atteint l'âge légal pour utiliser le site Web. Un utilisateur ne peut être éligible pour accéder à toutes les fonctionnalités du site Web qu'en créant un compte enregistré sur le site Web. Une personne et/ou un utilisateur qui a la capacité de modérer et/ou de gérer une partie particulière du site Web peut également, à sa seule discrétion, modifier, suspendre ou mettre fin à votre accès à la partie spécifique de la plateforme.",
            subTitle2: 'Identifiants et confidentialité du compte.',
            subText2:
              "Lors de l'enregistrement de votre compte sur la plateforme, vous acceptez de nous fournir les informations nécessaires, y compris, mais sans s'y limiter, une adresse e-mail valide et un mot de passe. Vous comprenez qu'il est important et de votre seule responsabilité de garder intactes la confidentialité de vos mots de passe , sinon, votre compte peut être désactivé.",
            title5: 'Emoluments pour le site Web.',
            subTitle3: 'Frais.',
            subText3:
              "Certaines fonctionnalités du site Web sont gratuites. Cependant, il existe également des services payants sur le site Web pour l'utilisation de ceux-ci, vous devez payer certains frais via les prestataires de services de paiement désignés et les personnes autorisées uniquement. Vous déclarez et garantissez que vous êtes autorisé à utiliser le mode de paiement que vous désignez via la plateforme. Vous nous autorisez (et nos processeurs de paiement tiers désignés) à facturer votre mode de paiement désigné pour le montant total de tous les frais que vous devez aux ÉVÉNEMENTS JUTSU, y compris les taxes applicables associées et autres frais.",
            subTitle4: 'Renouvellements.',
            subText4:
              "Le site Web facturera à l'utilisateur pour chaque période de renouvellement jusqu'à l'annulation. En achetant toute fonctionnalité ou tout autre aspect de notre plateforme pour lequel nous facturons, vous nous autorisez à maintenir votre paiement à jour en facturant les frais applicables sur votre compte de carte de crédit (ou tout autre moyen de paiement que vous utilisez).",
            title6: "Données et confidentialité de l'utilisateur.",
            text6:
              "L'utilisateur est seul responsable de la fourniture du contenu du site Web et le contenu de l'utilisateur sera traité conformément à la politique de confidentialité de JUTSU EVENTS.",
            title7: 'Propriété intellectuelle',
            text7:
              "Les marques, logos, marques de service et noms de service de JUTSU sont la propriété intellectuelle de JUTSU. L'utilisateur n'est pas autorisé à utiliser sans autorisation toute propriété intellectuelle, y compris, mais sans s'y limiter, les brevets, les marques, les dessins, les droits d'auteur, les secrets commerciaux, etc. du site Web et/ou de tout autre tiers qui les a publiés sur le site Web. Le site Web, à sa seule discrétion, peut, en cas de suspicion que le matériel publié sur le site Web par l'utilisateur enfreint les droits de quelqu'un d'autre, le supprimer ou le désactiver.",
            title8: 'Indemnité',
            text8:
              "L'utilisateur du site s'engage à tenir le site indemne en toutes circonstances, notamment lorsque la plateforme est poursuivie par quiconque du fait de votre utilisation du site. Dans toute la mesure permise par la loi applicable, vous acceptez d'indemniser, de défendre et de dégager toutes les parties JUTSU de toute réclamation, faite par un tiers en raison de ou découlant de (a) vos violations du présent accord, (b) votre utilisation , mauvaise utilisation ou abus de notre plateforme, (c) votre contenu, (d) votre violation de toute loi, statut, ordonnance ou règlement ou des droits d'un tiers, ou (e) votre participation ou conduite dans un groupe JUTSU ou un événement JUTSU qui viole le présent accord.",
            title9: 'Règlement des différends',
            text9:
              "Si l'utilisateur a un litige impliquant le site Web, l'utilisateur comprend et accepte que le moyen le plus efficace de le résoudre est de contacter le représentant autorisé du site Web désigné à cet effet. Si les griefs de l'utilisateur restent sans réponse, l'utilisateur peut les soumettre à un arbitre neutre au lieu de porter la réclamation devant un tribunal. Les griefs contre le site Web ne peuvent être déposés qu'individuellement et non dans le cadre d'un recours collectif. Le site Web n'a aucune obligation de s'impliquer dans les différends que vous pourriez avoir avec d'autres membres ou club, bien que nous puissions essayer de faciliter une résolution.",
            title10: 'Conditions générales',
            subTitle101: 'Langue.',
            subText101:
              "Ces termes et conditions sont rédigés en anglais et peuvent ensuite être traduits dans d'autres langues. En cas d'incohérence entre la version anglaise et une version traduite, la version anglaise prévaudra.",
            subTitle102: "Pas d'agence.",
            subText102:
              "Aucune relation d'agence, de partenariat, de coentreprise, d'employé-employeur ou de franchiseur-franchisé entre vous et JUTSU n'est prévue ou créée par le présent accord.",
            subTitle103: 'Loi applicable.',
            subText103:
              'Le présent accord et la relation entre vous et JUTSU sont régis par les lois applicables du pays, sans égard à ses dispositions en matière de conflit de lois.',
            subTitle104: 'Aucune renonciation.',
            subText104:
              "Le défaut de l'utilisateur d'exercer ou d'appliquer tout droit ou disposition du présent accord ne constitue pas une renonciation à ce droit ou à cette disposition et ne renonce à aucun droit d'agir en cas de violations ultérieures ou similaires.",
            subTitle105: 'Exclusion de garanties.',
            subText105:
              "VOTRE UTILISATION DU SITE, DE SON CONTENU ET DE TOUT SERVICE DISPONIBLE SUR LE SITE WEB EST À VOS PROPRES RISQUES. LE SITE, SON CONTENU ET TOUS LES SERVICES DISPONIBLES PAR L'INTERMÉDIAIRE DU SITE WEB SONT FOURNIS « EN L'ÉTAT » ET « SELON LA DISPONIBILITÉ », SANS AUCUNE GARANTIE D'AUCUNE SORTE, EXPLICITE OU IMPLICITE. NI JUTSU NI AUCUNE PERSONNE ASSOCIÉE AU SITE WEB N'OFFRE DE GARANTIE OU DE DÉCLARATION QUANT À L'EXHAUSTIVITÉ, LA SÉCURITÉ, LA FIABILITÉ, LA QUALITÉ, L'EXACTITUDE OU LA DISPONIBILITÉ DU SITE. CE QUI PRÉCÈDE N'AFFECTE PAS LES GARANTIES QUI NE PEUVENT ÊTRE EXCLUES OU LIMITÉES EN VERTU DE LA LOI APPLICABLE.",
            subTitle106: 'Limitation de responsabilité.',
            subText106:
              "EN AUCUN CAS, JUTSU, SES AFFILIÉS OU LEURS CONCÉDANTS, FOURNISSEURS DE SERVICES, EMPLOYÉS, AGENTS, DIRIGEANTS OU ADMINISTRATEURS NE SERONT RESPONSABLES DES DOMMAGES DE QUELQUE NATURE QUE CE SOIT, SELON UNE THÉORIE JURIDIQUE, RÉSULTANT DE / OU EN RELATION AVEC VOTRE UTILISATION OU VOTRE INCAPACITÉ À UTILISER, LE SITE, TOUT SITE WEB LIÉ À CELUI-CI, TOUT CONTENU SUR LE SITE WEB OU CES AUTRES SITES WEB OU TOUT SERVICE DISPONIBLE PAR L'INTERMÉDIAIRE DU SITE OU DE CES AUTRES SITES WEB, Y COMPRIS TOUT DOMMAGE DIRECT, INDIRECT, SPÉCIAL, ACCESSOIRE, CONSÉCUTIF OU PUNITIF, Y COMPRIS, MAIS SANS S'Y LIMITER , BLESSURE PERSONNELLE, DOULEUR ET SOUFFRANCE, DÉTRESSE ÉMOTIONNELLE, PERTE DE REVENUS, PERTE DE PROFITS, PERTE D'AFFAIRES OU D'ÉCONOMIES ANTICIPÉES, PERTE D'UTILISATION, PERTE DE BONNE VOLONTÉ, PERTE DE DONNÉES ET QU'ELLES SOIENT CAUSÉES PAR UN DÉLIT (Y COMPRIS LA NÉGLIGENCE), LA VIOLATION DE CONTRACTUEL OU AUTRE, MÊME PRÉVISIBLE.",
          },
          confidential: {
            title1: 'Politique de confidentialité de Jutsu Events.',
            text1:
              "Les termes suivants résument la politique de confidentialité de Jutsu Event, 4 route de las illas, 66480 Maureillas Las Illas, France. Il en va de même pour la manière dont Jutsu Event collectera, utilisera, diffusera, etc. les informations collectées auprès de l'utilisateur lors de sa navigation sur le site. Les expressions telles que JUTSU EVENT, JUTSU, nous, notre et nos désignent le site Web, et les expressions telles qu'un utilisateur, vous, votre, membre, désignent toute personne utilisant et/ou naviguant sur le site Web ou la plateforme. Cette politique de confidentialité vise à filtrer les informations collectées auprès des utilisateurs lors de leur navigation sur le site. Les informations que vous partagez avec des tiers sur le site Web n'entrent pas dans le champ d'application de cette politique de confidentialité. Il vous est conseillé, dans votre meilleur intérêt, de lire attentivement cette politique de confidentialité afin de savoir comment les informations personnelles vous concernant sont collectées, stockées et utilisées via le site Web.",
            title2: "Instances de collecte d'informations.",
            text2:
              "Des informations personnelles vous concernant sont collectées lorsque vous créez un compte sur le site Web et/ou utilisez la plateforme et/ou assistez à tout événement organisé et/ou géré via le site Web, visitez le site Web et/ou communiquez avec le site Web. À l'aide de cookies et d'autres technologies connexes, des informations telles que des statistiques d'utilisation sont également collectées. Si vous choisissez d'utiliser certaines autres informations sur les fonctionnalités via certaines autres sources telles que les services de médias sociaux, etc.",
            title3: "Informations fournies par l'utilisateur.",
            text3:
              "Certaines informations, y compris, mais sans s'y limiter, les informations sur la création d'un compte sur le site Web, la préférence d'un intérêt et / ou les informations collectées lors de la correspondance entre le site Web et vous, sont collectées directement sur le site Web et sont considérées comme des informations fournies par l'utilisateur. Les informations fournies par l'utilisateur peuvent être classées comme des identifiants, tels que le nom de l'utilisateur, le nom d'utilisateur de l'utilisateur sur le site Web, le code de sécurité de l'utilisateur, l'adresse e-mail unique de l'utilisateur, l'adresse postale aux fins de déterminer l'emplacement de l'utilisateur et le numéro de contact de l'utilisateur, paiement et informations commerciales , des informations démographiques et/ou toute autre information personnelle que vous choisissez de fournir, y compris, mais sans s'y limiter, des photos, des groupes, y compris votre rôle, votre entreprise et vos années d'expérience. Des informations telles que le groupe que vous aimez le plus sont également facultatives à fournir, mais pour filtrer cette option, ces informations sont également nécessaires pour prendre soin de votre compte et pour marquer que vous êtes membre d'un groupe que vous rejoignez. Dans certains cas, les informations que vous fournissez et qui évoquent vos convictions, vos opinions politiques, votre état de santé et votre identité sexuelle bénéficient d'une protection spéciale en vertu des lois applicables.",
            title4:
              'Données recueillies automatiquement et/ou requises explicitement par le site Web.',
            text4:
              "Pendant que l'utilisateur navigue sur le site Web, certaines informations sur la connexion Internet de l'utilisateur et/ou d'autres informations sur l'activité du réseau électronique sont collectées automatiquement. Des informations telles que le moyen par lequel vous accédez ou d'où vous accédez au site Web sont également collectées automatiquement par le site Web. , y compris, mais sans s'y limiter, le navigateur dont vous disposez , l'heure, la durée et la fréquence de votre accès au site Web , l'activité sur le site Web , l'adresse IP , la page que vous avez visitée avant de vous diriger vers le site Web, l'ordinateur et/ ou appareil mobile utilisé par l'utilisateur pour accéder au site Web, le modèle matériel de celui-ci, le système d'exploitation et la version utilisés sur l'appareil à travers lequel le site Web a été consulté, les informations sur le réseau mobile si la navigation est effectuée via le mobile téléphone, localité de la zone dans laquelle l'appareil est utilisé pour accéder au site Web et ces informations sont collectées uniquement sur consentement explicite, que vous donnez pour activer l'emplacement de l'appareil que vous utilisez, en votre nom, les informations collectées via les cookies conformément à la politique en matière de cookies et les informations basées sur le consentement, comme l'accès aux photos de votre gallerie et/ou des informations concernant le calendrier que vous souhaitez utiliser peuvent également être collectés.",
            title5: 'Informations collectées via des sources tierces.',
            text5:
              "Certaines informations vous concernant peuvent être collectées par l'intermédiaire de tiers. Il est précisé que vos informations collectées via ces sources tierces sont strictement soumises aux politiques de confidentialité de ces parties. Si un utilisateur s'inscrit ou se connecte au compte en utilisant un tiers canal, la plateforme aura accès aux informations de votre compte conservées par ce fournisseur de services tiers, y compris, mais sans s'y limiter, les identifiants, comme votre nom et d'autres informations dans ce compte, et les mêmes peuvent être utilisés aux fins de rendre le recommandations sur la plateforme pour vous les plus pertinentes.",
            title6: "Motif(s) de la collecte d'informations.",
            text61:
              "Les informations obtenues comme décrit ci-dessus peuvent être utilisées dans le but d'inclure, mais sans s'y limiter, la mise à disposition, la poursuite et l'amélioration du site Web, le traitement des transactions, le développement de nouveaux produits et services, l'administration du fonctionnement du site Web, afficher des informations vous concernant, par exemple, votre liste d'intérêts, qui seront visibles sur votre profil , personnaliser le site Web pour votre meilleure expérience, par exemple, en vous donnant des recommandations sur le contenu le plus pertinent , vous permettre de diffuser des vues sur votre expérience du site Web par le biais de services de médias sociaux tiers, pour surveiller et analyser les mouvements, l'utilisation et les activités en rapport avec notre site Web , détecter, enquêter et empêcher les transactions frauduleuses et/ou suspectes, les abus et autres activités illégales , protéger les droits, les biens ou la sécurité du site Web et d'autres , faire respecter les politiques du site , se conformer à la loi applicable et aux demandes gouvernementales , effectuer des tâches financières et administratives, et faire valoir ou gérer des réclamations légales.",
            text62:
              "Les informations recueillies auprès de vous comme décrit ci-dessus peuvent également être utilisées dans le but de communiquer avec vous de manière à répondre de manière appropriée et juste à vos demandes de renseignements, requêtes et appels, et à fournir un service client. Nous pouvons également utiliser les informations pour vous contacter, en fonction des préférences que vous avez définies, sur les produits, services et événements proposés par nous et d'autres, et pour vous mettre à jour avec des nouvelles et des informations qui, selon notre analyse, dépendent des cookies, vous intéressera, pour effectuer des évaluations en ligne, et pour vous transmettre les avis techniques, les mises à jour, les alertes de sécurité, les messages d'assistance et d'administration nécessaires au bon fonctionnement du site.",
            text63:
              "Les publicités et le matériel promotionnel sont nécessaires pour générer des revenus afin de rendre les affaires financières et le fonctionnement du site Web aussi fluides que possible. À ces fins, nous ne vendons pas vos informations à d'autres parties, mais nous vous montrons le contact le plus pertinent correspondant à vos intérêts, fonctionnalités ou parrainages , vous transmettre des communications marketing, sous réserve de la loi applicable.",
            title7: 'Divulgation des informations recueillies.',
            text7:
              "Les informations collectées ne sont pas vendues et/ou échangées et/ou autrement transférées à des tiers les informations personnellement identifiables de l'utilisateur à moins que le site Web ne fournisse à l'utilisateur un préavis, sauf comme décrit ci-dessous. Cela n'inclut pas non plus les partenaires d'hébergement de sites Web et les autres parties qui aident à exploiter le site Web, à mener les activités du site Web ou à vous servir, tant que ces parties acceptent de garder ces informations confidentielles. Nous pouvons également divulguer vos informations lorsque nous estimons qu'une telle divulgation est appropriée pour se conformer à la loi, aux politiques de notre site Web ou pour protéger nos droits, notre propriété ou notre sécurité ou ceux d'autrui. Cependant, des informations non personnellement identifiables sur les visiteurs peuvent être fournies à d'autres parties à des fins de marketing, de publicité ou à d'autres fins.",
            title8:
              "Sécurité des informations recueillies auprès de l'utilisateur.",
            text8:
              "Nous mettons en œuvre un certain nombre de contrôles de sécurité et/ou de techniques de cryptage pour assurer la protection et la sécurité des informations collectées auprès de vous lorsque vous visitez le site Web. Les informations collectées en votre nom sont conservées dans des réseaux hautement sécurisés et ne sont accessibles que par un nombre limité de personnes autorisées qui disposent de droits d'accès spéciaux à ces systèmes et qui s'engagent à préserver la confidentialité des informations consultées.",
          },
          help: {
            menu: {
              faq: 'FAQ',
              contact: 'Contact',
              report: 'Signaler',
              about: 'À propos',
            },
            faq: {
              mainTitle:
                "FAQ de JutsuEvent - Plateforme d'événements d'arts martiaux",
              title1: "1. Qu'est-ce que JutsuEvent ?",
              text1:
                "JutsuEvent est une plateforme en ligne dédiée à la promotion et à l'organisation d'événements d'arts martiaux. Nous offrons un espace centralisé où les organisateurs peuvent créer, gérer et promouvoir leurs événements, et où les participants peuvent découvrir et s'inscrire à des compétitions, des séminaires, des ateliers et d'autres événements liés aux arts martiaux.",
              title2: '2. Comment créer un compte sur JutsuEvent ?',
              text2:
                "Pour créer un compte, cliquez sur 'Connexion' en haut à droite de la page d'accueil. Puis sur 'S'inscrire' en haut à droite de la page de connection. Remplissez le formulaire avec vos informations personnelles, puis cliquez sur 'Inscription'.",
              title3: '3. Comment ajouter un club sur JutsuEvent ?',
              text3:
                "Pour ajouter un club, connectez-vous à votre compte et cliquez sur 'Compte' dans le menu déroulant en haut à droite de la page d'acceuil. Puis sur 'Club' en haut a gauche, prenez le temps de lire nos CGU et cliquez 'Créer un nouveau club'. Remplissez les informations requises et cliquez sur 'Enregistrer' pour sauvegarder votre club !",
              title4: '4. Comment enregistrer mon club sur Stripe ?',
              text4:
                "Pour vous permettre de créer des évènements payants il est nécessaire que vous vous enregistriez auprés de Stripe notre prestataire de paiments.Pour cela connectez-vous à votre compte disposant d'un club et cliquez sur 'Compte' dans le menu principal, puis sur 'Options'. De cet onglet cliquez sur 'S'enregistrer' et suivez les instructions de la plateforme Stripe",
              title5: '5. Comment ajouter un événement sur JutsuEvent ?',
              text5:
                "Pour ajouter un événement, connectez-vous à votre compte disposant d'un club et cliquez sur 'Créer un événement' dans le menu principal. Remplissez les informations requises, telles que le nom de l'événement, la date, l'heure, le lieu et une description détaillée. Vous pouvez également ajouter des images et des liens pour aider à promouvoir votre événement. Notez que pour tout évènement payant vous devez préalablement avoir enregistrer vos informations bancaires sur Stripe depuis l'onglet 'Options' de votre 'Compte'.",
              title6: "6. Comment s'inscrire à un événement sur JutsuEvent ?",
              text6:
                "Pour s'inscrire à un événement, recherchez l'événement qui vous intéresse à l'aide des filtres de recherche ou en parcourant les événements d'un club. Une fois sur la page de l'événement, cliquez sur 'S'inscrire' et suivez les instructions pour compléter votre inscription.",
              title7:
                "7. Comment payer les frais d'inscription pour un événement ?",
              text7:
                "Les frais d'inscription peuvent être payés en ligne via notre système de paiement sécurisé. Nous acceptons la plupart des cartes de crédit et de débit, ainsi que certaines méthodes de paiement en ligne.",
              title8:
                '8. Comment annuler ou modifier mon inscription à un événement ?',
              text8:
                "Dans le cas d'un évènement gratuit cliquez sur l'événement concerné puis sélectionnez “Se désinscrire”. Concernant l'annulation ou la modification de votre inscription, si il s'agit d'un évènement payant veuillez contacter le club organisateur et voir avec lui sur ses modalités de remboursement. Veuillez noter que des frais d'annulation ou de modification peuvent s'appliquer selon les conditions de l'événement.",
              title9:
                "9. Comment puis-je contacter l'organisateur d'un événement ?",
              text9:
                "Sur la page de l'événement,une fois connecté vous trouverez les coordonnées de l'organisateur. Vous pouvez également utiliser le formulaire de contact fourni sur la page de l'événement pour envoyer un message directement à l'organisateur.",
              title10:
                '10. JutsuEvent est-il disponible pour les utilisateurs internationaux ?',
              text10:
                "Oui, JutsuEvent est disponible pour les utilisateurs de toute l'europe. Cependant, veuillez noter que certains événements peuvent être limités à certaines régions ou pays en raison de restrictions légales ou de politiques spécifiques à l'organisateur.",
              title11:
                '11. Est-ce que JutsuEvent propose une application mobile ?',
              text11:
                "Pour l'instant, JutsuEvent ne dispose pas d'une application mobile dédiée. Cependant, notre site Web est entièrement optimisé pour les appareils mobiles, ce qui vous permet de naviguer, de créer et de gérer des événements, et de vous inscrire à des événements d'arts martiaux depuis votre smartphone ou tablette.",
              title12:
                "12. Comment puis-je obtenir de l'aide ou signaler un problème sur JutsuEvent ?",
              text12:
                "Si vous avez besoin d'aide ou souhaitez signaler un problème, veuillez consulter notre section 'Aide' en bas de la page d'accueil. Vous y trouverez des articles d'aide et des guides pour résoudre les problèmes courants. Si vous ne trouvez pas la réponse à votre question, vous pouvez nous contacter directement en utilisant le formulaire de contact disponible dans cette même section.",
              title13:
                '13. Comment puis-je me tenir informé des nouveaux événements et actualités sur JutsuEvent ?',
              text13:
                'Pour recevoir les dernières nouvelles et les mises à jour sur les événements vous pouvez nous suivre sur les réseaux sociaux, tels que Facebook, Twitter et Instagram, pour être informé des dernières actualités et événements.',
              title14: '14. Comment puis-je supprimer mon compte JutsuEvent ?',
              text14:
                "Si vous souhaitez supprimer définitivement votre compte JutsuEvent, Cliquez sur “Compte” depuis le menu en haut de page, sélectionnez l'onglet “Options”. D'ici vous pourrez sélectionner la suppression de votre compte utilisateur ou seulement de votre club si vous en disposez d'un.",
            },
            contact: {
              title1: 'Contactez-nous',
              text1:
                "Chez JutsuEvent, nous nous engageons à fournir un service de qualité et une expérience exceptionnelle pour nos utilisateurs. Si vous avez des questions, des préoccupations ou des suggestions concernant notre plateforme, n'hésitez pas à nous contacter.",
              text2:
                "Vous pouvez remplir le formulaire de contact ci-dessous, et l'un de nos représentants du service clientèle vous répondra dans les plus brefs délais. Si vous préférez, vous pouvez également nous envoyer un e-mail directement à ",
              text2Email: 'contact@jutsuevent.com.',
              text3:
                'Pour rester informé des dernières actualités et événements, suivez-nous sur nos réseaux sociaux.',
              text4:
                "Nous apprécions vos commentaires et nous sommes impatients de vous aider dans vos aventures d'arts martiaux avec JutsuEvent !",
            },
            report: {
              title2: 'Signaler une erreur',
              text21:
                "Voici la procédure idéale pour le report d'un bug ou d'un comportement qui vous semble anormal ou dérangeant lors de votre utilisation du site.",
              text22: 'Envoyer par e-mail à contact@jutsuevent.com',
              title3: 'Depuis votre téléphone :',
              text31:
                'Un imprime écran du bug et une explication la plus détaillée possible des actions qui ont amené au bug.',
              text32:
                "Ex:  Après mon inscription a un évènement, les images des participants à cet évènement ne s'affichent pas",
              title4: 'Depuis votre ordinateur :',
              text41:
                'un imprime écran du bug et une explication la plus détaillée possible des actions qui ont amené au bug.',
              text42:
                "un imprime écran de votre inspecteur de données (clic droit 'inspecter') avec l'onglet console ouvert sur l'erreur ou les erreurs.",
              text43:
                "un imprime écran de votre inspecteur de données (clic droit 'inspecter') avec l'onglet de l'inspecteur “Network” ouvert sur la partie Fetch/XHR, si possible en ayant les détails de l'erreur de la requête.",
            },
            about: {
              title1: 'À propos de nous',
              text1:
                "JutsuEvent est une plateforme innovante dédiée aux passionnés d'arts martiaux et de sports de combat. Notre mission est de promouvoir et de célébrer ces disciplines en proposant une plateforme en ligne où les organisateurs et les participants peuvent facilement trouver, créer et s'inscrire à des événements liés aux arts martiaux et aux sports de combat.",
              text2:
                "Le nom 'JutsuEvent' tire son inspiration du mot japonais 'jutsu' (術), qui signifie 'technique' ou 'art' en référence aux compétences spécifiques développées dans les arts martiaux. En associant 'jutsu' à 'event', notre objectif est de mettre en avant l'importance du partage dans l'apprentissage de ces disciplines, tout en créant un espace communautaire pour les amateurs et les professionnels.",
              text3:
                "Sur notre plateforme, vous trouverez une grande variété d'événements, tels que des compétitions, des séminaires, des stages et des ateliers, couvrant un large éventail de disciplines comme le judo, le karaté, le jiu-jitsu brésilien, la boxe, le muay-thaï et bien d'autres encore. Nous souhaitons offrir une expérience complète pour nos utilisateurs, en facilitant la découverte et la participation à des événements locaux, nationaux et internationaux.",
              text4:
                "JutsuEvent s'adresse à tous les niveaux, des débutants aux experts, et encourage le partage de connaissances et la progression dans les arts martiaux et les sports de combat. Nous sommes convaincus que ces disciplines offrent non seulement des avantages physiques, mais aussi des bénéfices sur le plan mental et émotionnel, en inculquant des valeurs telles que le respect, la discipline et la persévérance.",
              text5:
                "Rejoignez-nous dès aujourd'hui et découvrez comment JutsuEvent peut vous aider à explorer et à vous épanouir dans le monde fascinant des arts martiaux et des sports de combat. Ensemble, nous bâtirons une communauté soudée, engagée et passionnée, en célébrant et en partageant notre amour pour ces disciplines extraordinaires.",
            },
          },
        },
      },
      en: {
        translation: {
          sports: {
            judo: 'Judo',
            jujitsu: 'Jujitsu',
            shotokanKarate: 'Shotokan Karate',
            kyokushinkaiKarate: 'Kyokushinkai Karate',
            aikido: 'Aikido',
            taekwondo: 'Taekwondo',
            brazilianJiuJitsu: 'Brazilian Jiu-Jitsu',
            boxing: 'Boxing',
            yoga: 'Yoga',
            thaiBoxing: 'Thai Boxing',
            wrestling: 'Wrestling',
            fencing: 'Fencing',
            bareknuckle: 'Bare Knuckle',
            burmeseBoxing: 'Burmese Boxing',
            chineseBoxing: 'Chinese Boxing',
            frenchBoxing: 'French boxing',
            capoeira: 'Capoeira',
            dambe: 'Dambe',
            fullContact: 'Full Contact',
            grappling: 'Grappling',
            kendo: 'Kendo',
            kickBoxing: 'Kick Boxing',
            kravMaga: 'Krav-Maga',
            kungFu: 'Kung-Fu',
            meditation: 'Meditation',
            mma: 'MMA',
            penchakSilat: 'Penchak Silat',
            selfDefense: 'Self Defense',
            systema: 'Systema',
            taiChiChuan: 'Tai Chi Chuan',
            vietVoDao: 'Viet Vo Dao',
          },
          grades: {
            white: 'White',
            cyan: 'Cyan',
            yellow: 'Yellow',
            orange: 'Orange',
            green: 'Green',
            blue: 'Blue',
            purple: 'Purple',
            brown: 'Brown',
            black: 'Black',
            blackRed: 'Black and Red',
            whiteRed: 'Black and White',
            red: 'Red',
            beginner: 'Beginner',
            initiate: 'Initiate',
            amateur: 'Amateur',
            pro: 'Professionnal',
          },
          components: {
            utils:{
              expanded:"Less",
              collapsed:"Read more"
            },
            topBar: {
              newEvent: 'Create an Event',
              newClub: 'Create a Club',
              logOut: 'Log Out',
              logIn: 'Log In',
              traduct: {
                language: 'Langage',
              },
              search: {
                sport: 'Sport',
                location: 'City',
                events: 'Events',
              },
              subMenu: {
                home: 'Home',
                account: 'Account',
                myEvents: 'My events',
                logOut: 'Log Out',
              },
            },
            eventList: {
              title: 'Events',
              subtitle: 'Find your next event',
              noEvents: 'Currently no events are available for this search',
            },
            confirmModal: {
              wait: 'Hey, Wait!!',
              areYouSureDelete: 'Are you sure you want to delete this event ?',
              areYouSureUnregister:
                'Are you sure you want to leave this event ?',
              yesDelete: 'Yes, delete',
              yesUnregister: 'Yes, unsubscribe',
              noDelete: 'No, keep it',
              noUnregister: 'No, I stay',
            },
            payingEventModal: {
              cool: 'This event looks perfect for you!',
              recap: 'Here is a summary of important information',
              sport: 'Sport',
              start: 'Start',
              price: 'Price',
              warning:
                'Please note that this event is chargeable, so you cannot unsubscribe and the amount above will be requested on site.',
              address: 'Address',
              yes: 'Register!',
            },
            qrCodeModal: {
              startDate: 'Start : ',
              warning:
                'You may be asked to show proof of identity on presentation of your ticket',
            },
            scanModal: {
              scanHere: 'Scan a ticket',
              success: 'Ticket validated',
              error: {
                already: 'Ticket already validated',
                wrongEvent: 'Ticket from another event',
                unvalid: 'Invalid ticket',
                errorScan: 'An error occurred while scanning the ticket',
              },
            },
            sportSelector: {
              selectNewEvent: 'Select the sport and the minimum level',
              selectClub: 'Select up to 5 sports for your club',
              tellUs: 'Tell us what you like to do',
              sport: 'Sport',
              level: 'Level',
            },
            pictureModal: {
              profile: {
                title: 'Profile picture',
              },
              landscape: {
                title: 'Cover picture',
              },
              event: {
                title: 'Event picture',
              },
              chooseFile: 'Choose a file',
              save: 'Save picture',
              errors: {
                otherFile: 'Please choose another file.',
                size: 'The file must be less than 2MB.',
              },
            },
          },
          home: {
            topVideo: {
              text1: 'Do you like martial arts ?',
              text2: 'Share and practice with Jutsu',
              warning1: 'Jutsu is warming up!',
              warning2:
                'All payments are in test mode and the data will be reset at the end of this period.',
              warning3: 'Launch scheduled for March 31.',
              letsGo: 'Find an event',
              createClub: 'Create my club',
              createEvent: 'Create an event',
              signIn: 'Sign Up',
            },
            sportFilter: {
              boxe: 'Boxing',
              jjb: 'Brasilian Jiujitsu',
              yoga: 'Yoga',
              thai: 'Thai Boxing',
              judo: 'Judo',
            },
            nextEvents: {
              nextEvents: 'Next Events',
              moreEvents: 'More Events',
              noEvents: 'No upcoming event yet',
            },
          },
          footer: {
            newEvent: 'Make your own Jutsu event',
            start: 'Start',
            yourAccount: 'Your Account',
            parameters: 'Parameters',
            logOut: 'Log Out',
            discover: 'Discover',
            events: 'Events',
            sport: 'Sport',
            city: 'City',
            calendar: 'Calendar',
            about: 'About Us',
            follow: 'Follow Us',
            cgu: 'User Conditions',
            confidential: 'Confidential Politic',
            help: 'Help',
          },
          login: {
            welcome1: 'Welcome to',
            welcome2: 'Jutsu 👋',
            subtitle: 'The best place to share and work your art',
            choose: 'Choose your connection',
            noAccount: 'No account?',
            haveAccount: 'Already have an account?',
            register: 'Sign up',
            connect: 'Log in',
            createAccount: 'Create your account',
            firstName: 'First name',
            lastName: 'Last name',
            email: 'e-mail address',
            password: 'Password',
            forgotPassword: 'Forgot your password?',
            login: 'Log in',
            signUp: 'Sign up',
            or: 'Or',
            cgu: 'Terms of use',
            confidential: 'Privacy Policy',
            help: 'Help',
            errors: {
              login: 'Wrong email or password.',
              wrongPsw:
                'Password should be at least 8 characters long, contain MAJ and min letter, number and special character.',
              accountExist: 'This account already exists.',
              missingField: 'Some fields are missing.',
              wrongEmail: 'This email is invalid.',
              wrongLink: 'Your link has expired',
            },
            success: 'Your account has been successfully created.',
          },
          forgot: {
            backToConnection: 'Back to login',
            newPsw: 'New Password',
            confirmPassword: 'Password confirmation',
            save: 'Save',
            send: 'Send an email',
            wrongConfirm: 'Passwords must be the same',
            wrongPsw:
              'Password should be at least 8 characters long, contain MAJ and min letter, number and special character.',
            mail: 'An email has been sent',
            success: 'Your password has been changed',
          },
          account: {
            club: {
              title: 'Club Information',
              name: 'Club Name',
              address: 'Address',
              zipCode: 'Zip Code',
              cityCountry: 'City, Country',
              cityCountryPlaceholder: 'Select your city',
              instagram: 'Instagram',
              website:"Website",
              description: 'Description',
              save: 'Save Club',
              cancel: 'Cancel',
              success: {
                create: 'Your club as been created',
                modif: 'Your club as been updated',
              },
              error: {
                modif: 'An error append during club update',
                create: 'An error append during club creation',
                exist: 'This Club already exist',
                missingField: 'Some missing fields',
                localisation: 'We are unable to locate your club',
                tooLongString: 'Maximum number of characters reached',
                wrongUrl: "Unvalid https URL",
              },
            },
            noClub: {
              title: 'Creation of a Club',
              text1: 'Register your club on Jutsu to publish events',
              text2: 'and share your passion!',
              text3: 'Before you start, please take the time to read our ',
              cgu: 'TOS',
              createClub: 'Create a new club',
            },
            profile: {
              title: 'Profile Information',
              firstName: 'First Name',
              lastName: 'Last Name',
              cityCountry: 'City, Country',
              cityCountryPlaceholder: 'Select your city',
              instagram: 'Instagram',
              description: 'Description',
              save: 'Save profil',
              cancel: 'Cancel',
              success: {
                create: 'Your profile as been created',
                modif: 'Your profile as been updated',
              },
              error: {
                modif: 'An error append during profile update',
                missingField: 'Some missing fields',
                tooLongString: 'Maximum number of characters reached',
              },
            },
            settings: {
              paymentTitle: 'Bank details',
              parametersTitle: 'Account settings',
              registerText: 'Registration on Stripe',
              registerSubText:
                'Jutsu uses the Stripe payment terminal to secure your transactions, to start receiving your paid reservations please register!',
              registerButton: 'Register',
              updateText: 'Edit your Stripe information',
              updateSubText:
                'Jutsu uses the Stripe payment terminal to secure your transactions, you are currently registered, if you wish you can change your payment details.',
              updateButton: 'Edit',
              errorStripe: 'A problem occurred while registering on Stripe',
              deleteClubText: 'Delete club ',
              clubSuppressToo:
                'The events and all associated data linked to it will also be deleted.',
              deleteClubButton: 'Delete club',
              deleteUserText: 'Delete account from ',
              clubLinked: 'This account is the manager of the club ',
              userSuppressToo:
                'it will also be deleted along with all associated data.',
              noClub: 'This account is not linked to any club',
              deleteUserButton: 'Delete my account',
              cantSuppUser: 'This account cannot be deleted at this time.',
              cantSuppClubUser:
                'This account cannot be deleted at this time as his club has future paid events with registrants.',
              cantSuppClub:
                'This club cannot be deleted at this time as you have future paying events with registrants.',
              deleteModal: {
                titleUser: 'Account deletion',
                titleClub: 'Club deletion',
                sureUser:
                  'Are you sure you want to delete your account and all associated data?',
                sureClub:
                  'Are you sure you want to delete your club and all associated data?',
                no: 'Cancel',
                yes: 'Delete',
              },
            },
            menu: {
              profile: 'Profile',
              club: 'Club',
              settings: 'Settings',
            },
          },
          newEvent: {
            title: 'New event',
            eventName: 'Event name',
            sport: 'Sport',
            recurrentEvent: 'Recurring event',
            dateSelect: 'Selection of dates',
            start: 'Start',
            end: 'End',
            places: 'Availability',
            price: 'Place price',
            stripeSubText:
              'Please fill in your bank details to create a paid event',
            jutsuFee: "Jutsu Fee (15% VAT included)",
            youGet: "You will receive per ticket sold",
            equipments: 'Equipements',
            description: 'Description',
            uploadImage: 'Upload Image',
            save: 'Save event',
            cancel: 'Cancel',
            placeholder: {
              eventName: 'Ex: Training all levels on the jab',
              places: 'For a pleasant practice',
              price: '10',
              equipments: 'Ex: Boxing gloves, mouth guard, shell, shorts',
              description:
                'Ex: Discover the secrets and training techniques to jab like a pro. Having a good jab or direct front arm is essential, in addition to being a powerful blow that can do damage to your opponent, it can allow you to disrupt, feint, prepare your next moves. Join us to learn more about this basic but very useful boxing move!',
            },
            error: {
              moreInfos:
                'Please change your club informations before creating an event',
              exist: 'This event already exist',
              missingField: 'Some missing fields',
              error: 'An error append during event creation',
              tooLongString: 'Maximum number of characters reached',
              wrongAddress: 'Wrong address or postcode',
            },
            success: {
              created: 'Your event as been created',
            },
          },
          profile: {
            aboutMe: 'Description',
            events: 'Events',
            futureEvents: 'Futur Events',
            pastEvents: 'Old events',
            club: 'Club',
            contact: 'Contact',
            sports: 'Sports',
            noSlider: 'No event currently',
          },
          club: {
            aboutMe: 'Description',
            events: 'Events',
            futureEvents: 'Futur Events',
            pastEvents: 'Old Events',
            club: 'Club',
            location: 'Address',
            contact: 'Contact',
            sports: 'Sports',
            noSlider: 'No event currently',
            notSpecified: 'Not specified',
          },
          myEvents: {
            title: 'My Events',
            todayEvents: 'Today',
            futurEvents: 'Coming soon',
            pastEvents: 'Already passed',
            scanButton1: 'Scanner',
            scanButton2: 'a place',
          },
          search: {
            week: 'This week',
            nextWeek: 'Next week',
            month: 'This month',
            nextMonth: 'Next month',
            allDates: 'Date(all)',
            maxRange: 'Far far away',
            allSports: 'All sports',
            noLocation: 'City(all)',
          },
          events: {
            description: 'Description',
            registers: 'Registers',
            unsubscribe: 'Unsubscribe',
            subscribe: 'Subscribe',
            host: 'Organizer',
            equipments: 'Equipements',
            minimumLevel: 'Minimum level',
            noRegisters: 'Be the first to register!',
            free: 'Free',
            fullPlaces: 'Full',
            subscribed: 'Registered',
            qrCode: 'See my ticket',
            delete: 'Delete event',
            successRegister: 'Your subscription is saved',
            successUnregister: 'Your unsubscription is saved',
            errors: {
              close: 'This event registers are closed',
              started: 'This event as already started',
              passed: 'This event is alresdy passed',
            },
          },
          cgu: {
            title1:
              'Terms and conditions of the use of the website “JUTSU EVENTS”',
            text1:
              'Terms and conditions enunciated hereinafter explain in detail, how you interact with the website, what are your responsibilities while you visit the website, certain terms and conditions on the basis of which the services are provided to you, etc. You are advised to read the following in detail and with all attention as these terms and conditions create a contractual relationship between the website and you, setting certain liabilities upon both the parties, i.e. the website and the user. Violation of these terms and conditions of use of the website may result in certain repercussions for the user, including, among others, the blockage of the user account, etc. You are also advised in your best interest to comply with these terms and conditions in order to enjoy any interruption-free services through the website. These terms and conditions may be upgraded from time to time and shall be notified to the users.',
            title2: 'JUTSU EVENTS.',
            text2:
              'JUTSU EVENTS as platform warrants you and other members and/or users of the platform to put out offline and/or real-world groups and events. Availing the services from the platform and/or even browsing through the Platform implies that you acknowledge these terms and conditions of services of use.',
            title3: "User's use of the website.",
            text3:
              "It is a necessary requirement to follow website's policies and guidelines when using our Platform. We have no responsibility for Content that anyone posts to our Platform. You understand and take responsibility for complying with and agreeing to all the applicable laws, rules, and regulations, and do not violate or infringe the rights of any third party. In addition, your access to and use of some areas or features of the website may be subject to additional terms, policies, standards or guidelines (“Additional Terms”). You may be required to accept these Additional Terms before you can access these Platform areas and features.",
            title4: "User's Account.",
            subTitle1: 'Entitlement.',
            subText1:
              'The website is only available to one who is at least 18 years of age. Your use of the website warrants that you have attained the age of entitlement for using the website. A user may only be eligible to get access to the full features of the website by creating a registered account with the website. A person and/or user who has the ability to moderate and/or manage a particular portion of the website can also, in his or her sole discretion, alter, suspend, or put an end to your access to the specific portion of the Platform.',
            subTitle2: "Account's credentials and confidentiality.",
            subText2:
              'On registering your account with the platform, you agree to provide us with the necessary information, including but not limited to, a valid email address and a password. You understand that it is important and solely your responsibility to keep the privacy and confidentiality of your passwords intact, otherwise, your account may be disabled.',
            title5: 'Emoluments for the Website.',
            subTitle3: 'Fees.',
            subText3:
              'Certain features on the website are free of cost. However, there are paid services also on the website for using the same you have to pay certain fees through the designated payment service providers and authorized persons only. You represent and warrant that you are authorized to use the payment method you designate via the Platform. You permit us (and our designated third-party payment processors) to charge your designated payment method for the total amount of any fees you owe to the JUTSU EVENTS, including any associated applicable taxes and other charges.',
            subTitle4: 'Renewals.',
            subText4:
              'The website shall on its own bill the user for each renewal period until cancelation. By purchasing any feature or other aspect of our Platform for which we charge, you authorize us to keep your payment current by charging the applicable fee to your credit card account (or any other means of payment used by you).',
            title6: "User's DATA and Privacy.",
            text6:
              'The user is solely responsible for providing content to the website and the content of the user shall be dealt with in accordance with the privacy policy of the JUTSU EVENTS.',
            title7: 'Intellectual Property ',
            text7:
              "JUTSU trademarks, logos, service marks, and service names are the intellectual property of JUTSU. User is not allowed to use without authorization any Intellectual Property, including but not limited to Patents, Trademarks, Designs, Copyright, trade secrets, etc. of the website and/or any other third party who have posted the same on the website. The website, in its sole discretion, may, on suspicion that material posted on the website by the user is infringing anyone else's rights, remove or disable the same.",
            title8: 'Indemnification ',
            text8:
              'User of the website pledges to hold the website indemnified in all circumstances, especially when the platform is getting sued by anyone because of your use of the website. To the full extent permitted by applicable law, you agree to indemnify, defend and hold all JUTSU Parties harmless from any Claims, made by any third party due to or arising out of (a) your violations of this Agreement, (b) your use, misuse, or abuse of our Platform, (c) your Content, (d) your violation of any law, statute, ordinance or regulation or the rights of a third party, or (e) your participation or conduct in a JUTSU group or a JUTSU event that violates this Agreement.',
            title9: 'Dispute Resolution',
            text9:
              "If the user has a dispute, involving the website, the user understands and agrees that the most effective way to resolve the same is to contact the website's authorized representative appointed on this behalf. If the grievances of the user remain unaddressed, the user may submit the same to a neutral arbitrator instead of taking the claim to a court of law. Grievances against the website can only be brought individually, and not as part of a class action. The website has no obligation to get involved with any disputes you may have with other members, although we may try to facilitate a resolution.",
            title10: 'General Terms',
            subTitle101: 'Language.',
            text101:
              'These terms and conditions are written in English and subsequently may be translated into other languages. If there is any inconsistency between the English version and a translated version, the English language version shall prevail.',
            subTitle102: 'No Agency.',
            text102:
              'No agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship between you and JUTSU is intended or created by this Agreement.',
            subTitle103: 'Governing Law.',
            text103:
              'This Agreement and the relationship between you and JUTSU shall be governed by the relevant laws of the land without regard to its conflict of laws provisions.',
            subTitle104: 'No Waiver.',
            text104:
              "The user's failure to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision and does not waive any right to act with respect to subsequent or similar breaches.",
            subTitle105: 'Disclaimer of Warranties.',
            text105:
              'YOUR USE OF THE SITE, ITS CONTENT, AND ANY SERVICES AVAILED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE SITE, ITS CONTENT, AND ANY SERVICES AVAILED THROUGH THE WEBSITE ARE PROVIDED ON AN ``AS IS`` AND ``AS AVAILABLE`` BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER JUTSU NOR ANY PERSON ASSOCIATED WITH THE WEBSITE MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CAN NOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.',
            subTitle106: 'Limitation on Liability.',
            text106:
              'IN NO EVENT WILL JUTSU, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES AVAILED THROUGH THE SITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.',
          },
          confidential: {
            title1: 'Privacy Policy of Jutsu Events.',
            text1:
              'The following terms encapsulate the privacy policy of the Jutsu Event, 4 route de las illas, 66480 Maureillas Las Illas, France. The same discusses in detail how the Jutsu Event will collect, use, disseminate, etc. the information collected from the user while browsing the website. The expressions like JUTSU EVENT, JUTSU, we, us, and our connote the website, and the expressions like a user, you, your, member, connote every person using and/or browsing the website, or platform. This privacy policy aims to filter the information collected from users while they browse through the website. The information you share with third parties on the website does not fall within the scope of this privacy policy. You are advised in your best interest to go through this privacy policy carefully in order to know how personal information about you is collected, stored, and used through the website.',
            title2: 'Instances of collecting information.',
            text2:
              'Personal information about you is collected when you create an account on the website and/or use the platform and/or attend any event organized and/or managed through the website, visit the website, and/or communicate with the website. With the help of cookies and other related technologies information like usage statistics is also collected. If you opt to use certain other features information through certain other sources like social media services, etc.',
            title3: "User's Provided Information.",
            text3:
              "Some information including but not limited to, the information about creating an account on the website, preferring an interest, and/or information collected during the correspondence between the website and you, is collected at the website directly and is considered as user's provided information. User's provided information may be classified as identifiers, such as the user's name, user's username on the website, user's security code, unique email address of the user, postal address for the purposes of ascertaining the location of the user, and contact number of the user, payment and commercial information, demographic information, and/or any other personal information that you choose to provide, including but not limited to photos, groups, including your role, company, and years of experience. Information like which group you like the most is also optional to provide but to filter this option this information is also necessary for taking care of your account and for marking that you are a member of a group that you join. In certain instances, the information provided by you that connotes your beliefs, political views, your health condition, and your sexual identity is given special protection under the relevant applicable laws.",
            title4:
              'Particulars collected automatically and/or required explicitly by the website.',
            text4:
              "While the user browses through the website, certain information about the user's internet connection and/or other electronic network activity information is collected automatically. Information like by which means you access or from where you are accessing the website is also collected automatically by the website, including but not limited to the browser that you avail of, the time, duration, and frequency of your access to the website, activity on the website, IP address, the page you have visited before directing to the website, the computer and/or mobile device used by the user for accessing the website, the hardware model of the same, operating system and version which is being used on the device through which the website has been accessed, mobile network information if the browsing is being done through the mobile phone, locality of the area in which the device is being used for accessing the website and this information is collected solely on explicit consent, which you give by turning on the device location which you use, from you in this behalf, information collected through the cookies as per the cookies policy, and consent based information, like accessing the photos from your camera roll and/or information regarding the calendar you want to manage though the website may also be collected.",
            title5: 'Information collected via third-party sources.',
            text5:
              "Certain information about you may be collected through third parties. It is apprised that your information collected through these third-party sources is strictly subject to these parties' privacy policies. If a user registers or log into the account by using a third-party channel, the platform shall have the access to your account information maintained by that third-party service provider, including but not limited to identifiers, like your name and other information in that account, and the same may be used for the purposes of making the recommendations on the platform to you the most relevant.",
            title6: 'Motive(s) of Collecting Information.',
            text61:
              "Information obtained as described above may be used for the purposes of including but not limited to making available, continuing, and improving the website, processing transactions, developing new products and services, administering the working of the website, displaying information about you, for instance, your list of interests, which will be visible on your profile, personalizing the website for your best experience, for example, by giving you recommendation about the most relevant content, allowing you to spread views about your experience of the website through a third-party social media services, for monitoring and analyzing movements, usage, and activities in connection with our website, detecting, investigating, and preventing crooked and/or suspicious transactions, abuse, and other illegal activities, protecting the entitlements, belongings, or safety of the website and others, enforcing compliance with website's policies, complying with applicable law and government requests, performing financial and administrative tasks, and enforce or manage legal claims.",
            text62:
              'Information collected from you as described above may also be used for the purposes of communicating with you in the manner as to make proper and just reply to your inquires, queries, and appeals, and provide customer service. We may also use the information for contacting you, depending upon the preferences being set by you, about products, services, and events offered by us and others, and to update you with news and information that according to our analysis depends on the cookies, will be of interest to you, to conduct online valuations, and for forwarding you the technical notices, updates, security alerts, and support, and administrative messages necessary for the effective working of the website.',
            text63:
              'Advertisements and promotional materials are necessary for generating revenues in order to make the financial affairs and working of the website as smooth as possible. For the purposes, we do not sell your information to other parties but, show you the most relevant contact matching your interests, features, or sponsorships, forward you marketing communications, subject to applicable law.',
            title7: 'Disclosure of information collected.',
            text7:
              "The information collected is not sold and/or being traded and/or otherwise transfer to third parties the user’s personally identifiable information unless the website provide the user with advance notice, except as described below. It also does not include website hosting partners and other parties who assist in operating the website, conducting the website's business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe that such disclosure is appropriate to comply with the law, our website policies, or protect ours or others' rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.",
            title8: 'Safety of the information collected from the user.',
            text8:
              'We implement a number of safety checks and/or encryption techniques to ensure the protection and the safety of the information collected from you while you visit the website. The information collected on your behalf is maintained under tight secured networks and is only reachable by a limited number of authorized persons only who have special access rights to such systems, and who are pledged to keep the information accessed confidential.',
          },
          help: {
            menu: {
              faq: 'FAQ',
              contact: 'Contact',
              report: 'Report',
              about: 'About',
            },
            faq: {
              mainTitle: 'JutsuEvent FAQ - Martial Arts Events Platform',
              title1: '1. What is JutsuEvent?',
              text1:
                'JutsuEvent is an online platform dedicated to promoting and organizing martial arts events. We provide a centralized space where organizers can create, manage, and promote their events, and where participants can discover and register for competitions, seminars, workshops, and other martial arts-related events.',
              title2: '2. How do I create an account on JutsuEvent?',
              text2:
                "To create an account, click on 'Login' in the top right corner of the homepage. Then click on 'Sign Up' in the top right corner of the login page. Fill in the form with your personal information and click on 'Register'.",
              title3: '3. How do I add a club on JutsuEvent?',
              text3:
                "To add a club, log in to your account and click on 'Account' in the dropdown menu in the top right corner of the homepage. Then click on 'Club' in the top left, take the time to read our Terms and Conditions and click 'Create a new club'. Fill in the required information and click on 'Save' to save your club!",
              title4: '4. How do I register my club with Stripe?',
              text4:
                "In order to create paid events, it is necessary to register with Stripe, our payment provider. To do this, log in to your account with a club and click on 'Account' in the main menu, then on 'Options'. From this tab, click on 'Register' and follow the instructions on the Stripe platform.",
              title5: '5. How do I add an event on JutsuEvent?',
              text5:
                "To add an event, log in to your account with a club and click on 'Create an Event' in the main menu. Fill in the required information, such as the event name, date, time, location, and a detailed description. You can also add images and links to help promote your event. Note that for any paid event, you must first register your bank details with Stripe from the 'Options' tab in your 'Account'.",
              title6: '6. How do I register for an event on JutsuEvent?',
              text6:
                "To register for an event, search for the event you are interested in using the search filters or by browsing a club's events. Once on the event page, click on 'Register' and follow the instructions to complete your registration.",
              title7: '7. How do I pay the registration fees for an event?',
              text7:
                'Registration fees can be paid online through our secure payment system. We accept most credit and debit cards, as well as some online payment methods.',
              title8:
                '8. How do I cancel or modify my registration for an event?',
              text8:
                "In the case of a free event, click on the concerned event then select 'Unregister'. For canceling or modifying your registration for a paid event, please contact the organizing club and discuss their refund policies. Please note that cancellation or modification fees may apply depending on the event's conditions.",
              title9: '9. How can I contact the organizer of an event?',
              text9:
                "On the event page,once connected you will find the organizer's contact details. You can also use the contact form provided on the event page to send a message directly to the organizer.",
              title10: '10. Is JutsuEvent available for international users?',
              text10:
                'Yes, JutsuEvent is availablefor users throughout Europe. However, please note that some events may be limited to certain regions or countries due to legal restrictions or specific policies set by the organizer.',
              title11: '11. Does JutsuEvent offer a mobile app?',
              text11:
                'Currently, JutsuEvent does not have a dedicated mobile app. However, our website is fully optimized for mobile devices, allowing you to browse, create, and manage events, as well as register for martial arts events from your smartphone or tablet.',
              title12:
                '12. How can I get help or report an issue on JutsuEvent?',
              text12:
                "If you need help or would like to report an issue, please visit our 'Help' section at the bottom of the homepage. There, you will find help articles and guides for resolving common issues. If you cannot find the answer to your question, you can contact us directly using the contact form available in that same section.",
              title13:
                '13. How can I stay informed about new events and news on JutsuEvent?',
              text13:
                'To receive the latest news and event updates you can follow us on social media, such as Facebook, Twitter, and Instagram, to stay informed about the latest news and events.',
              title14: '14. How can I delete my JutsuEvent account?',
              text14:
                "If you wish to permanently delete your JutsuEvent account, click on 'Account' from the top menu, then select the 'Options' tab. From there, you can choose to delete your user account or only your club if you have one.",
            },
            contact: {
              title1: 'Contact Us',
              text1:
                'At JutsuEvent, we are committed to providing quality service and an exceptional experience for our users. If you have any questions, concerns, or suggestions about our platform, please feel free to contact us.',
              text2:
                'You can fill out the contact form below, and one of our customer service representatives will get back to you as soon as possible. If you prefer, you can also send us an email directly at ',
              text2Email: 'contact@jutsuevent.com.',
              text3:
                'To stay informed about the latest news and events, follow us on our social media channels.',
              text4:
                'We appreciate your feedback and look forward to assisting you in your martial arts adventures with JutsuEvent!',
            },
            report: {
              title1: 'Need help ?',
              text1:
                'Email your questions and other requests to contact@jutsuevent.com',
              title2: 'Report a bug',
              text21:
                'Here is the ideal procedure for reporting a bug or behavior that seems abnormal or disturbing to you when using the site.',
              text22: 'Send by email to contact@jutsuevent.com',
              title3: 'From your phone:',
              text31:
                'A screenshot of the bug and an explanation as detailed as possible of the actions that led to the bug.',
              text32:
                'Ex: After my registration for an event, the images of the participants in this event are not displayed',
              title4: 'From your computer:',
              text41:
                'a screenshot of the bug and an explanation as detailed as possible of the actions that led to the bug.',
              text42:
                "a screen print of your data inspector (right click 'inspect') with the console tab open on the error or errors.",
              text43:
                "a screenshot of your data inspector (right click 'inspect') with the “Network” inspector tab open on the Fetch/XHR part, if possible with the details of the request error.",
            },
            about: {
              title1: 'About Us',
              text1:
                'JutsuEvent is an innovative platform dedicated to martial arts and combat sports enthusiasts. Our mission is to promote and celebrate these disciplines by providing an online platform where organizers and participants can easily find, create, and register for martial arts and combat sports-related events.',
              text2:
                "The name 'JutsuEvent' is inspired by the Japanese word 'jutsu' (術), which means 'technique' or 'art' in reference to the specific skills developed in martial arts. By combining 'jutsu' with 'event', our goal is to emphasize the importance of sharing in learning these disciplines, while creating a community space for amateurs and professionals.",
              text3:
                'On our platform, you will find a wide variety of events, such as competitions, seminars, workshops, and training camps, covering a broad range of disciplines like judo, karate, Brazilian jiu-jitsu, boxing, muay-thai, and many more. We aim to offer a comprehensive experience for our users, making it easy to discover and participate in local, national, and international events.',
              text4:
                'JutsuEvent caters to all skill levels, from beginners to experts, and encourages the sharing of knowledge and progress in martial arts and combat sports. We believe that these disciplines provide not only physical benefits, but also mental and emotional gains, instilling values such as respect, discipline, and perseverance.',
              text5:
                'Join us today and discover how JutsuEvent can help you explore and thrive in the fascinating world of martial arts and combat sports. Together, we will build a tight-knit, committed, and passionate community, celebrating and sharing our love for these extraordinary disciplines.',
            },
          },
        },
      },
      es: {
        translation: {
          sports: {
            judo: 'Judo',
            jujitsu: 'Jiujitsu',
            shotokanKarate: 'Kárate Shotokan',
            kyokushinkaiKarate: 'Kárate Kyokushinkai',
            aikido: 'Aikido',
            taekwondo: 'Taekwondo',
            brazilianJiuJitsu: 'Jiu Jitsu Brasileño',
            boxing: 'Boxeo inglés',
            yoga: 'Yoga',
            thaiBoxing: 'Boxeo tailandés',
            wrestling: 'Lucha',
            fencing: 'Esgrima',
            bareknuckle: 'Bare Knuckle',
            burmeseBoxing: 'Boxeo Birmano',
            chineseBoxing: 'Boxeo Chino',
            frenchBoxing: 'Boxeo Francés',
            capoeira: 'Capoeira',
            dambe: 'Dambe',
            fullContact: 'Full Contact',
            grappling: 'Grappling',
            kendo: 'Kendo',
            kickBoxing: 'Kick Boxing',
            kravMaga: 'Krav-Maga',
            kungFu: 'Kung-Fu',
            meditation: 'Meditación',
            mma: 'MMA',
            penchakSilat: 'Penchak Silat',
            selfDefense: 'Autodefensa',
            systema: 'Systema',
            taiChiChuan: 'Tai Chi Chuan',
            vietVoDao: 'Viet Vo Dao',
          },
          grades: {
            cyan: 'Cian',
            white: 'Blanco',
            yellow: 'Amarillo',
            orange: 'Naranja',
            green: 'Verde',
            blue: 'Azul',
            purple: 'Violeta',
            brown: 'Marrón',
            black: 'Negro',
            blackRed: 'Rojo y Negro',
            whiteRed: 'Blanco et Negro',
            red: 'Rojo',
            beginner: 'Principiante',
            initiate: 'Confirmado',
            amateur: 'Aficionado',
            pro: 'Profesional',
          },
          components: {
            utils:{
              expanded:"Menos",
              collapsed:"Leer más"
            },
            topBar: {
              newEvent: 'Crear un evento',
              newClub: 'Crear un club',
              logOut: 'Cerrar sesión',
              logIn: 'Acceso',
              traduct: {
                language: 'Idioma',
              },
              search: {
                sport: 'Deporte',
                location: 'Pueblo',
                events: 'Eventos',
              },
              subMenu: {
                home: 'Inicio',
                account: 'Cuenta',
                logOut: 'Cerrar sesión',
                myEvents: 'Mis eventos',
              },
            },
            eventList: {
              title: 'Eventos',
              subtitle: 'Encuentra tu próximo evento',
              noEvents:
                'Actualmente no hay ningún evento disponible para esta búsqueda',
            },
            confirmModal: {
              wait: '¡Hey, espera!',
              areYouSureDelete:
                '¿Está seguro de que desea eliminar este evento?',
              areYouSureUnregister: '¿Seguro que quieres salir de este evento?',
              yesDelete: 'Si, borrar',
              yesUnregister: 'Si me voy',
              noDelete: 'No, sigue',
              noUnregister: 'No, me quedo',
            },
            payingEventModal: {
              cool: '¡Este evento suena perfecto para ti!',
              recap: 'Aquí hay un resumen de información importante.',
              sport: 'Deporte',
              start: 'Empieza',
              price: 'Precio',
              warning:
                'Tenga en cuenta que este evento es de pago, por lo que no puede darse de baja y precio anterior se solicitará en el sitio.',
              address: 'Dirección',
              yes: '¡Registro!',
            },
            qrCodeModal: {
              startDate: 'Inicio : ',
              warning:
                'Es posible que se le pida un documento de identidad al presentar el billete.',
            },
            scanModal: {
              scanHere: 'Escanear un billete',
              success: 'Billete validado',
              error: {
                already: 'Billete ya validado',
                wrongEvent: 'Entrada para otro evento',
                unvalid: 'Billete no válido',
                errorScan: 'Se ha producido un error al escanear el billete',
              },
            },
            sportSelector: {
              selectNewEvent: 'Selecciona el deporte y el nivel mínimo',
              selectClub: 'Selecciona hasta 5 deportes para tu club',
              tellUs: 'Cuéntanos qué te gusta hacer',
              sport: 'Deporte',
              level: 'Nivel',
            },
            pictureModal: {
              profile: {
                title: 'Foto de perfil',
              },
              landscape: {
                title: 'Foto de portada',
              },
              event: {
                title: 'Foto del evento',
              },
              chooseFile: 'Elija el archivo',
              save: 'Guardar imagen',
              errors: {
                otherFile: 'Elija otro archivo.',
                size: 'El archivo debe tener menos de 2 MB.',
              },
            },
          },
          home: {
            topVideo: {
              text1: '¿Te gustan las artes marciales?',
              text2: 'Comparte y practica con Jutsu',
              warning1: 'Jutsu se calienta!',
              warning2:
                'Todos los pagos están en modo de prueba y los datos se restablecerán al final de este período.',
              warning3: 'Lanzamiento previsto para el 31 de marzo.',
              letsGo: 'Encuentra un evento',
              createClub: 'Crear mi club',
              createEvent: 'Crear un evento',
              signIn: 'Registro',
            },
            sportFilter: {
              boxe: 'Boxeo',
              jjb: 'Jiu Jitsu Brasileño',
              yoga: 'Yoga',
              thai: 'Boxeo tailandés',
              judo: 'Judo',
            },
            nextEvents: {
              nextEvents: 'Próximos eventos programados',
              moreEvents: 'Más eventos',
              noEvents: 'Aún no hay eventos',
            },
          },
          footer: {
            newEvent: 'Crea tu propio evento Jutsu',
            start: 'Comenzar',
            yourAccount: 'Su cuenta',
            parameters: 'Configuraciones',
            logOut: 'Desconectarse',
            discover: 'Descubrir',
            events: 'Eventos',
            sport: 'Deporte',
            city: 'Pueblo',
            calendar: 'Calendario',
            about: 'Nosotros',
            follow: 'Síganos',
            cgu: 'Condiciones de uso',
            confidential: 'Política de confidencialidad',
            help: 'Ayuda',
          },
          login: {
            welcome1: 'Bienvenido a',
            welcome2: 'Jutsu 👋',
            subtitle: 'El mejor lugar para practicar tu arte.',
            noAccount: '¿Sin cuenta?',
            haveAccount: '¿Ya tienes una cuenta?',
            register: 'Inscribirse',
            connect: 'Conectarse',
            choose: 'Elige cómo iniciar sesión',
            createAccount: 'Cree su cuenta',
            firstName: 'Nombre',
            lastName: 'Apellido',
            email: 'Correo electrónico',
            password: 'Contraseña',
            forgotPassword: '¿Contraseña olvidada?',
            login: 'Acceso',
            signUp: 'Registro',
            or: 'O',
            cgu: 'Condiciones de uso',
            confidential: 'Política de confidencialidad',
            help: 'Ayuda',
            errors: {
              login: 'Correo o contraseña equivocada.',
              wrongPsw:
                'Su contraseña debe contener una letra mayúscula, una letra minúscula, un número, un símbolo y al menos 8 caracteres.',
              accountExist: 'Esta cuenta ya existe.',
              missingField: 'Certains champs sont manquants.',
              wrongEmail: 'Este email es invalido.',
              wrongLink: 'Su enlace ha caducado',
            },
            success: 'Tu cuenta ha sido creada satisfactoriamente.',
          },
          forgot: {
            backToConnection: 'Atrás para iniciar sesión',
            newPsw: 'Nueva contraseña.',
            confirmPassword: 'Confirmación de contraseña',
            save: 'Guardar',
            send: 'Enviador un correo electrónico',
            wrongConfirm: 'Las contraseñas deben ser las mismas',
            wrongPsw:
              'Su contraseña debe contener mayúsculas, minúsculas, un número, un símbolo y al menos 8 caracteres.',
            mail: 'Se le ha enviado un correo electrónico.',
            success: 'Tu contraseña ha sido cambiada',
          },
          account: {
            club: {
              title: 'Información del club',
              name: 'Nombre del club',
              address: 'Dirección',
              zipCode: 'Código postal',
              cityCountry: 'Ciudad, País',
              cityCountryPlaceholder: 'Selecciona tu ciudad',
              instagram: 'Instagram',
              website:"Sitio web",
              description: 'Descripción',
              save: 'Salva el Club',
              cancel: 'Anular',
              success: {
                create: 'Tu club ha sido creado',
                modif: 'Tu club ha sido cambiado',
              },
              error: {
                modif: 'Ocurrió un error al modificar el club.',
                create: 'Ocurrió un error al crear el club.',
                exist: 'Este club ya existe',
                missingField: 'Faltan algunos campos',
                localisation: 'No podemos localizar tu club.',
                tooLongString: 'Número máximo de caracteres alcanzado',
                wrongUrl: "URL no conforme https",
              },
            },
            noClub: {
              title: 'Creación de un Club',
              text1: '¡Registra tu club en Jutsu para publicar eventos',
              text2: 'y comparte tu pasión!',
              text3: 'Antes de comenzar, tómese el tiempo para leer nuestro ',
              cgu: 'CGU',
              createClub: 'Crear un nuevo club',
            },
            profile: {
              title: 'Información del perfil',
              firstName: 'Nombre',
              lastName: 'Apellido',
              cityCountry: 'Ciudad, País',
              cityCountryPlaceholder: 'Selecciona tu ciudad',
              instagram: 'Instagram',
              description: 'Descripción',
              save: 'Guardar perfil',
              cancel: 'Anular',
              success: {
                create: 'Tu perfil ha sido creado',
                modif: 'Tu perfil ha sido modificado',
              },
              error: {
                modif: 'Ocurrió un error al editar el perfil.',
                missingField: 'Faltan algunos campos',
                tooLongString: 'Número máximo de caracteres alcanzado',
              },
            },
            settings: {
              paymentTitle: 'Datos bancarios',
              parametersTitle: 'Configuraciones de la cuenta',
              registerText: 'Registro en Stripe',
              registerSubText:
                'Jutsu utiliza el terminal de pago Stripe para asegurar sus transacciones, ¡para empezar a recibir sus reservas pagadas por favor regístrese!',
              registerButton: 'Registrarse',
              updateText: 'Edite su información de Stripe',
              updateSubText:
                'Jutsu utiliza el terminal de pago Stripe para asegurar tus transacciones, actualmente estás registrado, si lo deseas puedes cambiar tus datos de pago.',
              updateButton: 'Editar',
              errorStripe:
                'Se ha producido un problema al registrarse en Stripe',
              deleteClubText: 'Eliminar club ',
              clubSuppressToo:
                'Los eventos y todos los datos asociados vinculados a él también se eliminarán.',
              deleteClubButton: 'Eliminar club',
              deleteUserText: 'Eliminar cuenta de ',
              clubLinked: 'Esta cuenta es el administrador del club. ',
              userSuppressToo:
                'también se eliminará junto con todos los datos asociados.',
              noClub: 'Esta cuenta no está vinculada a ningún club.',
              deleteUserButton: 'Borrar mi cuenta',
              cantSuppUser: 'Esta cuenta no se puede eliminar en este momento.',
              cantSuppClubUser:
                'Esta cuenta no se puede eliminar en este momento ya que su club tiene futuros eventos pagos con inscritos.',
              cantSuppClub:
                'Este club no se puede eliminar en este momento ya que tiene futuros eventos de pago con los registrados.',
              deleteModal: {
                titleUser: 'Eliminación de cuenta',
                titleClub: 'Eliminación de clubes',
                sureUser:
                  '¿Está seguro de que desea eliminar su cuenta y todos los datos asociados?',
                sureClub:
                  '¿Está seguro de que desea eliminar su club y todos los datos asociados?',
                no: 'Anular',
                yes: 'Borrar',
              },
            },
            menu: {
              profile: 'Perfil',
              club: 'Club',
              settings: 'Opciones',
            },
          },
          newEvent: {
            title: 'Nuevo evento',
            eventName: 'Nombre del evento',
            sport: 'Deporte',
            recurrentEvent: 'Evento recurrente',
            dateSelect: 'Selección de fechas',
            start: 'Inicio',
            end: 'Final',
            places: 'Plazas disponibles',
            stripeSubText: "Por favor, proporcione sus datos bancarios para crear un evento de pago",
            feeJutsu: "Tarifa Jutsu (15% IVA incluido)",
            youGet: "Recibirás por entrada vendida",
            price: 'Precio de un lugar',
            equipments: 'Equipo',
            description: 'Descripción',
            save: 'Salvar',
            cancel: 'Anular',
            uploadImage: 'Nueva imagen',
            placeholder: {
              eventName: 'Ej: Entrenando todos los niveles en el jab',
              places: 'Para una práctica placentera',
              price: '10',
              equipments:
                'Ej: Guantes de boxeo, protector bucal, caparazón, pantalones cortos',
              description:
                'Ej: Descubre los secretos y las técnicas de entrenamiento para jabear como un profesional. Tener un buen jab o brazo frontal directo es fundamental, además de ser un golpe poderoso que puede hacer daño a tu oponente, puede permitirte desbaratar, fintar, preparar tus próximos movimientos. ¡Únase a nosotros para aprender más sobre este movimiento de boxeo básico pero muy útil!',
            },
            error: {
              moreInfos:
                'Por favor, modifique la información de su club para continuar con la creación de un evento.',
              exist: 'Este evento ya existe',
              missingField: 'Faltan algunos campos',
              error: 'Ocurrió un error al crear el evento.',
              tooLongString: 'Número máximo de caracteres alcanzado',
              wrongAddress: 'Dirección o código postal erróneos',
            },
            success: {
              created: 'Tu evento ha sido creado',
            },
          },
          profile: {
            aboutMe: 'Descripción',
            events: 'Eventos',
            futureEvents: 'Viniendo',
            pastEvents: 'Ya pasó',
            club: 'Club',
            contact: 'Contactar',
            sports: 'Deportes',
            noSlider: 'Ningún evento actualmente',
          },
          club: {
            aboutMe: 'Descripción',
            events: 'Eventos',
            futureEvents: 'Viniendo',
            pastEvents: 'Ya pasó',
            club: 'Club',
            contact: 'Contactar',
            sports: 'Deportes',
            noSlider: 'Ningún evento actualmente',
            location: 'Dirección',
            notSpecified: 'No especificado',
          },
          myEvents: {
            title: 'Mis eventos',
            todayEvents: 'Actualmente',
            futurEvents: 'Próximamente',
            pastEvents: 'Ya aprobado',
            scanButton1: 'Escanear',
            scanButton2: 'un billete',
          },
          search: {
            week: 'Esta semana',
            nextWeek: 'Próxima semana',
            month: 'Este mes',
            nextMonth: 'Próximo mes',
            allDates: 'Fecha(todas)',
            maxRange: 'Muy muy lejos',
            allSports: 'Todos los deportes',
            noLocation: 'Cuidad(todas)',
          },
          events: {
            fullPlaces: 'Completo',
            qrCode: 'Ver mi entrada',
            host: 'Organizador',
            description: 'Descripción',
            equipments: 'Equipo',
            minimumLevel: 'nivel mínimo',
            registers: 'Asistentes',
            noRegisters: '¡Sé el primero en registrarte!',
            free: 'Libre',
            unsubscribe: 'Desinscribirse',
            subscribe: 'Inscribirse',
            subscribed: 'Registrado',
            delete: 'Eliminar evento',
            successRegister: 'Su registro está registrado',
            successUnregister: 'Tu baja está guardada',
            errors: {
              close: 'El registro para este evento esta cerrado.',
              started: 'Este evento ya comenzó',
              passed: 'Cet événement est déjà passé',
            },
          },
          cgu: {
            title1:
              'Términos y condiciones de uso del sitio web “JUTSU EVENTS”',
            text1:
              'Los términos y condiciones enunciados a continuación explican en detalle cómo interactúa con el sitio web, cuáles son sus responsabilidades mientras visita el sitio web, ciertos términos y condiciones en base a los cuales se le brindan los servicios, etc. Se le recomienda leer el siguiente en detalle y con toda atención ya que estos términos y condiciones crean una relación contractual entre el sitio web y usted, estableciendo ciertas responsabilidades para ambas partes, es decir, el sitio web y el usuario. La violación de estos términos y condiciones de uso del sitio web puede tener ciertas repercusiones para el usuario, incluido, entre otros, el bloqueo de la cuenta de usuario, etc. También se le recomienda en su mejor interés cumplir con estos términos y condiciones en para poder disfrutar de cualquier servicio sin interrupciones a través del sitio web. Estos términos y condiciones pueden actualizarse de vez en cuando y se notificarán a los usuarios.',
            title2: 'JUTSU EVENTS.',
            text2:
              'JUTSU EVENTS como plataforma garantiza que usted y otros miembros y/o usuarios de la plataforma organicen grupos y eventos fuera de línea y/o del mundo real. Hacer uso de los servicios de la plataforma y/o incluso navegar a través de la Plataforma implica que usted reconoce estos términos y condiciones de uso de los servicios.',
            title3: 'Uso del sitio web por parte del usuario.',
            text3:
              "Es un requisito necesario seguir las políticas y lineamientos del sitio web al momento de utilizar nuestra Plataforma. No tenemos ninguna responsabilidad por el Contenido que alguien publique en nuestra Plataforma. Usted comprende y asume la responsabilidad de cumplir y aceptar todas las leyes, normas y reglamentos aplicables, y no viola ni infringe los derechos de ningún tercero. Además, su acceso y uso de algunas áreas o características del sitio web pueden estar sujetos a términos, políticas, estándares o pautas adicionales ('Términos adicionales'). Es posible que deba aceptar estos Términos adicionales antes de poder acceder a estas áreas y funciones de la Plataforma.",
            title4: 'Cuenta de usuario.',
            subTitle1: 'Derecho.',
            subText1:
              'El sitio web solo está disponible para personas mayores de 18 años. Su uso del sitio web garantiza que ha alcanzado la edad legal para usar el sitio web. Un usuario solo puede ser elegible para obtener acceso a todas las funciones del sitio web al crear una cuenta registrada en el sitio web. Una persona y/o usuario que tenga la capacidad de moderar y/o administrar una parte particular del sitio web también puede, a su exclusivo criterio, alterar, suspender o poner fin a su acceso a la parte específica de la Plataforma.',
            subTitle2: 'Credenciales y confidencialidad de la cuenta.',
            subText2:
              'Al registrar su cuenta en la plataforma, acepta proporcionarnos la información necesaria, que incluye, entre otros, una dirección de correo electrónico válida y una contraseña. Usted comprende que es importante y de su exclusiva responsabilidad mantener intacta la privacidad y confidencialidad de sus contraseñas, de lo contrario, su cuenta puede ser deshabilitada.',
            title5: 'Emolumentos para el Sitio Web.',
            subTitle3: 'Tarifa.',
            subText3:
              'Certain features on the website are free of cost. However, there are paid services also on the website for using the same you have to pay certain fees through the designated payment service providers and authorized persons only. You represent and warrant that you are authorized to use the payment method you designate via the Platform. You permit us (and our designated third-party payment processors) to charge your designated payment method for the total amount of any fees you owe to the JUTSU EVENTS, including any associated applicable taxes and other charges.',
            subTitle4: 'Renovaciones.',
            subText4:
              'El sitio web facturará por su cuenta al usuario por cada período de renovación hasta la cancelación. Al comprar cualquier función u otro aspecto de nuestra Plataforma por el que cobramos, nos autoriza a mantener su pago al día cargando la tarifa correspondiente a su cuenta de tarjeta de crédito (o cualquier otro medio de pago que utilice).',
            title6: 'DATOS del Usuario y Privacidad.',
            text6:
              'El usuario es el único responsable de proporcionar contenido al sitio web y el contenido del usuario se tratará de acuerdo con la política de privacidad de JUTSU EVENTS.',
            title7: 'Propiedad intelectual',
            text7:
              'Las marcas comerciales, los logotipos, las marcas de servicio y los nombres de servicio de JUTSU son propiedad intelectual de JUTSU. El usuario no puede usar sin autorización ninguna propiedad intelectual, incluidas, entre otras, patentes, marcas comerciales, diseños, derechos de autor, secretos comerciales, etc. del sitio web y/o cualquier otro tercero que haya publicado la misma en el sitio web. El sitio web, a su exclusivo criterio, puede, ante la sospecha de que el material publicado en el sitio web por el usuario infringe los derechos de otra persona, eliminar o desactivar el mismo.',
            title8: 'Indemnización',
            text8:
              'El usuario del sitio web se compromete a mantener el sitio web indemnizado en todas las circunstancias, especialmente cuando la plataforma está siendo demandada por alguien debido a su uso del sitio web. En la máxima medida permitida por la ley aplicable, usted acepta indemnizar, defender y eximir de responsabilidad a todas las Partes de JUTSU de cualquier Reclamación, realizada por un tercero debido a o que surja de (a) sus violaciones de este Acuerdo, (b) su uso , mal uso o abuso de nuestra Plataforma, (c) su Contenido, (d) su violación de cualquier ley, estatuto, ordenanza o regulación o los derechos de un tercero, o (e) su participación o conducta en un grupo JUTSU o un evento JUTSU que viole este Acuerdo.',
            title9: 'Resolución de conflictos',
            text9:
              'Si el usuario tiene una disputa relacionada con el sitio web, el usuario comprende y acepta que la forma más eficaz de resolverla es ponerse en contacto con el representante autorizado del sitio web designado en su nombre. Si las quejas del usuario no se resuelven, el usuario puede presentarlas a un árbitro neutral en lugar de llevar el reclamo a un tribunal de justicia. Las quejas contra el sitio web solo pueden presentarse individualmente y no como parte de una demanda colectiva. El sitio web no tiene la obligación de involucrarse en ninguna disputa que pueda tener con otros miembros, aunque podemos intentar facilitar una resolución.',
            title10: 'Términos generales',
            subTitle101: 'Idioma.',
            text101:
              'Estos términos y condiciones están escritos en inglés y, posteriormente, pueden traducirse a otros idiomas. Si hay alguna inconsistencia entre la versión en inglés y una versión traducida, prevalecerá la versión en inglés.',
            subTitle102: 'Sin agencia.',
            text102:
              'Este Acuerdo no pretende ni crea ninguna relación de agencia, sociedad, empresa conjunta, empleado-empleador o franquiciador-franquiciado entre usted y JUTSU.',
            subTitle103: 'Ley que rige.',
            text103:
              'Este Acuerdo y la relación entre usted y JUTSU se regirán por las leyes pertinentes del país sin tener en cuenta sus disposiciones sobre conflictos de leyes.',
            subTitle104: 'No renuncio.',
            text104:
              'El hecho de que el usuario no ejerza o haga cumplir cualquier derecho o disposición de este Acuerdo no constituirá una renuncia a tal derecho o disposición y no renuncia a ningún derecho de actuar con respecto a incumplimientos posteriores o similares.',
            subTitle105: 'Renuncia de garantías.',
            text105:
              "SU USO DEL SITIO, SU CONTENIDO Y CUALQUIER SERVICIO DISPONIBLE A TRAVÉS DEL SITIO WEB ES BAJO SU PROPIO RIESGO. EL SITIO, SU CONTENIDO Y CUALQUIER SERVICIO DISPONIBLE A TRAVÉS DEL SITIO WEB SE PROPORCIONAN 'TAL CUAL' Y 'SEGÚN DISPONIBILIDAD', SIN NINGUNA GARANTÍA DE NINGÚN TIPO, YA SEA EXPLÍCITA O IMPLÍCITA. NI JUTSU NI NINGUNA PERSONA ASOCIADA CON EL SITIO WEB OFRECE NINGUNA GARANTÍA O DECLARACIÓN CON RESPECTO A LA INTEGRIDAD, SEGURIDAD, FIABILIDAD, CALIDAD, EXACTITUD O DISPONIBILIDAD DEL SITIO. LO ANTERIOR NO AFECTA LAS GARANTÍAS QUE NO PUEDEN SER EXCLUIDAS O LIMITADAS BAJO LA LEY APLICABLE.",
            subTitle106: 'Limitación de responsabilidad.',
            text106:
              'EN NINGÚN CASO JUTSU, SUS AFILIADOS O SUS LICENCIATARIOS, PROVEEDORES DE SERVICIOS, EMPLEADOS, AGENTES, FUNCIONARIOS O DIRECTORES SERÁN RESPONSABLES POR DAÑOS DE NINGÚN TIPO, BAJO CUALQUIER TEORÍA LEGAL, QUE SURJAN DE O EN CONEXIÓN CON SU USO O INCAPACIDAD DE USO, EL SITIO, CUALQUIER SITIO WEB VINCULADO A ÉL, CUALQUIER CONTENIDO DEL SITIO WEB U OTROS SITIOS WEB O CUALQUIER SERVICIO DISPONIBLE A TRAVÉS DEL SITIO U OTROS SITIOS WEB, INCLUYENDO CUALQUIER DAÑO DIRECTO, INDIRECTO, ESPECIAL, INCIDENTAL, CONSECUENTE O PUNITIVO, INCLUYENDO PERO NO LIMITADO A , LESIONES PERSONALES, DOLOR Y SUFRIMIENTO, ANGUSTIA EMOCIONAL, PÉRDIDA DE INGRESOS, PÉRDIDA DE BENEFICIOS, PÉRDIDA DE NEGOCIOS O AHORROS ESPERADOS, PÉRDIDA DE USO, PÉRDIDA DE FONDO DE COMERCIO, PÉRDIDA DE DATOS Y YA SEA CAUSADA POR AGRAVIO (INCLUIDA NEGLIGENCIA), INCUMPLIMIENTO DE CONTRATO O DE OTRO MODO, AUNQUE PREVISIBLE.',
          },
          confidential: {
            title1: 'Política de privacidad de Jutsu Events.',
            text1:
              'Los siguientes términos resumen la política de privacidad de Jutsu Event, 4 route de las illas, 66480 Maureillas Las Illas, Francia. El mismo analiza en detalle cómo Jutsu Event recopilará, utilizará, difundirá, etc. la información recopilada del usuario mientras navega por el sitio web. Las expresiones como JUTSU EVENT, JUTSU, nosotros y nuestro connotan el sitio web, y las expresiones como usuario, usted, su miembro, connotan a cada persona que usa y/o navega por el sitio web o la plataforma. Esta política de privacidad tiene como objetivo filtrar la información recopilada de los usuarios mientras navegan por el sitio web. La información que comparte con terceros en el sitio web no entra dentro del alcance de esta política de privacidad. Se le recomienda en su mejor interés que lea esta política de privacidad detenidamente para saber cómo se recopila, almacena y utiliza su información personal a través del sitio web.',
            title2: 'Instancias de recopilación de información.',
            text2:
              'La información personal sobre usted se recopila cuando crea una cuenta en el sitio web y/o utiliza la plataforma y/o asiste a cualquier evento organizado y/o administrado a través del sitio web, visita el sitio web y/o se comunica con el sitio web. Con la ayuda de cookies y otras tecnologías relacionadas, también se recopila información como estadísticas de uso. Si opta por usar cierta información de otras funciones a través de otras fuentes, como servicios de redes sociales, etc.',
            title3: 'Información proporcionada por el usuario.',
            text3:
              'Parte de la información, incluida, entre otras, la información sobre la creación de una cuenta en el sitio web, la preferencia de un interés y/o la información recopilada durante la correspondencia entre el sitio web y usted, se recopila directamente en el sitio web y se considera información proporcionada por el usuario. La información proporcionada por el usuario puede clasificarse como identificadores, como el nombre del usuario, el nombre de usuario del usuario en el sitio web, el código de seguridad del usuario, la dirección de correo electrónico única del usuario, la dirección postal con el fin de determinar la ubicación del usuario y el número de contacto del usuario, información comercial y de pago, información demográfica y/o cualquier otra información personal que elija proporcionar, incluidos, entre otros, fotos, grupos, incluido su cargo, empresa y años de experiencia. También es opcional proporcionar información como qué grupo te gusta más, pero para filtrar esta opción, esta información también es necesaria para cuidar tu cuenta y para marcar que eres miembro de un grupo al que te unes. En ciertos casos, la información proporcionada por usted que connota sus creencias, puntos de vista políticos, su estado de salud y su identidad sexual recibe protección especial en virtud de las leyes aplicables pertinentes.',
            title4:
              'Datos recopilados automáticamente y/o requeridos explícitamente por el sitio web.',
            text4:
              'Mientras el usuario navega por el sitio web, se recopila automáticamente cierta información sobre la conexión a Internet del usuario y/u otra información sobre la actividad de la red electrónica. El sitio web también recopila automáticamente información como por qué medio accede o desde dónde accede al sitio web, incluido, entre otros, el navegador que utiliza, la hora, duración y frecuencia de su acceso al sitio web, actividad en el sitio web, Dirección IP, la página que ha visitado antes de dirigir al sitio web, el ordenador y/o dispositivo móvil utilizado por el usuario para acceder al sitio web, el modelo de hardware del mismo, el sistema operativo y la versión que se está utilizando en el dispositivo a través del cual se tiene el sitio web se ha accedido, información de la red móvil si la navegación se está realizando a través del teléfono móvil, localidad de la zona en la que se está utilizando el dispositivo para acceder al sitio web y esta información se recopila únicamente con el consentimiento explícito, que usted otorga al encender el dispositivo la ubicación que usa, de usted en este nombre, la información recopilada a través de las cookies según la política de cookies y la información basada en el consentimiento, como acceder a las fotos de su carrete de cámara y/o información sobre el calendario que desea administrar a través del sitio web puede también ser recogido.',
            title5: 'Información recopilada a través de fuentes de terceros.',
            text5:
              'Cierta información sobre usted puede ser recopilada a través de terceros. Se informa que su información recopilada a través de estas fuentes de terceros está estrictamente sujeta a las políticas de privacidad de estas partes. Si un usuario se registra o inicia sesión en la cuenta utilizando un canal de terceros, la plataforma tendrá acceso a la información de su cuenta mantenida por ese proveedor de servicios de terceros, incluidos, entre otros, identificadores, como su nombre y otra información en esa cuenta, y la misma puede usarse con el fin de hacer que las recomendaciones en la plataforma sean las más relevantes para usted.',
            title6: 'Motivo(s) de la recopilación de información.',
            text61:
              'La información obtenida como se describe anteriormente se puede utilizar con el fin de incluir, entre otros, poner a disposición, continuar y mejorar el sitio web, procesar transacciones, desarrollar nuevos productos y servicios, administrar el funcionamiento del sitio web, mostrar información sobre usted, por ejemplo, su lista de intereses, que será visible en su perfil, personalizar el sitio web para su mejor experiencia, por ejemplo, recomendándole el contenido más relevante, permitirle difundir opiniones sobre su experiencia en el sitio web a través de servicios de redes sociales de terceros, para monitorear y analizar movimientos, uso y actividades en relación con nuestro sitio web, detectar, investigar y prevenir transacciones torcidas y/o sospechosas, abuso y otras actividades ilegales, proteger los derechos, pertenencias o seguridad del sitio web y otros, exigir el cumplimiento de las políticas del sitio web, cumplir con la ley aplicable y las solicitudes del gobierno, realizar tareas financieras y administrativas, y hacer cumplir o gestionar reclamaciones legales.',
            text62:
              'La información recopilada de usted como se describe anteriormente también se puede utilizar con el fin de comunicarnos con usted de manera que se responda de manera adecuada y justa a sus consultas, consultas y apelaciones, y se brinde servicio al cliente. También podemos usar la información para comunicarnos con usted, según las preferencias que establezca, sobre productos, servicios y eventos ofrecidos por nosotros y otros, y para actualizarlo con noticias e información que, según nuestro análisis, depende de las cookies. será de su interés, para realizar valoraciones en línea, y para remitirle los avisos técnicos, actualizaciones, alertas de seguridad y mensajes de soporte y administrativos necesarios para el efectivo funcionamiento del sitio web.',
            text63:
              'Los anuncios y los materiales promocionales son necesarios para generar ingresos a fin de que los asuntos financieros y el funcionamiento del sitio web sean lo más fluidos posible. Para los fines, no vendemos su información a otras partes, pero le mostramos el contacto más relevante que coincida con sus intereses, características o patrocinios, reenviarle comunicaciones de marketing, sujeto a la ley aplicable.',
            title7: 'Divulgación de la información recopilada.',
            text7:
              'La información recopilada no se vende y/o se intercambia y/o se transfiere a terceros la información de identificación personal del usuario a menos que el sitio web proporcione al usuario un aviso previo, excepto como se describe a continuación. Tampoco incluye a los socios de alojamiento de sitios web y otras partes que ayuden a operar el sitio web, llevar a cabo los negocios del sitio web o prestarle servicios, siempre que esas partes acuerden mantener esta información confidencial. También podemos divulgar su información cuando creamos que dicha divulgación es apropiada para cumplir con la ley, las políticas de nuestro sitio web o proteger los derechos, la propiedad o la seguridad nuestros o de otros. Sin embargo, la información del visitante no identificable personalmente se puede proporcionar a otras partes para marketing, publicidad u otros usos.',
            title8: 'Seguridad de la información recabada del usuario.',
            text8:
              'Implementamos una serie de controles de seguridad y/o técnicas de encriptación para garantizar la protección y seguridad de la información recopilada de usted mientras visita el sitio web. La información recopilada en su nombre se mantiene bajo estrictas redes seguras y solo es accesible para un número limitado de personas autorizadas que tienen derechos especiales de acceso a dichos sistemas y que se comprometen a mantener la confidencialidad de la información a la que acceden.',
          },
          help: {
            menu: {
              faq: 'FAQ',
              contact: 'Contacto',
              report: 'Informar',
              about: 'Acerca',
            },
            faq: {
              mainTitle:
                'FAQ de JutsuEvent - Plataforma de eventos de artes marciales',
              title1: '1. ¿Qué es JutsuEvent?',
              text1:
                'JutsuEvent es una plataforma en línea dedicada a la promoción y organización de eventos de artes marciales. Ofrecemos un espacio centralizado donde los organizadores pueden crear, gestionar y promocionar sus eventos, y donde los participantes pueden descubrir e inscribirse en competiciones, seminarios, talleres y otros eventos relacionados con las artes marciales.',
              title2: '2. ¿Cómo crear una cuenta en JutsuEvent?',
              text2:
                "Para crear una cuenta, haga clic en 'Iniciar sesión' en la parte superior derecha de la página de inicio. Luego haga clic en 'Registrarse' en la parte superior derecha de la página de inicio de sesión. Complete el formulario con su información personal y haga clic en 'Registrarse'.",
              title3: '3. ¿Cómo agregar un club en JutsuEvent?',
              text3:
                "Para agregar un club, inicie sesión en su cuenta y haga clic en 'Cuenta' en el menú desplegable en la parte superior derecha de la página de inicio. Luego haga clic en 'Club' en la parte superior izquierda, tómese el tiempo para leer nuestros Términos y condiciones y haga clic en 'Crear un nuevo club'. Complete la información requerida y haga clic en 'Guardar' para guardar su club.",
              title4: '4. ¿Cómo registrar mi club en Stripe?',
              text4:
                "Para permitirle crear eventos de pago, es necesario que se registre en Stripe, nuestro proveedor de pagos. Para hacerlo, inicie sesión en su cuenta con un club y haga clic en 'Cuenta' en el menú principal, luego en 'Opciones'. Desde esta pestaña, haga clic en 'Registrarse' y siga las instrucciones de la plataforma Stripe.",
              title5: '5. ¿Cómo agregar un evento en JutsuEvent?',
              text5:
                "Para agregar un evento, inicie sesión en su cuenta con un club y haga clic en 'Crear evento' en el menú principal. Complete la información requerida, como el nombre del evento, la fecha, la hora, el lugar y una descripción detallada. También puede agregar imágenes y enlaces para ayudar a promocionar su evento. Tenga en cuenta que para cualquier evento de pago, debe registrar previamente su información bancaria en Stripe desde la pestaña 'Opciones' de su 'Cuenta'.",
              title6: '6. ¿Cómo inscribirse en un evento en JutsuEvent?',
              text6:
                "Para inscribirse en un evento, busque el evento que le interesa utilizando los filtros de búsqueda o navegando por los eventos de un club. Una vez en la página del evento, haga clic en 'Registrarse' y siga las instrucciones para completar su inscripción.",
              title7:
                '7. ¿Cómo pagar las tarifas de inscripción para un evento?',
              text7:
                'Las tarifas de inscripción se pueden pagar en línea a través de nuestro sistema de pago seguro. Aceptamos la mayoría de las tarjetas de crédito y débito, así como algunos métodos de pagoen línea.',
              title8:
                '8. ¿Cómo cancelar o modificar mi inscripción en un evento?',
              text8:
                "En caso de un evento gratuito, haga clic en el evento en cuestión y seleccione 'Cancelar inscripción'. Para cancelar o modificar su inscripción en un evento de pago, comuníquese con el club organizador y consulte con ellos sus políticas de reembolso. Tenga en cuenta que pueden aplicarse cargos de cancelación o modificación según las condiciones del evento.",
              title9: '9. ¿Cómo puedo contactar al organizador de un evento?',
              text9:
                'En la página del evento, una vez conectado encontrará los datos de contacto del organizador. También puede utilizar el formulario de contacto proporcionado en la página del evento para enviar un mensaje directamente al organizador.',
              title10:
                '10. ¿JutsuEvent está disponible para usuarios internacionales?',
              text10:
                'Sí, JutsuEvent está disponible para usuarios de toda Europa. Sin embargo, tenga en cuenta que algunos eventos pueden estar limitados a ciertas regiones o países debido a restricciones legales o políticas específicas del organizador.',
              title11: '11. ¿JutsuEvent ofrece una aplicación móvil?',
              text11:
                'Por el momento, JutsuEvent no cuenta con una aplicación móvil dedicada. Sin embargo, nuestro sitio web está completamente optimizado para dispositivos móviles, lo que le permite navegar, crear y gestionar eventos e inscribirse en eventos de artes marciales desde su teléfono inteligente o tableta.',
              title12:
                '12. ¿Cómo puedo obtener ayuda o informar un problema en JutsuEvent?',
              text12:
                "Si necesita ayuda o desea informar un problema, consulte nuestra sección 'Ayuda' en la parte inferior de la página de inicio. Allí encontrará artículos de ayuda y guías para resolver problemas comunes. Si no encuentra la respuesta a su pregunta, puede contactarnos directamente utilizando el formulario de contacto disponible en esta misma sección.",
              title13:
                '13. ¿Cómo puedo mantenerme informado sobre nuevos eventos y noticias en JutsuEvent?',
              text13:
                'Para recibir las últimas noticias y actualizaciones sobre eventos puede seguirnos en las redes sociales, como Facebook, Twitter e Instagram, para estar informado de las últimas noticias y eventos.',
              title14: '14. ¿Cómo puedo eliminar mi cuenta de JutsuEvent?',
              text14:
                "Si desea eliminar su cuenta de JutsuEvent de forma permanente, haga clic en 'Cuenta' en el menú en la parte superior de la página, luego seleccione la pestaña 'Opciones'. Desde aquí, podrá seleccionar la eliminación de su cuenta de usuario o solo de su club si tiene uno.",
            },
            contact: {
              title1: 'Contáctanos',
              text1:
                'En JutsuEvent, nos comprometemos a brindar un servicio de calidad y una experiencia excepcional para nuestros usuarios. Si tiene preguntas, inquietudes o sugerencias sobre nuestra plataforma, no dude en ponerse en contacto con nosotros.',
              text2:
                'Puede completar el formulario de contacto que se encuentra abajo, y uno de nuestros representantes de atención al cliente le responderá lo antes posible. Si lo prefiere, también puede enviarnos un correo electrónico directamente a ',
              text2Email: 'contact@jutsuevent.com.',
              text3:
                'Para mantenerse informado sobre las últimas noticias y eventos, síganos en nuestras redes sociales.',
              text4:
                '¡Apreciamos sus comentarios y esperamos ayudarlo en sus aventuras de artes marciales con JutsuEvent!',
            },
            report: {
              title1: '¿Necesitas ayuda?',
              text1:
                'Envíe sus preguntas y otras solicitudes por correo electrónico a contact@jutsuevent.com',
              title2: 'Informar de un error',
              text21:
                'Este es el procedimiento ideal para informar de un error o de un comportamiento que le parezca anormal o molesto al utilizar el sitio.',
              text22: 'Enviar por correo electrónico a contact@jutsuevent.com',
              title3: 'Desde tu teléfono :',
              text31:
                'Una impresión del fallo y una explicación lo más detallada posible de las acciones que lo provocaron.',
              text32:
                'Ex: Después de inscribirme en un evento, no se muestran las imágenes de los participantes en el mismo.',
              title4: 'Desde tu ordenador :',
              text41:
                'una impresión de pantalla del fallo y una explicación lo más detallada posible de las acciones que lo provocaron.',
              text42:
                'una impresión de pantalla de su inspector de datos (haga clic con el botón derecho en "inspeccionar") con la pestaña de la consola abierta en el error o errores.',
              text43: `una pantalla de impresión de su inspector de datos (botón derecho del ratón 'inspeccionar') con la pestaña del inspector "Red" abierta en la parte Fetch/XHR, si es posible con los detalles del error de consulta.`,
            },
            about: {
              title1: 'Acerca de nosotros',
              text1:
                'JutsuEvent es una plataforma innovadora dedicada a los entusiastas de las artes marciales y deportes de combate. Nuestra misión es promover y celebrar estas disciplinas ofreciendo una plataforma en línea donde organizadores y participantes pueden encontrar, crear e inscribirse fácilmente en eventos relacionados con artes marciales y deportes de combate.',
              text2:
                "El nombre 'JutsuEvent' se inspira en la palabra japonesa 'jutsu' (術), que significa 'técnica' o 'arte' en referencia a las habilidades específicas desarrolladas en las artes marciales. Al combinar 'jutsu' con 'event', nuestro objetivo es destacar la importancia del intercambio en el aprendizaje de estas disciplinas, a la vez que creamos un espacio comunitario para aficionados y profesionales.",
              text3:
                'En nuestra plataforma, encontrarás una gran variedad de eventos, como competiciones, seminarios, cursos y talleres, que abarcan un amplio abanico de disciplinas como el judo, el karate, el jiu-jitsu brasileño, el boxeo, el muay thai y muchos más. Deseamos ofrecer una experiencia completa para nuestros usuarios, facilitando el descubrimiento y la participación en eventos locales, nacionales e internacionales.',
              text4:
                'JutsuEvent está dirigido a todos los niveles, desde principiantes hasta expertos, y fomenta el intercambio de conocimientos y el progreso en las artes marciales y deportes de combate. Creemos que estas disciplinas ofrecen no solo beneficios físicos, sino también beneficios mentales y emocionales, inculcando valores como el respeto, la disciplina y la perseverancia.',
              text5:
                'Únete a nosotros hoy y descubre cómo JutsuEvent puede ayudarte a explorar y prosperar en el fascinante mundo de las artes marciales y deportes de combate. Juntos, construiremos una comunidad unida, comprometida y apasionada, celebrando y compartiendo nuestro amor por estas disciplinas extraordinarias.',
            },
          },
        },
      },
    },
  })

export default i18n
