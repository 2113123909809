/** Import Externals */
import React from "react";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */

/** Import Context */

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    background: theme.palette.common.white,
    margin: "10px auto",
    borderRadius: "10px",
    padding: "20px 0",
  },
  title: {
    fontWeight:600,
    fontSize:"1.3rem",
    textAlign:"center",
    margin:"20px 0",
  },
  text:{
    width: "80%",
    margin: "0 auto",
  },
});

function ConfText() {
  const styles = useStyles();

  const { t } = useTranslation();

  return (
    <div className={styles.container}>
        <div className={styles.title}>
            {t("confidential.title1")}
        </div>
        <div className={styles.text}>
            {t("confidential.text1")}
        </div>
        <div className={styles.title}>
            {t("confidential.title2")}
        </div>
        <div className={styles.text}>
            {t("confidential.text2")}
        </div>
        <div className={styles.title}>
            {t("confidential.title3")}
        </div>
        <div className={styles.text}>
            {t("confidential.text3")}
        </div>
        <div className={styles.title}>
            {t("confidential.title4")}
        </div>
        <div className={styles.text}>
            {t("confidential.text4")}
        </div>
        <div className={styles.title}>
            {t("confidential.title5")}
        </div>
        <div className={styles.text}>
            {t("confidential.text5")}
        </div>
        <div className={styles.title}>
            {t("confidential.title6")}
        </div>
        <div className={styles.text}>
            {t("confidential.text61")}
        </div>
        <div className={styles.text}>
            {t("confidential.text62")}
        </div>
        <div className={styles.text}>
            {t("confidential.text63")}
        </div>
        <div className={styles.title}>
            {t("confidential.title7")}
        </div>
        <div className={styles.text}>
            {t("confidential.text7")}
        </div>
        <div className={styles.title}>
            {t("confidential.title8")}
        </div>
        <div className={styles.text}>
            {t("confidential.text8")}
        </div>
    </div>
  );
}

export default ConfText;
