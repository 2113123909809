/** Import Externals */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */
import Loader from "assets/svg/loader";

/** Import Context */

/** Import API */
import { FormLogIn } from "data/api/connectionQuerys";

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
    width: "70%",
    cursor: "default",
    marginTop: "5vh",
  },
  inputContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  inputLabel: {
    marginBottom: "1vh",
  },
  inputText: {
    border: "1px solid",
    borderRadius: "10px",
    borderColor: theme.palette.common.lightGrey,
    fontSize: "16px",
    height: "35px",
  },
  line: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    margin: "2vH 0",
  },
  forgot: {
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    width: "100%",
    margin: "1vW 0",
  },
  logInButton: {
    width: "100%",
    textAlign: "center",
    color: theme.palette.common.green,
    fontWeight: 600,
    border: "2px solid",
    borderRadius: "10px",
    borderColor: theme.palette.common.green,
    padding: ".5vW",
    cursor: "pointer",
  },
  error: {
    color: theme.palette.common.red,
  },
});

function SignInForm() {
  const styles = useStyles();

  let history = useHistory();

  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleForgotPassword = () => {
    history.push("/forgot");
  };

  const handleLogin = () => {
    setLoading(true);
    FormLogIn(email, password)
      .then((res) => {
        const {
          xsrfToken,
          accessToken,
          accessTokenExpiresIn,
          refreshToken,
          refreshTokenExpiresIn,
        } = res.data;
        localStorage.setItem("xsrfToken", xsrfToken);
        document.cookie = `accessToken=${accessToken};${accessTokenExpiresIn};path=/`;
        document.cookie = `refreshToken=${refreshToken};${refreshTokenExpiresIn};path=/`;
        history.push(`/`);
      })
      .catch(() => {
        setLoading(false);
        setError(t("login.errors.login"));
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <label className={styles.inputLabel}>{t("login.email")}</label>
        <input
          className={styles.inputText}
          type="email"
          value={email}
          onChange={(e) => handleChangeEmail(e)}
        />
      </div>
      <div className={styles.inputContainer}>
        <label className={styles.inputLabel}>{t("login.password")}</label>
        <input
          className={styles.inputText}
          type="password"
          value={password}
          onChange={(e) => handleChangePassword(e)}
        />
      </div>
      <div className={styles.line}>
        <div className={styles.forgot} onClick={handleForgotPassword}>
          {t("login.forgotPassword")}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.logInButton} onClick={handleLogin}>
          {loading ? <Loader /> : t("login.login")}
        </div>
      </div>
      <div className={styles.statusContainer}>
        {error ? <div className={styles.error}>{error}</div> : null}
      </div>
    </div>
  );
}

export default SignInForm;
