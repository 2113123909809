/** Import Externals */
import axios from "axios";

/** Import Utils */

export const LaunchQuery = async (url, method, object) => {
  const token = localStorage.getItem("xsrfToken");
  const cookies = document.cookie;
  let returnQuery;

  if (method === "post") {
    try {
      returnQuery = await axios.post(url, object, {
        headers: {
          Authorization: `${token}`,
          Cookies: cookies,
        },
      });
    } catch (error) {
      if (error.response) {
        if (error.response.status !== 401) {
          throw error;
        } else {
          if (error.response.data.message === "Refreshing accessToken") {
            const { xsrfToken, accessToken, accessTokenExpiresIn } =
              error.response.data.token;
            localStorage.setItem("xsrfToken", xsrfToken);
            document.cookie = `accessToken=${accessToken};${accessTokenExpiresIn};path=/`;
            return LaunchQuery(url, method, object);
          } else {
            document.cookie =
              "accessToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie =
              "refreshToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            localStorage.removeItem("xsrfToken");
          }
        }
      } else {
        console.log("Server offline");
      }
    }
  } else {
    try {
      returnQuery = await axios.get(url, {
        params: object,
        headers: {
          Authorization: `${token}`,
          Cookies: cookies,
        },
      });
    } catch (error) {
      if (error.response) {
        if (error.response.status !== 401) {
          throw error;
        } else {
          if (error.response.data.message === "Refreshing accessToken") {
            const { xsrfToken, accessToken, accessTokenExpiresIn } =
              error.response.data.token;
            localStorage.setItem("xsrfToken", xsrfToken);
            document.cookie = `accessToken=${accessToken};${accessTokenExpiresIn};path=/`;
            return LaunchQuery(url, "get", object);
          } else {
            document.cookie =
              "accessToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            document.cookie =
              "refreshToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            localStorage.removeItem("xsrfToken");
          }
        }
      } else {
        console.log("Server offline");
      }
    }
  }
  return returnQuery;
};
