/** Import Externals */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */
import backgroundVideo from "assets/videos/homeVideo.mp4";

/** Import Context */
import { UserContext } from "data/context/userContext";
import { GlobalContext } from "data/context/globalContext";

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "60vh",
    textAlign: "center",
    background: theme.palette.common.black,
    zIndex:0,
    [theme.breakpoints.down("md")]: {
      height: "100vw",
    },
  },
  video: {
    position: "absolute",
    top: 0,
    zIndex:-2,
    width: "100%",
    height: "60vh",
    objectFit: "cover",
    opacity: 0.7,
    [theme.breakpoints.down("md")]: {
      height: "100vw",
    },
  },
  title: {
    color: theme.palette.common.white,
    fontWeight: 800,
    fontSize: "3rem",
    marginTop:"5vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
  },
  subTitle: {
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: "2rem",
    marginTop:"5vh",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
      marginTop:"20px",
    },
  },
  buttonContainer: {
    display: "flex",
    width: "80%",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  button: {
    fontSize: "2rem",
    fontWeight: 600,
    color: theme.palette.common.white,
    background: theme.palette.common.green,
    borderRadius: "10px",
    padding: "1vW",
    cursor: "pointer",
    transitionDuration: "500ms",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      padding:"5px",
      margin:"5px",
    },
  },
});

function TopVideo() {
  const styles = useStyles();
  const { t } = useTranslation();
  let history = useHistory();

  const { user } = useContext(UserContext);
  const { setGlobalContext} = useContext(GlobalContext);

  const handleGoCreateClub = () => {
    setGlobalContext({ accountPage: "club" });
    history.push(`/account`);
  };

  return (
    <div className={styles.container}>
      <video autoPlay playsInline loop muted className={styles.video}>
        <source src={backgroundVideo} type="video/mp4"></source>
      </video>
      <div className={styles.textContainer}>
        <div className={styles.title}>{t("home.topVideo.text1")}</div>
        <div className={styles.subTitle}>{t("home.topVideo.text2")}</div>
      </div>
      {localStorage.getItem("xsrfToken") ? (
        <div className={styles.buttonContainer}>
          <div
            className={styles.button}
            onClick={() => {
              history.push(`/events`);
            }}
          >
            {t("home.topVideo.letsGo")}
          </div>
          {user.club_id ? (
            <div
              className={styles.button}
              onClick={() => {
                history.push(`/newevent`);
              }}
            >
              {t("home.topVideo.createEvent")}
            </div>
          ) : (
            <div className={styles.button} onClick={handleGoCreateClub}>
              {t("home.topVideo.createClub")}
            </div>
          )}
        </div>
      ) : (
        <div className={styles.buttonContainer}>
          <div
            className={styles.button}
            onClick={() => {
              history.push(`/login`);
            }}
          >
            {t("home.topVideo.signIn")}
          </div>
        </div>
      )}
    </div>
  );
}

export default TopVideo;
