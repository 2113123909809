/** Import Internals */
import { LaunchQuery } from "./queryLauncher";

/** Import Utils */

export const GetEvent = (event_id) => {
  const url = process.env.REACT_APP_GET_EVENT;

  return LaunchQuery(url, "get", { event_id: event_id });
};

export const GetNextEvents = () => {
  const url = process.env.REACT_APP_GET_NEXT_EVENTS;

  return LaunchQuery(url, "get", {});
};

export const GetMultiEvents = (sport, dateStart, dateEnd, lat, lng, range) => {
  const url = process.env.REACT_APP_GET_MULTI_EVENTS;

  return LaunchQuery(url, "get", {
    sport: sport,
    dateStart: dateStart,
    dateEnd: dateEnd,
    searchLat: lat,
    searchLng: lng,
    range: range,
  });
};

export const GetAllEventsUser = (eventsId) => {
  const url = process.env.REACT_APP_GET_ALL_EVENTS_USER;

  return LaunchQuery(url, "get", { eventsId: eventsId });
};

export const GetAllEventsClub = (clubId) => {
  const url = process.env.REACT_APP_GET_ALL_EVENTS_CLUB;

  return LaunchQuery(url, "get", { clubId: clubId });
};

export const CreateEvent = (
  name,
  address,
  zipCode,
  city,
  country,
  lat,
  lng,
  sport,
  start,
  end,
  places,
  price,
  equipments,
  description,
  images
) => {
  const url = process.env.REACT_APP_CREATE_EVENT;

  const postObject = {
    name: name,
    address: address,
    zipCode: zipCode,
    city: city,
    country: country,
    lat: lat,
    lng: lng,
    sport: sport,
    start: start,
    end: end,
    places: places,
    price: price,
    equipments: equipments,
    description: description,
    images: images,
  };

  return LaunchQuery(url, "post", postObject);
};

export const CreateMultipleEvents = (
  name,
  address,
  zipCode,
  city,
  country,
  lat,
  lng,
  sport,
  dates,
  start,
  end,
  places,
  price,
  equipments,
  description,
  images
) => {
  const url = process.env.REACT_APP_CREATE_MULTIPLE_EVENTS;

  const postObject = {
    name: name,
    address: address,
    zipCode: zipCode,
    city: city,
    country: country,
    lat: lat,
    lng: lng,
    sport: sport,
    dates: dates,
    start: start,
    end: end,
    places: places,
    price: price,
    equipments: equipments,
    description: description,
    images: images,
  };

  return LaunchQuery(url, "post", postObject);
};

export const SubscribeEvent = (event_id, language) => {
  const url = process.env.REACT_APP_SUBSCRIBE_EVENT;

  const postObject = {
    event_id: event_id,
    language: language,
  };

  return LaunchQuery(url, "post", postObject);
};

export const UnSubscribeEvent = (event_id, language) => {
  const url = process.env.REACT_APP_UNSUBSCRIBE_EVENT;

  const postObject = {
    event_id: event_id,
    language: language,
  };

  return LaunchQuery(url, "post", postObject);
};

export const DeleteEvent = (event_id, language) => {
  const url = process.env.REACT_APP_DELETE_EVENT;

  const postObject = {
    event_id: event_id,
    language:language
  };

  return LaunchQuery(url, "post", postObject);
};
