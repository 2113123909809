import React from "react";

const ScanInlay = (props) => {
  let size = props.size || 50;
  let color = props.color || "white";

  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path fill={color} d="M28.757 183.502V35.678a6.92 6.92 0 0 1 6.919-6.919h147.82c3.82 0 6.919 3.099 6.919 6.919s-3.099 6.919-6.919 6.919H42.595v140.905c0 3.82-3.099 6.919-6.919 6.919s-6.919-3.099-6.919-6.919zM328.505 42.597h140.896v140.905c0 3.82 3.099 6.919 6.919 6.919s6.919-3.099 6.919-6.919V35.678a6.92 6.92 0 0 0-6.919-6.919H328.505c-3.82 0-6.919 3.099-6.919 6.919s3.099 6.919 6.919 6.919zm-145.01 426.806h-140.9V328.507c0-3.82-3.099-6.919-6.919-6.919s-6.919 3.099-6.919 6.919v147.815a6.92 6.92 0 0 0 6.919 6.919h147.82c3.82 0 6.919-3.099 6.919-6.919s-3.1-6.919-6.92-6.919zM476.32 321.588a6.92 6.92 0 0 0-6.919 6.919v140.896H328.505c-3.82 0-6.919 3.099-6.919 6.919s3.099 6.919 6.919 6.919H476.32a6.92 6.92 0 0 0 6.919-6.919V328.507a6.92 6.92 0 0 0-6.919-6.919zm28.761-72.505H6.919c-3.82 0-6.919 3.099-6.919 6.919s3.099 6.919 6.919 6.919h498.162c3.82 0 6.919-3.099 6.919-6.919s-3.099-6.919-6.919-6.919z" />
    </svg>
  );
};

export default ScanInlay;