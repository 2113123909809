/** Import Internals */
import { LaunchQuery } from "./queryLauncher";

/** Import Utils */

export const GetCurrentUser = () => {
  const url = process.env.REACT_APP_GET_CURRENT_USER;

  return LaunchQuery(url,"get",{});
};

export const FetchMultiUsers = (users) => {
  const url = process.env.REACT_APP_MULTI_USER;

  const postObject = {
    users: users,
  };

  return LaunchQuery(url,"post",postObject);
};

export const GetOtherUser = (user_id) => {
  const url = process.env.REACT_APP_GET_OTHER_USER;

  const postObject = {
    user: user_id,
  };

  return LaunchQuery(url,"post",postObject);
};

export const ChangeProfileUser = (
  first_name,
  last_name,
  city,
  insta_link,
  description,
  sports,
  club_id
) => {
  const url = process.env.REACT_APP_CHANGE_PROFILE_USER;

  const postObject = {
    user_first_name: first_name,
    user_last_name: last_name,
    user_city: city,
    user_insta_link: insta_link,
    user_description: description,
    user_sports: sports,
    user_club_id: club_id,
  };

  return LaunchQuery(url,"post",postObject);
};

export const ChangeUserClubId = (clubId) => {
  const url = process.env.REACT_APP_CHANGE_USER_CLUB_ID;

  const postObject = {
    club_id: clubId,
  };

  return LaunchQuery(url,"post",postObject);
};

export const ChangeImageUser = (typePicture, filePicture) => {
  const url = process.env.REACT_APP_CHANGE_USER_IMAGE;

  const postObject = {
    type: typePicture,
    file: filePicture,
  };

  return LaunchQuery(url,"post",postObject);
};

export const DeleteUser = () => {
  const url = process.env.REACT_APP_DELETE_USER;

  return LaunchQuery(url,"post",{});
};
