/** Import Externals */
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

/** Import Internals */
import LogoJutsu from "assets/pngJpg/jutsuLogo.png";
import InstaLogo from "assets/svg/instaLogo";
import FacebookLogo from "assets/svg/facebookLogo";
import TwitterLogo from "assets/svg/twitterLogo";

/** Import Context */
import { UserContext } from "data/context/userContext";

/** Import API */

/** Import Utils */
import theme from "config/uiTheme";

const useStyles = makeStyles({
  container: {
    backgroundColor: theme.palette.common.darkPlusGrey,
  },
  topContainer: {
    display: "flex",
    color: theme.palette.common.darkWhite,
    margin: "1vw 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      margin: "1vw auto",
    },
  },
  imgLogo: {
    maxWidth: "150px",
    height: "100px",
    margin: "0 2vW",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      maxWidth: "50%",
      margin: "1vw auto",
    },
  },
  mapLinkSection: {
    display: "flex",
    color: theme.palette.common.darkWhite,
    margin: "1vw 0",
    [theme.breakpoints.down("md")]: {
      width: "max-content",
      margin: "0 auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      flexWrap:"wrap",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
    margin: "0 5vW",
    [theme.breakpoints.down("xs")]: {
      width: "max-content",
      margin: "0 2vW",
    },
  },
  subTitle: {
    fontWeight: 600,
    cursor: "default",
    fontSize: "1rem",
    marginBottom: "2vh",
  },
  text: {
    marginBottom: "1vh",
    fontSize: "0.9rem",
    cursor: "pointer",
  },
  logoContainer: {
    display: "flex",
  },
  logo: {
    marginRight: "1vW",
    cursor: "pointer",
  },
  bottomLine: {
    display: "flex",
    justifyContent: "space-between",
    background: "#1E1925",
    padding: "1vH 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  copyrightYear: {
    fontSize: "0.9rem",
    whiteSpace: "nowrap",
    color: theme.palette.common.lightGrey,
    marginLeft: "4vW",
    cursor: "default",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "max-content",
      margin: "1vw auto",
    },
  },
  bottomRight: {
    display: "flex",
    marginRight: "4vW",
    [theme.breakpoints.down("md")]: {
      width: "max-content",
      margin: "1vw auto",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
      width: "max-content",
      margin: "1vw auto",
    },
  },
  botttomText: {
    fontSize: "0.9rem",
    whiteSpace: "nowrap",
    color: theme.palette.common.lightGrey,
    marginLeft: "4vW",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      margin: "2vw auto",
    },
  },
});

function Footer(theme) {
  const styles = useStyles();
  const { t } = useTranslation();

  let history = useHistory();

  const { setUserContext, user } = useContext(UserContext);

  const handleLogOut = () => {
    setUserContext({
      user: {},
    });
    localStorage.removeItem("xsrfToken");
    history.push(`/login`);
  };

  const handleConnexion = () => {
    history.push(`/login`);
  };

  const d = new Date();
  let year = d.getFullYear();

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <img
          src={LogoJutsu}
          alt="logo"
          className={styles.imgLogo}
          onClick={() => {
            history.push(`/home`);
            window.scrollTo(0, 0);
          }}
        />
        <div className={styles.mapLinkSection}>
          <div className={styles.column}>
            <div className={styles.subTitle}>{t("footer.yourAccount")}</div>
            <div
              className={styles.text}
              onClick={() => {
                history.push(`/account`);
                window.scrollTo(0, 0);
              }}
            >
              {t("footer.parameters")}
            </div>
            {user.user_id ? (
              <div className={styles.text} onClick={handleLogOut}>
                {t("footer.logOut")}
              </div>
            ) : (
              <div className={styles.text} onClick={handleConnexion}>
                {t("footer.connexion")}
              </div>
            )}
          </div>
          <div className={styles.column}>
            <div className={styles.subTitle}>{t("footer.discover")}</div>
            <div
              className={styles.text}
              onClick={() => {
                history.push(`/events`);
                window.scrollTo(0, 0);
              }}
            >
              {t("footer.events")}
            </div>
          </div>
          {/* <div className={styles.column}>
            <div className={styles.subTitle}>Jutsu</div>
            <div className={styles.text}>{t("footer.about")}</div>
          </div> */}
          <div className={styles.column}>
            <div className={styles.subTitle}>{t("footer.follow")}</div>
            <div className={styles.logoContainer}>
              <div
                className={styles.logo}
                onClick={() => {
                  window.open("https://www.instagram.com/jutsuevent", "_blank");
                }}
              >
                <InstaLogo size={20} color={"#d9d9d9"} />
              </div>
              <div
                className={styles.logo}
                onClick={() => {
                  window.open("https://www.facebook.com/jutsuevent", "_blank");
                }}
              >
                <FacebookLogo size={20} color={"#d9d9d9"} fColor={"#1E2325"} />
              </div>
              <div
                className={styles.logo}
                onClick={() => {
                  window.open("https://www.twitter.com/jutsuevent_app", "_blank");
                }}
              >
                <a href="www.twitter.com/jutsuevent_app" target="_blank">
                  <TwitterLogo size={20} color={"#d9d9d9"} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomLine}>
        <div className={styles.copyrightYear}>© {year} Jutsu</div>
        <div className={styles.bottomRight}>
          <div
            className={styles.botttomText}
            onClick={() => {
              history.push(`/cgu`);
              window.scrollTo(0, 0);
            }}
          >
            {t("footer.cgu")}
          </div>
          <div
            className={styles.botttomText}
            onClick={() => {
              history.push(`/confidential`);
              window.scrollTo(0, 0);
            }}
          >
            {t("footer.confidential")}
          </div>
          <div
            className={styles.botttomText}
            onClick={() => {
              history.push(`/help`);
              window.scrollTo(0, 0);
            }}
          >
            {t("footer.help")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
