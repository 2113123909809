

import React from "react";

const Boxing = (props) => {
  let size = props.size || 50;

  return (
    <svg height={size} width={size} viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.26 85.362h37.21v7.668H78.26zM78.256 111.71h37.215v1.932a3.6 3.6 0 0 1-3.6 3.6H81.852a3.6 3.6 0 0 1-3.6-3.6z" fill="#aac3d7" />
    <rect fill="#be281e" height="18.679" rx="3.596" width="45.206" x="74.256" y="93.031" />
    <path d="m123.97 39.82-2.86 37.71a10.794 10.794 0 0 1-10.75 9.97H87.37q-2.955 0-5.64-.12h-.02c-15.51-1.06-23.62-9.1-26.66-17.65-2.93-8.23-1.16-16.94 3.25-20.37h.01a7.105 7.105 0 0 1 2.2-1.18c7.4-2.37 10.32 5.78 10.32 5.78l-1.07-14.14a10.794 10.794 0 0 1 10.75-11.59h32.7a10.787 10.787 0 0 1 10.76 11.59z" fill="#f04b37" />
    <path d="M110.35 60.21v3.56a3.775 3.775 0 0 1-3.78 3.77H88.33a3.775 3.775 0 0 1-3.78-3.77v-3.56a8.388 8.388 0 0 1 8.39-8.39h9.02a8.4 8.4 0 0 1 8.39 8.39z" fill="#fabe19" />
    <g fill="#f5871e">
        <path d="M100.522 59.616h-6.148a1.75 1.75 0 0 1 0-3.5h6.148a1.75 1.75 0 0 1 0 3.5zM100.522 64.011h-6.148a1.75 1.75 0 0 1 0-3.5h6.148a1.75 1.75 0 0 1 0 3.5z" />
    </g>
    <path d="M119.462 96.626v11.488a3.6 3.6 0 0 1-3.6 3.6h-12.149a9.34 9.34 0 1 1 0-18.679h12.153a3.6 3.6 0 0 1 3.596 3.591z" fill="#f04b37" />
    <g fill="#be281e">
        <path d="M89.142 87.5a1.734 1.734 0 0 0-.324-1.033 13.5 13.5 0 0 1-2.2-5.4 1.75 1.75 0 0 0-3.479.387 15.955 15.955 0 0 0 2.177 6.024c.678.01 1.353.021 2.054.021zM110.579 81.455a1.75 1.75 0 1 0-3.479-.387 13.471 13.471 0 0 1-2.2 5.4 1.743 1.743 0 0 0-.325 1.034h3.815a15.977 15.977 0 0 0 2.189-6.047zM98.572 81.261a1.75 1.75 0 0 0-3.5 0V87.5h3.5z" />
    </g>
    <circle cx="101.18" cy="102.37" fill="#fabe19" r="1.75" />
    <g>
        <path d="M16.56 65.05h37.21v11.761H16.56z" fill="#87a4c1" />
        <path d="m69.76 39.82 1.07 14.14s-2.92-8.15-10.32-5.78a7.105 7.105 0 0 0-2.2 1.18h-.01c-4.41 3.43-6.18 12.14-3.25 20.37a43.805 43.805 0 0 1-12.68 1.55H17.4a10.787 10.787 0 0 1-10.22-9.96L4.32 23.61a10.774 10.774 0 0 1 10.75-11.59h32.7a10.774 10.774 0 0 1 10.75 11.59l-1.07 14.13s2.93-8.14 10.32-5.77a7.6 7.6 0 0 1 3.18 2.07 10.655 10.655 0 0 0-1.19 5.78z" fill="#be281e" />
        <g fill="#f04b37">
            <path d="M48.219 65.243a1.75 1.75 0 1 0-3.478-.387 13.487 13.487 0 0 1-2.2 5.4 1.735 1.735 0 0 0-.325 1.026h.155c1.351 0 2.579-.036 3.723-.1a15.838 15.838 0 0 0 2.125-5.939zM36.287 65.049a1.75 1.75 0 1 0-3.5 0v6.231h3.5zM26.459 70.255a13.482 13.482 0 0 1-2.2-5.4 1.75 1.75 0 1 0-3.478.387 15.96 15.96 0 0 0 2.184 6.037h3.819a1.738 1.738 0 0 0-.325-1.024z" />
        </g>
        <path d="M16.559 95.485h37.214v1.932a3.6 3.6 0 0 1-3.6 3.6H20.154a3.6 3.6 0 0 1-3.595-3.6z" fill="#87a4c1" />
        <rect fill="#f04b37" height="18.679" rx="3.596" width="45.206" x="12.558" y="76.805" />
        <path d="M57.764 80.4v11.489a3.6 3.6 0 0 1-3.6 3.6H42.015a9.34 9.34 0 1 1 0-18.68h12.154a3.6 3.6 0 0 1 3.595 3.591z" fill="#be281e" />
        <path d="M59.285 37.874a1.747 1.747 0 0 0-.832-2.106 11.565 11.565 0 0 0-1 1.972l.165-2.177a1.743 1.743 0 0 0-1.64 1.174 41.806 41.806 0 0 0-1.24 4.642H28.711a12.4 12.4 0 0 1-12.036-9.672L15.109 24.6a1.75 1.75 0 0 0-3.418.753l1.566 7.108a15.737 15.737 0 0 0 4.315 7.784 3.9 3.9 0 0 1-2.914 2.985 1.75 1.75 0 0 0 .26 3.481 1.7 1.7 0 0 0 .264-.02 6.836 6.836 0 0 0 5.26-4.188 15.718 15.718 0 0 0 8.269 2.377h25.48c-.044.453-.081.906-.1 1.358a1.751 1.751 0 0 0 1.676 1.822h.074a1.749 1.749 0 0 0 1.747-1.677 35.679 35.679 0 0 1 1.697-8.509z" fill="#f04b37" />
        <circle cx="39.491" cy="86.161" fill="#fabe19" r="1.739" />
    </g>
</svg>
  );
};

export default Boxing;
