/** Import Externals */
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

/** Import Internals */
import osm from "./osm-providers";
import marker from "assets/icons/placeholder.png";

/** Import Context */

/** Import API */

/** Import Utils */

/** Import CSS */
import "leaflet/dist/leaflet.css";
import "./index.css";

const useStyles = makeStyles({
  container: {
    width: "95%",
    marginBottom: "2vh",
  },
});

function SmallMap({ event, link }) {
  const styles = useStyles();

  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const ZOOM_LEVEL = 12;
  const mapRef = useRef();

  useEffect(() => {
    if (event.event_lat) {
      setCenter({ lat: event.event_lat, lng: event.event_lng });
    } else {
      setCenter({ lat: event.club_lat, lng: event.club_lng });
    }
  }, []);

  let markerIcon = L.icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    iconSize: [35, 35],
  });

  return (
    <div className={styles.container}>
      {center.lat !== 0 || center.lng !== 0 ? (
        <MapContainer center={center} zoom={ZOOM_LEVEL} innerRef={mapRef}>
          <TileLayer
            url={osm.maptiler.url}
            attribution={osm.maptiler.attribution}
          />
          <Marker position={center} icon={markerIcon}>
            <Popup>
              <a href={link} target="_blank" rel="noreferrer">
                Show <br /> in Maps.
              </a>
            </Popup>
          </Marker>
        </MapContainer>
      ) : null}
    </div>
  );
}

export default SmallMap;
